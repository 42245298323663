import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Input from 'components/CreateFeed/Input';

import { Form } from './styles';

const ReplyBoxInput = (props) => {
  const {
    onSubmit, submitting, handleSubmit, name, placeholder, maxLength,
  } = props;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} onClick={(e) => e.stopPropagation()}>
      <Field
        component={Input}
        name={name}
        submitting={submitting}
        placeholder={placeholder}
        startAdornment={false}
        maxLength={maxLength}
      />
    </Form>
  );
};

ReplyBoxInput.defaultProps = {
  name: 'description',
  placeholder: 'Write a comment...',
};

ReplyBoxInput.propTypes = {
  maxLength: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

export default reduxForm({
  form: 'ReplyBoxInputForm',
})(ReplyBoxInput);
