import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import * as validates from 'helpers/validates';

import Input from 'components/commons/Input';
import Captcha from 'components/commons/Captcha';

import * as constants from './constants';
import {
  Container, ContentContainer, CustomButton, CustomLink, Form,
  Text, Title,
} from './styles';

const ForgotPassword = (props) => {
  const {
    handleSubmit, invalid, onSubmit,
  } = props;

  return (
    <Container>
      <ContentContainer>
        <Title>
          {constants.TITLE}
        </Title>
        <Text>
          {constants.TEXT}
        </Text>
        <Form onSubmit={handleSubmit(onSubmit)} data-testid="form">
          <Field
            component={Input}
            variant="standard"
            name="email"
            label="Email"
            validate={[validates.required, validates.email]}
            isDarkLabel
          />
          <Field name="captcha" component={Captcha} validate={validates.required} />
          <CustomButton type="submit" variant="contained" disabled={invalid} data-testid="button-send">
            Reset my password
          </CustomButton>
          <CustomLink to="signup">
            Create account
          </CustomLink>
        </Form>
      </ContentContainer>
    </Container>
  );
};

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'ForgotPassword',
})(ForgotPassword);
