import styled from 'styled-components';

import { DIPPER_BLUE } from 'styles/colors';
import { BOOSTRAP_MEDIUM } from 'constants/sizes';

export const Container = styled.div`
  height: 100%;
  overflow: scroll;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;

export const ProfileContainer = styled.div` 
  color: ${DIPPER_BLUE};
  padding: 0 0 0 15px;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    margin: 10px 0;
    padding-right: 15px;
  }
`;
