import styled from 'styled-components';

import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { BOOSTRAP_MEDIUM } from 'constants/sizes';

import {
  DIPPER_BLUE, GRAY_ALTO, GRAY_COMMUNITY, GREEN_REVIEW,
} from 'styles/colors';

import { GreenButton } from 'styles/commons';

export const ClickableContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 15px 0 10px;
  cursor: pointer;
`;

export const ClickableBar = styled.div`
  width: 58px;
  border-radius: 8px;
  border: 3px solid ${DIPPER_BLUE};
  background: ${DIPPER_BLUE};
  justify-self: center;
`;

export const CustomButton = styled(GreenButton)`
  && {
    padding: 8px 30px; 
    margin: 20px 5px 29px 0;
    background: ${({ disabled }) => (disabled ? GRAY_ALTO : GREEN_REVIEW)} !important;
    border-radius: 8px !important;
    font: normal normal bold 16px/20px Mulish;

    @media (max-width: ${BOOSTRAP_MEDIUM}px) {
      width: 106px;
      height: 40;
      margin: 10px auto 0;
    }
  }
`;

export const CustomDiv = styled.div`
  width: 100%;
  margin-right: 10px;
  display: flex;
`;

export const CustomLocationIcon = styled(LocationOnIcon)`
  color: ${GREEN_REVIEW};
  width: 18px !important;
  height: 18px !important;
  margin-right: 0px;
`;

export const CustomSearchIcon = styled(SearchIcon)`
  color: ${GREEN_REVIEW};
  width: 18px !important;
  height: 18px !important;
`;

export const DesktopContainer = styled.div`
  color: ${DIPPER_BLUE};
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: none;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: -20px;
`;

export const LocationFilterDiv = styled.div`
  margin: 0;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    margin-top: -10px;
  }
`;

export const MobileContainer = styled.div`
  color: ${DIPPER_BLUE};
  background: ${GRAY_COMMUNITY};
  margin: 0 -30px;
  padding: 20px 30px 0;
  display: none;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: flex;
    padding: 15px 30px 0;
  }
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;
