import * as constants from 'redux/constants/app.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState = {
  loading: false,
  isMenuOpened: true,
  isPortraitMenuOpened: false,
};

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
  case constants.APP_ON_OPEN_MENU_REQUESTED:
    return {
      ...state,
      isMenuOpened: !state.isMenuOpened,
    };
  case constants.APP_ON_OPEN_PORTRAIT_MENU_REQUESTED:
    return {
      ...state,
      isPortraitMenuOpened: !state.isPortraitMenuOpened,
    };
  case constants.APP_ON_INITIALIZE_REQUESTED:
    return {
      ...state,
      loading: true,
    };
  case userConstants.USER_ON_INITIALIZE_SUCCEEDED:
  case userConstants.USER_ON_INITIALIZE_FAILED:
    return {
      ...state,
      loading: false,
    };
  case userConstants.USER_ON_LOGOUT:
    return defaultState;
  default:
    return state;
  }
};

export default appReducer;
