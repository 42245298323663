import { createSelector } from 'reselect';

import { getQuestion } from 'helpers/insight';
import { convertObjectToArray, findByCondition } from 'helpers/array';
import SIZE_OPTIONS from 'constants/companySize';

import DefaultProfileCompany from 'components/commons/assets/avatar-company.svg';

const getInsighs = (state) => state.insight.items;
const getRanking = (state) => state.insight.ranking;
const getUser = (state) => state.user.data;
const getCompanyCode = (state, props) => props.match.params.code;
const getCompanies = (state) => state.company.items;

export const insightSelector = createSelector([getInsighs, getUser],
  (insights, user) => convertObjectToArray(insights).map(([key, value]) => ({
    ...getQuestion(key, value, user?.role),
  })));

export const rankingSelector = createSelector([getRanking],
  (ranking) => ranking.map(({ company, stars }) => ({
    company: {
      name: company.name,
      image: company.logo?.path || DefaultProfileCompany,
    },
    stars: Math.round(parseFloat(stars)),
  })));

export const companySelector = createSelector([getCompanyCode, getCompanies],
  (code, companies) => {
    const searchCompany = findByCondition(companies, (company) => company.code === code);
    return {
      ...searchCompany,
      image: searchCompany?.logo?.path || DefaultProfileCompany,
      size: SIZE_OPTIONS[searchCompany?.size],
    };
  });
