import React from 'react';
import PropTypes from 'prop-types';
import { NavHashLink } from 'react-router-hash-link';

import { ReactComponent as DipeerIsologo } from './assets/dipper-isologo.svg';

import { PortraitContainer, LandscapeContainer, LogoDipper } from './styles';

const Logo = (props) => {
  const {
    isLandscape, open, whiteLogo, toDashboard,
  } = props;

  const LogoContainer = isLandscape ? LandscapeContainer : PortraitContainer;

  return (
    <LogoContainer open={open} data-testid="logo">
      <NavHashLink smooth to={toDashboard ? '/dashboard' : '/home#top'}>
        {open || !isLandscape
          ? <LogoDipper whiteLogo={whiteLogo} islandscape={+isLandscape} data-testid="LogoDipper" alt="Dipper logo" />
          : <DipeerIsologo src={DipeerIsologo} data-testid="DipperIsologo" alt="Dipper Isologo" />}
      </NavHashLink>
    </LogoContainer>
  );
};

Logo.defaultProps = {
  whiteLogo: false,
  isLandscape: false,
  toDashboard: false,
};

Logo.propTypes = {
  open: PropTypes.bool.isRequired,
  isLandscape: PropTypes.bool,
  toDashboard: PropTypes.bool,
  whiteLogo: PropTypes.bool,
};

export default Logo;
