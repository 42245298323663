import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { feedPropTypes } from 'helpers/propTypes';
import { timePassedSince } from 'helpers/dates';

import Comment from 'components/Comment';
import ItemsList from 'components/commons/ItemsList';
import ReplyBoxInput from 'components/commons/ReplyBoxInput';

import { ReactComponent as ReplyIcon } from 'components/commons/assets/ReplyIcon.svg';
import { ReactComponent as CommentIcon } from 'components/commons/assets/CommentIcon.svg';
import Metadata from 'components/commons/Metadata';

import { getLinksFromText, regexSpan } from 'helpers/string';
import checkUrl from 'helpers/links';
import { COMMUNITY_COMMENTS } from 'constants/inputSizes';

import {
  DetailsContainer, DetailsSection, FeedSection, FeedTitle, ProfilePicture, FeedText,
  FeedTime, Low, DivReplyIcons, CommentNumber, CommentLogo, ReplyLogo,
  CommentSection, FeedLink, DetailsDescription,
} from './styles';

const Highlight = ({ children }) => {
  const url = checkUrl(children);

  return (
    <FeedLink target="_blank" rel="noopener noreferrer" href={url}>{children}</FeedLink>
  );
};

const FeedDetails = (props) => {
  const { feed, onSubmitAddComment } = props;

  const [replyDetailsInput, setReplyDetailsInput] = useState(false);

  const onSubmit = (formData, feedId) => {
    setReplyDetailsInput(false);
    if (!formData.description) {
      return;
    }
    onSubmitAddComment({ ...formData, feed: feedId });
  };

  const renderComment = (comment) => (
    <Comment key={comment.id} comment={comment} />
  );

  const renderReplySection = (feedId) => (
    <ReplyBoxInput
      data-testid="reply-box"
      onSubmit={(formData) => onSubmit(formData, feedId)}
      maxLength={COMMUNITY_COMMENTS}
    />
  );

  const regexForURL = getLinksFromText(feed.description);
  /* eslint-disable-next-line */
  for (let i = 0; i < regexForURL.length; i++) {
    regexForURL[i] = regexForURL[i].replaceAll(' ', '');
    regexForURL[i] = regexForURL[i].replace(/<\/span>/g, '');
  }
  let description = feed.description.replace(regexSpan, '').replace(/&amp;/g, '&');
  description = description.replace(/<br>/g, '\n');

  return (
    <DetailsContainer className="row" data-testid="details-container">
      <DetailsSection>
        <FeedSection>
          <FeedTitle>
            <ProfilePicture
              src={feed.profilePic}
            />
            <FeedText>
              {feed.author}
              <FeedTime>{timePassedSince(feed.dateCreated)}</FeedTime>
            </FeedText>
          </FeedTitle>
          <DetailsDescription
            highlightTag={Highlight}
            searchWords={regexForURL}
            autoEscape
            textToHighlight={description}
          />
          {!!feed.metaLink
            && (
              <Metadata
                description={feed.metaLink.description}
                title={feed.metaLink.title}
                url={feed.metaLink.url}
                image={feed.metaLink.image}
                isBigger
              />
            )}
          <Low>
            {!replyDetailsInput
              ? (
                <DivReplyIcons>
                  <CommentNumber>{feed.comments?.length || 0}</CommentNumber>
                  <CommentLogo>
                    <CommentIcon />
                  </CommentLogo>
                  <ReplyLogo onClick={() => { setReplyDetailsInput(true); }} data-testid="reply-logo">
                    <ReplyIcon />
                  </ReplyLogo>
                </DivReplyIcons>
              )
              : renderReplySection(feed.id)}
          </Low>
        </FeedSection>
        <CommentSection>
          <h1>Latest comments</h1>
          <ItemsList
            emptyItemsText="There are no comments yet"
            items={feed.comments}
            renderItem={renderComment}
          />
        </CommentSection>
      </DetailsSection>
    </DetailsContainer>
  );
};

Highlight.propTypes = {
  children: PropTypes.node.isRequired,
};

FeedDetails.propTypes = {
  feed: feedPropTypes.isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
};

export default FeedDetails;
