import PropTypes from 'prop-types';

export const companyPropTypes = PropTypes.shape({
  code: PropTypes.string.isRequired,
  dateCreated: PropTypes.string.isRequied,
  dateDeleted: PropTypes.string,
  dateUpdated: PropTypes.string,
  deiCommitments: PropTypes.string,
  description: PropTypes.string,
  facebook: PropTypes.string,
  founded: PropTypes.string,
  id: PropTypes.number.isRequied,
  industry: PropTypes.string,
  instagram: PropTypes.string,
  isPremium: PropTypes.bool.isRequied,
  linkedIn: PropTypes.string,
  location: PropTypes.string,
  logo: PropTypes.shape({
    path: PropTypes.string,
  }),
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  website: PropTypes.string,
});

export const userPropTypes = PropTypes.shape({
  amountOfComments: PropTypes.number.isRequired,
  amountOfFeeds: PropTypes.number.isRequired,
  amountOfReviews: PropTypes.number.isRequired,
  company: companyPropTypes,
  firstName: PropTypes.string.isRequired,
  jobPosition: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  name: PropTypes.string,
  profileImage: PropTypes.string,
  profilePic: PropTypes.shape({
    path: PropTypes.string,
  }),
  role: PropTypes.number.isRequired,
});

export const commentPropTypes = PropTypes.shape({
  description: PropTypes.string.isRequired,
  feed: PropTypes.number,
  id: PropTypes.number.isRequired,
  user: userPropTypes.isRequired,
});

export const commentsArrayPropTypes = PropTypes.arrayOf(commentPropTypes);

export const feedPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  user: userPropTypes.isRequired,
  comments: commentsArrayPropTypes,
});

export const feedsArrayPropTypes = PropTypes.arrayOf(feedPropTypes);

export const insightPropTypes = PropTypes.shape({
  labels: PropTypes.arrayOf(PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
});

export const insightsArrayPropTypes = PropTypes.arrayOf(insightPropTypes);

export const rankPropTypes = PropTypes.shape({
  rank: PropTypes.number.isRequired,
  recommendedPercentage: PropTypes.number.isRequired,
  stars: PropTypes.number.isRequired,
});

export const companySizeOptionsPropTypes = PropTypes.arrayOf(PropTypes.shape({
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}));

export const reviewProptypes = PropTypes.shape({
  jobTitle: PropTypes.string.isRequired,
  anonymous: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  company: companyPropTypes.isRequired,
  companyAnswer: PropTypes.string,
  dateCreated: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  user: userPropTypes.isRequired,
});

export const jobProptypes = PropTypes.shape({
  contactEmail: PropTypes.string.isRequired,
  company: companyPropTypes.isRequired,
  dateCreated: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  employeeType: PropTypes.number.isRequired,
  experience: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isFeatured: PropTypes.bool.isRequired,
  jobPosition: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  numberOfApplicants: PropTypes.number.isRequired,
  overview: PropTypes.string.isRequired,
});

export const jobsArrayPropTypes = PropTypes.arrayOf(jobProptypes);
