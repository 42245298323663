import React from 'react';
import PropTypes from 'prop-types';

import MenuItems from 'components/SideMenu/MenuItems';

import {
  OpenMenu, DivContainer, MenuIconMenu, CloseRoundedIconMenu,
} from './styles';

const Menu = (props) => {
  const {
    links, loading, UlStyledComponent, user, isLandscape,
    onLogoutAction, open, onClickPortrait, openLanding,
  } = props;

  return (
    <DivContainer data-testid="navbar-menu">
      <OpenMenu className="fill-height" open={open}>
        {!isLandscape && (!openLanding
          ? <MenuIconMenu onClick={onClickPortrait} />
          : <CloseRoundedIconMenu onClick={onClickPortrait} />)}
      </OpenMenu>
      <MenuItems
        onClickPortrait={onClickPortrait}
        open={open}
        openLanding={openLanding}
        links={links}
        loading={loading}
        onLogoutAction={onLogoutAction}
        user={user}
        isLandscape={isLandscape}
        UlStyledComponent={UlStyledComponent}
      />
    </DivContainer>
  );
};

Menu.defaultProps = {
  user: null,
  onClickPortrait: () => {},
};

Menu.propTypes = {
  UlStyledComponent: PropTypes.elementType.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  open: PropTypes.bool.isRequired,
  openLanding: PropTypes.bool.isRequired,
  onLogoutAction: PropTypes.func.isRequired,
  onClickPortrait: PropTypes.func,
  isLandscape: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Menu;
