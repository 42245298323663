import React from 'react';

import {
  Container, RightTopText, RightBottomText,
} from './styles';

const GuidelinesText = () => (
  <Container>
    <RightTopText>
      <h4>Thank You For Your Submission, We Value Your Experience!</h4>
      <p>
        Your unique perspective on each employer in your work history is incredibly
        valued by the Dipper community, so please feel free to leave multiple reviews.
      </p>
      <p>
        Your candor is truly appreciated. If you prefer to remain anonymous,
        you have the option to do so.
      </p>
      <p>Please be mindful of the well being of your community.</p>
    </RightTopText>
    <RightBottomText>
      <p> Speak Your Truth but be Mindful of:</p>
      <li>Aggressive or discriminatory language</li>
      <li>Profane language</li>
      <li>Trade secrets/ confidential information</li>
      <br />
      <br />
    </RightBottomText>
  </Container>
);

export default GuidelinesText;
