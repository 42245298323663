import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';
import { ReactComponent as ArrowSvg } from 'components/commons/assets/arrow.svg';

import {
  DIPPER_BLUE, GRAY_COMMUNITY, GRAY_REVIEW_DATE, GREEN_REVIEW, LIGHT_GREEN, WHITE,
} from 'styles/colors';

import { MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';

import { GreenButton } from 'styles/commons';

export const Arrow = styled(ArrowSvg)`
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  align-self: center;
  margin-bottom: -2px;
`;

export const CardBox = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  border-top: 1px solid ${GRAY_COMMUNITY};
`;

export const CheckboxDiv = styled.div`
  display: flex;
  padding: 10px 0; 

  .MuiFormControlLabel-label {
    color: ${DIPPER_BLUE};
    font: 12px/16px Mulish;
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    padding: 10px 0; 
    align-self: flex-start;
  }
`;

export const CustomButton = styled(GreenButton)`
  && {
    text-transform: capitalize;
    padding: 8px 30px; 
    ${({ disabled }) => (disabled
    ? css`
        color: ${WHITE} !important;
        background: ${LIGHT_GREEN} !important;
        opacity: 0.5 !important;
      `
    : css`
        background: ${GREEN_REVIEW} !important;
      `
  )}
  }
`;

export const CustomLink = styled(Link)`
  display: flex;
  width: 200px;
`;

export const CreateJobContainer = styled.div`
  padding: 0 0 70px;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    padding: 0;
  }
`;

export const DivCenterButton = styled.div`
  text-align: left;
  margin-top: 42px;

  @media (max-width: ${TABLET_SIZE}px) {
    text-align: center;
  }
`;

export const DivDatePicker = styled.div`
  @media (max-width: ${MOBILE_SIZE}px) {
    margin-top: 20px;
  }
`;

export const DivForDropDown = styled.div`
  display: flex;
  width: 100%;
  padding-top: 23px;
`;

export const Form = styled.form`
  width: 100%;
`;

export const InputSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  @media (max-width: ${TABLET_SIZE}px) {
    flex-direction: column
  }
`;

export const LeftInputSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-right: 1px solid ${GRAY_COMMUNITY};
  padding-right: 71px;
  margin-top: 8px;

  @media (max-width: ${TABLET_SIZE}px) {
    padding: 0;
    border-right: none;
  }
`;

export const RightInputSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 66px;
  margin-top: 8px;

  @media (max-width: ${TABLET_SIZE}px) {
    padding: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
  }
`;

export const SubTitle = styled.h1`
  color: ${DIPPER_BLUE};
  text-align: left;
  margin: 20px 0 0;
  font: normal normal bold 14px/20px Mulish;

  @media (max-width: ${TABLET_SIZE}px) {
    margin: 40px 0 0;
  }
`;

export const SalaryFromContainer = styled.div`
  display: flex;
  flex: 1;
  padding-right: 5px;

  @media (max-width: ${MOBILE_SIZE}px) {
    padding: 0;
    width: 100%;
  }
`;

export const SalaryToContainer = styled.div`
  display: flex;
  flex: 1;
  padding-left: 5px;

  @media (max-width: ${MOBILE_SIZE}px) {
    padding: 0;
    width: 100%;
  }
`;

export const SalarySubtitle = styled.div`
color: ${DIPPER_BLUE};
  text-align: left;
  font: normal normal bold 14px/20px Mulish;
  margin-top: 10px;
  align-self: center;

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  color: ${DIPPER_BLUE};
  text-align: left;
  margin: 10px 0;
  font: normal normal bold 20px/25px Mulish;

  @media (max-width: ${TABLET_SIZE}px) {
    margin-left: 0;
  }
`;

export const Text = styled.div`
  margin-top: 10px;
  font: normal normal normal 12px/20px Mulish;
  color: ${GRAY_REVIEW_DATE};
`;
