import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

import { HelpBlock } from 'styles/commons';
import { BLUE_DIPPER } from 'styles/colors';

import { CustomSelect, CustomOption, FormGroup } from './styles';

const Dropdown = (props) => {
  const {
    input, isItalic, options, label, meta: { touched, error }, variant,
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      '&::placeholder': {
        textAlign: 'center',
        fontFamily: isItalic ? 'Mulish-Italic' : 'Mulish',
        fontSize: '14px',
      },
      color: BLUE_DIPPER,
      fontFamily: isItalic ? 'Mulish-Italic' : 'Mulish',
    },
  }));
  const classes = useStyles();

  return (
    <FormGroup variant={variant} size="small">
      <InputLabel>{label}</InputLabel>
      <CustomSelect
        {...input}
        label={label}
        data-testid="custom-dropdown"
        size="small"
        isItalic={isItalic}
        native
        inputProps={{
          classes: { root: classes.root },
        }}
      >
        {options.map(({ value, text }) => (
          <CustomOption key={value} value={value}>{text}</CustomOption>
        ))}

      </CustomSelect>
      {touched && (error && <HelpBlock data-testid="dropdown-help-block">{error}</HelpBlock>)}
    </FormGroup>
  );
};

Dropdown.defaultProps = {
  isItalic: false,
  variant: 'outlined',
};

Dropdown.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  isItalic: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  variant: PropTypes.string,
};

export default Dropdown;
