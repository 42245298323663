import styled from 'styled-components';

import { WHITE, BLUE_DIPPER } from 'styles/colors';

export const ChartsContainers = styled.div`
  display: flex;
  flex: 1;
  background-color: ${WHITE};
  padding: 15px 20px 24px;
  border-radius: 6px;
  flex-direction: column;
`;

export const TitleDiv = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: ${BLUE_DIPPER};
  margin-bottom: 10px;
`;

export const PieChartContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-end')};
  justify-content: center;
`;
