import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';

import { GRAY_BACKGROUND } from 'styles/colors';

import {
  PieChartContainer, TitleList, CustomLabel, Legend, EmptyLabel, Container,
} from './styles';

const PieChartComponent = ({ labels, colors, total }) => {
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent,
  }) => {
    if (!!total && (percent * 100).toFixed() > 0) {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <CustomLabel x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </CustomLabel>
      );
    }
    return '';
  };

  return (
    <PieChartContainer>
      <TitleList>
        {
          labels && labels.map((entry, index) => (
            <Legend showBullet={!!total} bulletColor={colors[index % colors.length]}>
              {entry.label}
              {' '}
            </Legend>
          ))
        }
      </TitleList>
      <Container>
        {!total && <EmptyLabel>0%</EmptyLabel>}
        <PieChart width={176} height={170} padding={0}>
          <Pie
            data={labels}
            innerRadius={45}
            outerRadius={83}
            paddingAngle={0}
            dataKey="value"
            labelLine={false}
            isAnimationActive={false}
            label={renderCustomizedLabel}
          >
            {
              !!labels && labels.map((entry, index) => <Cell key={`cell-${entry.label}`} fill={total ? colors[index % colors.length] : GRAY_BACKGROUND} />)
            }
          </Pie>
        </PieChart>
      </Container>
    </PieChartContainer>
  );
};

PieChartComponent.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  total: PropTypes.number.isRequired,
};

export default PieChartComponent;
