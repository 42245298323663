import styled, { css } from 'styled-components';

import { DIPPER_BLUE, WHITE } from 'styles/colors';

export const FullScreen = styled.div`
  height: 90vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 37px;
`;

export const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${WHITE};
  align-items: center;
  width: 100%;
  max-width: 488px;
  padding: 25px 0 14px 0;
  border-radius: 6px;
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 488px;
  margin-top: 32px;
`;

export const Title = styled.h1`
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: ${DIPPER_BLUE};

  ${({ isSmaller }) => (
    isSmaller
      && css`
        font-size: 14px;
        align-self: flex-start;
    `
  )};
`;

export const SubTitle = styled.h1`
  display: flex;
  font-size: 12px;
  color: ${DIPPER_BLUE};
`;

export const CustomList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const CustomItemsList = styled.li`
  font-size: 14px;
  line-height: 20px;
  color: ${DIPPER_BLUE};
  margin-top: 14px;
  list-style: none;

  ::before {
    content: "•";
    color: ${DIPPER_BLUE};
    margin-right: 7px;
    font-size: 14px;
  }
`;
