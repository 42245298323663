import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { GreenButton } from 'styles/commons';
import {
  BLUE_DARK, BLUE_DIPPER, GREEN_REVIEW,
} from 'styles/colors';

export const Container = styled.div`
  width: 90%;
  margin: 16px auto;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.p`
  font: normal normal bold 32px/26px Mulish;
  padding-bottom: 30px;
  color: ${BLUE_DARK};
`;

export const Text = styled.p`
  font: 18px/26px Mulish;
  color: ${BLUE_DIPPER};
`;

export const Form = styled.form`
  width: 100%;
  font-size: 18px;
`;

export const CustomButton = styled(GreenButton)`
  && {
    font: normal normal bold 14px/18px Mulish;
    padding: 12px 23px;
    margin: 15px 0 30px;
  }
`;

export const CustomLink = styled(Link)`
  font-size: 13px;
  color: ${GREEN_REVIEW};
  cursor: pointer;
  display: block;

  :hover {
    color: ${BLUE_DIPPER};
  }
`;
