import styled from 'styled-components';

import { BOOSTRAP_MEDIUM } from 'constants/sizes';
import {
  WHITE, DIPPER_BLUE, BACKGROUND_COLOR,
} from 'styles/colors';

export const DivInsights = styled.div` 
  color: ${DIPPER_BLUE};
  padding: 0 15px 0 0;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    margin-bottom: 10px;
    padding-left: 15px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  
  h1 {
    color: ${DIPPER_BLUE};
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }
`;

export const InsightsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 6px;
  background: ${WHITE};
`;

export const Insight = styled.div`
  display: flex;
  flex: 1;
`;

export const Line = styled.div`
  margin: 0 20px;
  border-bottom: 1px solid ${BACKGROUND_COLOR};
`;
