import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { MOBILE_SIZE } from 'constants/sizes';
import Arrow from 'components/Quotes/components/Arrow/Arrow';

import { quotes } from './constants/quotes';

import {
  Container, QuoteImage, SliderContainer, TextContainer, Name, JobTitle,
  DescriptionContainer, OpenQuotesImage, DescriptionText, ClosingQuotesImage,
} from './styles';

const Quotes = (props) => {
  const { autoPlay } = props;
  const [activeSlide, setActiveSlide] = useState(0);

  const refSlider = useRef(null);

  useEffect(() => (
    autoPlay ? refSlider?.current?.slickPlay() : refSlider?.current?.slickPause()
  ), [autoPlay]);

  const settings = {
    dots: true,
    draggable: true,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: '34%',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setActiveSlide(next),
    nextArrow: <Arrow />,
    prevArrow: <Arrow reverse />,
    responsive: [
      {
        breakpoint: MOBILE_SIZE,
        settings: {
          centerMode: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          dots: false,
          centerMode: false,
        },
      },

    ],
  };

  return (
    <Container>
      <SliderContainer>
        <Slider {...settings} ref={refSlider}>
          {quotes.map((quote, index) => (
            <QuoteImage
              src={quote.image}
              active={index === (activeSlide)}
              key={quote.id}
            />
          ))}
        </Slider>
      </SliderContainer>
      <TextContainer>
        <Name>
          {quotes[activeSlide].name}
        </Name>
        <JobTitle>
          {quotes[activeSlide].jobTitle}
        </JobTitle>
        <DescriptionContainer>
          <OpenQuotesImage />
          <DescriptionText>
            {quotes[activeSlide].description}
          </DescriptionText>
          <ClosingQuotesImage />
        </DescriptionContainer>
      </TextContainer>
    </Container>
  );
};

Quotes.propTypes = {
  autoPlay: PropTypes.bool.isRequired,
};

export default Quotes;
