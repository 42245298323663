import styled, { css } from 'styled-components';

import { ReactComponent as FacebookIcon } from 'components/commons/assets/FacebookIcon.svg';
import { ReactComponent as LinkedInIcon } from 'components/commons/assets/LinkedinIcon.svg';
import { ReactComponent as InstagramIcon } from 'components/commons/assets/InstagramIcon.svg';
import { ReactComponent as InfoIcon } from 'components/commons/assets/InfoIcon.svg';

import { TABLET_SIZE } from 'constants/sizes';
import {
  GRAY_COMMUNITY_LINES, GREEN_DECO, GREEN_REVIEW, GREEN_SELECTED_NAVBAR, WHITE,
} from 'styles/colors';

export const FooterContainer = styled.div`
  margin-bottom: 25px;
  
  @media (max-width: ${TABLET_SIZE}px) {
    display: none;
  }
`;

export const MoreInfoDiv = styled.div`
  width: 80%;
  margin-left: 10%;
  border-bottom: 1px solid black;
  flex: 1;

  .MuiSvgIcon-root {
    color: ${GREEN_SELECTED_NAVBAR};
  }

  ${({ open }) => (
    !open && css`
      padding-left: 13px;     
    `
  )};
`;

export const SocialMedia = styled.div`
  padding: 10px 0px 0px 35px;
    
  ${({ open }) => (
    !open && css`
      display: flex;
      flex-direction:${({ flexRow }) => (flexRow ? 'row' : 'column')};    
    `
  )};
`;

export const Icons = styled.div`
  padding-right: 5px;
`;

export const Info = styled(InfoIcon)`
  width: 20px;
  height: 20px;
  fill: ${GREEN_REVIEW} !important;
  
  :hover {
    fill: ${GREEN_DECO} !important;
  }
`;

export const Facebook = styled(FacebookIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 16px;
  height: 16px;

  :hover {
    fill: ${WHITE};
  }
`;

export const Instagram = styled(InstagramIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 16px;
  height: 16px;

  :hover {
    fill: ${WHITE};
  }
`;

export const LinkedIn = styled(LinkedInIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 16px;
  height: 16px;

  :hover {
    fill: ${WHITE};
  }
`;
