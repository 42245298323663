import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { DIPPER_BLUE, WHITE } from 'styles/colors';
import { MOBILE_SIZE } from 'constants/sizes';
import { GreenButton } from 'styles/commons';

export const ImageCoitainer = styled.div`
  margin: 100px auto 92px;
  width: 404px;

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 250px
  }
`;

export const TextContainer = styled.div`
  text-align: center;
  font: normal normal bold 20px/30px Mulish;
  letter-spacing: 0px;
  width: 100%;
  margin: 0 auto;
  color: ${DIPPER_BLUE};
`;

export const ButtonContainer = styled.div`
  margin: 20px 0 60px;
  display: flex;
  justify-content: center;
`;

export const StyledGreenButton = styled(GreenButton)`
  margin: 0 auto;
`;

export const CustomLink = styled(Link)`
  margin: 14px 64px;
  text-align: center;
  font: normal normal bold 14px/17px Mulish;
  color: ${WHITE};
  cursor: pointer;
  display: block;

  :hover {
    color: ${WHITE};
  }
`;
