import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import { TABLET_SIZE } from 'constants/sizes';
import { GRAY_ALTO } from 'styles/colors';

const CheckBoxStyled = styled(Checkbox)`
  && {
    color: ${GRAY_ALTO};

    @media (max-width: ${TABLET_SIZE}px) {
      padding: 0 10px 0 0;
    }
  }
`;

export default CheckBoxStyled;
