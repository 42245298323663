import * as React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  required,
  RichTextField,
  DateField,
  DateInput,
  FunctionField,
  BooleanInput,
} from 'react-admin';
import { validateMaxSize, validateRequired } from 'admin/helpers/formValidation';
import { parseOptionValues } from 'admin/helpers/selectInput';
import {
  labels, companyDiversity, subjectSituation, reviewStatus,
} from 'constants/labels';

import { AdminFormContainer } from 'styles/commons';
import {
  REVIEW_COMPANY_ANSWER, REVIEW_DESCRIPTION, REVIEW_JOB_TITLE, REVIEW_TITLE,
} from 'constants/inputSizes';

const subjectSituationOptions = () => parseOptionValues(subjectSituation);
const agreementOptions = () => parseOptionValues(labels);
const companyDiversityOptions = () => parseOptionValues(companyDiversity);
const reviewStatusOptions = () => parseOptionValues(reviewStatus);

const reviewStatusDesc = (record) => reviewStatus[record.status];

const ReviewFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by description" source="description" alwaysOn />
    <TextInput label="Search by Company Name" source="company.name" alwaysOn />
    <TextInput label="Search by Email" source="user.email" alwaysOn />
  </Filter>
);

export const ReviewList = (props) => (
  <List filters={<ReviewFilter />} sort={{ field: 'dateUpdated', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField label="Last Update" source="dateUpdated" />
      <TextField source="title" />
      <RichTextField source="description" />
      <ReferenceField label="Company" source="company.id" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="User" source="user.id" reference="users">
        <TextField source="firstName" />
      </ReferenceField>
      <FunctionField label="Status" render={reviewStatusDesc} />
      <EditButton />
    </Datagrid>
  </List>
);

const ReviewTitle = ({ record }) => (
  <span>
    {record ? `${record.title}` : ''}
  </span>
);

const ReviewForm = ({ isEditForm }) => (
  <AdminFormContainer>
    {isEditForm && <TextInput disabled source="id" validate={required()} />}
    <SelectInput
      source="status"
      validate={required()}
      choices={reviewStatusOptions()}
      optionText="value"
      optionValue="id"
    />
    <ReferenceInput label="User" source="user.id" perPage={1000} reference="users">
      <SelectInput optionText="firstName" validate={required()} />
    </ReferenceInput>
    <ReferenceInput label="Company reviewed" perPage={1000} source="company.id" reference="companies">
      <SelectInput optionText="name" validate={required()} />
    </ReferenceInput>
    <TextInput
      label="Job position"
      source="jobTitle"
      inputProps={{ maxLength: REVIEW_JOB_TITLE }}
      validate={[required(), validateRequired, (field) => validateMaxSize(REVIEW_JOB_TITLE, field)]}
    />
    <DateInput type="month" label="Starting date" source="startDate" validate={[required(), validateRequired]} />
    <DateInput type="month" source="endDate" />
    <SelectInput
      source="companyIsGreatPlaceToWork"
      label="Is company a great place to work?"
      validate={required()}
      choices={agreementOptions()}
      optionText="value"
      optionValue="id"
    />
    <SelectInput
      source="companyTreatment"
      label="Are you valued at work?"
      validate={required()}
      choices={agreementOptions()}
      optionText="value"
      optionValue="id"
    />
    <SelectInput
      source="companyDiversity"
      label="Company diversity and inclusion"
      validate={required()}
      choices={companyDiversityOptions()}
      optionText="value"
      optionValue="id"
    />
    <BooleanInput label="Would you recommend this company?" source="recommendCompany" />
    <SelectInput
      source="personalSituation"
      validate={required()}
      choices={subjectSituationOptions()}
      optionText="value"
      optionValue="id"
    />
    <BooleanInput label="Would you like to receive feedback?" source="feedback" />
    <TextInput
      label="Review title"
      source="title"
      inputProps={{ maxLength: REVIEW_TITLE }}
      validate={[required(), validateRequired, (field) => validateMaxSize(REVIEW_TITLE, field)]}
    />
    <TextInput
      multiline
      source="description"
      inputProps={{ maxLength: REVIEW_DESCRIPTION }}
      validate={[required(), validateRequired,
        (field) => validateMaxSize(REVIEW_DESCRIPTION, field)]}
    />
    <BooleanInput label="Remain anonymous?" source="anonymous" />
    <TextInput
      label="Company reply"
      source="companyAnswer"
      inputProps={{ maxLength: REVIEW_COMPANY_ANSWER }}
      validate={[(field) => validateMaxSize(REVIEW_COMPANY_ANSWER, field)]}
    />
  </AdminFormContainer>
);

export const ReviewEdit = (props) => (
  <Edit title={<ReviewTitle />} {...props}>
    <SimpleForm>
      <ReviewForm isEditForm />
    </SimpleForm>
  </Edit>
);

export const ReviewCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReviewForm isEditForm={false} />
    </SimpleForm>
  </Create>
);

ReviewForm.propTypes = {
  isEditForm: PropTypes.bool.isRequired,
};

ReviewTitle.defaultProps = {
  record: null,
};

ReviewTitle.propTypes = {
  record: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
};
