import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { MOBILE_SIZE, SMALL_DESKTOP_SIZE, TABLET_SIZE } from 'constants/sizes';
import {
  BLUE_DARK, DIPPER_BLUE, GREEN_REVIEW, WHITE,
} from 'styles/colors';

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  padding-right: 30px;
  height: 100%;

  @media (max-width: ${SMALL_DESKTOP_SIZE}px) {
    flex-direction: column;
    height: auto;
    margin-left: 0;
    padding: 0 0 30px 0;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    padding: 0 0 30px;
    margin-left: 0px;
  }
`;

export const Title = styled.div`
  padding-bottom: 10px;
  font: normal normal bold 22px/25px Mulish;
  color: ${DIPPER_BLUE};
`;

export const EmptyReview = styled.div`
  border-radius: 10px;
  margin-bottom: 30px;
  flex: 1;
  background: ${WHITE};
  justify-content: center;
`;

export const Description = styled.p`
  display: flex;
  justify-content: center;
  padding: 57px 0 20px;
  align-items: center;
  font: 20px/25px Mulish;
`;

export const ButtonContainer = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomButton = styled(Link)`
  font-size: 15px;
  text-transform: capitalize;
  width: 151px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${GREEN_REVIEW};
  border-radius: 4px;
  opacity: 1;
  color: ${WHITE} !important;

  :hover {
    background: ${BLUE_DARK};
  }
`;

export const ImageContainer = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;

  @media (max-width: ${TABLET_SIZE}px) {
   padding: 50px 0;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
   padding: 30px 0;
  }
`;

export const CustomImage = styled.img`
  display: flex;
  justify-content: center;
  width: 458px;
  height: 384px;

  @media (max-width: ${TABLET_SIZE}px) {
    height: 200px;
    width: auto;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    height: 140px;
    width: auto;
  }
`;

export const Container = styled.div`
  padding: 0;
  width: 35%;

  @media (max-width: ${SMALL_DESKTOP_SIZE}px) {
    width: 100%;
  }
`;

export const ReviewEmptyContainer = styled.div`
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;
