export const USER_ON_INITIALIZE_REQUESTED = 'USER_ON_INITIALIZE_REQUESTED';
export const USER_ON_INITIALIZE_SUCCEEDED = 'USER_ON_INITIALIZE_SUCCEEDED';
export const USER_ON_INITIALIZE_FAILED = 'USER_ON_INITIALIZE_FAILED';

export const USER_ON_GOOGLE_LOGIN_REQUESTED = 'USER_ON_GOOGLE_LOGIN_REQUESTED';

export const USER_ON_LOGIN_REQUESTED = 'USER_ON_LOGIN_REQUESTED';
export const USER_ON_LOGIN_SUCCEEDED = 'USER_ON_LOGIN_SUCCEEDED';
export const USER_ON_LOGIN_FAILED = 'USER_ON_LOGIN_FAILED';

export const USER_ON_LOGOUT = 'USER_ON_LOGOUT';

export const USER_ON_GOOGLE_SIGNUP_REQUESTED = 'USER_ON_GOOGLE_SIGNUP_REQUESTED';

export const USER_ON_REGISTER_REQUESTED = 'USER_ON_REGISTER_REQUESTED';
export const USER_ON_REGISTER_SUCCEEDED = 'USER_ON_REGISTER_SUCCEEDED';
export const USER_ON_REGISTER_FAILED = 'USER_ON_REGISTER_FAILED';

export const USER_ON_RESET_PASSWORD_REQUESTED = 'USER_ON_RESET_PASSWORD_REQUESTED';
export const USER_ON_RESET_PASSWORD_SUCCEEDED = 'USER_ON_RESET_PASSWORD_SUCCEEDED';
export const USER_ON_RESET_PASSWORD_FAILED = 'USER_ON_RESET_PASSWORD_FAILED';

export const USER_ON_UPDATE_REQUESTED = 'PROFILE_ON_UPDATE_REQUESTED';
export const USER_ON_UPDATE_SUCCEEDED = 'PROFILE_ON_UPDATE_SUCCEEDED';
export const USER_ON_UPDATE_FAILED = 'PROFILE_ON_UPDATE_FAILED';

export const USER_ON_ACTIVATE_ACCOUNT_REQUESTED = 'USER_ON_ACTIVATE_ACCOUNT_REQUESTED';
export const USER_ON_ACTIVATE_ACCOUNT_SUCCEEDED = 'USER_ON_ACTIVATE_ACCOUNT_SUCCEEDED';
export const USER_ON_ACTIVATE_ACCOUNT_FAILED = 'USER_ON_ACTIVATE_ACCOUNT_FAILED';

export const USER_ON_FORGOT_PASSWORD_REQUESTED = 'USER_ON_FORGOT_PASSWORD_REQUESTED';
export const USER_ON_FORGOT_PASSWORD_SUCCEEDED = 'USER_ON_FORGOT_PASSWORD_SUCCEEDED';
export const USER_ON_FORGOT_PASSWORD_FAILED = 'USER_ON_FORGOT_PASSWORD_FAILED';

export const USER_ON_CONTACT_REQUESTED = 'USER_ON_CONTACT_REQUESTED';
export const USER_ON_CONTACT_SUCCEEDED = 'USER_ON_CONTACT_SUCCEEDED';
export const USER_ON_CONTACT_FAILED = 'USER_ON_CONTACT_FAILED';

export const USER_ON_UPDATE_PASSWORD_REQUESTED = 'USER_ON_UPDATE_PASSWORD_REQUESTED';
export const USER_ON_UPDATE_PASSWORD_SUCCEEDED = 'USER_ON_UPDATE_PASSWORD_SUCCEEDED';
export const USER_ON_UPDATE_PASSWORD_FAILED = 'USER_ON_UPDATE_PASSWORD_FAILED';

export const USER_ON_CLOSE_ONBOARDING_REQUESTED = 'USER_ON_CLOSE_ONBOARDING_REQUESTED';
export const USER_ON_CLOSE_ONBOARDING_SUCCEEDED = 'USER_ON_CLOSE_ONBOARDING_SUCCEEDED';
export const USER_ON_CLOSE_ONBOARDING_FAILED = 'USER_ON_CLOSE_ONBOARDING_FAILED';

export const USER_ON_LOAD_PROFILE_IMAGE_FAILED = 'USER_ON_LOAD_PROFILE_IMAGE_FAILED';
