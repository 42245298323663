import * as constants from 'redux/constants/insight.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState = {
  items: [],
  ranking: [],
};

const insightReducer = (state = defaultState, action) => {
  const { data } = action;
  switch (action.type) {
  case constants.INSIGHT_ON_GET_ALL_SUCCEEDED:
    return {
      ...state,
      items: data,
    };
  case constants.INSIGHT_ON_GET_RANKING_SUCCEEDED:
    return {
      ...state,
      ranking: data.ranking,
    };
  case userConstants.USER_ON_LOGOUT:
    return defaultState;
  case constants.INSIGHT_ON_GET_ALL_FAILED:
  case constants.INSIGHT_ON_GET_RANKING_FAILED:
  default:
    return state;
  }
};

export default insightReducer;
