import * as constants from 'redux/constants/insight.constants';

export const onGetAll = (companySerchId) => ({
  type: constants.INSIGHT_ON_GET_ALL_REQUESTED,
  companySerchId,
});

export const onGetRanking = () => ({
  type: constants.INSIGHT_ON_GET_RANKING_REQUESTED,
});
