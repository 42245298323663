import React from 'react';
import PropTypes from 'prop-types';
import { NavHashLink } from 'react-router-hash-link';

import { INSTAGRAM_LINK, LINKEDIN_LINK, FACEBOOK_LINK } from 'constants/socialMedias';

import {
  FooterContainer, MoreInfoDiv, SocialMedia, Icons, Facebook, Instagram, LinkedIn, Info,
} from './styles';

const NavbarFooter = (props) => {
  const { isLandscape, open } = props;

  return (
    isLandscape
      && (
        <FooterContainer data-testid="navbar-footer">
          <MoreInfoDiv open={open}>
            <NavHashLink target="_blank" rel="noopener noreferrer" smooth to="/legal#privacy">
              <Info data-testid="infoIcon" />
            </NavHashLink>
          </MoreInfoDiv>
          <SocialMedia open={open} className="row">
            <a target="_blank" rel="noopener noreferrer" href={LINKEDIN_LINK}>
              <Icons>
                <LinkedIn data-testid="linkedInIcon" />
              </Icons>
            </a>
            <a target="_blank" rel="noopener noreferrer" href={INSTAGRAM_LINK}>
              <Icons>
                <Instagram data-testid="instagramIcon" />
              </Icons>
            </a>
            <a target="_blank" rel="noopener noreferrer" href={FACEBOOK_LINK}>
              <Icons>
                <Facebook data-testid="facebookIcon" />
              </Icons>
            </a>
          </SocialMedia>
        </FooterContainer>
      )
  );
};

NavbarFooter.propTypes = {
  isLandscape: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
};

export default NavbarFooter;
