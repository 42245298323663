import React from 'react';
import PropTypes from 'prop-types';

import PieChartComponent from '../PieChartComponent';

import { ChartsContainers, TitleDiv, PieChartContainer } from './styles';

const PieChart = ({
  labels, title, colors, alignChartCenter, total,
}) => (
  <ChartsContainers>
    <TitleDiv>
      {title}
    </TitleDiv>
    <PieChartContainer alignCenter={alignChartCenter}>
      <PieChartComponent labels={labels} colors={colors} total={total} />
    </PieChartContainer>
  </ChartsContainers>
);

PieChart.defaultProps = {
  alignChartCenter: false,
};

PieChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  title: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  alignChartCenter: PropTypes.bool,
  total: PropTypes.number.isRequired,
};

export default PieChart;
