import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup as MaterialRadioGroup } from '@material-ui/core/';

import { HelpBlock } from 'styles/commons';

import FormGroup from './styles';

const RadioGroup = (props) => {
  const { input, meta: { touched, error }, ...rest } = props;

  return (
    <FormGroup>
      <MaterialRadioGroup
        {...input}
        {...rest}
        onChange={(event, value) => input.onChange(value)}
        valueSelected={input.value}
      />
      {touched && (error && <HelpBlock data-testid="help-block">{error}</HelpBlock>)}
    </FormGroup>
  );
};

RadioGroup.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default RadioGroup;
