import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { companySizeOptionsPropTypes, userPropTypes } from 'helpers/propTypes';
import { isSmallTablet } from 'helpers/devices';

import {
  onUpdate, onLogout, onUpdatePassword, onLoadProfileImageFailed,
} from 'redux/actions/user.actions';
import { onCompanyUpdateRequested } from 'redux/actions/company.actions';
import { userSelector, companySizeSelector, profileFormImageSelector } from 'redux/selectors/user.selector';
import { isPoc } from 'constants/roles';
import { Container } from 'styles/commons';

import Layout from 'containers/Layout';
import PremiumContactContainer from 'containers/PremiumContactContainer';

import CustomModal from 'components/commons/Modal';

import ProfileView from 'views/Profile';

const ProfileContainer = (props) => {
  const {
    image, initialValues, onLogoutAction, companySize, onSubmit, onSubmitCompany,
    onUpdatePasswordAction, user, showSettings, onLoadImageFailedAction,
  } = props;

  const { isPremium } = initialValues;

  const isPocUser = isPoc(user?.role);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModal = () => setIsModalOpen(false);
  const onOpenModal = () => setIsModalOpen(true);

  const onSubmitForm = (formData) => {
    const imagetoSubmit = formData.image;
    if (isPocUser) {
      onSubmit({ ...formData, profilePic: imagetoSubmit });
    } else {
      onSubmitCompany({ ...formData, companyId: user.company.id, logo: imagetoSubmit });
    }
  };
  const showSettingsMobile = showSettings && isSmallTablet();

  return (
    <Container>
      <Layout isLandscape hideAddReview>
        {user && (
          <>
            <ProfileView
              image={image?.path || image}
              initialValues={initialValues}
              isPoc={isPocUser}
              isPremium={isPremium}
              onLoadImageFailed={onLoadImageFailedAction}
              onLogout={onLogoutAction}
              onSubmit={onSubmitForm}
              onSubmitPassword={onUpdatePasswordAction}
              companySizeOptions={companySize}
              showSettingsMobile={showSettingsMobile}
              onOpenModal={onOpenModal}
            />
            <CustomModal
              isOpen={isModalOpen}
              onClose={onCloseModal}
            >
              <PremiumContactContainer
                onCloseModal={onCloseModal}
              />
            </CustomModal>
          </>
        )}
      </Layout>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  image: profileFormImageSelector(state),
  initialValues: userSelector(state),
  companySize: companySizeSelector(),
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  onLogoutAction: bindActionCreators(onLogout, dispatch),
  onSubmit: bindActionCreators(onUpdate, dispatch),
  onSubmitCompany: bindActionCreators(onCompanyUpdateRequested, dispatch),
  onUpdatePasswordAction: bindActionCreators(onUpdatePassword, dispatch),
  onLoadImageFailedAction: bindActionCreators(onLoadProfileImageFailed, dispatch),
});

ProfileContainer.defaultProps = {
  initialValues: {
    isPremium: false,
  },
  user: null,
  showSettings: false,
};

ProfileContainer.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.shape({ path: PropTypes.string }),
    PropTypes.string,
  ]).isRequired,
  initialValues: PropTypes.shape({ isPremium: PropTypes.bool }),
  onLoadImageFailedAction: PropTypes.func.isRequired,
  onLogoutAction: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitCompany: PropTypes.func.isRequired,
  onUpdatePasswordAction: PropTypes.func.isRequired,
  user: userPropTypes,
  companySize: companySizeOptionsPropTypes.isRequired,
  showSettings: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
