import styled from 'styled-components';

import { MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';
import { DIPPER_BLUE, GREEN_REVIEW } from 'styles/colors';

export const Scroll = styled.div`
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 

  ::-webkit-scrollbar {
    display: none;
  }


  @media (max-width: ${MOBILE_SIZE}px) {
    padding-bottom: 34px;
  }
`;

export const TopTitles = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 11px;

  @media (max-width: ${TABLET_SIZE}px) {
   justify-content: space-between;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};

  @media (max-width: ${TABLET_SIZE}px) {
    font-size: 22px;
  }
`;

export const Border = styled.div`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};
  margin-left: 8px;

  @media (max-width: ${TABLET_SIZE}px) {
    display: none;
  }
`;

export const TitleCompany = styled.div`
  font-size: 12px;
  line-height: 25px;
  letter-spacing: 0px;
  align-self: flex-end;
  color: ${GREEN_REVIEW};
  margin: 2px 0 0 8px;

  @media (max-width: ${TABLET_SIZE}px) {
    font-size: 16px;
  }

  @media (max-width: 341px) {
    text-align: end;
  }
`;

export const InsightsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  margin-top: ${({ isInBottom }) => (isInBottom && '10px')};
  display: flex;
  margin: 0;
`;

export const ChartContainer = styled.div`
  display: flex;
  min-height: 260px;
  margin-bottom: 20px;
  padding: 0 30px 0 0;

  @media (max-width: ${TABLET_SIZE}px) {
    padding: 0;
  }
`;
