import styled, { css } from 'styled-components';
import { ReactComponent as LogoDipperPic } from 'components/commons/assets/dipper-logo.svg';
import {
  TABLET_SIZE, MOBILE_SIZE, BOOSTRAP_MEDIUM, SMALL_TABLET_SIZE, SMALL_MOBILE_SIZE,
  SMALL_DESKTOP_SIZE_WITH_PADDING, BIG_MOBILE_SIZE,
} from 'constants/sizes';
import {
  DIPPER_BLUE, BLUE_DARK, LIGHT_LIME_GREEN, GREEN_REVIEW, GRAY_BACKGROUND, BLUE, BLUE_PORT,
} from 'styles/colors';

import Button from 'components/commons/Button';

import { ReactComponent as DipperStarsImage } from 'components/commons/assets/dipper-stars.svg';
import HeroImg1920 from './assets/hero-1920-img.png';
import HeroImg1024 from './assets/hero-1024-img.png';
import HeroImgTablet from './assets/hero-tablet-img.png';
import HeroImgMobile from './assets/hero-mobile-img.png';
import HeroImgMobileMini from './assets/hero-mobile-mini-img.png';
import { ReactComponent as DipperStarImage } from './assets/star.svg';
import { ReactComponent as DipperCommunity } from './assets/dipper-community.svg';
import { ReactComponent as DipperEaseUse } from './assets/dipper-ease-use.svg';
import { ReactComponent as DipperFirstMarket } from './assets/dipper-first-market.svg';

export const LandingContainer = styled.div`
  position: relative;

  @media (max-width: ${TABLET_SIZE}px) {
    ${({ isPortraitMenuOpened }) => (isPortraitMenuOpened && css`
      box-shadow: 0px 3px 6px #00000017;
      opacity: 1;
      filter: blur(3px);
      -webkit-filter: blur(3px);    
    `)}
  }           
`;

export const HeroContainer = styled.div`
  background-image: url(${HeroImg1920});
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: bottom;
  height: 646px; 

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    background-image: url(${HeroImg1024});
    background-position: right;
    height: 618px;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    background-position-y: top;
    background-image: url(${HeroImgTablet});
    height: 670px;
    background-position: right; 
    margin-bottom: 160px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE - 30}px) {
    background-position: left;
  }

  @media (max-width: ${BIG_MOBILE_SIZE}px) {
    background-position: center;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    background-image: url(${HeroImgMobile});
    background-position: center;
    height: 521px;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    background-image: url(${HeroImgMobileMini});
    background-position: right;
    height: 353px;
    margin-top: 49px;
    margin-bottom: 237px;
  }

  @media (max-width: 430px) {
    background-position: center;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const Top = styled.div`
  // Scroll to the top no matter device height
  position: absolute;
  margin-top: -1000px;
`;

export const TextDiv = styled.div`
  width: 52%;
  display: flex;
  flex-direction: column;
  color: ${DIPPER_BLUE};
  margin-top: 70px;

  @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px)
    and (-webkit-min-device-pixel-ratio: 2) {
      width: 40%;
  }

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    width: 40%;
    padding-left: 25px;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    width: 100%;
    margin-top: 600px;
    padding-left: 0;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    margin-top: 470px;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    margin-top: 342px;
  }
`;

export const LogoDipper = styled(LogoDipperPic)`
  height: 74px;
  width: 178px;

  @media (max-width: ${TABLET_SIZE}px) {
    display: none;
  }
`;

export const Title = styled.span`
  font-size: 36px;
  font-weight: bold;
  line-height: 46px;
  margin-top: 34px;

  @media (max-width: ${TABLET_SIZE}px) {
    font-size: 27px;
    margin-top: 55px;
    text-align: center;
    padding: 0 70px;
  }

  @media (max-width: ${BIG_MOBILE_SIZE}px) {
    padding: 0 30px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    font-size: 27px;
    line-height: 36px;
    margin-top: 55px;
    text-align: center;
    position: static;
    padding-bottom: 0;
  }
`;

export const Welcome = styled.div`
    width: 100%;
    display: flex;
    margin-top: 60px;

    @media (max-width: ${TABLET_SIZE}px) {
      margin: 0px;
    }

    @media (max-width: ${MOBILE_SIZE}) {
      margin: 0px;
    }
`;

export const HeroButton = styled(Button)`
  font: normal normal bold 18px/23px Mulish !important;
`;

export const ButtonContainer = styled.div`
  margin-top: 33px;

  @media (max-width: ${TABLET_SIZE}px) {
    display: flex;
    justify-content: center;
  }
`;

export const TitleReview = styled.div`
  margin: 20px 0;
  color: ${BLUE_DARK};
  font-weight: bold;
  font-size: 32px;
  line-height: 26px;
`;

export const WhatIsDipperTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 20px;

  @media (max-width: ${TABLET_SIZE}px) {
    flex-direction: column;
    padding-top: 0;
  }
`;

export const WhatIsDipperDescription = styled.div`
  width: 50%;
  padding-right: 15px;

  @media (max-width: ${TABLET_SIZE}px) {
    width: 100%;
    padding: 0;
  }

  /* ----------- iPad ----------- */
  /* Portrait and Landscape */
  @media only screen 
    and (min-device-width: 768px)
    and (max-device-width: 1366px)
    and (-webkit-min-device-pixel-ratio: 2) {
      flex: 1;
  }
`;

export const WhatIsDipperCard = styled.div`
  width: 50%;
  background: ${LIGHT_LIME_GREEN};
  display: table;
  margin-left: 60px;
  padding: 44px 30px 43px 27px;
  position: relative;

  p {
    color: ${DIPPER_BLUE};
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
  }

  span {
    color: ${GREEN_REVIEW};
  }

  @media (max-width: ${TABLET_SIZE}px) {
    width: 100%;
    height: 154px;
    margin: 20px 0 0;
    padding: 44px 68px 43px 57px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
    height: 212px;
    margin: 20px 0px;
    padding: 44px 33px 33px 21px;
  }

  /* ----------- iPad ----------- */
  /* Portrait and Landscape */
  @media only screen 
    and (min-device-width: 768px)
    and (max-device-width: 1366px)
    and (-webkit-min-device-pixel-ratio: 2) {
      display: flex;
      flex: 1;
      height: 100%;
  }
`;

export const CustomH1 = styled.h1`
  margin-bottom: 20px;
  color: ${BLUE_DARK};
  font-weight: bold;
  font-size: 32px;
  line-height: 26px;
  letter-spacing: 0px;
  
  ${({ hasMarginTop }) => (hasMarginTop && css`
    margin-top: 20px;  
  `)}
`;

export const CustomH4 = styled.h4`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
`;

export const CustomH5 = styled.h5`
  margin: 20px 0px;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
`;

export const LandingSection = styled.div`
  width: 100%;
  color: ${DIPPER_BLUE};
  max-width: 1024px;
  margin: 0 auto;
  padding: 100px 0;

  ${({ avoidWebPaddingBotom }) => (!!avoidWebPaddingBotom && css`
    padding-bottom: 0;  
  `)}

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    padding: 100px 25px;
    max-width: 100%;

    ${({ avoidWebPaddingBotom }) => (!!avoidWebPaddingBotom && css`
      padding-bottom: 0;  
    `)}
  }

  @media (max-width: ${TABLET_SIZE}px) {
    padding: 100px 25px;

    ${({ avoidWebPaddingBotom }) => (!!avoidWebPaddingBotom && css`
      padding-bottom: 0;  
    `)}

    h1 {
      font-size: 32px;
      line-height: 42px;
    }
  
    h4 {
      font-size: 16px;
      line-height: 26px;
    }
  
    h5 {
      font-size: 16px;
      line-height: 26px;
    }
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    h1 {
      font-size: 28px;
      line-height: 42px;
    }
  }
`;

export const StarsImage = styled(DipperStarsImage)`
  position: absolute;
  right: -18px;
  bottom: -40px;
  height: 108px;
  width: 81px;
  fill: ${BLUE_PORT};

  @media (max-width: 1090px) {
    right: 0;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    right: 0px;
    bottom: -10px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    right: 5px;
    bottom: -50px;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    right: 5px;
    bottom: -70px;
  }
`;

export const HowItWorksContainer = styled.div`
  background: ${GRAY_BACKGROUND};

  @media (max-width: ${TABLET_SIZE}px) {
    h1 {
      margin: 0px;
    }
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    h1 {
      margin: 27px 0px;
    }
  }
`;

export const HowItWorksTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    flex-direction: column;
  }
`;

export const HowItWorksCard = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ firstCard }) => (firstCard ? '30px' : '0')};

  h5 {
    margin: 20px 0 0;
  }

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    width:  100%;
    flex-direction: row;
    margin-top: ${({ firstCard }) => (firstCard ? '30px' : '70px')};
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 90%;
    flex-direction: column;
    margin-top: ${({ firstCard }) => (firstCard ? '0px' : '40px')};
  }
`;

export const HowItWorksCommunityImage = styled(DipperCommunity)`
  width: 133px;
  height: auto;
  margin: 0 auto 0;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    width: 270px;
    margin: 0;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100px;
    margin: auto auto 0px 0px;
  }
`;

export const HowItWorksEaseUseImage = styled(DipperEaseUse)`
  width: 150px;
  height: auto;
  padding-left: 30px;
  margin: auto;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    width: 198px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100px;
    padding-left: 10px;
    margin: auto auto 0px 0px;
  }
`;

export const HowItWorksFirstMarketImage = styled(DipperFirstMarket)`
  width: 133px;
  height: auto;
  margin: auto auto 0px;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    width: 230px;
    margin: 0;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100px;
    margin: auto auto 0px 0px;
  }
`;

export const HowItWorksText = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: ${DIPPER_BLUE};
  margin-top: 30px;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 35px;
    text-align: left;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    text-align: left;
    margin: 30px 0px 0px;
  }
`;

export const CorporateTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 47px;

  h5 {
    background: ${LIGHT_LIME_GREEN};
    text-align: center;
    padding: 30px 20px 20px;
    margin: 35px 35px 0px 0px;
    position: relative;
    width: 50%;
  }

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    flex-direction: column;

    h5 {
      padding: 37px 35px 27px;
    }
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    h5 {
      width: 100%;
      padding: 28px 35px;
    }
  }
`;

export const TwoCards = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${({ avoidLastMargin }) => (!!avoidLastMargin && css`
    h5:last-child {
      margin-right: 0;
    }
  `)}

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    width: 100%;

    h5:last-child {
      margin-right: auto;
    }
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
  }
`;

export const StarImageFirstPanel = styled(DipperStarImage)`
  position: absolute;
  heigth: 37px;
  width: 38px;
  top: -17px;
  left: 20px;

  @media (max-width: ${TABLET_SIZE}px) {
    top: -19px;
    left: -18px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    top: -5px;
    left: -15px;
  }
`;

export const StarImageSecondPanel = styled(DipperStarImage)`
  position: absolute;
  heigth: 33px;
  width: 34px;
  bottom: 20px;
  right: -10px;

  @media (max-width: ${TABLET_SIZE}px) {
    bottom: -17px;
    left: -17px;
    right: auto;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    top: -33px;
    right: 0px;
    bottom: auto;
    left: auto;
  }
`;

export const StarImageFourthPanel = styled(DipperStarImage)`
  position: absolute;
  heigth: 42px;
  width: 43px;
  top: 50px;
  left: -25px;

  @media (max-width: ${TABLET_SIZE}px) {
    bottom: 25px;
    right: -15px;
    left: auto;
    top: auto;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    top: -25px;
    right: 0px;
    left: auto;
    bottom: auto;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${MOBILE_SIZE}px) {
    justify-content: start;
  }
`;

export const CustomButton = styled(Button)`
  padding: 11px 40px;
`;

export const StyledText = styled.div`
  margin: ${({ last }) => (last ? '13px 0px 30px' : '13px 0px')};
  color: ${DIPPER_BLUE};
  font-size: 18px;
  line-height: 26px;

  @media (max-width: ${TABLET_SIZE}px) and (min-width: ${MOBILE_SIZE}px) {
    font-size: 16px;
    line-height: 22px;
    margin: ${({ last }) => (last ? '13px 0px 50px' : '13px 0px')};
  }
`;

export const FoundersContainer = styled.div`
  background: ${BLUE};
  position: relative;
  overflow: hidden;
`;

export const QuotesContainer = styled.div`
  background: ${GRAY_BACKGROUND};
`;
