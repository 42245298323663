import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import { LoadingContainer, LoadingText } from './styles';

const Loading = (props) => {
  const { message, size } = props;
  return (
    <LoadingContainer>
      <CircularProgress data-testid="loader" size={size} />
      {message && <LoadingText>{message}</LoadingText>}
    </LoadingContainer>
  );
};

Loading.defaultProps = {
  message: null,
  size: 65,
};

Loading.propTypes = {
  message: PropTypes.string,
  size: PropTypes.number,
};

export default Loading;
