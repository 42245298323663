import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import PLACEHOLDER from 'components/commons/CustomContentEditable/constants';

import CustomContentEditable from 'components/commons/CustomContentEditable';

import { COMMUNITY_FEED } from 'constants/inputSizes';

import { CardBox, Form } from './styles';

const validate = (values) => {
  const errors = {};
  if (!values.description) {
    errors.description = 'Required';
  }
  return errors;
};

const CreateFeed = (props) => {
  const {
    handleChangeFeed, handleSubmit, html, onSubmit, setHtml,
  } = props;
  const [startAdornment, setHasStartAdornment] = useState(false);

  const focusInFeed = () => {
    if (html === PLACEHOLDER) setHtml('');
    setHasStartAdornment(true);
  };

  return (
    <CardBox data-testid="add-feed-component">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          component={CustomContentEditable}
          focus={focusInFeed}
          handleChangeFeed={handleChangeFeed}
          html={html}
          isFocused={startAdornment}
          name="description"
          setHtml={setHtml}
          maxLength={COMMUNITY_FEED}
        />
      </Form>
    </CardBox>
  );
};

CreateFeed.propTypes = {
  handleChangeFeed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  html: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setHtml: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'createFeedForm',
  validate,
})(CreateFeed);
