import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const FormGroup = styled.div`
  padding-top: 20px;
  width: 100%;
`;

export const CustomDatePicker = styled(TextField)`
  height: fit-content;
  width: 100%;
  label {
    display: flex;
    font-family: ${({ isItalic }) => (isItalic ? 'Mulish-Italic' : 'Mulish')};
    font-size: 14px;
    span {
      display: none;
    }
  }
 
  .MuiOutlinedInput-inputMarginDense {
    margin-top: 2px;
  }

  .MuiOutlinedInput-root {
    font-family: 'Mulish';
  }
`;
