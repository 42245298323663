import * as React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  required,
  DateField,
  DateInput,
  BooleanField,
  BooleanInput,
  email,
} from 'react-admin';
import { validateRequired } from 'admin/helpers/formValidation';
import { getStringDate } from 'helpers/dates';
import { parseOptionValues } from 'admin/helpers/selectInput';

import { AdminFormContainer } from 'styles/commons';

import { employeeTypes } from 'constants/jobs';
import { isValidNumberWithCurrency } from 'helpers/validates';

const employeeTypeOption = () => parseOptionValues(employeeTypes);

const JobFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by description" source="description" alwaysOn />
    <ReferenceInput label="Company's name" source="company.id" reference="companies" perPage={1000} alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const JobList = (props) => (
  <List filters={<JobFilter />} sort={{ field: 'dateUpdated', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField source="dateUpdated" />
      <TextField source="jobPosition" />
      <TextField source="location" />
      <ReferenceField label="Company" source="company.id" reference="companies">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="isFeatured" />
      <EditButton />
    </Datagrid>
  </List>
);

const JobTitle = ({ record }) => (
  <span>
    {record ? `${record.jobPosition}` : ''}
  </span>
);

const JobForm = ({ isEditForm }) => (
  <AdminFormContainer>
    {isEditForm && <TextInput disabled source="id" validate={required()} />}
    {isEditForm
      ? <DateInput source="dateCreated" />
      : <DateInput source="dateCreated" initialValue={getStringDate()} disabled />}
    {isEditForm
      ? <DateInput source="expirationDate" initialValue={getStringDate()} />
      : <DateInput source="expirationDate" />}
    <TextInput multiline source="jobPosition" validate={[required(), validateRequired]} />
    <TextInput multiline source="location" validate={[required(), validateRequired]} />
    <TextInput multiline source="fromSalary" validate={[(num) => (!num ? null : isValidNumberWithCurrency(num))]} />
    <TextInput multiline source="toSalary" validate={[(num) => (!num ? null : isValidNumberWithCurrency(num))]} />
    <SelectInput
      source="employeeType"
      validate={required()}
      choices={employeeTypeOption()}
      optionText="value"
      optionValue="id"
    />
    <TextInput multiline source="overview" validate={[required(), validateRequired]} />
    <TextInput multiline source="description" validate={[required(), validateRequired]} />
    <ReferenceInput label="Company" source="company.id" reference="companies">
      <SelectInput source="name" validate={required()} />
    </ReferenceInput>
    <TextInput source="contactEmail" validate={[required(), validateRequired, email()]} />
    <BooleanInput source="isActive" />
    <BooleanInput source="isFeatured" />
  </AdminFormContainer>
);

export const JobEdit = (props) => (
  <Edit title={<JobTitle />} {...props}>
    <SimpleForm>
      <JobForm isEditForm />
    </SimpleForm>
  </Edit>
);

export const JobCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <JobForm />
    </SimpleForm>
  </Create>
);

JobForm.propTypes = {
  isEditForm: PropTypes.bool.isRequired,
};

JobTitle.defaultProps = {
  record: null,
};

JobTitle.propTypes = {
  record: PropTypes.shape({
    jobPosition: PropTypes.string.isRequired,
  }),
};
