import styled, { css } from 'styled-components';
import AddIcon from '@material-ui/icons/Add';

import {
  SMALL_DESKTOP_SIZE, TABLET_SIZE, SMALL_TABLET_SIZE,
} from 'constants/sizes';
import { ReactComponent as FacebookIcon } from 'components/commons/assets/FacebookIcon.svg';
import { ReactComponent as LinkedInIcon } from 'components/commons/assets/LinkedinIcon.svg';
import { ReactComponent as InstagramIcon } from 'components/commons/assets/InstagramIcon.svg';
import { ReactComponent as EditProfileImage } from 'components/commons/assets/dipper-edit-profile-img.svg';
import { GreenButton } from 'styles/commons';
import {
  GRAY_COMMUNITY, GRAY_BAR, BLUE_DIPPER, WHITE,
  GRAY_COMMUNITY_LINES, GREEN_REVIEW, BLUE, BLUE_PREMIUM,
} from 'styles/colors';

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${GRAY_BAR};
  justify-content: space-between;

  h1 {
    color: ${BLUE_DIPPER};
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    h1 {
      font-size: 22px;
    }
  }
`;

export const CardBox = styled.div`
  padding: 30px 20px 30px;
  display: flex;
  flex-direction: column;
`;

export const ProfileContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; 
  
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const EditProfileLogo = styled(EditProfileImage)`
  height: 25px;
  width: 28px;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    height: 28px;
    width: 31px;
  }
`;

export const LabelImage = styled.label`
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  text-transform: initial;
  width: fit-content;
  height: auto;
  color: ${WHITE};
  background-color: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;

  .MuiButton-label {
    font-family: mulish;
  }
  :hover{
    cursor: pointer;
  }
`;

export const Text = styled.p`
  color: ${BLUE_DIPPER};
  font-size: 12px;
  margin: 5px 0 0 0;
  min-width: 156px;
`;

export const PictureSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 11px;
  position: relative;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    justify-content: center;
    margin-top: 30px;
    position: relative;
  }
`;

export const ProfileImageContainer = styled.div`
  position: relative;
`;

export const ImageContainer = styled.div`
  display: none;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 82px;
  height: 82px;
  border: 1px solid ${GRAY_COMMUNITY};
  object-fit: cover;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    margin-right: 0;
    width: 143px;
    height: 143px;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 20px;

  @media (max-width: 1276px) {
    padding-top: 0;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    align-self: center;
    margin-top: 15px;
  }
`;

export const ButtonStyle = styled(GreenButton)`
&& {
  place-self: center;
  align-text: center;
  margin-left: 0px;
  padding: 12px 29px 12px 29px; 

  .MuiButton-label {
    font-family: mulish;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
  }

  @media (max-width: 1276px) {
    ${({ isInputPassword }) => (
    isInputPassword
        && css`
          margin-top: 30px;
          align-self: start;
      `
  )};
  }
}
`;

export const InputsSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ${({ isInputPassword }) => (
    isInputPassword
      && css`
        margin-right: 23%;
        margin-top: 0;
        
        @media (max-width: 1276px) {
          flex-direction: column;
        }

        @media (max-width: ${SMALL_TABLET_SIZE}px) {
          margin-right: 0;
        }
      `
  )};

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    flex-direction: column;
  }
`;

export const LeftInputsContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 100%;
  }
`;

export const LeftInputs = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right: 1px solid ${GRAY_COMMUNITY};
  padding-right: 71px;
  margin-top: 8px;

  @media (max-width: ${TABLET_SIZE}px) {
    padding-right: 25px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 100%;
    border-right: none;
    padding-right: 0;
  }
`;

export const InputsName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    flex-direction: column;
  }
`;

export const RightInputs = styled.div`
  display: ${({ isUserPremium }) => (isUserPremium ? 'flex' : 'none')};
  flex: 1;
  flex-direction: column;
  padding-left: 66px;

  h1 {
    color: ${BLUE_DIPPER};
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    padding-left: 25px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 100%;
    padding-left: 0;
    margin-top: 30px;
  }
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isInputName }) => (!isInputName ? '100%' : '48%')};
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? '20' : '0')}px;
  
  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 100%;
  }
`;

export const InputSelect = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
`;

export const LinkedIn = styled(LinkedInIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 16px;
  height: 16px;
`;

export const Facebook = styled(FacebookIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 16px;
  height: 16px;
`;

export const Instagram = styled(InstagramIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 16px;
  height: 16px;
`;

export const SendButton = styled.div`
  display: flex;
  justify-content: ${({ justifyContentCenter }) => (justifyContentCenter ? 'center' : 'flex-start')};
  margin-top: 50px;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    display: ${({ isPoc }) => (isPoc ? 'flex' : 'none')};
  }
`;

export const SendButtonCompany = styled.div`
  display: none;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    display: flex;
    width: 100%;
    margin-bottom: 20px; 
  }
`;

export const SettingsSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    display: none;
    
    ${({ displayInMobile }) => (
    displayInMobile
      && css`
        display: flex;
        margin-top: 0;
      `
  )};
  }
`;

export const SubTitle = styled.h2`
  color: ${BLUE_DIPPER};
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0 18px;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    ${({ hasLessMarginAndBorderTop }) => (
    hasLessMarginAndBorderTop
      && css`
        margin: 0;
        padding-top: 10px;
        border-top: 1px solid ${GRAY_COMMUNITY};
      `
  )};
  }
`;

export const PasswordInput = styled.div`
  display: flex;
  width: 26%;

  @media (max-width: 1276px) {
    width: 52%;
    margin-top: 20px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 100%;
  }
`;

export const OptionsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 60px 0 30px;
  border-top: 1px solid ${GRAY_BAR};
  
  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    display: none;

    ${({ displayInMobile }) => (
    displayInMobile
      && css`
        display: flex;
        margin: 35px 0; 
      `
  )};
  }
`;

export const UserOptions = styled.span`
  display: flex;
  color: ${GREEN_REVIEW};
  margin-top: 21px;
  ${({ isRemoveAcount }) => (
    isRemoveAcount
      ? css`
        padding-right: 7px;
        border-right: 1px solid ${GRAY_BAR};
    `
      : css`
        padding-left: 7px;
        cursor: pointer;
    `
  )};
`;

export const NotPremiumSection = styled.div`
  display: ${({ isUserPremium }) => (isUserPremium ? 'none' : 'flex')};
  flex-direction: column;
  width: 50%;
  padding-left: 5%;
  margin: 0 0 50px 66px;
  background-color: white;
  border-radius: 4px;

  @media (max-width: ${SMALL_DESKTOP_SIZE}px) {
    padding: 0 2%;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 100%;
    padding-left: 0;
    margin: 40px 0 0 0;
  }
`;

export const DivTextandImage = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 51px;

  @media (max-width: ${SMALL_DESKTOP_SIZE}px) {
    flex-direction: column;
  }
`;

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 32px;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    margin-left: 25px;
    margin-right: 20px;
  }
`;

export const ImagePremium = styled.img`
  width: 55%;
  height: auto;
  max-width: 263px;
  margin: auto;

  @media (max-width: ${SMALL_DESKTOP_SIZE}px) {
    width: 65%;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 79%;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 260px;
    height: 222px;
    align-self: center;
  }
`;

export const InputPremium = styled.div`
  display: ${({ isUserPremium }) => (isUserPremium ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  h1 {
    margin-bottom: 3px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 100%;
  }

`;
export const TitlePremium = styled.h1`
  display: flex;
  color: ${BLUE_DIPPER};
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    text-align: center;
  }
`;

export const SubTitlePremium = styled.h1`
  display: flex;
  color: ${BLUE_DIPPER};
  font-size: 12px;
  line-height: 16px;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    text-align: center;
  }
`;

export const ButtonPremium = styled(GreenButton)`
&& {
  place-self: center;
  padding: 11px 36px;
  margin-left: 0px;
  color: ${WHITE};
  background: ${BLUE};
  margin: 32px 0 28px;

  .MuiButton-startIcon {
    margin: 0;
  }

  .MuiButton-label {
    font-family: mulish;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    text-transform: initial;
  }

  :hover{
    background-color: ${BLUE_PREMIUM};
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    margin-bottom: 25px;
  }
}
`;

export const PlusIcon = styled(AddIcon)`
  margin: 0;
`;
