import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { feedsSelector } from 'redux/selectors/feed.selector';
import { onFeedGetRecent, onFeedAddComment, onSetFeedSelected } from 'redux/actions/feed.actions';
import { feedPropTypes, userPropTypes } from 'helpers/propTypes';
import { QUANTITY_FEED } from 'constants/configs';
import { isCompany } from 'constants/roles';

import Layout from 'containers/Layout';
import FeedsView from 'views/Feeds';
import { Container } from 'styles/commons';

const Feeds = (props) => {
  const {
    onFeedGetRecentAction, onSubmitAddComment, onSetFeedSelectedAction, feedsList,
    user, hasMore, selected,
  } = props;
  const [pageNumber, setPageNumber] = useState(0);

  const handleScroll = (event) => {
    const { target } = event;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    onFeedGetRecentAction(QUANTITY_FEED, pageNumber);
  }, [pageNumber, onFeedGetRecentAction, hasMore]);

  return (
    !!user && (
      <Container>
        <Layout isLandscape hideAddReview={user && isCompany(user.role)}>
          <FeedsView
            user={user}
            feedsList={feedsList}
            handleScroll={handleScroll}
            onSubmitAddComment={onSubmitAddComment}
            selected={selected}
            setFeedSelected={onSetFeedSelectedAction}
          />
        </Layout>
      </Container>
    )
  );
};

const mapStateToProps = (state) => ({
  user: state.user.data,
  feedsList: feedsSelector(state),
  hasMore: state.feed.hasMore,
  selected: state.feed.selected,
});

Feeds.defaultProps = {
  user: null,
};

const mapDispatchToProps = (dispatch) => ({
  onFeedGetRecentAction: bindActionCreators(onFeedGetRecent, dispatch),
  onSubmitAddComment: bindActionCreators(onFeedAddComment, dispatch),
  onSetFeedSelectedAction: bindActionCreators(onSetFeedSelected, dispatch),
});

Feeds.propTypes = {
  feedsList: feedPropTypes.isRequired,
  user: userPropTypes,
  hasMore: PropTypes.bool.isRequired,
  onFeedGetRecentAction: PropTypes.func.isRequired,
  onSetFeedSelectedAction: PropTypes.func.isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
  selected: feedPropTypes.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feeds);
