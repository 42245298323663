import React from 'react';
import PropTypes from 'prop-types';

import {
  feedsArrayPropTypes, insightsArrayPropTypes, jobsArrayPropTypes, userPropTypes,
} from 'helpers/propTypes';
import { PageContainer } from 'styles/commons';

import FeedsDashboard from 'components/commons/FeedsDashboard';
import InsightsDashboard from 'components/commons/InsightsDashboard';
import ProfileDashboard from 'components/commons/ProfileDashboard';
import CorporateMembership from 'components/CorporateMembership';

import JobsDashboard from 'components/commons/JobsDashboard';
import {
  Container, ProfileContainer,
} from './styles';

const DashboardCompany = (props) => {
  const {
    amountOfJobs, feedsList, insights, jobsList, onSubmitAddComment, user,
    isPremium, onOpenModal,
  } = props;

  return (
    <PageContainer data-testid="dashboard-company-page">
      <Container className="col-lg-12 row">
        <InsightsDashboard insights={insights} />
        <FeedsDashboard
          user={user}
          feedsList={feedsList}
          onSubmitAddComment={onSubmitAddComment}
        />
        <ProfileContainer className="col-lg-3 column">
          <ProfileDashboard data-testid="div-profile" user={user} isCompany />
          {isPremium
            ? (
              <JobsDashboard
                amountOfJobs={amountOfJobs}
                isCompany
                jobsList={jobsList}
                maxJobs={3}
              />
            )
            : <CorporateMembership onClick={onOpenModal} />}
        </ProfileContainer>
      </Container>
    </PageContainer>
  );
};

DashboardCompany.propTypes = {
  amountOfJobs: PropTypes.number.isRequired,
  feedsList: feedsArrayPropTypes.isRequired,
  insights: insightsArrayPropTypes.isRequired,
  jobsList: jobsArrayPropTypes.isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
  user: userPropTypes.isRequired,
  isPremium: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
};

export default DashboardCompany;
