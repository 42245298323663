import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import {
  CreateReviewFloatingContainer, NewReviewIcon, CreateReviewFloatingHome, EditIcon,
} from './styles';

const FloatingCreateReview = (props) => {
  const { homeAddReview } = props;
  return (
    <Tooltip title="New review">
      {!homeAddReview
        ? (
          <CreateReviewFloatingContainer to="/review/new">
            <NewReviewIcon />
          </CreateReviewFloatingContainer>
        )
        : (
          <CreateReviewFloatingHome to="/review/new">
            <EditIcon />
            {' '}
            Write a Review
          </CreateReviewFloatingHome>
        )}
    </Tooltip>
  );
};

FloatingCreateReview.propTypes = {
  homeAddReview: PropTypes.bool.isRequired,
};

export default FloatingCreateReview;
