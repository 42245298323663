import cookie from 'react-cookies';

export function setCookie(name, value, options = {}) {
  cookie.save(name, value, options);
}

export function getCookie(name) {
  return cookie.load(name);
}

export function removeCookie(name, options = {}) {
  cookie.remove(name, options);
}
