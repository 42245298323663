import styled from 'styled-components';
import { DIPPER_BLUE, GREEN_REVIEW } from 'styles/colors';

export const DivLink = styled.div`
  padding-bottom: ${({ biggerPaddingBottom }) => (biggerPaddingBottom ? '8px' : '10px')};
  height: fit-content;
  width: fit-content;
  display: flex;
  flex: 1;
  align-self: flex-end;
  justify-content: flex-end;
  min-width: 66px;

  .Link {
    color: ${GREEN_REVIEW};
    font-size: 14px;
    font-weight: medium;
    font-size: 11px;

    :hover {
      color: ${DIPPER_BLUE};
    }
  }
`;

export const CustomButton = styled.button`
  color: ${GREEN_REVIEW};
  font-size: 14px;
  font-weight: medium;
  font-size: 11px;
  margin: 0; 
  border: none;
  background: none;
  cursor: pointer;
  
  :hover {
    color: ${DIPPER_BLUE};
  }
`;
