import styled from 'styled-components';

import { MOBILE_SIZE, BOOSTRAP_MEDIUM } from 'constants/sizes';
import { DIPPER_BLUE, GRAY_COMMUNITY } from 'styles/colors';

export const HeaderContainer = styled.div`
  color: ${DIPPER_BLUE};
  margin-right: 30px;

  @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
    margin-right: 0;
  }

  h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    border-bottom: 1px solid ${GRAY_COMMUNITY};
    padding-bottom: 10px;

    @media (max-width: ${MOBILE_SIZE}px) {
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      margin-right: 0;
    }
  }

  h2 {
    padding-top: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const InputContainer = styled.div`

  @media (max-width: ${MOBILE_SIZE}px) {
    margin-right: 0;
  }
`;

export const CustomParagraph = styled.p`
  color: ${DIPPER_BLUE};
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;  
  margin: 0;
  
  span {
    font-weight: normal;
  }
`;
