// New sizes
export const MEDIUM_DESKTOP_SIZE = 1200;

/* 1024px + 25px * 2 sides = 1074px */
export const SMALL_DESKTOP_SIZE_WITH_PADDING = 1074;

export const SMALL_DESKTOP_SIZE = 1024;

export const BOOSTRAP_MEDIUM = 991;

export const TABLET_SIZE = 920;

export const SMALL_TABLET_SIZE = 769;

export const BIG_MOBILE_SIZE = 691;

export const MOBILE_SIZE = 576;

export const SMALL_MOBILE_SIZE = 480;
