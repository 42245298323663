const SIZE_OPTIONS = {
  0: 'Self-employed',
  1: '1-10 employees',
  2: '11-50 employees',
  3: '51-200 employees',
  4: '201-500 employees',
  5: '501-1000 employees',
  6: '1001-5000 employees',
  7: '5001-10,000 employees',
  8: '10,001+ employees',
};

export default SIZE_OPTIONS;
