import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import StarsRating from 'components/commons/StarsRating/index';

import { getStringMonthNameDayYear, getStringMonthYear } from 'helpers/dates';
import { fromBoolToYesOrNo } from 'helpers/string';
import { companyPropTypes, userPropTypes } from 'helpers/propTypes';

import { companyDiversity, subjectSituation } from 'constants/labels';
import DefaultProfile from 'components/commons/assets/avatar-poc.svg';

import {
  ReviewContainer, ReviewSection, TitleContainer, ReviewHeader, ColumnCompany, ReviewTitle,
  ProfilePicture, SmallTextDate, SmallText, ReviewDate, ReviewContent, ReviewInsightItem,
  ReviewInsightDescription, ReviewInsightDetails, WordAndReviewDateDiv, ArrowBack, ArrowDiv,
  CompanyNameTitle, ButtonsContainer, EditButton, VerticalLine, DeleteButton,
  ReviewOptionsContainer, MoreOptionsContainer, MoreOptionsButton, PopUpOptions,
  AllScreen,
} from './styles';

const ReviewDetails = (props) => {
  const {
    areReviewsFromProfile, isCompany, hasToRenderAllReviewDetails, onDelete, review,
  } = props;

  const [showAllTheReview, setShowAllTheReview] = useState(true);
  const [showReviewOptions, setShowReviewOptions] = useState(false);

  const renderName = () => (
    review.anonymous ? 'Anonymous' : (`${review.user.firstName} ${review.user.lastName}`)
  );

  const renderEditAndDeleteButtons = () => (
    <>
      <Link
        to={{
          pathname: '/review/new',
          state: { review },
        }}
      >
        <EditButton />
      </Link>
      <VerticalLine />
      <DeleteButton onClick={(e) => { onDelete(review.id); e.stopPropagation(); }} />
    </>
  );

  const renderMoreOptionsButton = () => (
    <MoreOptionsContainer>
      {showReviewOptions
      && (
        <>
          <PopUpOptions>
            {renderEditAndDeleteButtons()}
          </PopUpOptions>
          <AllScreen onClick={() => setShowReviewOptions(false)} />
        </>
      )}
      <MoreOptionsButton onClick={() => setShowReviewOptions(!showReviewOptions)} />
    </MoreOptionsContainer>
  );

  return (
    <ReviewContainer className="row" data-testid="div-review">
      <ReviewSection hasSmallerPadding={hasToRenderAllReviewDetails}>
        <TitleContainer>
          <ReviewHeader hasFlexDirectionRow={(isCompany || areReviewsFromProfile)}>
            {(isCompany || areReviewsFromProfile)
              && (
                <ProfilePicture
                  isBigger={hasToRenderAllReviewDetails}
                  src={review.user.profilePic && !review.anonymous
                    ? review.user.profilePic.path
                    : DefaultProfile}
                />
              )}
            <ColumnCompany
              hasFlexDirectionColumn={(isCompany || areReviewsFromProfile)}
            >
              <CompanyNameTitle>
                {(isCompany || areReviewsFromProfile)
                  ? renderName() : review.company.name}
              </CompanyNameTitle>
              {review.jobTitle
                && (
                  <>
                    {(!isCompany && !areReviewsFromProfile) && <SmallText isBolder>|</SmallText>}
                    <SmallText
                      isBlue={areReviewsFromProfile}
                      hasNotPaddingLeft={isCompany}
                      isJobTitle
                    >
                      {review.jobTitle}
                    </SmallText>
                  </>
                )}
              {(!isCompany && !areReviewsFromProfile)
                && (
                  <ReviewOptionsContainer>
                    {renderMoreOptionsButton()}
                  </ReviewOptionsContainer>
                )}
            </ColumnCompany>
            {!hasToRenderAllReviewDetails
              && (
                <ReviewDate>
                  {getStringMonthNameDayYear(review.dateCreated)}
                </ReviewDate>
              )}
            {areReviewsFromProfile
              && (
                <ArrowDiv>
                  <ArrowBack onClick={() => setShowAllTheReview(!showAllTheReview)} />
                </ArrowDiv>
              )}
          </ReviewHeader>
          <ReviewTitle>{review.title}</ReviewTitle>
          <WordAndReviewDateDiv>
            <SmallTextDate>
              From
              {' '}
              {getStringMonthYear(review.startDate)}
              {' '}
              {!review.endDate ? 'and currently working ' : `to ${getStringMonthYear(review.endDate)} ` }
              {' '}
              {!areReviewsFromProfile
                && (review.anonymous ? ' | Posted anonymously' : `| ${review.user.firstName} ${review.user.lastName}`)}
            </SmallTextDate>
            {hasToRenderAllReviewDetails
             && <ReviewDate>{getStringMonthNameDayYear(review.dateCreated)}</ReviewDate>}
            {!isCompany && !areReviewsFromProfile
            && (
              <ButtonsContainer>
                {renderEditAndDeleteButtons()}
              </ButtonsContainer>
            )}
          </WordAndReviewDateDiv>
        </TitleContainer>
        {showAllTheReview
          && (
            <>
              <ReviewContent>{review.description}</ReviewContent>
              <ReviewInsightItem hasRating>
                <ReviewInsightDescription hasRating>
                  The company is a great place to work
                </ReviewInsightDescription>
                <ReviewInsightDetails hasRating>
                  <StarsRating
                    input={{ name: '', value: review.companyIsGreatPlaceToWork, onChange: () => {} }}
                    meta={{ touched: false, error: null }}
                    readOnly
                  />
                </ReviewInsightDetails>
              </ReviewInsightItem>
              <ReviewInsightItem hasRating>
                <ReviewInsightDescription hasRating>
                  Valued and viewed as competent at work
                </ReviewInsightDescription>
                <ReviewInsightDetails hasRating>
                  <StarsRating
                    input={{ name: '', value: review.companyTreatment, onChange: () => {} }}
                    meta={{ touched: false, error: null }}
                    readOnly
                  />
                </ReviewInsightDetails>
              </ReviewInsightItem>
              <ReviewInsightItem>
                <ReviewInsightDescription>
                  The company embrace diversity, inclusion, and equity
                </ReviewInsightDescription>
                <ReviewInsightDetails>
                  {companyDiversity[review.companyDiversity]}
                </ReviewInsightDetails>
              </ReviewInsightItem>
              <ReviewInsightItem>
                <ReviewInsightDescription>
                  Recommend the company
                </ReviewInsightDescription>
                <ReviewInsightDetails>
                  {fromBoolToYesOrNo(review.recommendCompany)}
                </ReviewInsightDetails>
              </ReviewInsightItem>
              <ReviewInsightItem>
                <ReviewInsightDescription>
                  Subject best describes your situation
                </ReviewInsightDescription>
                <ReviewInsightDetails>
                  {subjectSituation[review.personalSituation]}
                </ReviewInsightDetails>
              </ReviewInsightItem>
              <ReviewInsightItem lastItem>
                <ReviewInsightDescription>
                  Get feedback on your workplace challenge
                </ReviewInsightDescription>
                <ReviewInsightDetails>
                  {fromBoolToYesOrNo(review.feedback)}
                </ReviewInsightDetails>
              </ReviewInsightItem>
            </>
          )}
      </ReviewSection>
    </ReviewContainer>
  );
};

ReviewDetails.defaultProps = {
  areReviewsFromProfile: false,
  hasToRenderAllReviewDetails: false,
  onDelete: () => {},
};

ReviewDetails.propTypes = {
  areReviewsFromProfile: PropTypes.bool,
  isCompany: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  review: PropTypes.shape({
    id: PropTypes.number.isRequired,
    anonymous: PropTypes.bool.isRequired,
    jobTitle: PropTypes.string.isRequired,
    company: companyPropTypes.isRequired,
    companyDiversity: PropTypes.number.isRequired,
    companyIsGreatPlaceToWork: PropTypes.number.isRequired,
    companyTreatment: PropTypes.number.isRequired,
    dateCreated: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    feedback: PropTypes.bool.isRequired,
    personalSituation: PropTypes.number.isRequired,
    recommendCompany: PropTypes.bool.isRequired,
    startDate: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    user: userPropTypes.isRequired,
  }).isRequired,
  hasToRenderAllReviewDetails: PropTypes.bool,
};

export default ReviewDetails;
