import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Collapse } from '@material-ui/core';

import Input from 'components/commons/Input';

import { JOB_LOCATION, JOB_POSITION } from 'constants/inputSizes';
import {
  ClickableContainer, ClickableBar, CustomButton, CustomDiv, CustomLocationIcon,
  CustomSearchIcon, DesktopContainer, Form, LocationFilterDiv, MobileContainer, Column,
} from './styles';

const JobsFilter = (props) => {
  const {
    isOpen, onSubmit, setIsOpen, submitting, handleSubmit,
  } = props;

  const renderDesktopForm = () => (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CustomDiv>
        <Field
          component={Input}
          name="jobPositionFilter"
          label="Job title, Company or Keyword"
          size="small"
          isItalic
          hasLessPaddingTop
          hasBackgroundWhite
          renderStartAdornment={() => (<CustomSearchIcon />)}
          maxLength={JOB_POSITION}
        />
      </CustomDiv>
      <CustomDiv>
        <Field
          component={Input}
          name="locationFilter"
          label="City, State or Country"
          size="small"
          isItalic
          hasLessPaddingTop
          hasBackgroundWhite
          renderStartAdornment={() => (<CustomLocationIcon />)}
          maxLength={JOB_LOCATION}
        />
      </CustomDiv>
      <CustomButton type="submit" variant="contained" disabled={submitting} data-testid="button-send">
        Search
      </CustomButton>
    </Form>
  );

  const renderMobileForm = () => (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Column>
        <Collapse in={isOpen}>
          <Field
            component={Input}
            name="jobPositionFilter"
            label="Job title, Company or Keyword"
            size="small"
            isItalic
            hasLessPaddingTop
            hasBackgroundWhite
            renderStartAdornment={() => (<CustomSearchIcon />)}
            maxLength={JOB_POSITION}
            borderRadius="8px"
            isPlaceholder
          />
          <LocationFilterDiv>
            <Field
              component={Input}
              name="locationFilter"
              label="City, State or Country"
              size="small"
              isItalic
              hasLessPaddingTop
              hasBackgroundWhite
              renderStartAdornment={() => (<CustomLocationIcon />)}
              maxLength={JOB_LOCATION}
              borderRadius="8px"
              isPlaceholder
            />
          </LocationFilterDiv>
          <CustomDiv>
            <CustomButton
              type="submit"
              variant="contained"
              disabled={submitting}
              data-testid="button-send"
              onClick={() => setIsOpen(false)}
            >
              Search
            </CustomButton>
          </CustomDiv>
        </Collapse>
        <ClickableContainer onClick={() => setIsOpen(!isOpen)}>
          <ClickableBar />
        </ClickableContainer>
      </Column>
    </Form>
  );

  return (
    <>
      <DesktopContainer>
        {renderDesktopForm()}
      </DesktopContainer>
      <MobileContainer>
        {renderMobileForm()}
      </MobileContainer>
    </>
  );
};

JobsFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'jobFilterForm',
  destroyOnUnmount: false, // persist the form in the store on unmount
  enableReinitialize: true, // re-initalize the form when initialValues prop changes
})(JobsFilter);
