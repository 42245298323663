import * as userConstants from 'redux/constants/user.constants';
import * as appConstants from 'redux/constants/app.constants';
import { setCookie, removeCookie } from 'helpers/cookies';

const storageMiddleware = () => (next) => (action) => {
  const { data, type } = action;
  switch (type) {
  case appConstants.APP_ON_ACCEPT_COOKIES_REQUESTED:
    setCookie('acceptCookie', 1, {
      path: '/',
    });
    break;
  case userConstants.USER_ON_LOGIN_SUCCEEDED:
  case userConstants.USER_ON_ACTIVATE_ACCOUNT_SUCCEEDED:
    setCookie('accessToken', data.token.accessToken, {
      path: '/',
      maxAge: data.token.expiresIn,
    });
    break;
  case userConstants.USER_ON_LOGOUT:
  case userConstants.USER_ON_INITIALIZE_FAILED:
    removeCookie('accessToken');
    break;
  default:
    break;
  }
  return next(action);
};

export default storageMiddleware;
