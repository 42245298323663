import styled, { css } from 'styled-components';

import { BOOSTRAP_MEDIUM, MEDIUM_DESKTOP_SIZE } from 'constants/sizes';
import { DIPPER_BLUE } from 'styles/colors';

export const PieChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;

  @media (max-width: ${MEDIUM_DESKTOP_SIZE}px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 388px) {
    flex-direction: column-reverse;
    margin-top: 10px;
  }
`;

export const TitleList = styled.ul`
  flex: 1;
  padding: 0;
  align-self: center;

  @media (max-width: ${MEDIUM_DESKTOP_SIZE}px) {
    height: auto;
  }
`;

export const Legend = styled.li`
  font-size: 11px;
  line-height: 16px;
  color: ${DIPPER_BLUE};
  margin-top: 6px;
  list-style: none;
  padding-left: 1.1em;
  text-indent: ${({ showBullet }) => (showBullet && '-1.1em')};;

  ${({ showBullet }) => (showBullet
    ? css`
      ::before {
        content: '•';
        color: ${(props) => props.bulletColor};
        margin-right: 7px;
        font-size: 14px;
      }
    `
    : css`
      font-size: 13px;
      line-height: 15px;
    `
  )};

  @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
    font-size: 14px;
    margin-top: 16px;

    ::before {
      font-size: 16px;
    }
  }
`;

export const CustomLabel = styled.text`
  text-align: left;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0px;
`;

export const EmptyLabel = styled.h1`
  position: absolute;
  font-size: 25px;
  margin: 0;
  color: ${DIPPER_BLUE};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
