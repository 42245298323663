import styled from 'styled-components';

import { GreenButton } from 'styles/commons';
import { MOBILE_SIZE, TABLET_SIZE, SMALL_DESKTOP_SIZE } from 'constants/sizes';
import { BLUE_DARK } from 'styles/colors';

export const FormContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${SMALL_DESKTOP_SIZE}px) {
    padding-left: 15px;
  }
`;

export const InputsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;

  h1 {
    font: normal normal bold 32px/26px Mulish;
    color: ${BLUE_DARK};
    padding-bottom: 30px 
  }
  
  @media (max-width: ${TABLET_SIZE}px) {
    margin-top: 0;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const Inputs = styled.div`
  width: 90%;
  padding-bottom: 20px;
  
  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;

export const CustomButton = styled(GreenButton)`
  && {
    font: normal normal bold 14px/18px Mulish;
    padding: 12px 60px;
  }
`;

export const Form = styled.form`
  width: 100%;
  font-size: 18px;

  @media (max-width: ${TABLET_SIZE}px) {
    width: 90%;
  }
`;
