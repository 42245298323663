import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { jobProptypes, jobsArrayPropTypes } from 'helpers/propTypes';

import {
  onGetJobsFromCompany, onSetJobSelected, onJobDelete, onGetJobsForPoC,
  onSetFilters, onSetPageNumber, onSetStatusInactive,
} from 'redux/actions/job.actions';
import { roles } from 'constants/roles';
import { QUANTITY_JOB } from 'constants/configs';

import { Container, GreenButton } from 'styles/commons';

import Layout from 'containers/Layout';
import JobsView from 'views/Jobs';
import CustomModal from 'components/commons/Modal';

import {
  BLUE, BLUE_DARK, DIPPER_BLUE, WHITE,
} from 'styles/colors';
import PremiumContactContainer from './PremiumContactContainer';

const ModalLegend = styled.div`
  font: normal normal bold 14px/18px Mulish;
  letter-spacing: 0;
  color: ${DIPPER_BLUE};
  margin-bottom: 20px;
  letter-spacing: var(--unnamed-character-spacing-0);
  width: 200px;
`;

const CustomButton = styled(GreenButton)`
  background: ${BLUE} !important;

  :hover {
    background: ${BLUE_DARK} !important;
    color: ${WHITE} !important;
  }
`;

const ButtonText = styled.div`
  font: normal normal bold 14px/18px Mulish;
  padding: 13px 30px;
`;

const Jobs = (props) => {
  const {
    amountOfJobs, jobsList, jobPositionFilter, hasMore, locationFilter, onDeleteJobAction,
    onGetJobsForPocAction, onGetJobsFromCompanyAction, onSetJobSelectedAction, onSetFilterAction,
    onSetPageNumberAction, onSetStatusInactiveAction, selected, user, pageNumber, statusInactive,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPremiumCompanyModalOpen, setIsPremiumCompanyModalOpen] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const isCompany = user && user.role === roles.COMPANY.id;

  useEffect(() => {
    if (hasMore && user) {
      if (!isCompany) {
        onGetJobsForPocAction(QUANTITY_JOB, pageNumber, jobPositionFilter, locationFilter);
      } else {
        onGetJobsFromCompanyAction(QUANTITY_JOB, pageNumber, statusInactive);
      }
    }
  }, [
    onGetJobsFromCompanyAction, onGetJobsForPocAction, pageNumber,
    hasMore, user, statusInactive, isCompany, jobPositionFilter, locationFilter,
  ]);

  let scrollableListDiv;

  const handleScroll = (event) => {
    const { target } = event;
    scrollableListDiv = target;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
      onSetPageNumberAction(pageNumber + 1);
    }
  };

  const onDeleteJob = (id) => {
    setJobId(id);
    setIsModalOpen(true);
  };

  const onDelete = () => {
    onDeleteJobAction(jobId);
    setIsModalOpen(false);
    setJobId(null);
  };

  const onSetFilter = (formData) => {
    if (scrollableListDiv) {
      scrollableListDiv.scrollTop = 0;
    }
    onSetFilterAction(formData);
  };

  const renderModal = () => (
    <CustomModal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false); setJobId(null); }}>
      <ModalLegend>
        Are you sure you want to delete this job offer?
      </ModalLegend>
      <CustomButton onClick={onDelete}>
        <ButtonText>
          Yes, delete
        </ButtonText>
      </CustomButton>
    </CustomModal>
  );

  const renderPremiumCompanyModal = () => (
    <CustomModal
      isOpen={isPremiumCompanyModalOpen}
      onClose={() => setIsPremiumCompanyModalOpen(false)}
    >
      <PremiumContactContainer
        onCloseModal={() => setIsPremiumCompanyModalOpen(false)}
      />
    </CustomModal>
  );

  return (
    <Container>
      <Layout isLandscape hideAddReview smallPaddingMobile>
        {user
          && (
            <JobsView
              amountOfJobs={amountOfJobs}
              jobsList={jobsList}
              isCompany={isCompany}
              filterIsOpen={filterIsOpen}
              handleScroll={handleScroll}
              onDelete={onDeleteJob}
              onSubmitFilter={onSetFilter}
              setJobSelected={onSetJobSelectedAction}
              selectedJob={selected}
              setFilterIsOpen={setFilterIsOpen}
              onOpenCompanyPremiumModal={() => setIsPremiumCompanyModalOpen(true)}
              user={user}
              filters={{ jobPositionFilter, locationFilter }}
              companyJobFilter={statusInactive}
              setCompanyJobFilter={onSetStatusInactiveAction}
            />
          )}
        {user && renderModal()}
        {user && renderPremiumCompanyModal()}
      </Layout>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.data,
  jobsList: state.job.items,
  hasMore: state.job.hasMore,
  selected: state.job.selected,
  jobPositionFilter: state.job.jobPositionFilter,
  locationFilter: state.job.locationFilter,
  pageNumber: state.job.page,
  statusInactive: state.job.statusInactive,
  amountOfJobs: state.job.amountOfJobs,
});

const mapDispatchToProps = (dispatch) => ({
  onGetJobsFromCompanyAction: bindActionCreators(onGetJobsFromCompany, dispatch),
  onGetJobsForPocAction: bindActionCreators(onGetJobsForPoC, dispatch),
  onSetJobSelectedAction: bindActionCreators(onSetJobSelected, dispatch),
  onSetPageNumberAction: bindActionCreators(onSetPageNumber, dispatch),
  onSetStatusInactiveAction: bindActionCreators(onSetStatusInactive, dispatch),
  onDeleteJobAction: bindActionCreators(onJobDelete, dispatch),
  onSetFilterAction: bindActionCreators(onSetFilters, dispatch),
});

Jobs.defaultProps = {
  user: null,
  hasMore: true,
};

Jobs.propTypes = {
  amountOfJobs: PropTypes.number.isRequired,
  hasMore: PropTypes.bool,
  selected: jobProptypes.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.number.isRequired,
  }),
  jobsList: jobsArrayPropTypes.isRequired,
  jobPositionFilter: PropTypes.string.isRequired,
  locationFilter: PropTypes.string.isRequired,
  onDeleteJobAction: PropTypes.func.isRequired,
  onGetJobsForPocAction: PropTypes.func.isRequired,
  onGetJobsFromCompanyAction: PropTypes.func.isRequired,
  onSetJobSelectedAction: PropTypes.func.isRequired,
  onSetFilterAction: PropTypes.func.isRequired,
  onSetStatusInactiveAction: PropTypes.func.isRequired,
  onSetPageNumberAction: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  statusInactive: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
