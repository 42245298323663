import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardBox = styled.div`
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  text-align: center;
`;
