import styled, { css } from 'styled-components';
import {
  Button,
} from '@material-ui/core';
import { NavHashLink } from 'react-router-hash-link';

import {
  SMALL_DESKTOP_SIZE_WITH_PADDING, BOOSTRAP_MEDIUM, MOBILE_SIZE, SMALL_MOBILE_SIZE, TABLET_SIZE,
} from 'constants/sizes';
import {
  BLUE_DARK, DIPPER_BLUE, GOOGLE_RED, GRAY_BACKGROUND, GREEN_REVIEW, WHITE,
} from 'styles/colors';
import { GreenButton } from 'styles/commons';

import { ReactComponent as LogoGoogle } from './assets/logo-google.svg';
import { ReactComponent as POCUserIcon } from './assets/poc-user.svg';
import { ReactComponent as CompanyUserIcon } from './assets/company-user-g.svg';

import signUpImg from './assets/signUpImage.png';

export const Container = styled.div`
  background-image: url(${signUpImg});
  background-position: center top;
  background-repeat: no-repeat;

  @media (max-width: ${BOOSTRAP_MEDIUM}px){
    background-image: none;
  }
`;

export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 60px auto auto;
  max-width: 1024px;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    margin: 60px 25px auto;
  }

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    flex-direction: column;
    align-items: center;

    ${({ isPortraitMenuOpened }) => (isPortraitMenuOpened && css`
      box-shadow: 0px 3px 6px #00000017;
      opacity: 1;
      filter: blur(3px);
      -webkit-filter: blur(3px);    
    `)}
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    margin-top: 48px;
  }
`;

export const CardBox = styled.div`
  width: 50%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
  margin-bottom: 30px;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    width: 80%;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  color: ${DIPPER_BLUE};
`;

export const Title = styled.h1`
  text-align: left;
  text-align: flex-start;
  color: ${BLUE_DARK};
  margin: 32px 0 20px 0;
  font-size: 26px;
  font-weight: bold;
  line-height: 40px;
`;

export const TwoFieldOnLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
  }
`;

export const FormSelectorButton = styled.div`
  && {
    width: 48%;
    height: 50px;
    cursor: pointer;
    text-transform: none;
    font: normal normal normal 18px/35px Mulish;
    border-radius: 0px;
    border-bottom: 3px solid ${({ selected }) => (selected ? GREEN_REVIEW : DIPPER_BLUE)};
    color: ${DIPPER_BLUE};

    :hover {
      color: ${DIPPER_BLUE};
      background: ${GRAY_BACKGROUND};
    }    

    @media (max-width: ${MOBILE_SIZE}px) {
      width: 100%;
      margin-top: 15px;
    }
  }
`;

export const IconImage = styled.img`
  height: 40px;
  padding: 5px;

  @media(max-width: ${MOBILE_SIZE}px) {
    display: block;
    margin: auto;
  }
`;

const svgIconStyle = css`
  height: 40px;
  padding: 5px;
  vertical-align: middle;
  fill: ${({ selected }) => (selected ? GREEN_REVIEW : DIPPER_BLUE)};
`;

export const UserText = styled.div`
  display: flex;
  justify-content: flex-end;
  line-height: 20px;
  text-align: left;
  margin-left: 30px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 30px;
`;

export const POCUserImage = styled(POCUserIcon)`
  ${svgIconStyle}
`;

export const CompanyUserImage = styled(CompanyUserIcon)`
  ${svgIconStyle}
`;

export const OneFieldOnLineContainer = styled.div`
  display: flex;
`;

export const CustomLengthField = styled.div`
  width: 42%;

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;

export const Text = styled.div`
  font: normal normal normal 14px/35px Mulish;
  color: ${DIPPER_BLUE};
  justify-content: center;
  margin-top: 10px;
`;

export const SmallText = styled(Text)`
  font-size: 12px;
  margin-top: 50px;
`;

export const StyledAnchor = styled(NavHashLink)`
  margin: 0px 5px;
  color: ${GREEN_REVIEW};

  :hover {
    color: ${DIPPER_BLUE};
  }

  :focus {
    color: ${GREEN_REVIEW};
    outline: none;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    :hover {
      color: ${GREEN_REVIEW};
    }
  }
`;

export const OrContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font: normal normal normal 18px/35px Mulish;
  color: ${DIPPER_BLUE};
`;

export const Line = styled.div`
  margin: 0px 10px;
  width: 20%; 
  align-self: center;
  border-bottom: 1px solid ${GREEN_REVIEW};

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
    flex: 0 0 50%;
  }
`;

export const GoogleSection = styled.div`
  padding: 26px 20px 24px;
  justify-content: center;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    padding: 26px 0 24px
  }
`;

export const GoogleButton = styled(Button)`
  && {
    border:1px solid ${GOOGLE_RED};
    padding: 10px 34px;
    font: normal normal bold 14px/18px Mulish;
    text-transform: none;
    background: ${GOOGLE_RED};
    color: ${WHITE};
    fill: ${WHITE};

    :hover {
      color: ${GOOGLE_RED};
      background: transparent;
      fill: ${GOOGLE_RED};
    }

    @media (max-width: ${SMALL_MOBILE_SIZE}px) {
      width: 100%;
    }
  }
`;

export const GoogleLogo = styled(LogoGoogle)`
  margin: 0px 5px;
`;

export const CustomButton = styled(GreenButton)`
  && {
    padding: 10px 90px;
    font: normal normal bold 14px/18px Mulish;
  }
`;

export const ButtonContainer = styled.div`
  margin: 26px 20px;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    margin: 26px 0;
  }
`;
