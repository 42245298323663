import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import * as validates from 'helpers/validates';

import { PageContainer } from 'styles/commons';

import Input from 'components/commons/Input';
import CustomCheckbox from 'components/commons/Checkbox';
import StarsRating from 'components/commons/StarsRating';
import RadioButton from 'components/commons/RadioButton';
import AutoComplete from 'components/commons/AutoComplete';
import RadioGroup from 'components/commons/RadioGroup';

import GuidelinesText from 'components/commons/GuidelinesText';

import { REVIEW_DESCRIPTION, REVIEW_JOB_TITLE, REVIEW_TITLE } from 'constants/inputSizes';

import {
  CardBox, Form, Title, SubTitle, AutoCompleteDiv, TopInputsContainer, JobTitle, DateTitles,
  DateContainer, DateDiv, DateInputMonth, DateInputYear, CurrentlyWorkingDiv, RequiredField,
  AnswerDiv, QuestionText, CustomAnswersSection, DivCenterButton,
  CustomButton, AllText, DateSection, CreateReviewContainer, TextContainer, StyledAnchor,
} from './styles';

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Title is required';
  }
  if (!values.jobTitle) {
    errors.jobTitle = 'Job title is required';
  }
  if (!values.description) {
    errors.description = 'Description is required';
  }
  if (!values.company || !values.company.value) {
    errors.company = 'Company is required';
  }
  if (!values.companyIsGreatPlaceToWork) {
    errors.companyIsGreatPlaceToWork = 'Rating is required';
  }
  if (!values.companyTreatment) {
    errors.companyTreatment = 'Rating is required';
  }
  if (!values.companyDiversity) {
    errors.companyDiversity = 'Option is required';
  }
  if (!values.recommendCompany) {
    errors.recommendCompany = 'Option is required';
  }
  if (!values.personalSituation) {
    errors.personalSituation = 'Option is required';
  }
  if (!values.feedback) {
    errors.feedback = 'Option is required';
  }
  if (!values.anonymous) {
    errors.anonymous = 'Option is required';
  }
  // start date validation
  if (!values.startMonth) {
    errors.startMonth = 'Required';
  } else if (!/^(1[0-2]|0[1-9])$/.test(values.startMonth)) {
    errors.startMonth = 'Invalid month';
  }
  if (!values.startYear) {
    errors.startYear = 'Required';
  } else if (!/^(\d{4})$/.test(values.startYear) || parseInt(values.startYear, 10) < 1900
            || parseInt(values.startYear, 10) > new Date().getFullYear()) {
    errors.startYear = 'Invalid year';
  }
  if (values.startMonth && values.startYear) {
    if (parseInt(values.startYear, 10) === new Date().getFullYear()
      && parseInt(values.startMonth, 10) > new Date().getMonth() + 1) {
      errors.startMonth = 'Invalid date';
      errors.startYear = ' ';
    }
  }
  // end date validation
  if (!values.currentlyWorking) {
    if (values.endMonth) {
      if (!/^(1[0-2]|0[1-9])$/.test(values.endMonth)) {
        errors.endMonth = 'Invalid month';
      }
    } else {
      errors.endMonth = 'Required';
    }
    if (values.endYear) {
      if (!/^(\d{4})$/.test(values.endYear) || parseInt(values.endYear, 10) < 1900
          || parseInt(values.endYear, 10) > new Date().getFullYear()) {
        errors.endYear = 'Invalid year';
      }
    } else {
      errors.endYear = 'Required';
    }
    if (values.endMonth && values.endYear) {
      // end after start
      if (parseInt(values.startYear, 10) > parseInt(values.endYear, 10)
          || (values.startYear === values.endYear
          && parseInt(values.startMonth, 10) > parseInt(values.endMonth, 10))) {
        errors.endMonth = 'Invalid month';
        errors.endYear = 'Invalid year';
      }
      if (parseInt(values.endYear, 10) === new Date().getFullYear()
        && parseInt(values.endMonth, 10) > new Date().getMonth() + 1) {
        errors.endMonth = 'Invalid date';
        errors.endYear = ' ';
      }
    }
  }
  return errors;
};

const CreateReview = (props) => {
  const {
    CompanyAutocompleteDisable, companiesList, handleSubmit, onSubmit, submitting,
    currentlyWorking, invalid, pristine,
  } = props;

  return (
    <PageContainer className="row" data-testid="add-review-view">
      <CreateReviewContainer className="col-lg-12 col-xl-8">
        <Title> New review </Title>
        <CardBox>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <SubTitle>Company Information</SubTitle>
            <TopInputsContainer>
              <AutoCompleteDiv>
                <Field
                  component={AutoComplete}
                  name="company"
                  label="Your company name *"
                  size="small"
                  options={companiesList}
                  validate={validates.required}
                  disabled={CompanyAutocompleteDisable}
                  autoSelect
                />
              </AutoCompleteDiv>
              <JobTitle className="col-lg-6">
                <Field
                  component={Input}
                  name="jobTitle"
                  label="Job title"
                  size="small"
                  isItalic
                  maxLength={REVIEW_JOB_TITLE}
                />
              </JobTitle>
            </TopInputsContainer>
            <DateContainer>
              <DateDiv>
                <DateSection>
                  <DateTitles>Start</DateTitles>
                  <DateInputMonth>
                    <Field
                      component={Input}
                      name="startMonth"
                      label="MM"
                      isDateInput
                    />
                  </DateInputMonth>
                  <DateInputYear>
                    <Field
                      component={Input}
                      name="startYear"
                      label="YYYY"
                      isDateInput
                    />
                  </DateInputYear>
                </DateSection>
                <DateSection>
                  <DateTitles second>End</DateTitles>
                  <DateInputMonth>
                    <Field
                      component={Input}
                      name="endMonth"
                      label="MM"
                      disabled={currentlyWorking}
                      isDateInput
                    />
                  </DateInputMonth>
                  <DateInputYear isDateEnd>
                    <Field component={Input} name="endYear" label="YYYY" disabled={currentlyWorking} isDateInput />
                  </DateInputYear>
                </DateSection>
              </DateDiv>
              <CurrentlyWorkingDiv>
                <Field name="currentlyWorking" component={CustomCheckbox} label="I am currently working at this company" isSmaller />
              </CurrentlyWorkingDiv>
            </DateContainer>
            <SubTitle second>Survey on the company</SubTitle>
            <AnswerDiv>
              <QuestionText>
                1. Would you agree that your company is a great place to work?
              </QuestionText>
              <RequiredField>*</RequiredField>
            </AnswerDiv>
            <CustomAnswersSection className="col-lg-12">
              <Field
                component={StarsRating}
                name="companyIsGreatPlaceToWork"
                isColumn
              />
            </CustomAnswersSection>
            <AnswerDiv>
              <QuestionText>
                2. Do you feel that you are valued and viewed as competent at work?
              </QuestionText>
              <RequiredField>*</RequiredField>
            </AnswerDiv>
            <CustomAnswersSection className="col-lg-12">
              <Field
                component={StarsRating}
                name="companyTreatment"
                isColumn
              />
            </CustomAnswersSection>
            <AnswerDiv>
              <QuestionText>
                3. How does the company you work for embrace diversity, inclusion and equity?
              </QuestionText>
              <RequiredField>*</RequiredField>
            </AnswerDiv>
            <CustomAnswersSection>
              <Field
                component={RadioGroup}
                name="companyDiversity"
              >
                <RadioButton name="companyDiversity" text="They get it and are doing great" value="1" />
                <RadioButton name="companyDiversity" text="They have the right intent, but poor execution" value="2" />
                <RadioButton name="companyDiversity" text="Its only lip service" value="3" />
                <RadioButton name="companyDiversity" text="Doesn't matter at all" value="4" />
              </Field>
            </CustomAnswersSection>
            <AnswerDiv>
              <QuestionText>
                4. Would you recommend the company you work for to another professional
                {' '}
                of color to work?
              </QuestionText>
              <RequiredField>*</RequiredField>
            </AnswerDiv>
            <CustomAnswersSection>
              <Field
                component={RadioGroup}
                name="recommendCompany"
              >
                <div className="row">
                  <div className="col-6 col-lg-3">
                    <RadioButton name="recommendCompany" text="Yes" value="Yes" />
                  </div>
                  <div className="col-6 col-lg-3">
                    <RadioButton name="recommendCompany" text="No" value="No" />
                  </div>
                </div>
              </Field>
            </CustomAnswersSection>
            <AnswerDiv>
              <QuestionText>5. What subject best describes your situation?</QuestionText>
              <RequiredField>*</RequiredField>
            </AnswerDiv>
            <CustomAnswersSection>
              <Field
                component={RadioGroup}
                name="personalSituation"
              >
                <RadioButton name="personalSituation" text="Navigating career mobility" value="1" />
                <RadioButton name="personalSituation" text="Company culture" value="2" />
                <RadioButton name="personalSituation" text="Racial or ethnic discrimination" value="3" />
                <RadioButton name="personalSituation" text="Code switch / Imposter Syndrome" value="4" />
                <RadioButton name="personalSituation" text="Dealing with microaggresions" value="5" />
                <RadioButton name="personalSituation" text="Not ready to share yet" value="6" />
                <RadioButton name="personalSituation" text="Other" value="7" />
              </Field>
            </CustomAnswersSection>
            <AnswerDiv>
              <QuestionText>
                6. Would you like to recieve advice or feedback in regards to your
                {' '}
                workplace challenge?
              </QuestionText>
            </AnswerDiv>
            <CustomAnswersSection>
              <Field
                component={RadioGroup}
                name="feedback"
              >
                <div className="row">
                  <div className="col-6 col-lg-3">
                    <RadioButton name="feedback" text="Yes" value="Yes" />
                  </div>
                  <div className="col-6 col-lg-3">
                    <RadioButton name="feedback" text="No" value="No" />
                  </div>
                </div>
              </Field>
            </CustomAnswersSection>
            <AnswerDiv>
              <QuestionText>7. Share a review about your personal experience</QuestionText>
              <RequiredField>*</RequiredField>
            </AnswerDiv>
            <CustomAnswersSection>
              <p>
                If you are seeking advice, insights, or simply would like to
                {' '}
                share your experience as a professional of color in the workplace--
                {' '}
                leave us a message.
              </p>
            </CustomAnswersSection>
            <TextContainer className="col-lg-6">
              <Field
                component={Input}
                name="title"
                label="Title review"
                size="small"
                isItalic
                maxLength={REVIEW_TITLE}
              />
            </TextContainer>
            <TextContainer className="col-lg-12">
              <Field
                component={Input}
                name="description"
                label="Review"
                type="textarea"
                isItalic
                maxLength={REVIEW_DESCRIPTION}
              />
            </TextContainer>
            <AnswerDiv>
              <QuestionText>8. Do you want to publish your review as anonymous?</QuestionText>
              <RequiredField>*</RequiredField>
            </AnswerDiv>
            <CustomAnswersSection>
              <Field component={RadioGroup} name="anonymous">
                <div className="row">
                  <div className="col-6 col-lg-3">
                    <RadioButton name="anonymous" text="Yes" value="Yes" />
                  </div>
                  <div className="col-6 col-lg-3">
                    <RadioButton name="anonymous" text="No" value="No" />
                  </div>
                </div>
              </Field>
            </CustomAnswersSection>
            <CurrentlyWorkingDiv avoidPaddingLeft addBorderTop>
              <Field
                name="termsOfUse"
                component={CustomCheckbox}
                isSmaller
                validate={validates.required}
              />
              <p>
                I agree to the Dipper
                {' '}
                <StyledAnchor target="_blank" rel="noopener noreferrer" smooth to="/legal#terms-and-conditions"> Term of use </StyledAnchor>
                {' '}
                This review of my experience at my current or former employer is truthful.
              </p>
            </CurrentlyWorkingDiv>
            <DivCenterButton>
              <CustomButton type="submit" variant="contained" disabled={pristine || submitting || invalid} data-testid="button-send">
                Submit
              </CustomButton>
            </DivCenterButton>
          </Form>
        </CardBox>
      </CreateReviewContainer>
      <AllText className="d-none d-lg-block col-lg-4">
        <GuidelinesText />
      </AllText>
    </PageContainer>
  );
};

CreateReview.propTypes = {
  CompanyAutocompleteDisable: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  currentlyWorking: PropTypes.bool.isRequired,
  companiesList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'CreateReviewForm',
  validate,
})(CreateReview);
