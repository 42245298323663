import styled from 'styled-components';
import { Button } from '@material-ui/core';

import { MOBILE_SIZE, SMALL_MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';
import {
  WHITE, GRAY, GRAY_BACKGROUND, GREEN_REVIEW, LIGHT_GREEN, RED_ERROR,
} from 'styles/colors';

export const Section = styled.div`
  background-color: ${WHITE};
  border-bottom: 1px solid ${GRAY};
  padding: 100px 0;
  h1 {
    font-weight: bold;
  }
`;

export const Title = styled.h1`
  color: ${GRAY};
`;

export const PageContainer = styled.div`
  max-width: 1366px;
  margin: auto;
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 80px;
  margin-right: auto;
  margin-left: auto;
  background-color:${GRAY_BACKGROUND};

  @media (min-width: 200px) {
    padding-left: 0;
  }

  @media (min-width: ${TABLET_SIZE}px) {
    padding-left: 0px;
  }
`;

export const DivLink = styled.div`
  position: absolute;
  bottom: 0px;
  left: 40%;
`;

export const AuthSubmitButton = styled(Button)`
  && {
    font: normal normal bold 14px/18px Mulish;
    padding: 10px;
    margin: 20px;
    text-transform: initial;
    background: ${GREEN_REVIEW};
    color: ${WHITE};

    :hover {
      background: ${LIGHT_GREEN};
    }

    @media (max-width: ${MOBILE_SIZE}px) {
      width: 120px;
    }
  }
`;

export const GreenButton = styled(Button)`
  && {
    text-transform: initial;
    background: ${GREEN_REVIEW};
    color: ${WHITE};
    padding: 0;

    :hover {
      background: ${LIGHT_GREEN};
    }
  }
`;

export const HelpBlock = styled.div`
  position: absolute;
  font-size: 13px;
  width: 100%;
  line-height: 1.5;
  color: ${RED_ERROR};
  text-align: left;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    padding-bottom: ${({ hasPaddingBottom }) => (hasPaddingBottom ? '60px' : '0')};
  }
`;

export const AdminFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
