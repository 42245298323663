import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, reset } from 'redux-form';

import { onOpenPortraitMenu } from 'redux/actions/app.actions';
import { onRegister, onGoogleSignup } from 'redux/actions/user.actions';

import { PLATFORMS } from 'constants/sso';
import { roles } from 'constants/roles';

import SignupView from 'views/Signup';
import Layout from 'containers/Layout';

const SignupContainer = (props) => {
  const {
    isPortraitMenuOpened, onChange, onOpenPortraitMenuAction, onReset, onSubmit, onGoogleSignup,
  } = props;
  const [userType, setUserType] = useState(roles.USER.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [googleResponse, setGoogleResponse] = useState('');

  const onClickOutsideMenu = () => isPortraitMenuOpened && onOpenPortraitMenuAction();

  const onCloseModal = () => setIsModalOpen(false);

  const OnSubmitModal = (formData) => {
    const {
      email, familyName, givenName, googleId, imageUrl,
    } = googleResponse;
    const data = {
      ...formData,
      email,
      firstName: givenName,
      lastName: familyName,
      platformId: googleId,
      platformType: PLATFORMS.google,
      profilePic: imageUrl,
      role: userType,
    };
    onSubmit(data);
    onCloseModal();
  };

  const onUserTypeClickHandler = (id) => {
    onReset('signUpForm');
    setUserType(id);
    onChange('signUpForm', 'role', id);
  };

  const GoogleSignupSuccess = (response) => {
    setIsModalOpen(true);
    setGoogleResponse(response.profileObj);
  };

  const handleGoogle = (response) => {
    onGoogleSignup(JSON.stringify({ credential: response.credential, userType }));
  };

  return (
    <Layout hideAddReview>
      <SignupView
        initialValues={{ role: userType }}
        isPortraitMenuOpened={isPortraitMenuOpened}
        isModalOpen={isModalOpen}
        onClickOutsideMenu={onClickOutsideMenu}
        onCloseModal={onCloseModal}
        onSubmit={onSubmit}
        onSubmitModal={OnSubmitModal}
        onUserTypeClick={onUserTypeClickHandler}
        responseSuccessGoogle={GoogleSignupSuccess}
        handleGoogle={handleGoogle}
        responseFailedGoogle={onCloseModal}
        userType={userType}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isPortraitMenuOpened: state.app.isPortraitMenuOpened,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: bindActionCreators(change, dispatch),
  onOpenPortraitMenuAction: bindActionCreators(onOpenPortraitMenu, dispatch),
  onReset: bindActionCreators(reset, dispatch),
  onSubmit: bindActionCreators(onRegister, dispatch),
  onGoogleSignup: bindActionCreators(onGoogleSignup, dispatch),
});

SignupContainer.propTypes = {
  isPortraitMenuOpened: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpenPortraitMenuAction: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onGoogleSignup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer);
