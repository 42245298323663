import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';

import { PageContainer } from 'styles/commons';

import ItemsListWithShowSection from 'components/commons/ItemsListWithShowSection';
import Job from 'components/Job';
import JobDetails from 'components/JobDetails';
import { jobProptypes, jobsArrayPropTypes, userPropTypes } from 'helpers/propTypes';
import CorporateMembership from 'components/CorporateMembership';
import JobsFilter from 'components/commons/JobsFilter';
import CustomToggle from 'components/commons/CustomToggle';
import * as constants from './constants';

import {
  CorporateContainer, CustomParagraph, CustomLi, CustomUl, CustomButton, CustomSpan,
  CustomButtonMobile, DivCenter, EmptyViewContainer, GrayBorderBottom,
  HeaderContainer, HeaderTitle, JobsSection, JobsCustomImage, NewJobButton, NewJobIcon,
  TextSection, JobsTitle, JobsText, JobsSoonText, JobsCustomImageFlex, ToggleContainer,
  OnlyDesktop, OnlyMobile,
} from './styles';

const Jobs = (props) => {
  const {
    amountOfJobs, companyJobFilter, filterIsOpen, setCompanyJobFilter, jobsList, isCompany,
    handleScroll, onDelete, onOpenCompanyPremiumModal, onSubmitFilter, setJobSelected,
    selectedJob, setFilterIsOpen, user,
  } = props;

  const renderJob = (job, selected) => (
    <Job onDelete={onDelete} job={job} selected={selected} isCompany={isCompany} />
  );

  const renderJobDetails = (job, resetSelected) => (
    <JobDetails
      job={job}
      isCompany={isCompany}
      onDelete={(id) => { onDelete(id); resetSelected(); }}
    />
  );

  const renderHeaderForCompany = () => (
    <OnlyDesktop className="no-gutters row">
      <div className="col-lg-5 col-12 no-gutters">
        <HeaderContainer>
          <HeaderTitle>
            Job Openings
            <CustomSpan>
              (
              {amountOfJobs}
              )
            </CustomSpan>
          </HeaderTitle>
          <ToggleContainer>
            <CustomToggle toggled={companyJobFilter} setToggled={setCompanyJobFilter} />
          </ToggleContainer>
        </HeaderContainer>
      </div>
    </OnlyDesktop>
  );

  const renderHeaderForPoC = () => (
    <OnlyDesktop className="no-gutters row">
      <div className="col-lg-3 col-12 no-gutters">
        <HeaderTitle withBorderBottom>
          Job Openings
          <CustomSpan>
            (
            {amountOfJobs}
            )
          </CustomSpan>
        </HeaderTitle>
      </div>
      <div className="col-lg-9 col-12 no-gutters">
        <JobsFilter onSubmit={onSubmitFilter} setIsOpen={setFilterIsOpen} isOpen={filterIsOpen} />
      </div>
    </OnlyDesktop>
  );

  const renderHeaderForPoCEmpty = () => (
    <div className="no-gutters row">
      <div className="col-lg-5 col-12 no-gutters">
        <DivCenter>
          <JobsCustomImage />
        </DivCenter>
      </div>
      <div className="col-lg-7 col-12 no-gutters">
        <JobsSection>
          <TextSection data-testid="jobs-text">
            <GrayBorderBottom>
              <JobsTitle>
                {constants.JOB_NOT_FOUND_TITLE}
              </JobsTitle>
              <JobsText>
                {constants.JOB_NOT_FOUND_DESCRIPTION}
              </JobsText>
            </GrayBorderBottom>
            <JobsSoonText>
              {constants.JOB_TRY_ANOTHER_SEARCH}
            </JobsSoonText>
            <CustomUl>
              <CustomLi>
                {constants.JOB_TRY_ANOTHER_SEARCH_1}
              </CustomLi>
              <CustomLi>
                {constants.JOB_TRY_ANOTHER_SEARCH_2}
              </CustomLi>
              <CustomLi>
                {constants.JOB_TRY_ANOTHER_SEARCH_3}
              </CustomLi>
            </CustomUl>
          </TextSection>
          <JobsCustomImageFlex />
        </JobsSection>
      </div>
    </div>
  );

  const renderHeaderForCompanyEmpty = () => (
    <div className="no-gutters row">
      <div className="col-lg-5 col-12 no-gutters">
        <DivCenter>
          <JobsCustomImage />
        </DivCenter>
        <>
          <NewJobButton>
            <CustomButton to="/jobs/new">
              <AddIcon />
              New Job
            </CustomButton>
          </NewJobButton>
          <CustomButtonMobile to="/jobs/new">
            <NewJobIcon />
          </CustomButtonMobile>
        </>
      </div>
      <div className="col-lg-7 col-12 no-gutters row">
        <JobsSection>
          <TextSection data-testid="jobs-text">
            <GrayBorderBottom>
              <JobsTitle>
                {constants.JOB_NOT_FOUND_TITLE}
              </JobsTitle>
              <br />
            </GrayBorderBottom>
            <JobsSoonText>
              {constants.JOB_TRY_ANOTHER_SEARCH_COMPANY}
            </JobsSoonText>
          </TextSection>
          <JobsCustomImageFlex />
        </JobsSection>
      </div>
    </div>
  );

  const listJobsHeader = () => (
    <OnlyMobile>
      {!isCompany ? (
        <>
          <HeaderTitle withBorderBottom>
            Job Openings
            <CustomSpan>
              (
              {amountOfJobs}
              )
            </CustomSpan>
          </HeaderTitle>
          <JobsFilter onSubmit={onSubmitFilter} setIsOpen={setFilterIsOpen} isOpen={filterIsOpen} />
        </>
      ) : (
        <>
          <HeaderContainer>
            <HeaderTitle>
              Job Openings
              <CustomSpan>
                (
                {amountOfJobs}
                )
              </CustomSpan>
            </HeaderTitle>
            <ToggleContainer>
              <CustomToggle toggled={companyJobFilter} setToggled={setCompanyJobFilter} />
            </ToggleContainer>
          </HeaderContainer>
        </>
      )}
    </OnlyMobile>
  );

  const renderEmptyJob = () => (
    <>
      {listJobsHeader()}
      <EmptyViewContainer>
        {isCompany ? renderHeaderForCompanyEmpty() : renderHeaderForPoCEmpty()}
      </EmptyViewContainer>
    </>
  );

  const listJobFooter = () => (
    <>
      <NewJobButton>
        {isCompany && (
          <CustomButton to="/jobs/new">
            <AddIcon />
            New Job
          </CustomButton>
        )}
      </NewJobButton>
      {isCompany && (
        <CustomButtonMobile to="/jobs/new">
          <NewJobIcon />
        </CustomButtonMobile>
      )}
    </>
  );

  const renderJobsText = () => (
    <CustomParagraph data-testid="custom-paragraph">
      Job Openings detail
    </CustomParagraph>
  );

  const renderListJobs = () => (
    <>
      {!isCompany ? renderHeaderForPoC() : renderHeaderForCompany()}
      <ItemsListWithShowSection
        selected={selectedJob}
        handleScroll={handleScroll}
        items={jobsList}
        renderItem={renderJob}
        emptyItemsView={renderEmptyJob}
        renderItemSelected={renderJobDetails}
        listItemsHeader={listJobsHeader}
        listItemsFooter={listJobFooter}
        renderGoToListText={renderJobsText}
        setSelected={setJobSelected}
      />
    </>
  );

  const renderViewForNotPremiumCompany = () => (
    <CorporateContainer>
      <CorporateMembership onClick={onOpenCompanyPremiumModal} isJobView />
    </CorporateContainer>
  );

  return (
    <PageContainer data-testid="jobs-page">
      {(isCompany && !user.company.isPremium)
        ? renderViewForNotPremiumCompany()
        : renderListJobs()}
    </PageContainer>
  );
};

Jobs.propTypes = {
  amountOfJobs: PropTypes.number.isRequired,
  companyJobFilter: PropTypes.bool.isRequired,
  filterIsOpen: PropTypes.bool.isRequired,
  isCompany: PropTypes.bool.isRequired,
  jobsList: jobsArrayPropTypes.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpenCompanyPremiumModal: PropTypes.func.isRequired,
  selectedJob: jobProptypes.isRequired,
  setJobSelected: PropTypes.func.isRequired,
  setCompanyJobFilter: PropTypes.func.isRequired,
  user: userPropTypes.isRequired,
  handleScroll: PropTypes.func.isRequired,
  onSubmitFilter: PropTypes.func.isRequired,
  setFilterIsOpen: PropTypes.func.isRequired,
};

export default Jobs;
