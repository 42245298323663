import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isBootstrapMedium, isMobile } from 'helpers/devices';

import {
  ItemsList, Arrow, Container, Column, CustomList, CustomMenuItem, Row, DetailsContainer,
} from './styles';

const ItemsListWithShowSection = (props) => {
  const {
    classContainer, emptyItemsView, handleScroll, items, isCommunity, listItemsHeader,
    listItemsFooter, renderItem, renderItemSelected, renderGoToListText, setSelected,
    selected,
  } = props;

  const [listView, setListView] = useState(true);

  const renderGoToList = () => (
    <Row onClick={() => setListView(true)} data-testid="go-to-list-button">
      <Arrow />
      {renderGoToListText()}
    </Row>
  );

  const resetSelected = () => {
    setListView(true);
  };

  const onMenuItemClick = (item) => {
    setSelected(item);
    setListView(false);
  };

  const isMobileView = isMobile() || isBootstrapMedium();

  return (
    <ItemsList className={classContainer} data-testid="items-list-with-show-section-component">
      {items.length ? (
        <Container isCommunity={+isCommunity} className="row">
          {(!isMobileView || listView)
            && (
              <Column className="col-lg-5 col-12">
                {listItemsHeader()}
                <CustomList onScroll={handleScroll} data-testid="custom-list">
                  {items.map((item) => (
                    <CustomMenuItem
                      key={item.id}
                      className="col-lg-12 MenuItem"
                      button
                      onClick={() => onMenuItemClick(item)}
                      selected={!!selected && selected.id === item.id}
                      data-testid={`custom-menu-item-${item.id}`}
                    >
                      {renderItem(item, (!!selected && selected.id === item.id))}
                    </CustomMenuItem>
                  ))}
                </CustomList>
                {listItemsFooter()}
              </Column>
            )}
          {(!isMobileView || !listView)
            && (
              <DetailsContainer className="col-lg-7 col-12">
                {isMobileView && renderGoToList()}
                {selected && renderItemSelected(selected, resetSelected)}
              </DetailsContainer>
            )}
        </Container>
      ) : (
        emptyItemsView()
      )}
    </ItemsList>
  );
};

ItemsListWithShowSection.defaultProps = {
  classContainer: '',
  isCommunity: false,
  handleScroll: () => {},
};

ItemsListWithShowSection.propTypes = {
  selected: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  setSelected: PropTypes.func.isRequired,
  classContainer: PropTypes.string,
  handleScroll: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCommunity: PropTypes.bool,
  emptyItemsView: PropTypes.func.isRequired,
  listItemsHeader: PropTypes.func.isRequired,
  listItemsFooter: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderItemSelected: PropTypes.func.isRequired,
  renderGoToListText: PropTypes.func.isRequired,
};

export default ItemsListWithShowSection;
