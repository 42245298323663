import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { onResetPassword } from 'redux/actions/user.actions';

import LoginContainer from 'containers/LoginContainer';
import Modal from 'components/commons/Modal';
import ResetPassword from 'components/commons/ResetPassword';

const ResetPasswordContainer = (props) => {
  const { token, onResetPasswordAction } = props;
  const [isModalOpen, setIsModalOpen] = useState(true);

  const onCloseModal = () => setIsModalOpen(false);

  const OnSubmitModal = (formData) => {
    onResetPasswordAction({ ...formData, token });
    onCloseModal();
  };

  return (
    <>
      <LoginContainer />
      <Modal isOpen={isModalOpen} onClose={onCloseModal}>
        {/* TODO: Change user to actual user name */}
        <ResetPassword onSubmit={OnSubmitModal} userName="user" />
      </Modal>
    </>
  );
};

const mapStateToProps = (state, props) => ({
  token: props.match.params.token,
});

const mapDispatchToProps = (dispatch) => ({
  onResetPasswordAction: bindActionCreators(onResetPassword, dispatch),
});

ResetPasswordContainer.propTypes = {
  token: PropTypes.string.isRequired,
  onResetPasswordAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
