import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Input from 'components/commons/Input';

import {
  LoginContainer,
  CardBox,
  Container,
  Form,
  Title,
  OneFieldOnLineContainer,
  ForgotPasswordContainer,
  ForgotPasswordLink,
  OrContainer,
  Line,
  GoogleSection,
  SmallText,
  StyledAnchor,
  CustomButton,
  ButtonContainer,
} from './styles';

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
  ) {
    errors.username = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters long';
  }
  if (values.password && values.password.length > 250) {
    return 'Password maximum characters is 250';
  }
  return errors;
};

const Login = (props) => {
  const {
    handleSubmit,
    isPortraitMenuOpened,
    onClickOutsideMenu,
    onOpenModal,
    handleGoogle,
    onSubmit,
    submitting,
  } = props;

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      window.google.accounts.id.renderButton(document.getElementById('loginDiv'), {
        // type: "standard",
        theme: 'filled_black',
        // size: "small",
        text: 'signin_with',
        shape: 'pill',
      });

      // google.accounts.id.prompt()
    } else {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleGoogle,
        });

        window.google.accounts.id.renderButton(document.getElementById('loginDiv'), {
          // type: "standard",
          theme: 'filled_black',
          // size: "small",
          text: 'signin_with',
          shape: 'pill',
        });
      };
      document.head.appendChild(script);
    }
  }, [handleGoogle]);

  return (
    <Container>
      <LoginContainer
        data-testid="login-page"
        isPortraitMenuOpened={isPortraitMenuOpened}
        onClick={onClickOutsideMenu}
      >
        <CardBox data-testid="poc-signup-page">
          <Form onSubmit={handleSubmit(onSubmit)} data-testid="form">
            <Title>Share Your Voice and Find Your Space </Title>
            <OneFieldOnLineContainer>
              <Field
                component={Input}
                variant="standard"
                name="username"
                label="E-mail"
                type="email"
                isDarkLabel
              />
            </OneFieldOnLineContainer>
            <OneFieldOnLineContainer>
              <Field
                component={Input}
                variant="standard"
                name="password"
                label="Password"
                type="password"
                isDarkLabel
                data-testid="input-email"
                maxLength={250}
              />
            </OneFieldOnLineContainer>
            <ForgotPasswordContainer>
              <ForgotPasswordLink onClick={onOpenModal}>
                Forgot your password?
              </ForgotPasswordLink>
            </ForgotPasswordContainer>
            <ButtonContainer>
              <CustomButton
                type="submit"
                variant="contained"
                disabled={submitting}
                data-testid="button-send"
              >
                Log in
              </CustomButton>
            </ButtonContainer>
          </Form>
          <OrContainer className="col-lg-10">
            <Line className="col-lg-7 col-sm-6" />
            or
            <Line className="col-lg-7 col-sm-6" />
          </OrContainer>
          <GoogleSection>
            <div id="loginDiv" />
          </GoogleSection>
          <SmallText>
            Don&apos;t have an account?
            <StyledAnchor to="/signup">Sign up</StyledAnchor>
          </SmallText>
        </CardBox>
      </LoginContainer>
    </Container>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isPortraitMenuOpened: PropTypes.bool.isRequired,
  onClickOutsideMenu: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleGoogle: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'login',
  validate,
})(Login);
