import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';

import styled from 'styled-components';
import { GREEN_REVIEW, RED_ERROR } from 'styles/colors';
import { MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';

export const StarsRatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  flex-wrap: nowrap;
  
  svg {
    width: 24px;
  }
  
  label {
    margin-bottom: 0;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    padding-left: ${({ hasPadding }) => (hasPadding ? '10px' : '0')};
    flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
    justify-content: center;
  }
`;

export const CustomError = styled.div`
  color: ${RED_ERROR};
  padding-left: 20px;


  @media (max-width: ${TABLET_SIZE}px) {
     text-align: center;
     padding-left: 0;
  }
`;

export const RateText = styled.label`
  margin: 0px 15px 0 0;

  @media (max-width: ${MOBILE_SIZE}px) {
    margin: 0;
  }
`;

export const StyledRating = withStyles({
  iconFilled: {
    color: GREEN_REVIEW,
  },
  iconHover: {
    color: GREEN_REVIEW,
  },
})(Rating);

export const DescriptionText = styled.label`
  margin-left: 20px;
  margin-bottom: 0px;

  
  @media (max-width: ${MOBILE_SIZE}px) {
    margin-left: ${({ isColumn }) => (isColumn ? '0' : '15px')};
    width: ${({ isColumn }) => (!isColumn && '30%')};
  }
`;
