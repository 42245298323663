import styled, { css } from 'styled-components';
import ContentEditable from 'react-contenteditable';

import { BOOSTRAP_MEDIUM, MOBILE_SIZE } from 'constants/sizes';
import {
  GRAY, GRAY_REVIEW_DATE, DIPPER_BLUE, GRAY_COMMUNITY_LINES,
} from 'styles/colors';

export const FeedContainer = styled.div`
  ${({ hasBorderBottom }) => (hasBorderBottom && css`
    border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
    padding: 0;
  `)}

  @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
    padding: 0;
  }
`;

export const FeedSection = styled.div`
  vertical-align: middle;
  dispĺay: flex;
  flex-direction: column;
  width: 100%;

  ${({ iscommunity }) => (!iscommunity
    && css`
      border-top: 1px solid ${GRAY_COMMUNITY_LINES};
    `
  )};
  
  span {
    color: ${GRAY};
  }
`;

export const FeedText = styled.div`
  color: ${DIPPER_BLUE};
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0 8px;
`;

export const FeedTime = styled.div`
  width: fit-content;
  height: fit-content;
  display:flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  color: ${GRAY_REVIEW_DATE};
  font: 11px/14px Mulish-Italic;
  text-align: end;
`;

export const Description = styled(ContentEditable)`
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: ${DIPPER_BLUE};
  word-break: break-word;
  font-family: Mulish;
  
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ iscommunity }) => (iscommunity ? '2' : '3')};
  -webkit-box-orient: vertical;
  white-space: normal;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 34px;
  height: 34px;
  object-fit: cover;
`;

export const DivReplyIcons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0 5px;
  justify-content: flex-end;
`;

export const CommentNumber = styled.div`
  margin-right: 4px;
  font-size: 11px;
  color: ${DIPPER_BLUE};
  font-weight: medium;

`;

export const CommentLogo = styled.div`
  border-right: 1px solid ${GRAY_COMMUNITY_LINES};
  padding-right: 7px;
  margin-right: 7px;
  height: fit-content;

  #commentIcon {
    display: block;
  }
`;

export const ReplyLogo = styled.div`
  height: fit-content;
  cursor: pointer;

  #replyIcon {
    display: block;
  }
`;

export const ReplyContainer = styled.div`
  width: 95%;
  margin-left: 5%;
  padding-top: 5px;

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const MetaDataDiv = styled.div`  
  margin-right: 22px;
`;
