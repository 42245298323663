import React from 'react';
import PropTypes from 'prop-types';

import {
  PublicationContainer, PublicationLogo, Text,
} from './styles';

const Publication = (props) => {
  const { publication } = props;

  return (
    <PublicationContainer
      href={publication.url}
      onClick={() => window.open(publication.url)}
      data-testid="publication-container"
    >
      <PublicationLogo src={publication.logo} />
      <Text data-testid="publication-text">
        {publication.description}
      </Text>
    </PublicationContainer>
  );
};

Publication.propTypes = {
  publication: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default Publication;
