export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const QUANTITY_FEED = parseInt(process.env.REACT_APP_QUANTITY_FEED, 10);

export const QUANTITY_FEED_DASHBOARD = parseInt(process.env.REACT_APP_QUANTITY_FEED_DASHBOARD, 10);

export const QUANTITY_REVIEWS_COMPANY_PROFILE = parseInt(
  process.env.REACT_APP_QUANTITY_REVIEWS_COMPANY_PROFILE, 10,
);

export const INSIGHTS_LIMIT_COMPANY_RANKING = parseInt(
  process.env.REACT_APP_INSIGHTS_LIMIT_COMPANY_RANKING, 10,
);

export const QUANTITY_JOB = parseInt(process.env.REACT_APP_QUANTITY_JOB, 10);

export const REVIEWS_LIMIT_ALL = process.env.REACT_APP_REVIEWS_LIMIT_ALL;

export const REVIEWS_LIMIT_RECENT = process.env.REACT_APP_REVIEWS_LIMIT_RECENT;

export const GOOGLE_AUTH_ID = process.env.REACT_APP_GOOGLE_SSO_KEY;

export const GOOGLE_CAPTCHA = process.env.REACT_APP_GOOGLE_CAPTCHA;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
