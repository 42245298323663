import { reset } from 'redux-form';

import * as feedConstants from 'redux/constants/feed.constants';
import * as userConstants from 'redux/constants/user.constants';

const formMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
  case feedConstants.FEED_ON_ADD_SUCCEDED:
    store.dispatch(reset('createFeedForm'));
    break;
  case userConstants.USER_ON_CONTACT_SUCCEEDED:
    store.dispatch(reset('contactForm'));
    break;
  case userConstants.USER_ON_UPDATE_PASSWORD_SUCCEEDED:
    store.dispatch(reset('updatePasswordForm'));
    break;
  default:
    break;
  }
  return next(action);
};

export default formMiddleware;
