import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  onForgotPassword, onLogin, onGoogleLogin,
} from 'redux/actions/user.actions';
import { onOpenPortraitMenu } from 'redux/actions/app.actions';
import Layout from 'containers/Layout';
import ForgotPassword from 'views/Login/components/ForgotPassword';
import LoginView from 'views/Login';
import CustomModal from 'components/commons/Modal';

const LoginContainer = (props) => {
  const {
    isPortraitMenuOpened, onSubmit, onGoogleLogin, onOpenPortraitMenuAction,
    onSubmitForgotPasswordModal, location,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCloseModal = () => setIsModalOpen(false);
  const onOpenModal = () => setIsModalOpen(true);
  const onClickOutsideMenu = () => isPortraitMenuOpened && onOpenPortraitMenuAction();

  const OnSubmitModal = (formData) => {
    onSubmitForgotPasswordModal(formData);
    onCloseModal();
  };

  const onSubmitWithRedirection = (formData) => onSubmit(formData, location?.state?.from?.pathname);

  const handleGoogle = (response) => {
    onGoogleLogin(JSON.stringify({ credential: response.credential }),
    location?.state?.from?.pathname);
  };

  return (
    <Layout hideAddReview>
      <LoginView
        isPortraitMenuOpened={isPortraitMenuOpened}
        onClickOutsideMenu={onClickOutsideMenu}
        onOpenModal={onOpenModal}
        onSubmit={onSubmitWithRedirection}
        handleGoogle={handleGoogle}
      />
      <CustomModal isOpen={isModalOpen} onClose={onCloseModal}>
        <ForgotPassword onSubmit={OnSubmitModal} />
      </CustomModal>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isPortraitMenuOpened: state.app.isPortraitMenuOpened,
});

const mapDispatchToProps = (dispatch) => ({
  onOpenPortraitMenuAction: bindActionCreators(onOpenPortraitMenu, dispatch),
  onSubmit: bindActionCreators(onLogin, dispatch),
  onSubmitForgotPasswordModal: bindActionCreators(onForgotPassword, dispatch),
  onGoogleLogin: bindActionCreators(onGoogleLogin, dispatch),

});

LoginContainer.propTypes = {
  isPortraitMenuOpened: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  onOpenPortraitMenuAction: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitForgotPasswordModal: PropTypes.func.isRequired,
  onGoogleLogin: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
