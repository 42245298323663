import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { onGetAll, onGetRanking } from 'redux/actions/insight.actions';
import {
  insightSelector, rankingSelector,
} from 'redux/selectors/insight.selector';
import { isCompany } from 'constants/roles';
import { Container } from 'styles/commons';

import Layout from 'containers/Layout';
import InsightsView from 'views/Insights';

const InsightsContainer = (props) => {
  const {
    company, insights, onGetAllAction, onGetRankingAction, ranking,
    user,
  } = props;

  useEffect(() => {
    onGetAllAction(company?.id);
    onGetRankingAction();
  }, [onGetAllAction, onGetRankingAction, user, company]);

  return (
    <Container>
      <Layout isLandscape hideAddReview={user && isCompany(user.role)}>
        {!user ? <h1>Loading...</h1>
          : (
            <InsightsView
              companyCode={user?.company?.code}
              insights={insights}
              isCompanyUser={isCompany(user.role)}
              ranking={ranking}
            />
          )}
      </Layout>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  insights: insightSelector(state),
  ranking: rankingSelector(state),
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  onGetAllAction: bindActionCreators(onGetAll, dispatch),
  onGetRankingAction: bindActionCreators(onGetRanking, dispatch),
});

InsightsContainer.defaultProps = {
  user: null,
  company: null,
};

InsightsContainer.propTypes = {
  insights: PropTypes.arrayOf(
    PropTypes.shape({
      labels: PropTypes.arrayOf(PropTypes.PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })).isRequired,
      title: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
    }).isRequired,
  ).isRequired,
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  onGetAllAction: PropTypes.func.isRequired,
  onGetRankingAction: PropTypes.func.isRequired,
  ranking: PropTypes.shape({
    company: PropTypes.arrayOf(PropTypes.PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    stars: PropTypes.number.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    role: PropTypes.number.isRequired,
    isPremium: PropTypes.bool,
    company: PropTypes.shape({
      code: PropTypes.string.isRequired,
    }),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsContainer);
