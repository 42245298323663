import styled from 'styled-components';

import { MEDIUM_DESKTOP_SIZE, BOOSTRAP_MEDIUM, SMALL_MOBILE_SIZE } from 'constants/sizes';
import { GreenButton } from 'styles/commons';
import { BLUE, WHITE, DARK_GREEN_BUTTON } from 'styles/colors';
import { ReactComponent as CorporateSvg } from './assets/ilus-member.svg';

export const CorporateContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${BLUE};
  color: ${WHITE};
  border-radius: 6px;
  padding: 12px 0 17px 20px;

  @media (max-width: ${MEDIUM_DESKTOP_SIZE}px) {
    padding-right: 12px;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  h2 {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
  }

  h1 {
    font-weight: 800;
    font-size: 26px;
    line-height: 30px;
  }

  h6 {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding-top: 10px;
  }

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    justify-content: space-around;
  }
`;

export const TextContainer = styled.div`
  width: ${({ isJobView }) => (isJobView ? '100%;' : '59%;')};

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    flex: 1;
  }
`;

export const CorporateImage = styled(CorporateSvg)`
  width: 40%;
  max-width: 120px;
  height: auto;
  margin: ${({ isJobView }) => (isJobView ? '10px 20px 0' : '10px auto auto 0;')};

  @media (max-width: ${MEDIUM_DESKTOP_SIZE}px) {
    display: ${({ isJobView }) => (isJobView ? 'flex' : 'none')};
  }

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: flex;
    margin: 0;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    display: none;
  }
`;

export const Button = styled(GreenButton)`
  && {
    font: normal normal bold 14px/18px Mulish;
    padding: 11px 36px;
    align-self: center;
    
    :hover {
      background: ${DARK_GREEN_BUTTON};
    }
  }
`;
