import { createSelector } from 'reselect';
import { getStringDate } from 'helpers/dates';

const getAllJobs = (state) => state.job.items;
const selectedJob = (props) => props.location.state?.job;

export const allJobsSelector = createSelector(
  [getAllJobs],
  (jobs) => jobs,
);

export const otherJobsSelector = createSelector(
  [getAllJobs],
  (jobs) => jobs.filter((job) => !job.isFeatured, []),
);

export const locationSplitterSelector = createSelector(
  [selectedJob],
  (job) => job?.location?.split(', '),
);

const getCity = (splittedLocation) => splittedLocation[0];

const getState = (splittedLocation) => (splittedLocation.length === 2
  ? undefined : splittedLocation[1]);

const getCountry = (splittedLocation) => (splittedLocation.length === 2
  ? splittedLocation[1] : splittedLocation[2]);

export const jobSelector = createSelector([selectedJob, locationSplitterSelector],
  (job, splittedLocation) => !!job && {
    ...job,
    isActive: job.isActive ? '1' : '0',
    city: getCity(splittedLocation),
    state: getState(splittedLocation),
    country: getCountry(splittedLocation),
    noSalary: !job.toSalary,
    expirationDate: job.expirationDate ? getStringDate(job.expirationDate).substr(0, 10) : null,
  });
