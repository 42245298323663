import { createSelector } from 'reselect';

const companiesList = (state) => state.company.items;
const getRank = (state) => state.company.rank;

export const formatCompaniesList = createSelector(
  [companiesList],
  (companies) => companies.map(({ code, name }) => ({ key: code, value: name })),
);

export const rankSelector = createSelector([getRank],
  (rank) => !!rank && {
    ...rank,
    stars: Math.round(parseFloat(rank.stars)),
    recommendedPercentage: Math.round(rank.recommendedPercentage * 100),
  });
