import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { getStringMonthNameDayYear } from 'helpers/dates';
import { reviewProptypes } from 'helpers/propTypes';
import { getSubstringWithElipsis } from 'helpers/string';

import ReplyBoxInput from 'components/commons/ReplyBoxInput';

import { ReactComponent as ReplyAnswerIcon } from 'components/commons/assets/ReplyIcon.svg';
import DefaultProfile from 'components/commons/assets/avatar-poc.svg';
import DefaultProfileComp from 'components/commons/assets/avatar-company.svg';

import { isApproved, isPending, isRejected } from 'constants/reviewStatus';
import { REVIEW_COMPANY_ANSWER } from 'constants/inputSizes';

import {
  ReviewSection, RowContainer, ProfilePicture, ReviewTitle, CompanyTitle, ReviewJob,
  ReviewDate, TitleDateDiv, FlexContainer, PendingText, RejectedText,
  Title, ReviewContent, CompanyAnswerContainer, Row, AnswerText, AnswerLogo,
  ReplyContainer, NameAndDateDiv, SmallMobileVisibleContainer, DesktopVisibleContainer,
  EditButton, VerticalLine, DeleteButton, MoreOptionsContainer, MoreOptionsButton, PopUpOptions,
  AllScreen,
} from './styles';

const Review = (props) => {
  const {
    answerCloseInput, answerSelected, answerOpenInput, isCompany, areReviewsFromProfile,
    onSubmitAnswer, onDelete, review, selected,
  } = props;

  const onSubmit = (formData, reviewId) => {
    const data = { ...formData };
    data.id = reviewId;
    answerCloseInput();
    onSubmitAnswer(data);
  };

  const renderName = () => (
    review.anonymous ? 'Anonymous' : (`${review.user.firstName} ${review.user.lastName}`)
  );

  const renderAnswerSection = (reviewId) => (
    <ReplyContainer>
      <ReplyBoxInput
        onSubmit={(formData) => onSubmit(formData, reviewId)}
        name="companyAnswer"
        placeholder="Write an answer..."
        maxLength={REVIEW_COMPANY_ANSWER}
      />
    </ReplyContainer>
  );

  const renderEditAndDeleteButtons = () => (
    <>
      <Link
        to={{
          pathname: '/review/new',
          state: { review },
        }}
      >
        <EditButton />
      </Link>
      <VerticalLine />
      <DeleteButton onClick={(e) => { onDelete(review.id); e.stopPropagation(); }} />
    </>
  );

  const renderMoreOptionsButton = () => (
    <MoreOptionsContainer onClick={(e) => {
      answerOpenInput();
      e.stopPropagation();
    }}
    >
      {answerSelected
      && (
        <>
          <PopUpOptions onClick={(e) => e.stopPropagation()}>
            {renderEditAndDeleteButtons()}
          </PopUpOptions>
          <AllScreen onClick={(e) => { answerCloseInput(); e.stopPropagation(); }} />
        </>
      )}
      <MoreOptionsButton />
    </MoreOptionsContainer>
  );

  return (
    <ReviewSection
      changeBackgroundMarginAndBorder={areReviewsFromProfile}
      withOpacity={isPending(review) || isRejected(review)}
      data-testid="review-component"
    >
      <RowContainer addBorderAndPaddingBottom={areReviewsFromProfile}>
        {(isCompany || areReviewsFromProfile)
          && (
            <ProfilePicture
              src={review.user.profilePic && !review.anonymous
                ? review.user.profilePic.path
                : DefaultProfile}
            />
          )}
        <NameAndDateDiv
          haveFlexDirectionColumn={areReviewsFromProfile}
          withOverflowHidden={isCompany}
        >
          <TitleDateDiv
            haveFlexDirectionColumn={areReviewsFromProfile}
            haveDirectionRowMobile={isCompany}
          >
            <ReviewTitle haveFlexDirectionColumn={isCompany}>
              {isCompany || areReviewsFromProfile
                ? <CompanyTitle>{renderName()}</CompanyTitle>
                : <CompanyTitle>{review.company?.name}</CompanyTitle>}
              {!areReviewsFromProfile && (
                <ReviewJob haveMarginLeft={!isCompany}>
                  <>
                    {!isCompany && <span>|</span>}
                    {getSubstringWithElipsis(review.jobTitle, 40)}
                  </>
                </ReviewJob>
              )}
            </ReviewTitle>
            {isApproved(review)
              && (
                <ReviewDate hasFlexEnd={isCompany || areReviewsFromProfile}>
                  {getStringMonthNameDayYear(review.dateCreated)}
                </ReviewDate>
              )}
          </TitleDateDiv>
          {!isCompany && isApproved(review) && !areReviewsFromProfile && (
            <div>
              <SmallMobileVisibleContainer>
                {renderMoreOptionsButton()}
              </SmallMobileVisibleContainer>
              <DesktopVisibleContainer hide={selected}>
                {renderEditAndDeleteButtons()}
              </DesktopVisibleContainer>
            </div>
          )}
          {isPending(review)
            && (
              <FlexContainer>
                <PendingText>
                  Pending Review
                </PendingText>
              </FlexContainer>
            )}
          {isRejected(review)
            && (
              <FlexContainer>
                <RejectedText>
                  Rejected Review
                </RejectedText>
              </FlexContainer>
            )}
        </NameAndDateDiv>
      </RowContainer>
      <Title>{review.title}</Title>
      <ReviewContent>
        {review.description}
      </ReviewContent>
      {!areReviewsFromProfile && (review.companyAnswer
        ? (
          <CompanyAnswerContainer>
            <Row>
              <ProfilePicture
                src={review.company.logo ? review.company.logo.path : DefaultProfileComp}
              />
              <ReviewTitle haveFlexDirectionColumn={isCompany}>
                {review.company.name}
                <ReviewJob haveMarginLeft={!isCompany}>
                  {!areReviewsFromProfile && (
                    <>
                      {review.company.industry}
                    </>
                  )}
                </ReviewJob>
              </ReviewTitle>
            </Row>
            <AnswerText>
              {review.companyAnswer}
            </AnswerText>
          </CompanyAnswerContainer>
        )
        : isCompany && (
          !answerSelected
            ? (
              <AnswerLogo onClick={(e) => { answerOpenInput(); e.stopPropagation(); }} data-testid="answer-logo-button">
                <ReplyAnswerIcon />
              </AnswerLogo>
            )
            : renderAnswerSection(review.id)
        ))}
    </ReviewSection>
  );
};

Review.defaultProps = {
  areReviewsFromProfile: false,
  onDelete: () => {},
};

Review.propTypes = {
  answerSelected: PropTypes.bool.isRequired,
  answerCloseInput: PropTypes.func.isRequired,
  answerOpenInput: PropTypes.func.isRequired,
  areReviewsFromProfile: PropTypes.bool,
  isCompany: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  onSubmitAnswer: PropTypes.func.isRequired,
  review: reviewProptypes.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Review;
