import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import * as validates from 'helpers/validates';
import { validatePassword } from 'admin/helpers/formValidation';

import Input from 'components/commons/Input';

import * as constants from './constants';
import {
  Container,
  CustomButton,
  CustomLink,
  Form,
  Text,
  Title,
} from './styles';

const ResetPassword = (props) => {
  const {
    handleSubmit, invalid, onSubmit, userName,
  } = props;

  return (
    <Container>
      <Title>{constants.TITLE}</Title>
      <Text>
        {constants.TEXT}
        {' '}
        {userName}
        {' '}
        {constants.DIPPER_ACCOUNT}
      </Text>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="form">
        <Field
          component={Input}
          variant="standard"
          type="password"
          name="password"
          label="Type in your new password"
          validate={[validatePassword, validates.required]}
          maxLength={250}
        />
        <Field
          component={Input}
          variant="standard"
          type="password"
          name="confirmPassword"
          label="Repeat your new password"
          validate={[validates.password, validates.required]}
          hasMorePaddingTop
          maxLength={250}
        />
        <CustomButton
          type="submit"
          variant="contained"
          disabled={invalid}
          data-testid="button-send"
        >
          Change password
        </CustomButton>
        <CustomLink to="/signup">Create an account</CustomLink>
      </Form>
    </Container>
  );
};

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'resetPassword',
})(ResetPassword);
