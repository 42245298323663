import styled from 'styled-components';

import {
  GRAY, WHITE, DIPPER_BLUE, GRAY_REVIEW_DATE,
} from 'styles/colors';

import { ReactComponent as JobsImage } from './assets/ilus-job-box.svg';

export const CustomSpan = styled.span`
  font: italic normal normal 11px/13px Mulish;
  letter-spacing: 0px;
  color: ${GRAY_REVIEW_DATE};
`;

export const DashboardSection = styled.div`
  border: 1px solid ${GRAY};
`;

export const JobsSection = styled.div`
  position: relative;
  min-height: 152px;
  border-radius: 6px;
  background-color: ${WHITE};
  color: ${DIPPER_BLUE};
  padding: 16px;
  margin-top: 20px;
  text-align: end;
`;

export const JobsTitle = styled.div`
  color: ${DIPPER_BLUE};
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
`;

export const JobsText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding-left: 80px;

  @media (max-width: 1300px) {
    padding-left: 0px;
  }
`;

export const JobsCustomImage = styled(JobsImage)`
  width: 127px;
  heigth: 141px;
  position: absolute;
  top: 10px;
  left: -10px;
  z-index: 10;

  @media (max-width: 1300px) {
    display: none;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  
  h1 {
    color: ${DIPPER_BLUE};
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }
`;

export const ListContainer = styled.div`
  padding-bottom: 10px;
`;
