import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  feedPropTypes, insightsArrayPropTypes, jobsArrayPropTypes, userPropTypes,
} from 'helpers/propTypes';

import PremiumContactContainer from 'containers/PremiumContactContainer';

import CustomModal from 'components/commons/Modal';

import DashboardCompanyView from 'views/DashboardCompany';

const DashboardCompany = (props) => {
  const {
    amountOfJobs, feedsList, insights, jobsList, onSubmitAddComment, user,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModal = () => setIsModalOpen(false);
  const onOpenModal = () => setIsModalOpen(true);

  return (
    <>
      <DashboardCompanyView
        amountOfJobs={amountOfJobs}
        feedsList={feedsList}
        insights={insights}
        jobsList={jobsList}
        onSubmitAddComment={onSubmitAddComment}
        user={user}
        isPremium={user.isPremium}
        onOpenModal={onOpenModal}
      />
      <CustomModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
      >
        <PremiumContactContainer
          onCloseModal={onCloseModal}
        />
      </CustomModal>
    </>
  );
};

const mapStateToProps = (state) => ({
  amountOfJobs: state.job.amountOfJobs,
});

DashboardCompany.propTypes = {
  amountOfJobs: PropTypes.number.isRequired,
  feedsList: PropTypes.arrayOf(
    feedPropTypes,
  ).isRequired,
  insights: insightsArrayPropTypes.isRequired,
  jobsList: jobsArrayPropTypes.isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
  user: userPropTypes.isRequired,
};

export default connect(mapStateToProps, null)(DashboardCompany);
