import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import { isSmallMobile } from 'helpers/devices';
import { GOOGLE_CAPTCHA } from 'constants/configs';
import { HelpBlock } from 'styles/commons';

import Container from './styles';

const Captcha = (props) => {
  const { input, meta } = props;
  const { error, touched } = meta;

  return (
    <Container>
      <ReCAPTCHA
        sitekey={GOOGLE_CAPTCHA}
        onChange={input.onChange}
        style={{ display: 'inline-block' }}
        size={isSmallMobile() ? 'compact' : 'normal'}
      />
      <HelpBlock data-testid="help-block">{(touched && error) || ' '}</HelpBlock>
    </Container>
  );
};

Captcha.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default Captcha;
