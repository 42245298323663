import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';

import { PageContainer } from 'styles/commons';

import ItemsListWithShowSection from 'components/commons/ItemsListWithShowSection';
import ItemsList from 'components/commons/ItemsList';
import Review from 'components/Review';
import ReviewDetails from 'components/Review/components/ReviewDetails';
import ReviewEmpty from 'components/Review/components/ReviewEmpty';

import { reviewProptypes } from 'helpers/propTypes';
import {
  ReviewHeaderContainer, MyReviewsHeaderText, ReviewsAmountText, NewReviewButton, CustomButton,
  CustomParagraph, NewReviewMobile, NewReviewIcon,
} from './styles';

const Reviews = (props) => {
  const {
    isCompany, areReviewsFromProfile, handleScroll, onClick, onSubmitAnswer, onDelete,
    hasToRenderAllReviewDetails, reviewList, user, totalAmountOfReviews,
    companyCode, setReviewSelected, selectedReview,
  } = props;

  const [answerSelected, setAnswerSelected] = useState(null);

  const renderReview = (review, selected) => (
    <Review
      key={review.id}
      review={review}
      isCompany={isCompany}
      selected={selected}
      answerSelected={review.id === answerSelected}
      answerOpenInput={() => setAnswerSelected(review.id)}
      answerCloseInput={() => setAnswerSelected(null)}
      onSubmitAnswer={onSubmitAnswer}
      onDelete={onDelete}
      areReviewsFromProfile={areReviewsFromProfile}
    />
  );

  const renderReviewDetails = (review, resetSelected) => (
    <ReviewDetails
      hasToRenderAllReviewDetails={hasToRenderAllReviewDetails}
      review={review}
      isCompany={isCompany}
      onDelete={(id) => { onDelete(id); resetSelected(); }}
      areReviewsFromProfile={areReviewsFromProfile}
    />
  );

  const renderEmptyReview = () => (
    <ReviewEmpty isCompany={isCompany} />
  );

  const renderMyReviewText = () => (
    isCompany
      ? 'Reviews of your company'
      : 'My Reviews'
  );

  const listReviewsHeader = () => (
    <ReviewHeaderContainer dontHaveBorderBottom={areReviewsFromProfile} dontHavePaddingBottom={hasToRenderAllReviewDetails} className="row">
      <MyReviewsHeaderText fontSizeSmaller={hasToRenderAllReviewDetails}>
        {areReviewsFromProfile ? 'Reviews' : renderMyReviewText()}
      </MyReviewsHeaderText>
      <ReviewsAmountText
        hasLessPaddingTop={areReviewsFromProfile}
        hasNotPaddingTopAndBottom={hasToRenderAllReviewDetails}
      >
        (
        {!areReviewsFromProfile ? user.amountOfReviews : totalAmountOfReviews}
        )
      </ReviewsAmountText>
    </ReviewHeaderContainer>
  );

  const listReviewFooter = () => (
    <>
      <NewReviewButton>
        <CustomButton to="/review/new">
          <AddIcon />
          New Review
        </CustomButton>
      </NewReviewButton>
      <NewReviewMobile to="/review/new">
        <NewReviewIcon />
      </NewReviewMobile>
    </>
  );

  const renderReviewsCompanyText = () => (
    <CustomParagraph>
      My Review |
      {' '}
      <span>Company</span>
    </CustomParagraph>
  );

  return (
    !areReviewsFromProfile
      ? (
        <PageContainer data-testid="reviews-page">
          <ItemsListWithShowSection
            selected={selectedReview}
            handleScroll={handleScroll}
            items={reviewList}
            renderItem={renderReview}
            emptyItemsView={renderEmptyReview}
            renderItemSelected={renderReviewDetails}
            listItemsHeader={listReviewsHeader}
            listItemsFooter={() => !isCompany && listReviewFooter()}
            renderGoToListText={renderReviewsCompanyText}
            setSelected={setReviewSelected}
          />
        </PageContainer>
      )
      : (
        <ItemsList
          items={reviewList}
          renderItem={hasToRenderAllReviewDetails ? renderReviewDetails : renderReview}
          hasToRenderAllReviewDetails={hasToRenderAllReviewDetails}
          listItemsHeader={listReviewsHeader}
          onClick={onClick}
          showHeader={areReviewsFromProfile}
          companyCode={companyCode}
          emptyItemsText="This company does not have reviews yet"
        />
      )
  );
};

Reviews.defaultProps = {
  areReviewsFromProfile: false,
  hasToRenderAllReviewDetails: false,
  onClick: () => {},
  companyCode: '',
};

Reviews.propTypes = {
  areReviewsFromProfile: PropTypes.bool,
  hasToRenderAllReviewDetails: PropTypes.bool,
  handleScroll: PropTypes.func.isRequired,
  isCompany: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSubmitAnswer: PropTypes.func.isRequired,
  setReviewSelected: PropTypes.func.isRequired,
  selectedReview: reviewProptypes.isRequired,
  totalAmountOfReviews: PropTypes.number.isRequired,
  reviewList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  user: PropTypes.shape({
    amountOfReviews: PropTypes.number.isRequired,
  }).isRequired,
  companyCode: PropTypes.string,
};

export default Reviews;
