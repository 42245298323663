import { showNotification } from 'react-admin';

import * as userConstants from 'redux/constants/user.constants';
import * as jobConstants from 'redux/constants/job.constants';
import * as reviewConstants from 'redux/constants/review.constants';
import * as feedConstants from 'redux/constants/feed.constants';
import * as companyConstants from 'redux/constants/company.constants';

const redirectMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
  case userConstants.USER_ON_ACTIVATE_ACCOUNT_FAILED:
  case userConstants.USER_ON_LOGIN_FAILED:
  case userConstants.USER_ON_REGISTER_FAILED:
  case userConstants.USER_ON_CONTACT_FAILED:
  case feedConstants.FEED_ON_ADD_COMMENT_FAILED:
  case userConstants.USER_ON_UPDATE_PASSWORD_FAILED:
  case userConstants.USER_ON_FORGOT_PASSWORD_FAILED:
  case userConstants.USER_ON_RESET_PASSWORD_FAILED:
  case reviewConstants.REVIEW_ADD_ANSWER_FAILED:
  case reviewConstants.REVIEW_ON_DELETE_FAILED:
    store.dispatch(showNotification(action.error.message, 'warning', { autoHideDuration: 3000 }));
    break;
  case userConstants.USER_ON_FORGOT_PASSWORD_SUCCEEDED:
    store.dispatch(showNotification('Thank you. Please check your Inbox to continue with your request.', 'success', { autoHideDuration: 5000 }));
    break;
  case userConstants.USER_ON_LOAD_PROFILE_IMAGE_FAILED:
    store.dispatch(showNotification('There was a problem with the image type, try again with png, jpg or jpeg lower than 5MB.', 'warning', { autoHideDuration: 7000 }));
    break;
  case userConstants.USER_ON_REGISTER_SUCCEEDED:
    store.dispatch(showNotification('Successfully registered. Please check your email to activate your account', 'success', { autoHideDuration: 5000 }));
    break;
  case jobConstants.JOB_ON_ADD_OFFER_SUCCEDED:
    store.dispatch(showNotification('The job offer was successfully added', 'success', { autoHideDuration: 2000 }));
    break;
  case jobConstants.JOB_ON_UPDATE_SUCCEDED:
    store.dispatch(showNotification('The job was successfully updated', 'success', { autoHideDuration: 2000 }));
    break;
  case reviewConstants.REVIEW_ON_ADD_SUCCEDED:
    store.dispatch(showNotification('The review was successfully added', 'success', { autoHideDuration: 2000 }));
    break;
  case reviewConstants.REVIEW_ON_UPDATE_SUCCEDED:
    store.dispatch(showNotification('The review was successfully updated', 'success', { autoHideDuration: 2000 }));
    break;
  case userConstants.USER_ON_CONTACT_SUCCEEDED:
    store.dispatch(showNotification('Your contact information was successfully sent', 'success', { autoHideDuration: 7000 }));
    break;
  case userConstants.USER_ON_UPDATE_SUCCEEDED:
  case companyConstants.COMPANY_ON_UPDATE_SUCCEEDED:
    store.dispatch(showNotification('The profile was updated', 'success', { autoHideDuration: 2000 }));
    break;
  case feedConstants.FEED_ON_ADD_SUCCEDED:
    store.dispatch(showNotification('The feed was posted', 'success', { autoHideDuration: 2000 }));
    break;
  case userConstants.USER_ON_RESET_PASSWORD_SUCCEEDED:
    store.dispatch(showNotification('The password was reseted', 'success', { autoHideDuration: 2000 }));
    break;
  case userConstants.USER_ON_UPDATE_PASSWORD_SUCCEEDED:
    store.dispatch(showNotification('The password was updated', 'success', { autoHideDuration: 2000 }));
    break;
  case feedConstants.FEED_ON_ADD_COMMENT_SUCCEDED:
    store.dispatch(showNotification('The comment was posted', 'success', { autoHideDuration: 2000 }));
    break;
  case reviewConstants.REVIEW_ON_DELETE_SUCCEDED:
    store.dispatch(showNotification('The review was deleted successfully', 'success', { autoHideDuration: 2000 }));
    break;
  default:
    break;
  }
  return next(action);
};

export default redirectMiddleware;
