import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import { BLACK, GRAY_COMMUNITY_LINES } from 'styles/colors';

export const CustomSelect = styled(Select)`
  height: fit-content;
  width: 100%;
  color: ${BLACK};
  label {
    font-family: Lato;
    font-size: 18px;
    span {
      display: none;
    }
  }
`;

export const FormGroup = styled(FormControl)`
  flex-direction: column;
  padding-top: 10px;
  margin-bottom: 30px;
  width: 100%;
`;

export const CustomOption = styled.option`
  cursor: pointer;

  :hover {
    background-color: ${GRAY_COMMUNITY_LINES};
   }
`;
