import { GREEN_REVIEW } from 'styles/colors';

export function fromBoolToYesOrNo(bool) {
  return bool ? 'Yes' : 'No';
}

export const getSubstringWithElipsis = (string, size) => {
  if (string.length <= size) {
    return string;
  }
  return `${string.substring(0, size)}...`;
};

export const removeAllTagsExceptBreaks = (string) => string.replace(/<\/div>/g, ' ').replace(/<br>/g, '[[br]]').replace(/<[^>]*>/g, '').replace(/\[\[br\]\]/g, '<br>')
  .replace(/&amp;/g, '&');

export const RemoveAllTagsForSubmission = (string) => string.replace(/<\/div>/g, ' ').replace(/<br>/g, '\n').replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')
  .replace(/&amp;/g, '&');

export const limitStringSize = (string, size) => {
  if (string.length >= size) {
    return string.substring(0, size);
  }
  return string;
};

/* eslint-disable no-useless-escape */
export const regexLink = /((((https|http)?\:\/\/(www.)?)|\s*(www.))[a-z]+([.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/([^\s]+))?)/g;
export const regexSpan = /(<span[^>]*>)|(<\/span>)/g;
/* eslint-enable */

export const getLinksFromText = (string) => {
  const words = [];
  let item;

  /* eslint-disable-next-line */
  while (item = regexLink.exec(string)) {
    if (!words.includes(item)) words.push(item[1]);
  }
  return words;
};

export const addStyleToLinks = (string) => {
  const linksFound = getLinksFromText(string);

  let styledString = string;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < linksFound.length; i++) {
    styledString = styledString.replaceAll(linksFound[i], `<span style="color: ${GREEN_REVIEW}">${linksFound[i]}</span>`);
  }
  return styledString;
};

export const getLocation = (city, state, country) => {
  const parsedCity = city.replace(',', ' ');
  const parsedCountry = country.replace(',', ' ');
  if (!state) {
    return [parsedCity, parsedCountry].join(', ');
  }
  const parsedState = state.replace(',', ' ');
  return [parsedCity, parsedState, parsedCountry].join(', ');
};

export const removeCurrency = (numberWithCurrency) => {
  const numberAsString = numberWithCurrency.replace(/(kr|\$|£|€|₽|¥|₹|,|\.)/g, '')
    .replace(/(k|K)/g, '000')
    .replace(/(m|M)/g, '000000');
  return parseInt(numberAsString, 10);
};
