import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { onOpenMenu, onOpenPortraitMenu } from 'redux/actions/app.actions';
import { onLogout } from 'redux/actions/user.actions';

import { PortraitLinks, LandscapeLinks } from 'constants/links';

import SideMenu from 'components/SideMenu/Navbar';

const SideMenuContainer = (props) => {
  const {
    loading, user, onLogoutAction, isLandscape, onClickAction, isMenuOpened, onClickPortraitAction,
    isPortraitMenuOpened,
  } = props;

  const onClickPortrait = () => {
    if (!isLandscape) {
      onClickPortraitAction();
    }
  };

  return (
    <SideMenu
      loading={loading}
      user={user}
      onLogoutAction={onLogoutAction}
      links={isLandscape ? LandscapeLinks : PortraitLinks}
      isLandscape={isLandscape}
      onClick={onClickAction}
      onClickPortrait={onClickPortrait}
      isMenuOpened={isMenuOpened}
      isPortraitMenuOpened={isPortraitMenuOpened}
    />
  );
};

const mapStateToProps = (state) => ({
  loading: state.app.loading,
  user: state.user.data,
  isMenuOpened: state.app.isMenuOpened,
  isPortraitMenuOpened: state.app.isPortraitMenuOpened,
});

const mapDispatchToProps = (dispatch) => ({
  onLogoutAction: bindActionCreators(onLogout, dispatch),
  onClickAction: bindActionCreators(onOpenMenu, dispatch),
  onClickPortraitAction: bindActionCreators(onOpenPortraitMenu, dispatch),
});

SideMenuContainer.defaultProps = {
  user: null,
};

SideMenuContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  onLogoutAction: PropTypes.func.isRequired,
  onClickAction: PropTypes.func.isRequired,
  onClickPortraitAction: PropTypes.func.isRequired,
  isLandscape: PropTypes.bool.isRequired,
  isMenuOpened: PropTypes.bool.isRequired,
  isPortraitMenuOpened: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuContainer);
