import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { BLUE_DIPPER } from 'styles/colors';
import { HelpBlock } from 'styles/commons';

import { CustomDatePicker, FormGroup } from './styles';

const DatePicker = (props) => {
  const {
    disabled, input, isItalic, label, meta: { touched, error },
    name, size, showFormatDate, variant, min, max,
  } = props;
  const { value } = input;
  const [shrinkLabel, setShrink] = useState(showFormatDate || value);

  const useStyles = makeStyles(() => ({
    root: {
      '&::placeholder': {
        textAlign: 'center',
        fontFamily: isItalic ? 'Mulish-Italic' : 'Mulish',
        fontSize: '14px',
      },
      color: value ? BLUE_DIPPER : 'transparent',
      fontFamily: isItalic ? 'Mulish-Italic' : 'Mulish',
    },
    focused: {
      color: BLUE_DIPPER,
    },
  }));
  const classes = useStyles();
  const showLabelClasses = { root: classes.root, focused: classes.focused };

  const onFocus = (event) => {
    setShrink(true);
    if (input.onFocus) {
      input.onFocus(event);
    }
  };

  const onBlur = (event) => {
    if (event.target.value) {
      setShrink(true);
    }
    if (input.onBlur) {
      input.onBlur(event);
    }
  };

  return (
    <FormGroup>
      <CustomDatePicker
        {...input}
        data-testid="datepicker"
        disabled={disabled}
        error={touched && !!error}
        isItalic={isItalic}
        label={label}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        size={size}
        type="date"
        value={value}
        variant={variant}
        InputLabelProps={{
          shrink: shrinkLabel,
        }}
        InputProps={{
          classes: !showFormatDate ? showLabelClasses : {},
          inputProps: { min, max },
        }}
      />
      <HelpBlock data-testid="help-block">{(touched && error) || ' '}</HelpBlock>
    </FormGroup>
  );
};

DatePicker.defaultProps = {
  disabled: false,
  isItalic: false,
  max: null,
  min: null,
  size: 'medium',
  showFormatDate: false,
  variant: 'outlined',
};

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
  }).isRequired,
  isItalic: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  min: PropTypes.string,
  size: PropTypes.string,
  showFormatDate: PropTypes.bool,
  variant: PropTypes.string,
};

export default DatePicker;
