import axios from 'axios';
import { API_BASE_URL } from 'constants/configs';
import { getCookie } from 'helpers/cookies';

const api = axios.create({
  baseURL: API_BASE_URL,
});

function getOptions() {
  const accessToken = getCookie('accessToken');
  const options = {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };
  return options;
}

function errorResponse(error) {
  const { response } = error;
  let message = error;
  if (response) {
    const { data } = response;
    message = data.message;
  }
  return new Error(message);
}

export async function post(url, params) {
  try {
    const options = getOptions();
    const { data } = await api.post(url, params, options);
    return data;
  } catch (error) {
    throw errorResponse(error);
  }
}

export async function get(url) {
  try {
    const options = getOptions();
    const { data } = await api.get(url, options);
    return data;
  } catch (error) {
    throw errorResponse(error);
  }
}

export async function put(url, params) {
  try {
    const options = getOptions();
    const { data } = await api.put(url, params, options);
    return data;
  } catch (error) {
    throw errorResponse(error);
  }
}

export async function remove(url) {
  try {
    const options = getOptions();
    const { data } = await api.delete(url, options);
    return data;
  } catch (error) {
    throw errorResponse(error);
  }
}
