import { createGlobalStyle } from 'styled-components';

import mulishItalicWoff from './Mulish-Italic.woff';
import mulishWoff from './Mulish-Regular.woff';
import mulishExtraBold from './Mulish-ExtraBold.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Mulish-Italic';
    src: local('Mulish-Italic'), url(${mulishItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'Mulish';
    src: local('Mulish'), url(${mulishWoff}) format('woff');
  }

  @font-face {
    font-family: 'Mulish-ExtraBold';
    src: local('Mulish-ExtraBold  '), url(${mulishExtraBold}) format('woff');
  }

  body {
    font-family: Mulish;
  }
`;
