import { validatePassword } from 'admin/helpers/formValidation';

export const required = (value) => (value ? undefined : 'Required');

export const password = (value, allValues) => {
  if (value !== allValues.password && value !== allValues.newPassword) {
    return 'Password does not match';
  }
  return validatePassword(value);
};

export const email = (value) => (!!value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
  ? 'Invalid email address' : undefined);

export const wholeNumber = (value) => {
  if (!!value && (Number.isNaN(value) || value % 1 !== 0 || value < 0)) {
    return 'Must be a whole positive number';
  }
  return undefined;
};

export const date = (value) => (!!value && new Date(value) >= new Date()
  ? 'Invalid date' : undefined);

export const phoneNumber = (value) => (
  !!value && !/^(\+|\(|[0-9]|[)]|[-]){6,20}$/i.test(value)
    ? 'Invalid phone number' : undefined);

export const website = (value) => (!!value && /^(http|https):\/\/[^ "]+$/i.test(value)
  ? 'It does not have to start with http/https' : undefined);

export const socialMedia = (value) => (!!value && !/^(http|https):\/\/[^ "]+$/i.test(value)
  ? 'Please it has to begin with http/https' : undefined);

export const isValidNumberWithCurrency = (number) => {
  const re = /^(kr|\$|£|M|K|m|k|€|₽|¥|₹)?(([0-9]|,|\.)){0,20}(kr|\$|£|M|K|m|k|€|₽|¥|₹)?$/;
  return !re.test(number) ? 'Invalid amount' : undefined;
};
