import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Input from 'components/commons/Input';
import { validatePassword } from 'admin/helpers/formValidation';
import { roles, isPoc, isCompany } from 'constants/roles';
import CustomModal from 'components/commons/Modal';

import {
  COMPANY_NAME,
  USER_EMAIL,
  USER_FIRST_NAME,
  USER_LAST_NAME,
} from 'constants/inputSizes';
import SSOForm from './components/SSOForm';

import {
  ButtonContainer,
  CardBox,
  Container,
  CustomButton,
  CompanyUserImage,
  CustomLengthField,
  Form,
  FormSelectorButton,
  Line,
  GoogleSection,
  OneFieldOnLineContainer,
  OrContainer,
  UserText,
  ImageContainer,
  POCUserImage,
  SignUpContainer,
  SmallText,
  StyledAnchor,
  Text,
  Title,
  TwoFieldOnLineContainer,
} from './styles';

const validate = (values) => {
  const errors = {};
  if (isCompany(values.role) && !values.companyName) {
    errors.companyName = 'Required';
  }
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Required';
  } else {
    errors.password = validatePassword(values.password);
  }
  if (values.confirmPassword) {
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Password does not match';
    }
  } else {
    errors.confirmPassword = 'Required';
  }
  return errors;
};

const Signup = (props) => {
  const {
    handleSubmit,
    isModalOpen,
    isPortraitMenuOpened,
    onClickOutsideMenu,
    onCloseModal,
    onSubmit,
    onSubmitModal,
    onUserTypeClick,
    handleGoogle,
    submitting,
    userType,
  } = props;

  useEffect(() => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      window.google.accounts.id.renderButton(document.getElementById('signUpDiv'), {
        // type: "standard",
        theme: 'filled_black',
        // size: "small",
        text: 'signin_with',
        shape: 'pill',
      });

      // google.accounts.id.prompt()
    } else {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleGoogle,
        });

        window.google.accounts.id.renderButton(document.getElementById('signUpDiv'), {
          // type: "standard",
          theme: 'filled_black',
          // size: "small",
          text: 'signin_with',
          shape: 'pill',
        });
      };
      document.head.appendChild(script);
    }
  }, [handleGoogle]);

  const isCompanyUser = isCompany(userType);
  const isPocUser = isPoc(userType);

  return (
    <>
      <Container>
        <SignUpContainer
          data-testid="signup-page"
          isPortraitMenuOpened={isPortraitMenuOpened}
          onClick={onClickOutsideMenu}
        >
          <CardBox data-testid="poc-signup-page">
            <Form onSubmit={handleSubmit(onSubmit)} data-testid="form">
              <Title>Be the Guide To a Better Workplace</Title>
              <TwoFieldOnLineContainer>
                <FormSelectorButton
                  selected={isPocUser}
                  onClick={() => onUserTypeClick(roles.USER.id)}
                >
                  <div className="row">
                    <UserText>
                      Independent
                      <br />
                      Member
                    </UserText>
                    <ImageContainer>
                      <POCUserImage selected={isPocUser} />
                    </ImageContainer>
                  </div>
                </FormSelectorButton>
                <FormSelectorButton
                  selected={isCompanyUser}
                  onClick={() => onUserTypeClick(roles.COMPANY.id)}
                >
                  <div className="row">
                    <UserText>
                      Company
                      <br />
                      Representative
                    </UserText>
                    <ImageContainer>
                      <CompanyUserImage selected={isCompanyUser} />
                    </ImageContainer>
                  </div>
                </FormSelectorButton>
              </TwoFieldOnLineContainer>
              {isCompanyUser && (
                <OneFieldOnLineContainer>
                  <Field
                    component={Input}
                    variant="standard"
                    name="companyName"
                    label="Company name"
                    isDarkLabel
                    maxLength={COMPANY_NAME}
                  />
                </OneFieldOnLineContainer>
              )}
              <TwoFieldOnLineContainer>
                <CustomLengthField>
                  <Field
                    className=""
                    component={Input}
                    variant="standard"
                    name="firstName"
                    label="First Name"
                    isDarkLabel
                    maxLength={USER_FIRST_NAME}
                  />
                </CustomLengthField>
                <CustomLengthField>
                  <Field
                    component={Input}
                    variant="standard"
                    name="lastName"
                    label="Last Name"
                    isDarkLabel
                    maxLength={USER_LAST_NAME}
                  />
                </CustomLengthField>
              </TwoFieldOnLineContainer>
              <OneFieldOnLineContainer>
                <Field
                  component={Input}
                  variant="standard"
                  name="email"
                  label="E-mail"
                  type="email"
                  isDarkLabel
                  maxLength={USER_EMAIL}
                />
              </OneFieldOnLineContainer>
              <TwoFieldOnLineContainer>
                <CustomLengthField>
                  <Field
                    component={Input}
                    variant="standard"
                    name="password"
                    label="Password"
                    type="password"
                    isDarkLabel
                    maxLength={250}
                  />
                </CustomLengthField>
                <CustomLengthField>
                  <Field
                    component={Input}
                    variant="standard"
                    name="confirmPassword"
                    label="Confirm password"
                    type="password"
                    isDarkLabel
                    maxLength={250}
                  />
                </CustomLengthField>
              </TwoFieldOnLineContainer>
              <SmallText>
                By creating an account, you agree and accept our
                <StyledAnchor
                  target="_blank"
                  rel="noopener noreferrer"
                  smooth
                  to="/legal#terms-and-conditions"
                >
                  Terms and Conditions
                </StyledAnchor>
              </SmallText>
              <ButtonContainer>
                <CustomButton
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  data-testid="button-send"
                >
                  Create account
                </CustomButton>
              </ButtonContainer>
            </Form>
            <OrContainer className="col-lg-10">
              <Line className="col-lg-7 col-sm-6" />
              or
              <Line className="col-lg-7 col-sm-6" />
            </OrContainer>
            <GoogleSection>
              <div id="signUpDiv" />
            </GoogleSection>
            <Text>
              Already have an account?
              <StyledAnchor to="/login">Log in</StyledAnchor>
            </Text>
          </CardBox>
        </SignUpContainer>
      </Container>
      <CustomModal isOpen={isModalOpen} onClose={onCloseModal}>
        <SSOForm isCompany={isCompanyUser} onSubmit={onSubmitModal} />
      </CustomModal>
    </>
  );
};

Signup.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isPortraitMenuOpened: PropTypes.bool.isRequired,
  onClickOutsideMenu: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitModal: PropTypes.func.isRequired,
  onUserTypeClick: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  userType: PropTypes.number.isRequired,
  handleGoogle: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'signUpForm',
  validate,
})(Signup);
