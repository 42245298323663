import styled, { css } from 'styled-components';
import { MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';

import { DIPPER_BLUE, GREEN_REVIEW } from 'styles/colors';

import { ReactComponent as CloseQuotation } from './assets/close-quotation.svg';
import { ReactComponent as OpenQuotation } from './assets/open-quotation.svg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20px;

  .slick-slide {
    text-align: center;
  }

  .slick-dots {
    position: absolute;
    bottom: -303px;
    display: flex;
    margin: 0px;

    li {
      margin: 0;
    }
    
    
    button:before {
      color: ${GREEN_REVIEW};
      opacity: 1;
      font-size: 7px;
    }
    
    @media (max-width: 683px) {
      display: none !important;
    }
  }

  .slick-dots li.slick-active button:before {
    color: ${DIPPER_BLUE};
  }
`;

export const SliderContainer = styled.div`
  width: 60%;
  margin: auto;

  @media (max-width: ${TABLET_SIZE}px) {
    width: 77%;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 90%;
  }
`;

export const QuoteImage = styled.img`
  border-radius: 50%;
  width: 126px !important;
  height: 126px;
  cursor: pointer;
  object-fit: cover;

  ${({ active }) => (!active && css`
    padding: 20px;
    opacity: 0.6;
  `)};
`;

export const TextContainer = styled.div`
  width: 100%;
  height: 200px;
  text-align: center;
  margin-top: 22px;

  @media (max-width: 620px) {
    height: 270px;
  }

  @media (max-width: 442px) {
    height: auto;
  }
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: ${DIPPER_BLUE};
`;

export const JobTitle = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: ${DIPPER_BLUE};
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 22px;
  align-items: end;
`;

export const OpenQuotesImage = styled(OpenQuotation)`
  width: 54px;
  height: auto;
  margin-right: 20px;

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 31px;
    margin-right: 11px;
  }
`;

export const DescriptionText = styled.div`
  width: 60%;

  @media (max-width: ${TABLET_SIZE}px) {
    width: 73%;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 91%;
  }
`;

export const ClosingQuotesImage = styled(CloseQuotation)`
  width: 54px;
  height: auto;
  margin-left: 20px;

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 31px;
    margin-left: 11px;
  }
`;
