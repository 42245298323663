import styled, { css } from 'styled-components';

import {
  BLUE_DARK,
} from 'styles/colors';
import { BIG_MOBILE_SIZE, TABLET_SIZE, SMALL_DESKTOP_SIZE } from 'constants/sizes';

import Button from 'components/commons/Button';

export const ContactTitle = styled.h1`
  margin: 20px 0;
  width: 45%;
  color: ${BLUE_DARK};
  font-weight: bold;
  font-size: 32px;
  line-height: 26px;
  display: ${({ tablet }) => (tablet ? 'none' : 'flex')};
  
  ${({ displayNone }) => (displayNone && css`
    display: none;
  `)}

  @media (max-width: ${TABLET_SIZE}px) {
    display: ${({ tablet }) => (tablet ? 'flex' : 'none')};
    width: 100%;
    font-size: 30px;
    line-height: 42px;
    margin: 8px 0 0 -3px;

    ${({ displayNone }) => (displayNone && css`
      display: none;
  `)}
  }
`;

export const ContactContainer = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
`;

export const CustomForm = styled.form`
  width: 100%;
  margin-top: 5px;

  @media (max-width: ${TABLET_SIZE}px) {
    margin-top: 0;
    padding-left:  ${({ hasNotPadding }) => (hasNotPadding ? '0' : '70px')};;
  }

  @media (max-width: ${BIG_MOBILE_SIZE}px) {
    padding-left: 5px;
  }
`;

export const FirstSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Inputs = styled.div`
  width: ${({ isMessage }) => (isMessage ? '100%' : '40%')};
  

  ${({ biggerWidth }) => (biggerWidth && css`
    width: 100%;
  `)}

  @media (max-width: ${BIG_MOBILE_SIZE}px) {
    width: 100%;
  }
`;

export const ContactContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${SMALL_DESKTOP_SIZE}px) {
    margin-right: 15px;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    margin-left: 0;
  }
`;

export const InputsSection = styled.div`
  display: flex;
  flex-direction: ${({ displayColumn }) => (displayColumn ? 'column' : 'row')};
  justify-content: space-between;
  
  @media (max-width: ${TABLET_SIZE}px) {
    margin-top: 0;
  }

  @media (max-width: ${BIG_MOBILE_SIZE}px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const MessageDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SubmitButton = styled(Button)`
  width: 120px;
  padding: 11px 40px;
  margin-top: 34px; 
  align-self: ${({ hasAlignSelfCenter }) => (hasAlignSelfCenter ? 'center' : 'auto')};
`;
