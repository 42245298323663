import styled, { css } from 'styled-components';

import {
  TABLET_SIZE, MOBILE_SIZE, BOOSTRAP_MEDIUM, SMALL_TABLET_SIZE, SMALL_MOBILE_SIZE, BIG_MOBILE_SIZE,
} from 'constants/sizes';
import { BLUE_DARK, WHITE } from 'styles/colors';

import { ReactComponent as DipperStarsImage } from 'components/commons/assets/dipper-stars.svg';

export const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: ${WHITE};
  position: relative;

  @media (max-width: ${TABLET_SIZE}px) {
    flex-direction: column;
  }
`;

export const TextSection = styled.div`

  @media (max-width: ${TABLET_SIZE}px) {
    width: 100%;
  }
`;

export const CustomH5 = styled.h5`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
`;

export const CustomH1 = styled.h1`
  font-size: 32px;
  line-height: 26px;
  color: white !important;
  margin-bottom: 37px !important;
`;

export const ImagesSection = styled.div`
  display: flex;
  align-self: center;
  padding: 20px 0 0 67px;
  text-align: center;

  @media (max-width: ${TABLET_SIZE}px) {
    width: 100%;
    justify-content: center;
    padding: 0 20px;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    justify-content: space-between;
    padding: 0;
  }
`;

export const OneImageContainer = styled.div`
  @media (max-width: ${TABLET_SIZE}px) {
    margin-top: 40px;
  }

  ${({ rightImage }) => (rightImage && css`
    margin-left: 70px;

    @media (max-width: ${TABLET_SIZE}px) {
      margin-left: 100px;
    }
  
    @media (max-width: ${BIG_MOBILE_SIZE}px) {
      margin-left: 70px;
    }
  
    @media (max-width: ${MOBILE_SIZE}px) {
      margin-left: 40px;
    }

    @media (max-width: ${SMALL_MOBILE_SIZE}px) {
      margin-left: 0;
    }
  `)};
`;

export const CustomImage = styled.img`
  width: 250px;
  height: auto;
  border-radius: 50%;
  border: 1px solid ${BLUE_DARK};
  margin-bottom: 24px;
  object-fit: cover;

  -webkit-box-shadow: 0px 16px 70px 1px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 16px 70px 1px rgba(0,0,0,0.4);
  box-shadow: 0px 16px 70px 1px rgba(0,0,0,0.4);

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    width: 230px;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    width: 250px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 230px;
  }

  @media (max-width: ${BIG_MOBILE_SIZE}px) {
    width: 200px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 150px;
  }

  @media (max-width: 360px) {
    width: 100px;
  }
`;

export const NameText = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
`;

export const CoFounderText = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
`;

export const StarsLeft = styled(DipperStarsImage)`
  width: auto;
  height: 650px;
  position: absolute;
  opacity: 0.01;
  fill: ${WHITE};
  left: -150px;
  top: -100px;

  @media (max-width: ${TABLET_SIZE}px) {
    heigth: 519px;
    top: -50px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 390px;
    top: -80px;
    left: -75px;
  }
`;

export const StarsRight = styled(DipperStarsImage)`
  width: auto;
  height: 650px;
  position: absolute;
  opacity: 0.01;
  fill: ${WHITE};
  right: -250px;
  top: -52px;
  
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);

  @media (max-width: ${TABLET_SIZE}px) {
    height: 502px;
    right: -120px;
    top: 150px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 377px;
    right: -100px;
    top: 160px;
  }
`;
