import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledDiv, TextSection, CustomButton, CustomImage,
} from './styles';

const LandingCard = (props) => {
  const {
    hideButton, href, image, isReverse, renderText,
  } = props;

  return (
    <StyledDiv isReverse={isReverse}>
      <TextSection isReverse={isReverse}>
        {renderText()}
        {!hideButton
          && (
            <CustomButton href={href}>
              View more info
            </CustomButton>
          )}
      </TextSection>
      <CustomImage src={image} />
    </StyledDiv>
  );
};

LandingCard.defaultProps = {
  hideButton: false,
  isReverse: false,
};

LandingCard.propTypes = {
  hideButton: PropTypes.bool,
  href: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isReverse: PropTypes.bool,
  renderText: PropTypes.func.isRequired,
};

export default LandingCard;
