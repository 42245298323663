import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { TABLET_SIZE } from 'constants/sizes';
import {
  BLUE, BLUE_DARK, DIPPER_BLUE, GRAY_BACKGROUND, WHITE, GRAY_COMMUNITY,
} from 'styles/colors';

import { ReactComponent as newReviewIcon } from './assets/dipper-bt-new.svg';

export const ReviewHeaderContainer = styled.div`
  padding-bottom: ${({ dontHavePaddingBottom }) => (dontHavePaddingBottom ? '0' : '10px')};

  ${({ dontHaveBorderBottom }) => (
    dontHaveBorderBottom
      ? css`
      padding-left: 17px;
    `
      : `
      border-bottom: 1px solid ${GRAY_COMMUNITY};
      padding-left: 25px;
    `
  )};
`;

export const MyReviewsHeaderText = styled.div`
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};

  ${({ fontSizeSmaller }) => (
    fontSizeSmaller
      ? css`
      font-size: 15px;
      line-height: 19px;
    `
      : `
      font-size: 20px;
      line-height: 25px;
    `
  )};
`;

export const ReviewsAmountText = styled.div`
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: italic normal normal 11px/14px Mulish;
  letter-spacing: 0px;
  color: #9C9BB4;
  align-self: center;
  padding: ${({ hasLessPaddingTop }) => (hasLessPaddingTop ? '4px 5px 0px 3px' : '5px 5px 3px 3px')};

  ${({ hasNotPaddingTopAndBottom }) => (
    hasNotPaddingTopAndBottom
      && css`
      padding: 0 5px 0 3px;
    `
  )};
`;

export const NewReviewButton = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 0 30px;
  background: ${GRAY_BACKGROUND};
  justify-content: center;

  @media (max-width: ${TABLET_SIZE}px) {
    display:none;
  }
`;

export const CustomButton = styled(Link)`
  background: ${BLUE};
  color: ${WHITE};
  display: flex;
  justify-content: center;
  font: normal normal bold 14px/18px Mulish;
  padding: 0 32px;
  border-radius: 4px;
  align-items: center;
  height: 40px;
  margin: auto;

  :hover {
    background: ${BLUE_DARK};
    color: ${WHITE};
  }
`;

export const CustomParagraph = styled.p`
  color: ${DIPPER_BLUE};
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;  
  margin: 0;
  
  span {
    font-weight: normal;
  }
`;

export const NewReviewMobile = styled(Link)`
  display: none;

  @media (max-width: ${TABLET_SIZE}px) {
    border-radius: 50%;
    display: flex;
    background-color: ${BLUE};
    padding: 13px;
    align-self: flex-end;
    margin-bottom: 26px;
    position: absolute;
    bottom: 0;
  }
`;

export const NewReviewIcon = styled(newReviewIcon)`
  width: 17px;
  height: 17px;
`;
