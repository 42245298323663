import styled from 'styled-components';

import { GREEN_REVIEW, LIGHT_GREEN, WHITE } from 'styles/colors';

export const CustomButton = styled.button`
  border: 0;
  background: ${GREEN_REVIEW};
  color: ${WHITE};
  font: normal normal bold 14px/18px Mulish;
  padding: 14px 30px;
  border-radius: 4px;
  cursor: pointer;

  :hover {
    background: ${LIGHT_GREEN};
    color: ${WHITE};
  }
`;

export default { CustomButton };
