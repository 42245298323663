import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { CustomButton } from './styles';

const Button = (props) => {
  const {
    children, className, disabled, href, onClick, type,
  } = props;

  const renderButton = () => (
    <CustomButton
      className={className}
      data-testid="button"
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </CustomButton>
  );

  return href ? <Link to={href}>{renderButton()}</Link> : renderButton();
};

Button.defaultProps = {
  className: '',
  disabled: false,
  href: null,
  onClick: () => {},
  type: 'button',
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

export default Button;
