import { NavHashLink } from 'react-router-hash-link';
import styled from 'styled-components';

import { GreenButton } from 'styles/commons';
import {
  DIPPER_BLUE, GREEN_REVIEW, GRAY_BAR, GRAY_ALTO,
} from 'styles/colors';
import { MOBILE_SIZE, SMALL_MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';

export const Title = styled.div`
  color: ${DIPPER_BLUE};
  text-align: left;
  margin: 10px 0;
  font: normal normal bold 20px/25px Mulish;

  @media (max-width: ${TABLET_SIZE}px) {
    margin-left: 0;
  }
`;

export const SubTitle = styled.div`
  color: ${DIPPER_BLUE};
  text-align: left;
  font: normal normal bold 16px/20px Mulish;
  padding: ${({ second }) => (second ? '30px 0 0' : '0 0 15px')};
`;

export const Form = styled.form`
  width: 100%;
`;

export const CardBox = styled.div`
  padding: 30px 20px 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  background-color: white;
  border-radius: 10px;
`;

export const TopInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${TABLET_SIZE}px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const AutoCompleteDiv = styled.div`
  width: 50%;
  
  @media (max-width: ${TABLET_SIZE}px) {
    width: 100%;
    padding: 0;
  }
`;

export const JobTitle = styled.div`
  width: 50%;
  padding: 0 10px 13px 0;
  margin-bottom: 0 !important;

  @media (max-width: ${TABLET_SIZE}px) {
    width: 100%;
    padding: 0 0 23px;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${TABLET_SIZE}px) {
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    align-items: space-between;
  }
`;

export const DateDiv = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${TABLET_SIZE}px) {
    width: 100%;
    justify-content: space-around;
    padding-bottom: 25px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE - 56}px) {
    flex-direction: column;
    align-self: center;
    padding-bottom: 0;
  }
`;

export const DateSection = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    padding-bottom: 35px;
  }
`;

export const CreateReviewContainer = styled.div`
  padding: 0;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    padding: 0;
  }
`;
export const DateTitles = styled.p`
  color: ${DIPPER_BLUE};
  margin-right: 12px;
  padding-top: 22px;
  font: 12px/16px Mulish;
  padding-left: ${({ second }) => (second ? '5px' : '0')};

  @media (max-width: ${TABLET_SIZE}px) {
    margin-right: 5px;
    padding-top: 12px;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    margin-right: ${({ second }) => (second ? '7px' : '5px')};
  }
`;

export const DateInputMonth = styled.div`
  margin-right: 8px;
  width: 60px;
`;

export const DateInputYear = styled.div`
  margin-right: ${({ isDateEnd }) => (isDateEnd ? '0' : '32px')};
  width: 60px;

  @media (max-width: ${TABLET_SIZE}px) {
    margin-right: ${({ isDateEnd }) => (isDateEnd ? '0' : '10px')};
  }
`;

export const CurrentlyWorkingDiv = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ avoidPaddingLeft }) => (avoidPaddingLeft ? '15px 0 0' : '15px 0 0 40px')};
  border-top: ${({ addBorderTop }) => (addBorderTop && `1px solid ${GRAY_BAR}`)};
  margin-top:  ${({ addBorderTop }) => (addBorderTop && '15px')};

  .MuiFormControlLabel-label {
    color: ${DIPPER_BLUE};
    font: 12px/16px Mulish;
  }

  .MuiFormControlLabel-root {
    margin: 0;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    padding: 0;
    padding: ${({ addBorderTop }) => (addBorderTop ? '15px 0 0' : '0')};
    align-self: flex-start;
  }

  p {
    margin: 0;
    font: 12px/16px Mulish;
    color: ${DIPPER_BLUE};
  }
`;

export const StyledAnchor = styled(NavHashLink)`
  font: 12px/16px Mulish;
  color: ${GREEN_REVIEW};
`;

export const AnswerDiv = styled.div`  
  margin-top: 25px;
  padding-bottom: 8px;
`;

export const QuestionText = styled.span`
  font: normal normal bold 14px/20px Mulish;
  color: ${DIPPER_BLUE};
`;

export const RequiredField = styled.span`
  margin-left: 5px;
  color: ${GREEN_REVIEW};
  font-size: 20px;
`;

export const CustomAnswersSection = styled.div`
  color: ${DIPPER_BLUE};
  font: 12px/16px Mulish;
  padding-left: 15px;
 
  @media (max-width: ${MOBILE_SIZE}px){
    .MuiFormControlLabel-label{
      padding-left: 10px;
      span {
        padding-left:0 
      }
    } 
  }
`;

export const DivCenterButton = styled.div`
  text-align: center;
  margin-top: 42px
`;

export const CustomButton = styled(GreenButton)`
  && {
    text-transform: capitalize;
    padding: 8px 30px; 
    background: ${({ disabled }) => (disabled ? GRAY_ALTO : GREEN_REVIEW)} !important;
  }
`;

export const AllText = styled.div`
  padding-right: 0px !important;
  padding-top: 45px;
`;

export const TextContainer = styled.div`
  padding-bottom: 20px;
`;
