import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, makeStyles, Radio } from '@material-ui/core';

import { GREEN_REVIEW, GRAY_COMMUNITY_LINES } from 'styles/colors';

import { StyledSpan } from './styles';

const useStyles = makeStyles(() => ({
  checked: {},
  radio: {
    color: GRAY_COMMUNITY_LINES,
    height: '17px',
    width: '17px',
    '&$checked': {
      color: GREEN_REVIEW,
    },
  },
  root: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '12px',
    marginLeft: '0',
  },
}));

const RadioButton = (props) => {
  const { isBold, text, value } = props;
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ root: classes.root }}
      control={<Radio classes={{ root: classes.radio, checked: classes.checked }} value={value} />}
      label={<StyledSpan isBold={isBold}>{text}</StyledSpan>}
    />
  );
};

RadioButton.defaultProps = {
  isBold: true,
};

RadioButton.propTypes = {
  isBold: PropTypes.bool,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default RadioButton;
