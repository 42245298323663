import * as React from 'react';
import PropTypes from 'prop-types';
import { Admin, Resource } from 'react-admin';

import UserIcon from '@material-ui/icons/Group';

import { UserList, UserEdit, UserCreate } from 'admin/views/User';
import { JobList, JobEdit, JobCreate } from 'admin/views/Job';
import { ReviewList, ReviewEdit, ReviewCreate } from 'admin/views/Review';
import { CompanyList, CompanyEdit, CompanyCreate } from 'admin/views/Company';
import { FeedList, FeedCreate, FeedEdit } from 'admin/views/Feed';
import { CommentList, CommentCreate, CommentEdit } from 'admin/views/Comment';

// import Dashboard from 'admin/views/Dashboard';

const Administrator = (props) => {
  const { authProvider, dataProvider, history } = props;

  return (
    <Admin
      // dashboard={Dashboard}
      authProvider={authProvider}
      dataProvider={dataProvider}
      history={history}
    >
      <Resource options={{ label: 'Users' }} name="users" list={UserList} edit={UserEdit} create={UserCreate} icon={UserIcon} />
      <Resource options={{ label: 'Reviews' }} name="reviews" list={ReviewList} edit={ReviewEdit} create={ReviewCreate} />
      <Resource options={{ label: 'Companies' }} name="companies" list={CompanyList} edit={CompanyEdit} create={CompanyCreate} />
      <Resource options={{ label: 'Feeds' }} name="feeds" list={FeedList} edit={FeedEdit} create={FeedCreate} />
      <Resource options={{ label: 'Comments' }} name="comments" list={CommentList} edit={CommentEdit} create={CommentCreate} />
      <Resource options={{ label: 'Jobs' }} name="jobs" list={JobList} edit={JobEdit} create={JobCreate} />
    </Admin>
  );
};

Administrator.propTypes = {
  authProvider: PropTypes.func.isRequired,
  dataProvider: PropTypes.func.isRequired,
  history: PropTypes.elementType.isRequired,
};

export default Administrator;
