import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import * as validates from 'helpers/validates';

import Input from 'components/commons/Input';

import {
  ContactContainer, CustomForm, ContactContent, ContactTitle, InfoSection,
  InputsSection, Inputs, MessageDiv, SubmitButton,
} from './styles';

const Contact = ({ handleSubmit, onSubmit, isFromModal }) => (
  <ContactContainer data-testid="contact-component">
    <ContactTitle displayNone={isFromModal} tablet>
      Contact us
    </ContactTitle>
    <ContactContent>
      <ContactTitle displayNone={isFromModal}>Contact us</ContactTitle>
      <CustomForm hasNotPadding={isFromModal} onSubmit={handleSubmit(onSubmit)}>
        <InfoSection data-testid="contact-form">
          <InputsSection displayColumn={isFromModal}>
            <Inputs biggerWidth={isFromModal}>
              <Field
                component={Input}
                variant="standard"
                name="firstName"
                label={isFromModal ? 'Full name*' : 'First Name*'}
                validate={validates.required}
                size="small"
                isDarkLabel
              />
            </Inputs>
            <Inputs biggerWidth={isFromModal}>
              <Field
                component={Input}
                variant="standard"
                name={isFromModal ? 'companyName' : 'lastName'}
                label={isFromModal ? 'Company Name*' : 'Last Name*'}
                validate={validates.required}
                size="small"
                isDarkLabel
              />
            </Inputs>
          </InputsSection>
          <InputsSection displayColumn={isFromModal}>
            <Inputs biggerWidth={isFromModal}>
              <Field
                component={Input}
                variant="standard"
                name="phoneNumber"
                label="Phone Number*"
                validate={[validates.required, validates.phoneNumber]}
                size="small"
                isDarkLabel
              />
            </Inputs>
            <Inputs biggerWidth={isFromModal}>
              <Field
                component={Input}
                variant="standard"
                name="email"
                label="Email*"
                validate={[validates.required, validates.email]}
                size="small"
                isDarkLabel
              />
            </Inputs>
          </InputsSection>
          <MessageDiv>
            <Inputs isMessage biggerWidth={isFromModal}>
              <Field
                component={Input}
                variant="standard"
                name="message"
                label="Message*"
                validate={validates.required}
                size="small"
                type="textarea"
                isDarkLabel
              />
            </Inputs>
          </MessageDiv>
          <SubmitButton hasAlignSelfCenter={isFromModal} type="submit">
            Send
          </SubmitButton>
        </InfoSection>
      </CustomForm>
    </ContactContent>
  </ContactContainer>
);
Contact.defaultProps = {
  isFromModal: false,
};

Contact.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFromModal: PropTypes.bool,
};

export default reduxForm({
  form: 'contactForm',
})(Contact);
