import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as constants from 'redux/constants/feed.constants';
import { getRecentFeed, addNewFeed, addNewCommentToFeed } from 'services/feed.services';

export function* getFeeds(action) {
  try {
    const { quantity, page } = action;
    const data = yield call(
      getRecentFeed,
      quantity,
      page,
    );
    yield put({ type: constants.FEED_GET_RECENT_SUCCEDED, data, page });
  } catch (error) {
    yield put({ type: constants.FEED_GET_RECENT_FAILED, error });
  }
}

export function* addFeed(action) {
  try {
    const data = yield call(addNewFeed, action.formData);
    yield put({ type: constants.FEED_ON_ADD_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.FEED_ON_ADD_FAILED, error });
  }
}

export function* addCommentToFeed(action) {
  try {
    const data = yield call(addNewCommentToFeed, action.formData);
    yield put({ type: constants.FEED_ON_ADD_COMMENT_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.FEED_ON_ADD_COMMENT_FAILED, error });
  }
}

export function* watchFeeds() {
  yield all([
    takeLatest(constants.FEED_GET_RECENT_REQUESTED, getFeeds),
    takeLatest(constants.FEED_ON_ADD_REQUESTED, addFeed),
    takeLatest(constants.FEED_ON_ADD_COMMENT_REQUESTED, addCommentToFeed),
  ]);
}
