import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import { fetchUtils as fetchUtilsCore } from 'ra-core';
import { API_BASE_URL } from 'constants/configs';

const helper = (list) => list.map((data) => data.id);

const httpClient = (url, fetchOptions = {}) => {
  const options = { ...fetchOptions };
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtilsCore.fetchJson(url, options);
};

const convertFileToBase64 = (file) => {
  const base64FilePromise = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });
  return base64FilePromise;
};

const prepareData = async (data) => {
  const object = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    let newValue = value;
    if (value && value.rawFile instanceof File) {
      // eslint-disable-next-line no-await-in-loop
      const picture64 = await convertFileToBase64(value);
      newValue = {
        src: picture64,
        originalname: value.rawFile.name,
        size: value.rawFile.size,
        mimetype: value.rawFile.type,
        filename: value.rawFile.name,
      };
    }
    object[key] = newValue;
  }
  return object;
};

const composeFilter = (filterParams) => {
  let paramsFilter;
  if (filterParams === '' || (typeof filterParams.q !== 'undefined' && filterParams.q === '')) {
    paramsFilter = {};
  } else {
    paramsFilter = { ...filterParams };
  }
  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  const filter = Object.keys(flatFilter).map((key) => {
    const splitKey = key.split('||');
    let field = splitKey[0];
    if (field.indexOf('_') === 0 && field.indexOf('.') > -1) {
      // eslint-disable-next-line prefer-destructuring
      field = field.split(/\.(.+)/)[1];
    }
    const ops = splitKey[1] ? splitKey[1] : 'cont';
    return { field, operator: ops, value: flatFilter[key] };
  });
  return filter;
};

const convertDataRequestToHTTP = async (type, resource, params) => {
  let url = '';
  const options = {};
  switch (type) {
  case GET_LIST: {
    const { page, perPage } = params.pagination;
    const query = RequestQueryBuilder
      .create({
        filter: composeFilter(params.filter),
      })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort)
      .setOffset((page - 1) * perPage)
      .query();
    url = `${API_BASE_URL}/${resource}?${query}`;
    break;
  }
  case GET_ONE: {
    url = `${API_BASE_URL}/${resource}/${params.id}`;
    break;
  }
  case GET_MANY: {
    const query = RequestQueryBuilder
      .create()
      .setFilter({
        field: 'id',
        operator: CondOperator.IN,
        value: `${params.ids}`,
      })
      .query();
    url = `${API_BASE_URL}/${resource}?${query}`;
    break;
  }
  case GET_MANY_REFERENCE: {
    const { page, perPage } = params.pagination;
    const filter = composeFilter(params.filter);
    filter.push({
      field: params.target,
      operator: CondOperator.EQUALS,
      value: params.id,
    });
    const query = RequestQueryBuilder
      .create({
        filter,
      })
      .sortBy(params.sort)
      .setLimit(perPage)
      .setOffset((page - 1) * perPage)
      .query();
    url = `${API_BASE_URL}/${resource}?${query}`;
    break;
  }
  case UPDATE: {
    url = `${API_BASE_URL}/${resource}/${params.id}`;
    options.method = 'PATCH';
    const body = await prepareData(params.data);
    options.body = JSON.stringify(body);
    break;
  }
  case CREATE: {
    url = `${API_BASE_URL}/${resource}`;
    options.method = 'POST';
    const body = await prepareData(params.data);
    options.body = JSON.stringify(body);
    break;
  }
  case DELETE: {
    url = `${API_BASE_URL}/${resource}/delete/${params.id}`;
    options.method = 'DELETE';
    break;
  }
  default:
    throw new Error(`Unsupported fetch action type ${type}`);
  }
  return { url, options };
};

const convertHTTPResponse = (response, type, resource, params) => {
  if (response.json.pets) {
    response.json.pets = helper(response.json.pets);
  }
  const { json } = response;
  switch (type) {
  case GET_LIST:
  case GET_MANY_REFERENCE:
    return {
      data: json.data,
      total: json.total,
    };
  case CREATE:
    return { data: { ...params.data, id: json.id } };
  default:
    return { data: json };
  }
};

const dataProviderPromise = async (type, resource, params) => {
  switch (type) {
  case UPDATE_MANY: {
    return Promise.all(
      params.ids.map((id) => httpClient(`${API_BASE_URL}/${resource}/${id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      })),
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  }
  case DELETE_MANY: {
    return Promise.all(
      params.ids.map((id) => httpClient(`${API_BASE_URL}/${resource}/delete/${id}`, {
        method: 'DELETE',
      })),
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    }));
  }
  default: {
    const { url, options } = await convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options)
      .then((response) => convertHTTPResponse(response, type, resource, params));
  }
  }
};

export default (type, resource, params) => dataProviderPromise(type, resource, params);
