import styled, { css } from 'styled-components';
import ContentEditable from 'react-contenteditable';
import { Button } from '@material-ui/core';
import { DIPPER_BLUE } from 'styles/colors';

export const EditableContent = styled(ContentEditable)`
  && {
    width: 100%;
    background: white;
    border-radius: 4px;
    padding: ${({ isFocused }) => (isFocused ? '10px 35px 10px 10px' : '10px 35px 10px 40px')};
    font: 16px 'Mulish-Italic';
    color: ${DIPPER_BLUE};
    word-break: break-word;
    max-height: 130px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  
    & {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }
`;

export const AdornmentDivStart = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 10px;

  ${({ isFocused }) => (
    isFocused
      && css`
        display: none;
        background: red;
    `
  )};
`;

export const AdornmentDivEnd = styled(Button)`
  position: absolute !important;
  right: 0;
  bottom: 0;
  cursor: pointer;
  padding: 10px 0 12px 25px !important;
`;
