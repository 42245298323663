import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  DIPPER_BLUE, GRAY_COMMUNITY, GRAY_REVIEW_DATE, GREEN_FEATURED,
  GREEN_REVIEW, LIGHT_GREEN, WHITE, WHITE_BACKGROUND,
} from 'styles/colors';

import { ReactComponent as LocationIcon } from 'components/commons/assets/icon-location.svg';
import { ReactComponent as EditIcon } from 'components/commons/assets/dipper-edit-review.svg';
import { ReactComponent as TrashIcon } from 'components/commons/assets/dipper-trash.svg';

import { MOBILE_SIZE, SMALL_MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';

export const Bullet = styled.div`
  width: 8px;
  min-width: 8px;
  height: 8px;
  display: flex;
  border: 2px solid ${GREEN_REVIEW};
  border-radius: 50%;
  margin-right: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CompanyName = styled(Link)`
  color: ${DIPPER_BLUE};
  display: flex;
  font: normal normal normal 16px/20px Mulish;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 4px 0;

  :hover {
    font: normal normal bold 16px/20px Mulish;
    color: ${DIPPER_BLUE};
  }

  @media (max-width: ${TABLET_SIZE}px) {
    font: normal normal normal 18px/24px Mulish;
  }
`;

export const CompanyNameAndDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  ${({ isCompany }) => (isCompany
    ? css`
      margin-top: 11px;
    `
    : css`
      align-items: baseline;
    `
  )};
`;

export const CustomLi = styled.div`
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  word-break: break-all;
`;

export const CustomUl = styled.div`
  display: flex;
  flex-direction: column;
  font: normal normal normal 12px/18px Mulish;
`;

export const CustomButton = styled(Link)`
  background: ${GREEN_REVIEW};
  color: ${WHITE};
  display: flex;
  justify-content: center;
  font: normal normal bold 14px/18px Mulish;
  padding: 0 32px;
  border-radius: 4px;
  align-items: center;
  height: 40px;
  width: 145px;
  margin: 7px auto auto;

  :hover {
    background: ${LIGHT_GREEN};
    color: ${WHITE};
  }
`;

export const DateDiv = styled.div`
  display: flex;
  font:  italic normal normal 11px/13px Mulish;
  color: ${GRAY_REVIEW_DATE};

  @media (max-width: ${TABLET_SIZE}px) {
    font: italic normal normal 12px/19px Mulish;
  }
`;

export const Description = styled.div`
  margin-bottom: 16px;

  h1 {
    font: normal normal bold 16px/20px Mulish;
    margin-bottom: 8px;
  }

  h2 {
    font: normal normal normal 12px/18px Mulish;
    margin-bottom: 3px;
    padding-left: 10px;
  }
`;

export const Featured = styled.div`
  font: normal normal 500 14px/18px Mulish;
  color: ${WHITE};
  background: ${GREEN_FEATURED};
  border: 1px solid ${GREEN_FEATURED};
  border-radius: 6px;
  margin: 0;
  padding: 4px 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${({ isCompany }) => (isCompany ? '0' : '0 0 0 14px')};
  width: 100%;
`;

export const LocationAndDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  ${({ isCompany }) => (isCompany
    && css`
      margin-top: 11px;
    `
  )};
`;

export const IconLocation = styled(LocationIcon)`
  margin-right: 5px;
  width: 8px;
  height: 12px;
`;

export const Item = styled.div`
  margin-bottom: 16px;

  h1 {
    font: normal normal bold 16px/20px Mulish;
    margin-bottom: 8px;
  }

  h2 {
    font: normal normal normal 12px/18px Mulish;
    margin-bottom: 3px;
    word-break: break-all;
  }

  a {
    font: normal normal normal 12px/18px Mulish;
    margin-bottom: 3px;
    color: ${GREEN_REVIEW};
    word-break: break-all;
    
    :hover {
      color: ${LIGHT_GREEN}
    }
  }
`;

export const JobContainer = styled.div`
  color: ${DIPPER_BLUE};
  margin: 0 -15px;
`;

export const JobSection = styled.div`
  vertical-align: middle;
  padding: 10px 30px 40px;
  background: ${WHITE};
  border-radius: 10px;
  flex: 1;
  z-index: 3;

  @media (max-width: ${TABLET_SIZE}px) {
    z-index: 0;
    margin: 10px 14px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    padding: 10px 15px 20px;
  }
`;

export const JobTitle = styled.div`
  font: normal normal bold 20px/25px Mulish;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  display: block;
  padding-right: 5px;

  @media (max-width: ${TABLET_SIZE}px) {
    font: normal normal bold 18px/24px Mulish;
  }
`;

export const Location = styled.div`
  font: italic normal normal 11px/14px Mulish;
  color: ${GRAY_REVIEW_DATE};
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  display: block;
  padding-right: 5px;

  @media (max-width: ${TABLET_SIZE}px) {
    font: italic normal normal 12px/19px Mulish;
  }
`;

export const NewText = styled.div`
  font: normal normal bold 11px/14px Mulish;
  color: ${GREEN_REVIEW};
  margin: 0 5px;

  @media (max-width: ${TABLET_SIZE}px) {
    font: normal normal normal 12px/19px Mulish;
  }
`;

export const ProfilePicture = styled.img`
  border-radius: 8px;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%;
`;

export const Row = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;
  border-radius: 8px;

  ${({ withBorder, isCompany }) => (withBorder
    ? css`
      padding: 10px;
      background: ${WHITE_BACKGROUND};
      margin-bottom: 15px; 

      @media (max-width: ${SMALL_MOBILE_SIZE}px) {
        flex-direction: column;
        text-align: center;
      }
    `
    : !isCompany && css`
      padding: 10px 0;
    `
  )};
`;

export const RowElement = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h1 {
    font: normal normal bold 11px/14px Mulish;
    margin: 3px;
  }
  
  h2 {
    font: normal normal 300 11px/14px Mulish;
    margin: 3px;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    width: 50%;
    margin: 5px 0;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const EditButton = styled(EditIcon)`
  height: 17px;
  width: auto;
  cursor: pointer;
`;

export const VerticalLine = styled.div`
  height: 15px;
  margin: 2px 5px 0 0;
  border-right: 1px solid ${GRAY_COMMUNITY};
`;

export const DeleteButton = styled(TrashIcon)`
  cursor: pointer;
  height: 13px;
  width: auto;
  margin-top: 2px;
`;
