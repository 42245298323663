export const REVIEW_ON_DELETE_REQUESTED = 'REVIEW_ON_DELETE_REQUESTED';
export const REVIEW_ON_DELETE_SUCCEDED = 'REVIEW_ON_DELETE_SUCCEDED';
export const REVIEW_ON_DELETE_FAILED = 'REVIEW_ON_DELETE_FAILED';

export const REVIEW_GET_RECENT_BY_COMPANY_ID_REQUESTED = 'REVIEW_GET_RECENT_BY_COMPANY_ID_REQUESTED';
export const REVIEW_GET_RECENT_BY_COMPANY_ID_SUCCEDED = 'REVIEW_GET_RECENT_BY_COMPANY_ID_SUCCEDED';
export const REVIEW_GET_RECENT_BY_COMPANY_ID_FAILED = 'REVIEW_GET_RECENT_BY_COMPANY_ID_FAILED';

export const REVIEW_GET_RECENT_BY_USER_ID_REQUESTED = 'REVIEW_GET_RECENT_BY_USER_ID_REQUESTED';
export const REVIEW_GET_RECENT_BY_USER_ID_SUCCEDED = 'REVIEW_GET_RECENT_BY_USER_ID_SUCCEDED';
export const REVIEW_GET_RECENT_BY_USER_ID_FAILED = 'REVIEW_GET_RECENT_BY_USER_ID_FAILED';

export const REVIEW_GET_ALL_BY_COMPANY_ID_REQUESTED = 'REVIEW_GET_ALL_BY_COMPANY_ID_REQUESTED';
export const REVIEW_GET_ALL_BY_COMPANY_ID_SUCCEDED = 'REVIEW_GET_ALL_BY_COMPANY_ID_SUCCEDED';
export const REVIEW_GET_ALL_BY_COMPANY_ID_FAILED = 'REVIEW_GET_ALL_BY_COMPANY_ID_FAILED';

export const REVIEW_GET_BY_COMPANY_ID_REQUESTED = 'REVIEW_GET_BY_COMPANY_ID_REQUESTED';
export const REVIEW_GET_BY_COMPANY_ID_SUCCEDED = 'REVIEW_GET_BY_COMPANY_ID_SUCCEDED';
export const REVIEW_GET_BY_COMPANY_ID_FAILED = 'REVIEW_GET_BY_COMPANY_ID_FAILED';

export const REVIEW_GET_ALL_BY_USER_ID_REQUESTED = 'REVIEW_GET_ALL_BY_USER_ID_REQUESTED';
export const REVIEW_GET_ALL_BY_USER_ID_SUCCEDED = 'REVIEW_GET_ALL_BY_USER_ID_SUCCEDED';
export const REVIEW_GET_ALL_BY_USER_ID_FAILED = 'REVIEW_GET_ALL_BY_USER_ID_FAILED';

export const REVIEW_ON_ADD_REQUESTED = 'REVIEW_ON_ADD_REQUESTED';
export const REVIEW_ON_ADD_SUCCEDED = 'REVIEW_ON_ADD_SUCCEDED';
export const REVIEW_ON_ADD_FAILED = 'REVIEW_ON_ADD_FAILED';

export const REVIEW_ADD_ANSWER_REQUESTED = 'REVIEW_ADD_ANSWER_REQUESTED';
export const REVIEW_ADD_ANSWER_SUCCEDED = 'REVIEW_ADD_ANSWER_SUCCEDED';
export const REVIEW_ADD_ANSWER_FAILED = 'REVIEW_ADD_ANSWER_FAILED';

export const REVIEW_ON_UPDATE_REQUESTED = 'REVIEW_ON_UPDATE_REQUESTED';
export const REVIEW_ON_UPDATE_SUCCEDED = 'REVIEW_ON_UPDATE_SUCCEDED';
export const REVIEW_ON_UPDATE_FAILED = 'REVIEW_ON_UPDATE_FAILED';

export const REVIEW_SET_SELECTED_REQUESTED = 'REVIEW_SET_SELECTED_REQUESTED';
