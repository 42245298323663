import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

import ImgPanel1 from './assets/img1.png';
import ImgPanel1_2 from './assets/img2.png';
import ImgPanel3 from './assets/img3.png';
import ImgPanel4 from './assets/img4.png';

import {
  ModalContainer, CircleImages, ModalContent, RowImages, Text, BoldText, Title, Button,
  SelectCircles, Circle, WelcomeImage, TextWelcome,
} from './styles';

const OnBoardingModal = (props) => {
  const { onFinishOnBoarding } = props;

  const [selectedPanel, setSelectedPanel] = useState(1);

  const welcome = () => (
    <ModalContent>
      <Title>Welcome!</Title>
      <RowImages>
        <WelcomeImage />
      </RowImages>
      <TextWelcome>We are glad to have you joined our community.</TextWelcome>
      <Button onClick={() => setSelectedPanel(2)}>
        Get started
      </Button>
    </ModalContent>
  );

  const writeReviewPanel = () => (
    <ModalContent>
      <Title>Write a review</Title>
      <RowImages>
        <CircleImages smallSize src={ImgPanel1} />
        <CircleImages smallSize src={ImgPanel1_2} />
      </RowImages>
      <Text>
        What would you like to share anonymously with your community?
        {' '}
        <br />
        Click
        {' '}
        <BoldText>Reviews</BoldText>
        {' '}
        on the sidebar or
        {' '}
        <BoldText>New Review </BoldText>
        {' '}
        on the banner under your profile
        summary.
      </Text>
      <Button onClick={() => setSelectedPanel(3)}>
        Learn more about reviews
      </Button>
    </ModalContent>
  );

  const discoverInsights = () => (
    <ModalContent>
      <Title>Discover insights and reviews from your peers</Title>
      <CircleImages src={ImgPanel3} />
      <Text>
        Want to learn what others have to say before you share?
        <br />
        We’ve got you.
        Find a company by typing its name in the
        ‘Search company’ box.
      </Text>
      <Button onClick={() => setSelectedPanel(4)}>
        Learn more about peer reviews
      </Button>
    </ModalContent>
  );

  const postingIsEasy = () => (
    <ModalContent>
      <Title>Posting is easy and anonymous</Title>
      <CircleImages src={ImgPanel4} />
      <Text>
        Answer a few quick questions to gain support and help others find a better workplace.
      </Text>
      <NavLink to="/review/new">
        <Button onClick={() => onFinishOnBoarding()}>
          Ready to share?
        </Button>
      </NavLink>
    </ModalContent>
  );

  return (
    <ModalContainer>
      {selectedPanel === 1 && welcome()}
      {selectedPanel === 2 && writeReviewPanel()}
      {selectedPanel === 3 && discoverInsights()}
      {selectedPanel === 4 && postingIsEasy()}
      <SelectCircles>
        <Circle onClick={() => setSelectedPanel(1)} isSelected={selectedPanel === 1} />
        <Circle onClick={() => setSelectedPanel(2)} isSelected={selectedPanel === 2} />
        <Circle onClick={() => setSelectedPanel(3)} isSelected={selectedPanel === 3} />
        <Circle
          onClick={() => setSelectedPanel(4)}
          isSelected={selectedPanel === 4}
          dontHaveMarginRight
        />
      </SelectCircles>
    </ModalContainer>
  );
};

OnBoardingModal.propTypes = {
  onFinishOnBoarding: PropTypes.func.isRequired,
};

export default OnBoardingModal;
