import * as constants from 'redux/constants/user.constants';
import * as companyConstants from 'redux/constants/company.constants';

const defaultState = {
  data: null,
  loading: false,
};

const userReducer = (state = defaultState, action) => {
  const { data } = action;
  switch (action.type) {
  case constants.USER_ON_ACTIVATE_ACCOUNT_REQUESTED:
    return {
      ...state,
      loading: true,
    };
  case constants.USER_ON_INITIALIZE_SUCCEEDED:
  case constants.USER_ON_LOGIN_SUCCEEDED:
  case constants.USER_ON_UPDATE_SUCCEEDED:
  case companyConstants.COMPANY_ON_UPDATE_SUCCEEDED:
  case constants.USER_ON_ACTIVATE_ACCOUNT_SUCCEEDED:
  case constants.USER_ON_UPDATE_PASSWORD_SUCCEEDED:
    return {
      ...state,
      loading: false,
      data: data.user,
    };
  case constants.USER_ON_ACTIVATE_ACCOUNT_FAILED:
    return {
      ...state,
      loading: false,
    };
  case constants.USER_ON_LOGOUT:
    return defaultState;
  case constants.USER_ON_CLOSE_ONBOARDING_SUCCEEDED:
    return {
      ...state,
      data: {
        ...state.data,
        userOnBoarding: {
          ...data.userOnBoarding,
          showOnBoarding: false,
        },
      },
    };
  case constants.USER_ON_INITIALIZE_FAILED:
  case constants.USER_ON_LOGIN_FAILED:
  case constants.USER_ON_REGISTER_FAILED:
  case constants.USER_ON_UPDATE_FAILED:
  case constants.USER_ON_REGISTER_SUCCEEDED:
  case constants.USER_ON_CONTACT_FAILED:
  case companyConstants.COMPANY_ON_UPDATE_FAILED:
  case constants.USER_ON_UPDATE_PASSWORD_FAILED:
  default:
    return state;
  }
};

export default userReducer;
