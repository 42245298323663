export const SOCIAL_MEDIAS = {
  email: 'email',
  facebook: 'facebook',
  instagram: 'instagram',
  linkedIn: 'linkedIn',
};

export const FACEBOOK_LINK = 'https://www.facebook.com/dippercommunity/';

export const INSTAGRAM_LINK = 'https://www.instagram.com/ourdipper/?hl=es';

export const LINKEDIN_LINK = 'https://www.linkedin.com/company/ourdipper/';
