import styled from 'styled-components';

export const CustomInput = styled.input`
  height: fit-content;
  width: 100%;
`;

export const FormGroup = styled.div`
  flex-direction: column;
`;
