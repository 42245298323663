import { labels } from 'constants/labels';
import { isPoc } from 'constants/roles';
import { convertObjectToArray, findByCondition, sumArrayByKey } from 'helpers/array';

export const COMPANY_IS_GREAT_PLACE_TO_WORK = 'companyIsGreatPlaceToWork';
export const COMPANY_TREATMENT = 'companyTreatment';
export const RECOMMEND_COMPANY = 'recommendCompany';
export const COMPANY_DIVERSITY = 'companyDiversity';
export const PERSONAL_SITUATION = 'personalSituation';

export const TITLE_FIRST_CHART_POC = 'Would you describe your company as a great place to work?';
export const TITLE_FIRST_CHART_COMPANY = 'Would you describe this company as a great place to work?';

export const TITLE_SECOND_CHART_POC = 'Do you believe your coworkers and management view you as a competent?';
export const TITLE_SECOND_CHART_COMPANY = 'Do you believe your coworkers and managment view you as a competent?';

export const TITLE_THIRD_CHART_POC = 'Would you recommend this employer to a professional colleague of color for work?';
export const TITLE_THIRD_CHART_COMPANY = 'Would PoC recommend this employer to other professionals of color for work?';

export const TITLE_FOURTH_CHART_POC = 'Has this company worked to embrace diversity, inclusion, and equity?';
export const TITLE_FOURTH_CHART_COMPANY = 'Is diversity, inclusion, and equity embraced? ';

export const TITLE_FIVETH_CHART_POC = 'What is a major factor in your employee experience at this company?';
export const TITLE_FIVETH_CHART_COMPANY = 'What is a major factor in the PoC employee experience?';

const booleanLabels = {
  0: 'No',
  1: 'Yes',
};

const companyDiversityLabels = {
  1: 'They get it and are doing great!',
  2: 'They have the right intent, but poor execution',
  3: 'It’s only lip service',
  4: 'Doesn’t matter at all',
};

const personalSituationLabels = {
  1: 'Navigating career mobility',
  2: 'Company culture',
  3: 'Racial or ethnic discrimination',
  4: 'Code switch / Imposter Syndrome',
  6: 'Not ready to share yet',
  7: 'Other',
};

const getEmptyLabel = (isPOC) => {
  const label = isPOC
    ? 'We do not have enough data to show results'
    : 'This company does not have enough data to show results';
  return (
    [{ label, value: 1 }]
  );
};

const getAnswerById = (id, answers) => {
  const condition = (value) => value.id === parseInt(id, 10);
  return findByCondition(answers, condition)?.count || 0;
};

const getLabels = (labelsTitles, answers) => (
  convertObjectToArray(labelsTitles).map(
    ([key, value]) => ({
      label: value,
      value: getAnswerById(key, answers),
    }),
  )
);

const getPieChartLabels = (labelsTitles, answers, isPOC) => (
  answers.length ? getLabels(labelsTitles, answers) : getEmptyLabel(isPOC)
);

const getTotalAnswers = (answers) => sumArrayByKey(answers, 'count');

export const getQuestion = (question, answers, userRole) => {
  const isPOC = isPoc(userRole);
  const total = getTotalAnswers(answers);

  switch (question) {
  case COMPANY_IS_GREAT_PLACE_TO_WORK:
    return {
      labels: getPieChartLabels(labels, answers, isPOC),
      orderId: 1,
      title: isPOC ? TITLE_FIRST_CHART_POC : TITLE_FIRST_CHART_COMPANY,
      total,
    };
  case COMPANY_TREATMENT:
    return {
      labels: getPieChartLabels(labels, answers, isPOC),
      orderId: 2,
      title: isPOC ? TITLE_SECOND_CHART_POC : TITLE_SECOND_CHART_COMPANY,
      total,
    };
  case RECOMMEND_COMPANY:
    return {
      labels: getPieChartLabels(booleanLabels, answers, isPOC).sort().reverse(),
      orderId: 3,
      title: isPOC ? TITLE_THIRD_CHART_POC : TITLE_THIRD_CHART_COMPANY,
      total,
    };
  case COMPANY_DIVERSITY:
    return {
      labels: getLabels(companyDiversityLabels, answers),
      orderId: 4,
      title: isPOC ? TITLE_FOURTH_CHART_POC : TITLE_FOURTH_CHART_COMPANY,
      total,
    };
  case PERSONAL_SITUATION:
    return {
      labels: getLabels(personalSituationLabels, answers),
      orderId: 5,
      title: isPOC ? TITLE_FIVETH_CHART_POC : TITLE_FIVETH_CHART_COMPANY,
      total,
    };
  default:
    return {
      labels: [],
      orderId: 6,
      title: '',
      total: 0,
    };
  }
};
