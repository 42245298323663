import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { otherJobsSelector } from 'redux/selectors/job.selector';
import { onOpenPortraitMenu } from 'redux/actions/app.actions';
import { onContact } from 'redux/actions/user.actions';

import Layout from 'containers/Layout';
import HomeView from 'views/Home';

const HomeContainer = (props) => {
  const {
    getFeaturedJobList, getOtherJobList, isPortraitMenuOpened, onOpenPortraitMenuAction,
    onSubmitContact,
  } = props;

  const onClickOutsideMenu = () => isPortraitMenuOpened && onOpenPortraitMenuAction();

  return (
    <Layout homeAddReview>
      <HomeView
        featuredJobsList={getFeaturedJobList}
        otherJobsList={getOtherJobList}
        isPortraitMenuOpened={isPortraitMenuOpened}
        onClickOutsideMenu={onClickOutsideMenu}
        onSubmitContact={onSubmitContact}
      />

    </Layout>
  );
};

const mapStateToProps = (state) => ({
  getFeaturedJobList: state.job.featuredJobs,
  getOtherJobList: otherJobsSelector(state),
  isPortraitMenuOpened: state.app.isPortraitMenuOpened,
});

const mapDispatchToProps = (dispatch) => ({
  onOpenPortraitMenuAction: bindActionCreators(onOpenPortraitMenu, dispatch),
  onSubmitContact: bindActionCreators(onContact, dispatch),
});

HomeContainer.propTypes = {
  getFeaturedJobList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      isFeatured: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  getOtherJobList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      isFeatured: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  isPortraitMenuOpened: PropTypes.bool.isRequired,
  onOpenPortraitMenuAction: PropTypes.func.isRequired,
  onSubmitContact: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
