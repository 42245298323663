import React from 'react';
import PropTypes from 'prop-types';

import { Container, Progress } from './styles';

const ProgressBar = ({ progress, total }) => (
  <Container data-testid="progress" isEmpty={!total}>
    <Progress progress={progress} total={total} />
  </Container>
);

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default ProgressBar;
