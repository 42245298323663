import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import {
  MOBILE_SIZE, SMALL_DESKTOP_SIZE_WITH_PADDING, BOOSTRAP_MEDIUM, TABLET_SIZE,
} from 'constants/sizes';
import {
  BLUE,
  BLUE_DARK, GRAY_REVIEW_DATE,
  DIPPER_BLUE, GRAY_BACKGROUND, GRAY_COMMUNITY, GREEN_REVIEW, WHITE,
} from 'styles/colors';

import { ReactComponent as AddIcon } from 'components/commons/assets/dipper-add.svg';
import { ReactComponent as JobsImage } from './assets/ilus-job.svg';

export const CorporateContainer = styled.div`
  width: 70%;
  display: flex;
  margin: 0 auto;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    width: 100%;
  }
`;

export const CustomParagraph = styled.p`
  color: ${DIPPER_BLUE};
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;  
  margin: 0;
  
  span {
    font-weight: normal;
  }
`;

export const CustomLi = styled.li`
  font: normal normal normal 14px/20px Mulish;
  margin: 8px 0;
  list-style: none;

  ::before {
    content: "•";
    color: ${GREEN_REVIEW};
    display: inline-block; width: 1em;
    margin-left: -1em
  }
`;

export const CustomUl = styled.div`
  margin-left: 1em;
`;

export const DivCenter = styled.div`
  width: 100%;
  text-align: center;
`;

export const EmptyViewContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    width: 100%;
    padding: 0 5px;
  }
`;

export const CustomButton = styled(Link)`
  background: ${BLUE};
  color: ${WHITE};
  display: flex;
  justify-content: center;
  font: normal normal bold 14px/18px Mulish;
  padding: 0 32px;
  border-radius: 4px;
  align-items: center;
  height: 40px;
  margin: auto;

  :hover {
    background: ${BLUE_DARK};
    color: ${WHITE};
  }
`;

export const CustomSpan = styled.span`
  font: italic normal normal 11px/13px Mulish;
  letter-spacing: 0px;
  color: ${GRAY_REVIEW_DATE};
`;

export const CustomButtonMobile = styled(Link)`
  display: none;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    z-index: 10;
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    padding: 13px;
    border-radius: 50%;
    color: ${WHITE};
    background: ${BLUE};
    bottom: 82px;
    
    :hover {
      color: ${WHITE};
      background: ${BLUE_DARK};
    }
  }
`;

export const FullScreen = styled.div`
  height: 100%;
  overflow: scroll;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;

export const GrayBorderBottom = styled.div`
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${GRAY_COMMUNITY};
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  width: 100%;
  align-items: center;
  margin: -10px 0 0;
`;

export const HeaderTitle = styled.div`
  flex: 1;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};
  font-size: 20px;
  line-height: 25px;

  ${({ withBorderBottom }) => (withBorderBottom && css`
    padding-bottom: 15px;
    border-bottom: 1px solid ${GRAY_COMMUNITY};
    margin-right: 40px;

    @media (max-width: ${BOOSTRAP_MEDIUM}px) {
      border-bottom: none;
      font-size: 22px;
    }
  `)};
`;

export const JobsSection = styled.div`
  position: relative;
  width: 100%;
  height: 80vh;
  border-radius: 6px;
  background-color: ${WHITE};
  color: ${DIPPER_BLUE};
  padding: 35px 30px;

  @media (max-width: ${MOBILE_SIZE}px) {
    height: 100%;
    margin: 17px 17px 17px 0;
  }
`;

export const NewJobButton = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 0 60px;
  background: ${GRAY_BACKGROUND};
  justify-content: center;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: none;
  }
`;

export const NewJobIcon = styled(AddIcon)`
  width: 17px;
  height: 17px;
`;

export const TextSection = styled.div`
  position: relative;
  width: 100%;
`;

export const JobsTitle = styled.div`
  font: normal normal 800 20px/24px Mulish;
  margin-bottom: 6px;
`;

export const JobsText = styled.div`
  font: normal normal normal 12px/16px Mulish;
`;

export const JobsSoonText = styled.div`
  font: normal normal bold 14px/20px Mulish;
  margin-top: 10px; 
`;

export const JobsCustomImage = styled(JobsImage)`
  width: 158px;
  height: 257px;
  margin: 150px auto;
  align-self: center;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: none;
  }
`;

export const JobsCustomImageFlex = styled(JobsImage)`
  display: none;
  width: 158px;
  height: 257px;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: flex;
    margin: 10px auto;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    display: flex;
    margin: 10px auto;
  }
`;

export const ToggleContainer = styled.div`
  margin-right: 20px; 
  
  @media (max-width: ${TABLET_SIZE}px) {
    margin: 0;
  }
`;

export const OnlyDesktop = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: none;
  }
`;

export const OnlyMobile = styled.div`
  display: none;
  width: 100%;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: flex;
    flex-direction: column;
  }
`;
