import styled from 'styled-components';

import { NavHashLink } from 'react-router-hash-link';
import {
  BLUE, DARK_GREEN_BUTTON, GREEN_REVIEW, WHITE,
} from 'styles/colors';
import { GreenButton } from 'styles/commons';
import { SMALL_DESKTOP_SIZE_WITH_PADDING, TABLET_SIZE } from 'constants/sizes';

export const CookieContainer = styled.div`
  background-color: ${BLUE};
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10;

  @media (max-width: ${TABLET_SIZE}px) {
    bottom: ${({ isLandscape }) => (isLandscape ? '50px' : '0')};
  }
`;

export const TextContainer = styled.div`
  max-width: 1024px;
  margin: 40px auto;
`;

export const Text = styled.div`
  color: ${WHITE};

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    margin: 0 25px;
  }
`;

export const CustomLink = styled(NavHashLink)`
  color: ${WHITE};
  text-decoration: underline;
  
  :hover {
    text-decoration: underline;
    color: ${GREEN_REVIEW};
  }
`;

export const CustomGreenButton = styled(GreenButton)`
  && {
    font: normal normal bold 14px/18px Mulish;
    padding: 11px 36px;
    align-self: center;
    margin-top: 20px;
    
    :hover {
      background: ${DARK_GREEN_BUTTON};
    }

    @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
      margin: 20px 25px 0;
    }
  }
`;
