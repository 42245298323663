import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { onFeedAddComment, onFeedGetRecent } from 'redux/actions/feed.actions';
import { dashboardFeedsSelector } from 'redux/selectors/feed.selector';
import { userSelector } from 'redux/selectors/user.selector';
import { isCompany, isPoc } from 'constants/roles';
import { onGetAll } from 'redux/actions/insight.actions';
import { insightSelector } from 'redux/selectors/insight.selector';

import {
  feedsArrayPropTypes, insightsArrayPropTypes, jobsArrayPropTypes, userPropTypes,
} from 'helpers/propTypes';
import { isBigMobile } from 'helpers/devices';
import { QUANTITY_FEED_DASHBOARD } from 'constants/configs';
import { Container } from 'styles/commons';

import Layout from 'containers/Layout';
import DashboardPoC from 'containers/DashboardPoC';
import DashboardCompany from 'containers/DashboardCompany';

const DashboardContainer = (props) => {
  const {
    feedsList, insights, jobsList, onFeedGetRecentAction, onGetAllInsightsAction,
    onSubmitAddComment, role, user,
  } = props;

  useEffect(() => {
    onFeedGetRecentAction(QUANTITY_FEED_DASHBOARD);
    onGetAllInsightsAction();
  }, [onFeedGetRecentAction, onGetAllInsightsAction, user]);

  const renderUserPoC = () => (
    <DashboardPoC
      user={user}
      feedsList={feedsList}
      insights={insights}
      jobsList={jobsList}
      onSubmitAddComment={onSubmitAddComment}
    />
  );

  const renderUserCompany = () => (
    <DashboardCompany
      feedsList={feedsList}
      insights={insights}
      jobsList={jobsList}
      onSubmitAddComment={onSubmitAddComment}
      user={user}
    />
  );

  return (
    <Container>
      <Layout
        isLandscape
        hideSearch={isBigMobile() && isPoc(role)}
        hideAddReview={isCompany(role)}
      >
        {!user && <h1>Loading...</h1>}
        {role && (isPoc(role) ? renderUserPoC() : renderUserCompany())}
      </Layout>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  feedsList: dashboardFeedsSelector(state),
  insights: insightSelector(state),
  jobsList: state.job.jobsDashboard,
  role: state.user.data?.role,
  user: userSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onFeedGetRecentAction: bindActionCreators(onFeedGetRecent, dispatch),
  onGetAllInsightsAction: bindActionCreators(onGetAll, dispatch),
  onSubmitAddComment: bindActionCreators(onFeedAddComment, dispatch),
});

DashboardContainer.defaultProps = {
  user: null,
};

DashboardContainer.propTypes = {
  feedsList: feedsArrayPropTypes.isRequired,
  insights: insightsArrayPropTypes.isRequired,
  jobsList: jobsArrayPropTypes.isRequired,
  onFeedGetRecentAction: PropTypes.func.isRequired,
  onGetAllInsightsAction: PropTypes.func.isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
  role: PropTypes.number.isRequired,
  user: userPropTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
