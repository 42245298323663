import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import BarChart from 'components/commons/BarChart';
import CompanyRanking from 'components/commons/CompanyRanking';
import PieChartComponent from 'components/commons/PieChart';

import { PageContainer } from 'styles/commons';

import { PIE_CHART_COLORS, YES_NO_CHART_COLORS } from './constants';
import {
  Scroll, TopTitles, Title, InsightsContainer, ChartContainer,
  RowContainer, TitleCompany, Border,
} from './styles';

const Insights = (props) => {
  const {
    companyCode, insights, isCompanyUser, ranking,
  } = props;

  const renderColumn = (children) => (
    <ChartContainer className="col-xl-4 col-lg-6 col-md-12">
      {children}
    </ChartContainer>
  );

  return (
    <PageContainer data-testid="insights-page">
      <Scroll>
        <TopTitles>
          <Title>
            Dipper Insights
          </Title>
          {isCompanyUser
          && (
            <>
              <Border>|</Border>
              <Link to={`/company/${companyCode}`}>
                <TitleCompany>
                  My Company Insights
                </TitleCompany>
              </Link>
            </>
          )}
        </TopTitles>
        <InsightsContainer>
          <RowContainer className="row">
            {renderColumn(<PieChartComponent {...insights[0]} colors={PIE_CHART_COLORS} />)}
            {renderColumn(<PieChartComponent {...insights[1]} colors={PIE_CHART_COLORS} />)}
            {renderColumn(<PieChartComponent
              {...insights[2]}
              colors={YES_NO_CHART_COLORS}
            />)}
            {renderColumn(<BarChart {...insights[3]} />)}
            {renderColumn(<BarChart {...insights[4]} />)}
            {renderColumn(<CompanyRanking ranking={ranking} />)}
          </RowContainer>
        </InsightsContainer>
      </Scroll>
    </PageContainer>
  );
};
Insights.defaultProps = {
  companyCode: '',
};

Insights.propTypes = {
  companyCode: PropTypes.string,
  insights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      labels: PropTypes.arrayOf(PropTypes.PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })).isRequired,
    }).isRequired,
  ).isRequired,
  isCompanyUser: PropTypes.bool.isRequired,
  ranking: PropTypes.shape({
    company: PropTypes.arrayOf(PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    stars: PropTypes.number.isRequired,
  }).isRequired,
};

export default Insights;
