import * as constants from 'redux/constants/job.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState = {
  jobsDashboard: [],
  items: [],
  selected: null,
  hasMore: true,
  jobPositionFilter: undefined,
  locationFilter: undefined,
  page: 0,
  statusInactive: false,
  amountOfJobs: 0,
};

const replaceJobIn = (job, colection) => {
  const newColection = colection.map((elem) => {
    if (elem.id === job.id) {
      return job;
    }
    return elem;
  });
  return newColection;
};

const jobReducer = (state = defaultState, action) => {
  const { data } = action;
  let items = [];
  switch (action.type) {
  case constants.JOB_GET_ALL_POC_SUCCEDED:
    items = (action.page === 0) ? [...data.jobs]
      : [...state.items, ...data.jobs];
    return {
      ...state,
      items,
      hasMore: data.jobs.length > 0,
      page: action.page,
      selected: (state.selected || items[0]),
      amountOfJobs: data.jobsCount,
    };
  case constants.JOB_GET_ALL_DASHBOARD_SUCCEDED:
    return {
      ...state,
      jobsDashboard: data.jobs,
      amountOfJobs: data.jobsCount,
    };
  case constants.JOB_GET_ALL_COMPANY_SUCCEDED:
    items = (action.page === 0) ? data.jobs
      : [...state.items, ...data.jobs];
    return {
      ...state,
      items,
      hasMore: data.jobs.length > 0,
      page: action.page,
      selected: (state.selected || items[0]),
      amountOfJobs: data.jobsCount,
    };
  case userConstants.USER_ON_LOGOUT:
    return defaultState;
  case constants.JOB_SET_FILTERS_SUCCEDED:
    return {
      ...state,
      hasMore: true,
      items: data.jobs,
      selected: data.jobs.length === 0 ? null : data.jobs[0],
      jobPositionFilter: action.jobPositionFilter,
      locationFilter: action.locationFilter,
      page: 0,
      amountOfJobs: data.jobsCount,
    };
  case constants.JOB_SET_SELECTED_REQUESTED:
    return {
      ...state,
      selected: action.job,
    };
  case constants.JOB_ON_ADD_OFFER_SUCCEDED:
    return {
      ...state,
      items: [data, ...state.items],
      selected: data,
      statusInactive: !data.isActive,
      amountOfJobs: state.amountOfJobs + 1,
    };
  case constants.JOB_ON_UPDATE_SUCCEDED:
    return {
      ...state,
      items: [...replaceJobIn(data.job, state.items)],
      selected: data.job,
      statusInactive: !data.job.isActive,
    };
  case constants.JOB_ON_DELETE_SUCCEDED:
    items = state.items.filter((elem) => elem.id !== action.id);
    return {
      ...state,
      items,
      selected: items.length ? items[0] : null,
      amountOfJobs: state.amountOfJobs - 1,
    };
  case constants.JOB_SET_PAGE_REQUESTED:
    return {
      ...state,
      page: action.page,
    };
  case constants.JOB_SET_INACTIVE_STATUS_SUCCEDED:
    return {
      ...state,
      items: data.jobs,
      statusInactive: action.status,
      hasMore: true,
      selected: data.jobs.length ? data.jobs[0] : null,
      page: 0,
      amountOfJobs: data.amountOfJobs,
    };
  case constants.JOB_GET_ALL_POC_FAILED:
  case constants.JOB_GET_ALL_DASHBOARD_FAILED:
  case constants.JOB_GET_ALL_COMPANY_FAILED:
  default:
    return state;
  }
};

export default jobReducer;
