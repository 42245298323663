import styled from 'styled-components';

import { BIG_MOBILE_SIZE, BOOSTRAP_MEDIUM } from 'constants/sizes';
import { DIPPER_BLUE } from 'styles/colors';

export const BackgroundWhite = styled.div` 
  background: white;
  width: 100%;
  border-radius: 4px;
  margin: 10px;
`;

export const Container = styled.div`
  height: 100%;
  overflow: scroll;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;

export const OnlyMobile = styled.div` 
  display: none;

  @media (max-width: ${BIG_MOBILE_SIZE}px) {
    display: flex;
    width: 100%;
  }
`;

export const OnlyDesktop = styled.div` 
  display: flex;
  width: 100%;

  @media (max-width: ${BIG_MOBILE_SIZE}px) {
    display: none;
  }
`;

export const ProfileContainer = styled.div` 
  color: ${DIPPER_BLUE};
  padding: 0 0 0 15px;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    margin: 10px 0;
    padding-right: 15px;
  }
`;

export const RowTest = styled.div` 
  display: flex;
  flex-direction: row;
  height: 100%;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const SearchBarContainer = styled.div` 
  display: flex;
  background: ${DIPPER_BLUE};
  width: calc(100%);
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-bottom: 20px;
`;
