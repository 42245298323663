import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Feed from 'components/Feed';
import CreateFeed from 'containers/CreateFeed';
import ItemsListWithShowSection from 'components/commons/ItemsListWithShowSection';
import { PageContainer } from 'styles/commons';

import { feedPropTypes } from 'helpers/propTypes';
import * as constants from './constants';
import {
  HeaderContainer, InputContainer, CustomParagraph,
} from './styles';
import FeedDetails from './components/FeedDetails';

const Feeds = (props) => {
  const {
    feedsList, handleScroll, onSubmitAddComment, selected, setFeedSelected,
  } = props;

  const [replySelected, setReplySelected] = useState(null);

  const replyOpenInput = (feed) => {
    setReplySelected(feed.id);
  };

  const replyCloseInput = () => {
    setReplySelected(null);
  };

  const renderFeed = (feed) => (
    <Feed
      feed={feed}
      key={feed.id}
      isCommunity
      replySelected={feed.id === replySelected}
      replyOpenInput={() => replyOpenInput(feed)}
      replyCloseInput={() => replyCloseInput()}
      onSubmitAddComment={onSubmitAddComment}
    />
  );

  const renderFeedDetails = (feed) => (
    <FeedDetails feed={feed} onSubmitAddComment={onSubmitAddComment} />
  );

  const listFeedsHeader = () => (
    <HeaderContainer>
      <h1>
        {constants.HEADER_TITLE}
      </h1>
      <h2>
        {constants.INPUT_LABEL}
      </h2>
      <InputContainer>
        <CreateFeed />
      </InputContainer>
    </HeaderContainer>
  );

  const renderEmptyFeed = () => (
    <>
      {listFeedsHeader()}
      <div data-testid="empty-feed">Currently there are no feeds</div>
    </>
  );

  const renderCommunityPostText = () => (
    <CustomParagraph data-testid="custom-paragraph">
      Community
      {' '}
      <span>|</span>
      {' '}
      <span>Post</span>
    </CustomParagraph>
  );

  return (
    <PageContainer data-testid="feeds-page">
      <ItemsListWithShowSection
        selected={selected}
        handleScroll={handleScroll}
        items={feedsList}
        isCommunity
        emptyItemsView={renderEmptyFeed}
        listItemsHeader={listFeedsHeader}
        listItemsFooter={() => {}}
        renderItem={renderFeed}
        renderItemSelected={renderFeedDetails}
        renderGoToListText={renderCommunityPostText}
        setSelected={setFeedSelected}
      />
    </PageContainer>
  );
};

Feeds.propTypes = {
  handleScroll: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.number.isRequired,
  }).isRequired,
  feedsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
  selected: feedPropTypes.isRequired,
  setFeedSelected: PropTypes.func.isRequired,
};

export default Feeds;
