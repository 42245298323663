import React from 'react';
import PropTypes from 'prop-types';

import {
  CookieContainer, TextContainer, Text, CustomLink, CustomGreenButton,
} from './styles';

const CookieBanner = (props) => {
  const { isLandscape, onAccept } = props;

  return (
    <CookieContainer isLandscape={isLandscape}>
      <TextContainer>
        <Text>
          By using this website, you agree to our use of cookies. We use cookies
          to provide you with a great experience and to help our website run effectively.
          To find out more, read our
          {' '}
          <CustomLink target="_blank" rel="noopener noreferrer" smooth to="/legal#privacy">
            Privacy Policy
          </CustomLink>
          .
        </Text>
        <CustomGreenButton onClick={onAccept}>
          ACCEPT
        </CustomGreenButton>
      </TextContainer>
    </CookieContainer>
  );
};

CookieBanner.propTypes = {
  onAccept: PropTypes.func.isRequired,
  isLandscape: PropTypes.bool.isRequired,
};

export default CookieBanner;
