import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import * as constants from 'redux/constants/company.constants';
import { getAll, getRank, update } from 'services/company.services';

export function* companyGetAll() {
  try {
    const data = yield call(
      getAll,
    );
    yield put({ type: constants.COMPANY_GET_ALL_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.COMPANY_GET_ALL_FAILED, error });
  }
}

export function* companyGetRank(action) {
  try {
    const { companySerchId } = action;
    const data = yield call(getRank, companySerchId);
    yield put({ type: constants.COMPANY_ON_GET_RANK_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.COMPANY_ON_GET_RANK_FAILED, error });
  }
}

export function* companyUpdate(action) {
  try {
    const data = yield call(update, action.formData);
    yield put({ type: constants.COMPANY_ON_UPDATE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.COMPANY_ON_UPDATE_FAILED, error });
  }
}

export function* watchCompanies() {
  yield all([
    takeLatest(
      [
        constants.COMPANY_GET_ALL_REQUESTED,
        constants.COMPANY_ON_UPDATE_SUCCEEDED,
      ],
      companyGetAll,
    ),
    takeLatest(constants.COMPANY_ON_UPDATE_REQUESTED, companyUpdate),
    takeLatest(constants.COMPANY_ON_GET_RANK_REQUESTED, companyGetRank),
  ]);
}
