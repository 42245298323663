import styled, { css } from 'styled-components';
import {
  BLACK, BOX_SHADOW, GRAY_DARK, GRAY, WHITE, DIPPER_BLUE, BLUE_DARK,
  WHITE_BACKGROUND, GRAY_SIDE_MENU_MOBILE,
} from 'styles/colors';
import { TABLET_SIZE, MOBILE_SIZE, SMALL_DESKTOP_SIZE_WITH_PADDING } from 'constants/sizes';

import { ReactComponent as MenuArrowIcon } from './assets/dipper-menu.svg';

export const ArrowIcon = styled(MenuArrowIcon)`
  cursor: pointer;
`;

export const All = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PortraitContainer = styled.div`
  width: 100%;
  background: ${WHITE};
  position: fixed;
  z-index: 4;
  padding-left: 0px !important;
  font-size: 20px;
  flex-direction: row;
  box-shadow: 0px 3px 6px ${BOX_SHADOW};

  .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0;

    @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
      padding: 0 25px;
      max-width: 100%;
    }
  }
`;

export const LandscapeContainer = styled.div`
  background-color: ${DIPPER_BLUE};
  font-size: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  
  @media (max-width: ${TABLET_SIZE}px) {
    background-color: ${WHITE};
    display: flex;
    position: absolute;
    z-index: 3;
    bottom: 0;
    height: fit-content;
    width: 100%;
    margin-bottom: 0;
    justify-content: space-between;
    align-items: center;

    li {
      display: flex;
    }
  }
`;

export const OpenMenu = styled.div`
  display: none;

  @media (max-width: ${TABLET_SIZE}px) {
    display: flex;
    float: right;
    position: fixed;
    right: 0;
    padding-right: 20px;
    z-index: 3;
  }
`;

export const Arrow = styled.div`
  position: absolute;
  width: fit-content;
  margin-top: 4px;
  z-index: 20;

  ${({ isLandscape }) => (
    !isLandscape && css`
      display: none;   
    `
  )};

  #Layer_1 {
    width: 16px;
    height: 16px;
  };

  ${({ open }) => (open
    ? css`
        transform: rotate(180deg);
        margin-left: 147px;
      `
    : css`
        margin-left: 49px;
        margin-top: -5px;
    `)
};

  @media (max-width: ${TABLET_SIZE}px) {
    display: none;
  }
`;

export const NavbarSection = styled.nav`
  width: 100%;
  line-height: 50px;
  padding-left: 0;
  padding-right: 0;

  ul li a,
  ul li span { 
    display: flex;
    flex: 1;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    -webkit-transition: color 0.3s ease;
    -moz-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  ul li a:hover,
  li span:hover {
   
  }

  @media (max-width: ${TABLET_SIZE}px) {
    margin-bottom: 0px;
  }
`;

export const PortraitContent = styled.div`
  align-items: center;
  margin-left: 0;
  display: flex;
  line-height: 50px;
  justify-content: space-between;
  padding-left: 0px !important;
  height: 60px;

  @media (max-width: ${MOBILE_SIZE}px) {
    height: 48px;
  }
  `;

export const LandscapeContent = styled.div`
  align-items: center;
  margin-left: 0;
  line-height: 50px;

  @media (max-width: ${TABLET_SIZE}px) {
    line-height: 0px;
    font-size: 1em;
  }
`;

export const PortraitMenu = styled.ul`
  margin-bottom: 0;
  float: right;
  display: flex;
  padding-left: 0px !important;

  li {
    display: inline-block;
  }

  li a,
  li span {
    color: ${BLUE_DARK};
    font-size: 14px;
  }

  li a.active {
    color: ${BLACK};
  }

  @media (max-width: ${TABLET_SIZE}px) {
    flex-flow: column nowrap;
    background-color: ${WHITE};
    position: fixed;
    transform: ${({ openLanding }) => (openLanding ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 239px;
    padding: 94px 10px 0px 0px;
    transition: transform 0.3s ease-in-out;
    
    li {
      color: ${GRAY};
    }

    li a,
    li span {
      padding: 0;
      color: ${DIPPER_BLUE};
      line-height: 18px;
    }
  }
`;

export const LandscapeMenu = styled.ul`
  margin-bottom: 0;
  color: ${GRAY_DARK};
  padding: 0;
  text-align: center;
  list-style-type: none;
  background-color: ${DIPPER_BLUE};

  li a,
  li span {
    color: ${WHITE_BACKGROUND};
  }

  @media (max-width: ${TABLET_SIZE}px) {
    display: flex;

    li {
      display: flex;
    }

    li a,
    li span {
      color: ${GRAY_SIDE_MENU_MOBILE};
    }
  }

  @media (max-width: 500px) {
    li a,
    li span {
      padding: 0 15px;
    }
  }
`;
