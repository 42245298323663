import React from 'react';
import PropTypes from 'prop-types';

import ViewAll from 'components/commons/ViewAll';

import TitleAndViewAllDiv from './styles';

const ItemsList = (props) => {
  const {
    showHeader, classContainer, emptyItemsText, items, listItemsHeader, onClick,
    renderItem, hasToRenderAllReviewDetails, companyCode,
  } = props;

  return (
    <div data-testid="list-component" className={classContainer}>
      {showHeader
          && (
            <TitleAndViewAllDiv>
              {listItemsHeader()}
              {!hasToRenderAllReviewDetails
                && (
                  <ViewAll
                    onClick={onClick}
                    companyCode={companyCode}
                  />
                )}
            </TitleAndViewAllDiv>
          )}
      {items.length ? (
        <>
          {items.map(renderItem)}
        </>
      ) : (
        <p>
          {' '}
          {emptyItemsText}
          {' '}
        </p>
      )}
    </div>

  );
};

ItemsList.defaultProps = {
  showHeader: false,
  classContainer: '',
  listItemsHeader: () => {},
  onClick: () => {},
  hasToRenderAllReviewDetails: false,
  companyCode: '',
};

ItemsList.propTypes = {
  showHeader: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderItem: PropTypes.func.isRequired,
  emptyItemsText: PropTypes.string.isRequired,
  classContainer: PropTypes.string,
  listItemsHeader: PropTypes.func,
  onClick: PropTypes.func,
  hasToRenderAllReviewDetails: PropTypes.bool,
  companyCode: PropTypes.string,
};

export default ItemsList;
