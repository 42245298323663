import styled from 'styled-components';

import { SMALL_MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';
import { DIPPER_BLUE, GRAY_BACKGROUND, GRAY_BACKGROUND_HOVER } from 'styles/colors';

export const PublicationLogo = styled.img`
  width: 100%;
  max-width: 200px;
  margin: 0 auto 40px;
  height: 70px;
  text-align: center;
  opacity: 0.7;
`;

export const PublicationContainer = styled.div`
  background: ${GRAY_BACKGROUND};
  height: 320px;
  text-align: center;
  padding: 50px 20px;
  margin: 40px 15px;
  cursor: pointer;

  :hover {
    background: ${GRAY_BACKGROUND_HOVER};

    ${PublicationLogo} {
      opacity: 1;
    }
  }

  @media (max-width: ${TABLET_SIZE}px) {
    margin: 40px 20px;
  }

  @media (max-width: 600px) {
    margin: 40px 60px;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    margin: 40px 20px;
  }
`;

export const Text = styled.div`
  color: ${DIPPER_BLUE};
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;
