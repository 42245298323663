import React from 'react';

import { insightsArrayPropTypes } from 'helpers/propTypes';

import ViewAll from 'components/commons/ViewAll';
import PieChartComponent from 'components/commons/PieChart';
import { PIE_CHART_COLORS } from 'views/Insights/constants';

import {
  DivInsights, Title, InsightsContainer, Insight, Line,
} from './styles';

const InsightsDashboard = ({ insights }) => (
  <DivInsights data-testid="div-insights" className="col-lg-4">
    <Title>
      <h1>Dipper Insights</h1>
      <ViewAll viewMoreLink="/insights" />
    </Title>
    <InsightsContainer>
      <Insight>
        <PieChartComponent {...insights[0]} colors={PIE_CHART_COLORS} alignChartCenter />
      </Insight>
      <Line />
      <Insight>
        <PieChartComponent {...insights[1]} colors={PIE_CHART_COLORS} alignChartCenter />
      </Insight>
    </InsightsContainer>
  </DivInsights>
);

InsightsDashboard.propTypes = {
  insights: insightsArrayPropTypes.isRequired,
};

export default InsightsDashboard;
