export const FEED_GET_RECENT_REQUESTED = 'FEED_GET_RECENT_REQUESTED';
export const FEED_GET_RECENT_SUCCEDED = 'FEED_GET_RECENT_SUCCEDED';
export const FEED_GET_RECENT_FAILED = 'FEED_GET_RECENT_FAILED';

export const FEED_ON_ADD_REQUESTED = 'FEED_ON_ADD_REQUESTED';
export const FEED_ON_ADD_SUCCEDED = 'FEED_ON_ADD_SUCCEDED';
export const FEED_ON_ADD_FAILED = 'FEED_ON_ADD_FAILED';

export const FEED_ON_ADD_COMMENT_REQUESTED = 'FEED_ON_ADD_COMMENT_REQUESTED';
export const FEED_ON_ADD_COMMENT_SUCCEDED = 'FEED_ON_ADD_COMMENT_SUCCEDED';
export const FEED_ON_ADD_COMMENT_FAILED = 'FEED_ON_ADD_COMMENT_FAILED';

export const FEED_SET_SELECTED_REQUESTED = 'FEED_SET_SELECTED_REQUESTED';
