import React from 'react';
import PropTypes from 'prop-types';

import { jobsArrayPropTypes } from 'helpers/propTypes';

import JobDashboard from 'components/JobDashboard';
import {
  CustomSpan, JobsSection, JobsTitle, JobsText, JobsCustomImage, Title, ListContainer,
} from './styles';
import ViewAll from '../ViewAll';

const JobsDashboard = (props) => {
  const {
    amountOfJobs, isCompany, jobsList, maxJobs,
  } = props;

  const renderJobsComingSoon = () => (
    !isCompany
    && (
      <JobsSection data-testid="div-jobs">
        <JobsCustomImage />
        <JobsTitle>
          Jobs
        </JobsTitle>
        <JobsText>
          A better workplace is possible! New oportunities will be posted here frequently,
          so keep coming back!
        </JobsText>
      </JobsSection>
    )
  );

  const renderFeaturedJobsList = () => (
    <ListContainer>
      <Title>
        <h1>
          Jobs
          <CustomSpan>
            (
            {amountOfJobs}
            )
          </CustomSpan>
        </h1>
        <ViewAll viewMoreLink="/jobs" />
      </Title>
      {jobsList.slice(0, maxJobs).map((job) => <JobDashboard job={job} />)}
    </ListContainer>
  );

  return (
    jobsList.length === 0 ? renderJobsComingSoon() : renderFeaturedJobsList()
  );
};

JobsDashboard.defaultProps = {
  isCompany: false,
};

JobsDashboard.propTypes = {
  amountOfJobs: PropTypes.number.isRequired,
  isCompany: PropTypes.bool,
  jobsList: jobsArrayPropTypes.isRequired,
  maxJobs: PropTypes.number.isRequired,
};

export default JobsDashboard;
