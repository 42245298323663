import styled from 'styled-components';

import { BLUE_DARK, BLUE_DIPPER } from 'styles/colors';
import { TABLET_SIZE, SMALL_TABLET_SIZE } from 'constants/sizes';

import Button from 'components/commons/Button';

import ReviewMobile from './assets/review-mobile.png';
import Review1920 from './assets/review-1920.png';
import Review1366 from './assets/review-1366.png';
import ReviewIpad from './assets/review-ipad.png';

export const ReviewContainer = styled.div`
  position: relative;
  display: flex;
  margin-right: auto;

  @media (max-width: ${TABLET_SIZE}px) {
    margin-bottom: -80px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    flex-direction: column;
    padding: 16px 0 0 26px;
    margin-bottom: 0;
  }
`;

export const ReviewPicture = styled.img`
  content: url(${Review1920});
  display: flex;
  left: 0;
  width: 50%;
  height: 100%;
  margin-top: -20%;
  z-index: -1;

  @media (max-width: 1366px) {
    content: url(${Review1366});
    width: 60%;
    margin-top: -10%;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    content: url(${ReviewIpad});
    width: 50%;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    content: url(${ReviewMobile});
    align-self: center;
    width: 80%;
    margin-top: 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 20% 0 40px;
  padding-top: 6%;

  @media (max-width: 1366px) {
    margin-right: 5%;
    padding-top: 5%;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    padding: 0;
    margin-left: 0;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    padding-right: 26px;
  }
`;

export const Title = styled.h1`
  margin: 22px 0 20px 0;
  color: ${BLUE_DARK};
  font-weight: bold;
  font-size: 30px;
  line-height: 26px;
`;

export const Description = styled.p`
  color: ${BLUE_DIPPER};
  size: 16px;
  line-height: 22px;
`;

export const ButtonReview = styled(Button)`
  padding: 11px 40px;
`;

export const Text = styled.div`
  margin-bottom: 14px;
  color: ${BLUE_DIPPER};
  font-size: 18px;
  line-height: 26px;

  @media (max-width: ${TABLET_SIZE}px) and (min-width: ${SMALL_TABLET_SIZE}px) {
    font-size: 16px;
    line-height: 22px;
    margin-top: 0;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 6px;
`;
