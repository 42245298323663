import React from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import { ReactComponent as AddPostIcon } from '../assets/AddPostIcon.svg';
import { ReactComponent as SendIcon } from '../assets/SendIcon.svg';

import { CustomInput, CustomButton } from './styles';

const Input = (props) => {
  const {
    autoComplete, input, maxLength, placeholder, showAdornment, startAdornment,
  } = props;

  return (
    <CustomInput
      {...input}
      disableUnderline="off"
      autoComplete={autoComplete}
      data-testid="input_create_feed"
      placeholder={placeholder || 'Start a post'}
      name="description"
      multiline
      onFocus={showAdornment}
      rowsMax={6}
      inputProps={{ maxLength }}
      focus
      startAdornment={startAdornment && (
        <InputAdornment position="start">
          <AddPostIcon />
        </InputAdornment>
      )}
      endAdornment={(
        <CustomButton
          type="submit"
          data-testid="button-send"
        >
          <InputAdornment position="end">
            <SendIcon />
          </InputAdornment>
        </CustomButton>
      )}
    />
  );
};

Input.defaultProps = {
  autoComplete: 'off',
  maxLength: 255,
  placeholder: null,
  startAdornment: true,
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  showAdornment: PropTypes.func.isRequired,
  startAdornment: PropTypes.bool,
};

export default Input;
