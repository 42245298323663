import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { HelpBlock } from 'styles/commons';

import { FormGroup, CustomInput } from './styles';

const FileInput = (props) => {
  const {
    disabled, input, label, meta: { touched, error }, onLoadImageFailed,
  } = props;
  const [value, setValue] = useState(input.value || '');

  const inputFileProps = { accept: 'image/jpeg,image/png', id: 'files' };

  const onChange = (event) => {
    const { files } = event.target;
    if (files.length) {
      const file = files[0];
      const fileType = file.type;
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(fileType) || file.size / 1024 / 1024 > 5) {
        // invalid file type or size bigger than 5MB
        setValue(event.target.value);
        onLoadImageFailed();
      } else {
        input.onChange({ path: URL.createObjectURL(file), rawFile: file });
      }
    } else {
      setValue(event.target.value);
    }
  };

  return (
    <FormGroup>
      <CustomInput
        {...input}
        {...inputFileProps}
        disabled={disabled}
        error={touched && !!error}
        label={label}
        type="file"
        value={value.path}
        onChange={onChange}
        InputProps={{
          readOnly: true,
        }}
      />
      <HelpBlock data-testid="help-block">{(touched && error) || ' '}</HelpBlock>
    </FormGroup>
  );
};

FileInput.defaultProps = {
  disabled: false,
  label: '',
  onLoadImageFailed: () => {},
};

FileInput.propTypes = {
  disabled: PropTypes.bool,
  onLoadImageFailed: PropTypes.func,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
  }).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string.isRequired,
    touched: PropTypes.bool.isRequired,
  }).isRequired,
};

export default FileInput;
