import styled from 'styled-components';

import { TABLET_SIZE, SMALL_TABLET_SIZE } from 'constants/sizes';

import Button from 'components/commons/Button';

export const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isReverse }) => (isReverse ? 'row-reverse' : 'row')};

  h5 {
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    width: 100%;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    flex-direction: column-reverse;
  }
`;

export const TextSection = styled.div`
  width: 42%;
  align-self: center;

  margin: ${({ isReverse }) => (isReverse ? '0px 0px 0px 30px' : '0px 30px 0px 0px')};

  h1 {
    font-size: 32px;
    line-height: 26px;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    width: 45%;
    margin: 0px 5% 0px 11px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 100%;
    margin: 0;

    h5 {
      margin: ${({ last }) => (last ? '13px 0px 28px' : '13px 0px')};
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

export const CustomButton = styled(Button)`
  padding: 11px 40px;
`;

export const CustomImage = styled.img`
  width: 58%;
  height: auto;
  align-self: center;

  @media (max-width: ${TABLET_SIZE}px) {
    width: 48%;
    margin-right: 20px;
  }

  @media (max-width: ${SMALL_TABLET_SIZE}px) {
    width: 80%;
    margin: 0;
  }
`;
