import styled from 'styled-components';

import { MOBILE_SIZE } from 'constants/sizes';

import {
  DIPPER_BLUE, GRAY_COMMUNITY, GRAY_REVIEW_DATE, GREEN_FEATURED, GREEN_REVIEW, WHITE,
} from 'styles/colors';

import { ReactComponent as LocationIcon } from 'components/commons/assets/icon-location.svg';
import { ReactComponent as EditIcon } from 'components/commons/assets/dipper-edit-review.svg';
import { ReactComponent as TrashIcon } from 'components/commons/assets/dipper-trash.svg';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const JobPosition = styled.div`
  font: normal normal bold 14px/16px Mulish;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  width: 100%;
  padding-right: 5px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const CompanyNameAndDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Company = styled.div`
  font: normal normal normal 14px/16px Mulish;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  width: 100%;
  display: block;
  padding-right: 5px;
`;

export const ProfileImageContainer = styled.div`
  width: 40px;
  display: block;
`;

export const DateDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  font:  ${({ isCompany }) => (isCompany ? 'italic' : 'normal')} normal normal 11px/13px Mulish;
  color: ${GRAY_REVIEW_DATE};
`;

export const DeleteButton = styled(TrashIcon)`
  cursor: pointer;
  height: 13px;
  width: auto;
  margin-top: 1px;
`;

export const EmployeeType = styled.div`
  display: flex;
  flex: 1;
  font: normal normal bold 12px/16px Mulish;
  margin: 3px 0;
`;

export const EmployeeAndFeatured = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
`;

export const EditButton = styled(EditIcon)`
  height: 17px;
  width: auto;
  cursor: pointer;
`;

export const Featured = styled.div`
  font: normal normal 500 14px/18px Mulish;
  color: ${WHITE};
  background: ${GREEN_FEATURED};
  border: 1px solid ${GREEN_FEATURED};
  border-radius: 6px;
  margin-left: 5px;
  padding: 4px 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
`;

export const IconLocation = styled(LocationIcon)`
  margin-right: 5px;
  width: 8px;
  height: 12px;
`;

export const JobContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  color: ${DIPPER_BLUE};

  @media (max-width: ${MOBILE_SIZE}px) {
    padding: 10px 0;
  }

`;

export const LocationAndEmployeeTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`;

export const Location = styled.div`
  font: italic normal normal 11px/14px Mulish;
  color: ${GRAY_REVIEW_DATE};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const NewText = styled.div`
  font: normal normal bold 11px/14px Mulish;
  color: ${GREEN_REVIEW};
  margin: 0 5px;
`;

export const OverviewContainer = styled.div`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font: normal normal normal 12px/16px Mulish;
  color: ${DIPPER_BLUE};
  word-break: break-word;
  padding-top: 5px;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-right: 5px;
  object-fit: cover;
`;

export const RowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const VerticalLine = styled.div`
  height: 15px;
  margin: 2px 5px 0 0;
  border-right: 1px solid ${GRAY_COMMUNITY};
`;
