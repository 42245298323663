import { get } from './api';
import { INSIGHTS_LIMIT_COMPANY_RANKING } from '../constants/configs';

export async function getAll(companyId = '') {
  const response = await get(`reviews/insights/${companyId}`);
  return response;
}

export async function getRanking(quantity = INSIGHTS_LIMIT_COMPANY_RANKING) {
  const response = await get(`companies/ranking?quantity=${quantity}`);
  return response;
}
