import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import HomeContainer from 'containers/HomeContainer';
import SignupContainer from 'containers/SignupContainer';
import LoginContainer from 'containers/LoginContainer';
import Admin from 'admin/Admin';
import Error from 'views/Error';
import ResetPasswordContainer from 'containers/ResetPasswordContainer';
import DashboardContainer from 'containers/DashboardContainer';
import Reviews from 'containers/Reviews';
import CreateReview from 'containers/CreateReview';
import Jobs from 'containers/JobsContainer';
import CreateJobContainer from 'containers/CreateJobContainer';
import ProfileContainer from 'containers/ProfileContainer';
import FeedsContainer from 'containers/FeedsContainer';
import InsightsContainer from 'containers/InsightsContainer';
import CompanyContainer from 'containers/CompanyContainer';
import ConfirmAccountContainer from 'containers/ConfirmAccountContainer';
import LegalInformationContainer from 'containers/LegalInformationContainer';

import PrivateRoute from './PrivateRoute';

const Navigation = (props) => (
  <Switch>
    <Redirect exact from="/" to="/home" />
    <Route path="/home" component={HomeContainer} />
    <Route path="/signup" component={SignupContainer} />
    <Route path="/login" component={LoginContainer} />
    <Route path="/resetPassword/:token" component={ResetPasswordContainer} />
    <PrivateRoute path="/dashboard" component={DashboardContainer} />
    <PrivateRoute exact path="/reviews" component={Reviews} />
    <PrivateRoute path="/review/new" component={CreateReview} />
    <PrivateRoute exact path="/jobs" component={Jobs} />
    <PrivateRoute path="/jobs/new" component={CreateJobContainer} />
    <PrivateRoute path="/profile" component={ProfileContainer} />
    <PrivateRoute path="/settings" component={() => <ProfileContainer showSettings />} />
    <PrivateRoute path="/feeds" component={FeedsContainer} />
    <PrivateRoute path="/insights" component={InsightsContainer} />
    <PrivateRoute path="/company/:code?" component={CompanyContainer} />
    <Route path="/confirmAccount/:token/:userType" component={ConfirmAccountContainer} />
    <Route path="/admin" component={() => <Admin {...props} />} />
    <Route path="/legal" component={LegalInformationContainer} />
    <Route component={Error} />
  </Switch>
);

export default Navigation;
