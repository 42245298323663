import styled, { css } from 'styled-components';
import { List, MenuItem } from '@material-ui/core';
import { BOOSTRAP_MEDIUM, MOBILE_SIZE } from 'constants/sizes';

import { ReactComponent as ArrowSvg } from 'components/commons/assets/arrow.svg';
import {
  GRAY_BAR, GRAY_COMMUNITY, WHITE, GRAY_BACKGROUND,
} from 'styles/colors';

export const ItemsList = styled.div`
  height: 100%;
`;

export const Arrow = styled(ArrowSvg)`
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  align-self: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0;
  height: 100%;

  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
  }

  .MenuItem.Mui-selected {
    border-radius: 8px 0 0 8px !important;
    ${({ isCommunity }) => (
    isCommunity
      ? css`
          background: ${GRAY_COMMUNITY};

          :hover {
            background: ${GRAY_COMMUNITY};
          }

        `
      : css`
          background: ${WHITE};
          padding-left: 15px !important;

          :hover {
            background: ${WHITE};
          }
        `
  )};

    @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
      padding-left: 0 !important;
      border-radius: 0 !important;
      background: ${GRAY_BACKGROUND};
    }

  }
  
  .MenuItem.MuiMenuItem-root {
    border-bottom: 1px solid ${GRAY_BAR};
    padding-left: 0;

    @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
      border-radius: 0;
      padding-right: 0;
    }
  }
`;

export const CustomList = styled(List)`
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;

export const Column = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 0 0 0 15px;
  
  @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -5px;
  cursor: pointer;

  @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
    padding-bottom: 10px;
    border-bottom: 1px solid ${GRAY_COMMUNITY};
  }
`;

export const DetailsContainer = styled.div`
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;
