import React from 'react';

import { PageContainer } from 'styles/commons';

import {
  FullScreen, Content, TopDiv, TextDiv, Title, SubTitle, CustomList, CustomItemsList,
} from './styles';

const NoResultsFound = () => (
  <PageContainer data-testid="insights-page">
    <FullScreen>
      <Content>
        <TopDiv>
          <Title>
            No Matches Found
          </Title>
          <SubTitle>
            Please try another search
          </SubTitle>
        </TopDiv>
        <TextDiv>
          <Title isSmaller>
            Adjust your search
          </Title>
          <CustomList>
            <CustomItemsList>
              Check your spelling
            </CustomItemsList>
            <CustomItemsList>
              Search by the company&apos;s parent company
              (&#39;Citi&#39; Instead of &#39;Citibank&#39;)
            </CustomItemsList>
            <CustomItemsList>
              Search for an acronym or abbreviation instead of the full name, or vice versa
              (&#39;Procter &amp; Gamble&#39; vs &#39;P&amp;G&#39;)
            </CustomItemsList>
          </CustomList>
        </TextDiv>
      </Content>
    </FullScreen>
  </PageContainer>
);

export default NoResultsFound;
