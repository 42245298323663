import { get, post } from 'services/api';

export async function getRecentFeed(quantity = 10, page = 0) {
  const response = await get(`feeds/getAll/${quantity}/${page}`);
  return response;
}

export async function addNewFeed(formData) {
  const response = await post('feeds/add', formData);
  return response;
}

export async function addNewCommentToFeed(formData) {
  const response = await post('comments/add', formData);
  return response;
}
