import React from 'react';
import PropTypes from 'prop-types';

import {
  Container, TextContainer, Title, Description, URLImage, ImageContainer, Link,
} from './styles';

const Metadata = (props) => {
  const {
    description, image, title, url, isBigger,
  } = props;

  return (
    <Container isBigger={isBigger}>
      <Link target="_blank" rel="noopener noreferrer" href={url}>
        <TextContainer>
          <Title isBigger={isBigger}>
            {title}
          </Title>
          <Description>
            {description}
          </Description>
        </TextContainer>
        <ImageContainer>
          <URLImage isBigger={isBigger} src={image} />
        </ImageContainer>
      </Link>
    </Container>
  );
};

Metadata.defaultProps = {
  isBigger: false,
};

Metadata.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isBigger: PropTypes.bool,
};

export default Metadata;
