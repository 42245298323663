import { createSelector } from 'reselect';
import { QUANTITY_FEED_DASHBOARD } from 'constants/configs';

import DefaultProfile from 'components/commons/assets/avatar-poc.svg';
import { isCompany } from 'constants/roles';

const feedsList = (state) => state.feed.items;

const getProfilePic = (feed) => {
  if (isCompany(feed.user.role)) {
    return (feed.user.company?.logo?.path || DefaultProfile);
  }
  return (feed.user.profilePic?.path || DefaultProfile);
};

export const feedsSelector = createSelector(
  [feedsList],
  (feeds) => feeds.map((feed) => ({
    ...feed,
    author: isCompany(feed.user.role) ? feed.user.company?.name : `${feed.user.firstName} ${feed.user.lastName}`,
    profilePic: getProfilePic(feed),
  })),
);

export const dashboardFeedsSelector = createSelector(
  [feedsSelector],
  (feeds) => feeds.slice(0, QUANTITY_FEED_DASHBOARD),
);

export default { dashboardFeedsSelector };
