import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import * as validates from 'helpers/validates';
import Input from 'components/commons/Input';

import {
  FormContainer, InputsSection, Inputs, CustomButton, Form,
} from './styles';

const SSOForm = (props) => {
  const {
    handleSubmit, isCompany, onSubmit, submitting,
  } = props;

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit(onSubmit)} data-testid="form">
        {isCompany && (
          <InputsSection data-testid="company-name-input-section">
            <h1> Please enter your company name </h1>
            <Inputs>
              <Field
                component={Input}
                variant="standard"
                autoComplete="chrome-off"
                name="companyName"
                label="Company Name"
                validate={validates.required}
              />
            </Inputs>
          </InputsSection>
        )}
        <InputsSection>
          <h1> Please enter a password </h1>
          <Inputs>
            <Field
              component={Input}
              variant="standard"
              autoComplete="chrome-off"
              name="password"
              label="New password"
              validate={[validates.required, validates.password]}
              type="password"
            />
          </Inputs>
          <Inputs>
            <Field
              component={Input}
              variant="standard"
              autoComplete="chrome-off"
              name="confirmPassword"
              label="Confirm password"
              validate={[validates.required, validates.password]}
              type="password"
            />
          </Inputs>
        </InputsSection>
        <CustomButton type="submit" variant="contained" disabled={submitting} data-testid="button-send">
          Submit
        </CustomButton>
      </Form>
    </FormContainer>
  );
};

SSOForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  isCompany: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'Modal',
})(SSOForm);
