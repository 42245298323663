import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import { Container } from 'styles/commons';
import Layout from 'containers/Layout';
import { onAddJobOffers, onUpdateJob } from 'redux/actions/job.actions';

import { jobSelector } from 'redux/selectors/job.selector';

import CustomModal from 'components/commons/Modal';
import CreateJobView from 'views/CreateJob';
import { isPoc } from 'constants/roles';
import { getLocation } from 'helpers/string';
import { companyPropTypes, jobProptypes } from 'helpers/propTypes';
import JobDetails from 'components/JobDetails';

import {
  DIPPER_BLUE, GREEN_REVIEW, LIGHT_GREEN, WHITE,
} from 'styles/colors';

const CustomDivModal = styled.div`
  margin-top: -15px;
  text-align: left;
`;

const Title = styled.div`
  font: normal normal bold 20px/25px Mulish;
  color: ${DIPPER_BLUE};
  padding-left: 15px;
`;

const JobDiv = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const CustomButton = styled(Link)`
  background: ${GREEN_REVIEW};
  color: ${WHITE};
  display: flex;
  justify-content: center;
  font: normal normal bold 14px/18px Mulish;
  padding: 0 32px;
  border-radius: 4px;
  align-items: center;
  height: 40px;
  width: 200px;
  margin: 7px auto auto;

  :hover {
    background: ${LIGHT_GREEN};
    color: ${WHITE};
  }
`;

const CreateJobContainer = (props) => {
  const {
    location, onSubmit, onUpdate, user, editJob, noSalary,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const onCloseModal = () => setIsModalOpen(false);

  const onSubmitOpenModal = (formData) => {
    const newFormData = { ...formData };
    newFormData.location = getLocation(formData.city, formData.state, formData.country);
    if (location.state?.job) {
      setSelectedJob(newFormData);
      setIsModalOpen(true);
    } else {
      newFormData.company = user.company;
      newFormData.employeeType = formData.employeeType || 1;
      setSelectedJob(newFormData);
      setIsModalOpen(true);
    }
  };

  const onSubmitForm = (formData) => {
    if (location.state?.job) {
      onUpdate(formData);
    } else {
      onSubmit(formData);
    }
  };

  if (user && isPoc(user.role)) {
    return <Redirect to="/jobs" />;
  }

  return (
    <Container>
      <Layout isLandscape hideAddReview>
        {!user && <h1>Loading...</h1>}
        {user && (
          <>
            <CreateJobView
              onSubmit={onSubmitOpenModal}
              initialValues={!!location && editJob}
              noSalary={noSalary}
            />
            <CustomModal
              isOpen={isModalOpen}
              onClose={onCloseModal}
            >
              <CustomDivModal>
                <Title>
                  Preview
                </Title>
                <JobDiv>
                  <JobDetails
                    isCompany={false}
                    job={selectedJob}
                  />
                </JobDiv>
              </CustomDivModal>
              <CustomButton onClick={() => onSubmitForm(selectedJob)}>
                Confirm & Post
              </CustomButton>
              <div />
            </CustomModal>
          </>
        )}
      </Layout>
    </Container>
  );
};

const selector = formValueSelector('CreateJobForm');

const mapStateToProps = (state, props) => ({
  user: state.user.data,
  editJob: jobSelector(props),
  noSalary: selector(state, 'noSalary'),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: bindActionCreators(onAddJobOffers, dispatch),
  onUpdate: bindActionCreators(onUpdateJob, dispatch),
});

CreateJobContainer.defaultProps = {
  location: {},
  noSalary: false,
};

CreateJobContainer.propTypes = {
  editJob: jobProptypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  noSalary: PropTypes.bool,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.number.isRequired,
    company: companyPropTypes.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({
      job: jobProptypes,
    }),
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobContainer);
