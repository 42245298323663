import React from 'react';
import PropTypes from 'prop-types';

import StarsRating from 'components/commons/StarsRating';

import {
  Container, Title, Ranking, ItemList, Content, LogoAndName, Logo, EmptyRankingText,
  Name, Stars,
} from './styles';

const CompanyRanking = (props) => {
  const { ranking } = props;

  return (
    <Container>
      <Title>
        Company Rating
      </Title>
      <Ranking>
        {ranking?.length > 0
          ? ranking.map(({ company, stars }) => (
            <ItemList>
              <Content>
                <LogoAndName>
                  <Logo src={company.image} />
                  <Name>
                    {company.name}
                  </Name>
                </LogoAndName>
                <Stars>
                  <StarsRating
                    input={{ name: '', value: stars, onChange: () => {} }}
                    meta={{ touched: false, error: '' }}
                    readOnly
                    showText={false}
                    size="small"
                  />
                </Stars>
              </Content>
            </ItemList>
          ))
          : <EmptyRankingText>Not enough data for insights yet</EmptyRankingText>}
      </Ranking>
    </Container>
  );
};

CompanyRanking.propTypes = {
  ranking: PropTypes.arrayOf(PropTypes.shape({
    company: PropTypes.arrayOf(PropTypes.PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    })).isRequired,
    stars: PropTypes.number.isRequired,
  }).isRequired).isRequired,
};

export default CompanyRanking;
