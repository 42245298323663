import styled from 'styled-components';
import {
  DIPPER_BLUE, BLACK_MINE_SHAFT, GRAY_FOOTER, GREEN_REVIEW, WHITE,
} from 'styles/colors';
import { TABLET_SIZE, MOBILE_SIZE, SMALL_DESKTOP_SIZE_WITH_PADDING } from 'constants/sizes';
import { ReactComponent as FacebookIcon } from 'components/commons/assets/FacebookIcon.svg';
import { ReactComponent as LinkedInIcon } from 'components/commons/assets/LinkedinIcon.svg';
import { ReactComponent as InstagramIcon } from 'components/commons/assets/InstagramIcon.svg';

export const FooterSection = styled.footer`
  background-color: ${DIPPER_BLUE};
  width: 100%;
  height: 145px;
  
  @media (max-width: ${MOBILE_SIZE}px) {
    height: 100%;
  }
`;

export const FooterLink = styled.div`
  display: flex;
  color: ${GRAY_FOOTER} !important;
  font-size: 14px;
  line-height: 16px;

  :hover {
    color: ${GREEN_REVIEW} !important;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const FooterContent = styled.div`
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ul {
    display: flex;
    list-style-type: none;
    padding: 0%;

    @media (max-width: ${MOBILE_SIZE}px) {
      display: inline;
    }

    li {
      padding-right: 54px;
      @media (max-width: ${TABLET_SIZE}px) {
        padding-right: 49px;
      }

      @media (max-width: ${MOBILE_SIZE}px) {
        padding: 0 0 60px 0;
      }
    }
  }

  a {
    color: inherit;

    @media (max-width: ${MOBILE_SIZE}px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
`;

export const Last = styled.li`
  padding-right: 0 !important;

  @media (max-width: ${MOBILE_SIZE}px) {
    padding: 0 0 64px 0 !important;
  }
`;

export const FooterSocialMediaBox = styled.div`
  float: right;
`;

export const FooterSocialMediaIcon = styled.a`
  padding-left: 10px;
  color: ${BLACK_MINE_SHAFT};
`;

export const FooterAboutBox = styled.div`
  color: ${GRAY_FOOTER};
  display: flex;
  flex-direction: row;
  margin-right: 9px;
  align-self: center;
  
  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  color: ${DIPPER_BLUE};
  max-width: 1024px;
  margin: 0 auto;
  height: 144px;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    padding: 0 25px;
    height: 100%;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
  }

`;

export const LogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const FooterCopyright = styled.p`
  color: ${GRAY_FOOTER};
  margin-top: 17px;
  font-size: 14px;
  line-height: 16px;
  display: ${({ last }) => (last && 'none')};
  
  @media (max-width: ${MOBILE_SIZE}px) {
    display: ${({ last }) => (!last ? 'none' : 'flex')};
    align-self: center;
    margin-top: 60px;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 11px;

  @media (max-width: ${MOBILE_SIZE}px) {
    align-self: center;
  }
`;

export const Facebook = styled(FacebookIcon)`
  height: 26px;
  width: 26px;
  fill: ${GRAY_FOOTER};

  :hover {
    fill: ${WHITE};
  }

  @media (max-width: ${TABLET_SIZE}px) {
      height: 17px;
      width: 17px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    height: 40px;
    width: 40px;
  }
  `;

export const Instagram = styled(InstagramIcon)`
  height: 26px;
  width: 26px;
  margin-right: 11px;
  fill: ${GRAY_FOOTER};

  :hover {
    fill: ${WHITE};
  }

  @media (max-width: ${TABLET_SIZE}px) {
    height: 17px;
    width: 17px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    margin-right: 54px;
    height: 40px;
    width: 40px;
  }
`;

export const LinkedIn = styled(LinkedInIcon)`
  height: 26px;
  width: 26px;
  margin-right: 11px;
  fill: ${GRAY_FOOTER};

  :hover {
    fill: ${WHITE};
  }

  @media (max-width: ${TABLET_SIZE}px) {
    height: 17px;
    width: 17px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    margin-right: 54px;
    height: 40px;
    width: 40px;
  }
`;
