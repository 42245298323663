import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { PageContainer } from 'styles/commons';
import DatePicker from 'components/commons/DatePicker';
import Input from 'components/commons/Input';
import Dropdown from 'components/commons/Dropdown';
import CustomCheckbox from 'components/commons/Checkbox';

import RadioGroup from 'components/commons/RadioGroup';
import RadioButton from 'components/commons/RadioButton';

import { formatDate, getStringDate } from 'helpers/dates';
import { convertObjectToArray, parseOptionsForDropdown } from 'helpers/array';
import {
  email, required, isValidNumberWithCurrency,
} from 'helpers/validates';
import { removeCurrency } from 'helpers/string';

import {
  JOB_POSITION, JOB_LOCATION_CITY, JOB_LOCATION_STATE, JOB_LOCATION_COUNTRY,
  JOB_OVERVIEW, JOB_DESCRIPTION, JOB_CONTACT_EMAIL, JOB_SALARY,
} from 'constants/inputSizes';
import { employeeTypes } from 'constants/jobs';

import {
  Arrow, CardBox, CheckboxDiv, CustomButton, CustomLink, CreateJobContainer, DivCenterButton,
  DivDatePicker, DivForDropDown, Form, InputSection, LeftInputSection, RightInputSection,
  Row, SubTitle, SalaryFromContainer, SalaryToContainer, SalarySubtitle, Title, Text,
} from './styles';

const validate = (values) => {
  const errors = {};
  if (!values.noSalary) {
    if (!values.toSalary) {
      errors.toSalary = 'Required';
    }
    if (!values.fromSalary) {
      errors.fromSalary = 'Required';
    }
    if (values.fromSalary) {
      errors.fromSalary = isValidNumberWithCurrency(values.fromSalary);
    }
    if (values.toSalary) {
      errors.toSalary = isValidNumberWithCurrency(values.toSalary);
    }
    if (values.fromSalary && values.toSalary && !errors.fromSalary && !errors.toSalary) {
      if (removeCurrency(values.fromSalary) >= removeCurrency(values.toSalary)) {
        errors.fromSalary = 'Salary should be a valid range';
        errors.toSalary = 'Salary should be a valid range';
      }
    }
  }
  return errors;
};

const CreateJob = (props) => {
  const {
    handleSubmit, noSalary, onSubmit, submitting, invalid, pristine,
  } = props;

  const employeeTypesOptions = parseOptionsForDropdown(convertObjectToArray(employeeTypes));

  return (
    <PageContainer className="row" data-testid="add-job-offer-view">
      <CreateJobContainer>
        <CustomLink to="/jobs">
          <Title>
            <Arrow />
            {' '}
            Create Posting
          </Title>
        </CustomLink>
        <CardBox>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputSection>
              <LeftInputSection>
                <Field
                  component={Input}
                  name="jobPosition"
                  label="Job Title *"
                  validate={[required]}
                  maxLength={JOB_POSITION}
                  variant="outlined"
                  size="small"
                  hasLessPaddingTop
                  isItalic
                />
                <DivForDropDown>
                  <Field
                    component={Dropdown}
                    name="employeeType"
                    label="Employee Type *"
                    options={employeeTypesOptions}
                    variant="outlined"
                    isProfileInput
                    hasLessPaddingTop
                    isItalic
                  />
                </DivForDropDown>
                <SubTitle>Location</SubTitle>
                <Field
                  component={Input}
                  name="city"
                  label="City *"
                  validate={[required]}
                  maxLength={JOB_LOCATION_CITY}
                  hasLessPaddingTop
                  variant="outlined"
                  size="small"
                  isItalic
                />
                <Field
                  component={Input}
                  name="state"
                  label="State"
                  maxLength={JOB_LOCATION_STATE}
                  hasLessPaddingTop
                  variant="outlined"
                  size="small"
                  isItalic
                />
                <Field
                  component={Input}
                  name="country"
                  label="Country *"
                  validate={[required]}
                  maxLength={JOB_LOCATION_COUNTRY}
                  hasLessPaddingTop
                  variant="outlined"
                  size="small"
                  isItalic
                />
                <SubTitle>Annual salary</SubTitle>
                <Row>
                  <SalaryFromContainer>
                    <Field
                      component={Input}
                      name="fromSalary"
                      label="Example: 50,000"
                      maxLength={JOB_SALARY}
                      variant="outlined"
                      size="small"
                      disabled={noSalary}
                      isPlaceholder
                      isItalic
                    />
                  </SalaryFromContainer>
                  <SalarySubtitle>
                    to
                  </SalarySubtitle>
                  <SalaryToContainer>
                    <Field
                      component={Input}
                      name="toSalary"
                      label="Example: 60,000"
                      maxLength={JOB_SALARY}
                      variant="outlined"
                      size="small"
                      hasLessPaddingTop
                      disabled={noSalary}
                      isPlaceholder
                      isItalic
                    />
                  </SalaryToContainer>
                </Row>
                <CheckboxDiv>
                  <Field
                    name="noSalary"
                    component={CustomCheckbox}
                    label="Don’t include compensation."
                    isSmaller
                  />
                </CheckboxDiv>
              </LeftInputSection>
              <RightInputSection>
                <Field
                  component={Input}
                  name="contactEmail"
                  label="Contact Information *"
                  validate={[required, email]}
                  maxLength={JOB_CONTACT_EMAIL}
                  variant="outlined"
                  size="small"
                  isItalic
                  hasLessPaddingTop
                />
                <Field
                  component={Input}
                  name="overview"
                  label="Overview *"
                  validate={[required]}
                  maxLength={JOB_OVERVIEW}
                  variant="outlined"
                  isItalic
                  type="textarea"
                  size="small"
                  rows={5}
                  rowsMax={5}
                  hasLessPaddingTop
                />
                <SubTitle>Description *</SubTitle>
                <Field
                  component={Input}
                  name="description"
                  label="Example: First bulleted list item;Second bulleted list item;etc"
                  validate={[required]}
                  maxLength={JOB_DESCRIPTION}
                  variant="outlined"
                  type="textarea"
                  size="small"
                  isItalic
                  isPlaceholder
                  rows={5}
                  rowsMax={5}
                  hasLessPaddingTop
                />
                <Text>
                  Display bullet points by separating text with a semicolon “;”
                </Text>
                <DivDatePicker>
                  <Field
                    component={DatePicker}
                    name="expirationDate"
                    label="Expiration Date"
                    size="small"
                    isItalic
                    min={formatDate(new Date())}
                  />
                </DivDatePicker>
                <Field component={RadioGroup} name="isActive" validate={[required]}>
                  <div className="row">
                    <div className="col-4 col-lg-3">
                      <RadioButton name="isActive" text="Active" value="1" isBold />
                    </div>
                    <div className="col-4 col-lg-3">
                      <RadioButton name="isActive" text="Inactive" value="0" isBold />
                    </div>
                  </div>
                </Field>
                <DivCenterButton>
                  <CustomButton type="submit" variant="contained" disabled={pristine || submitting || invalid} data-testid="button-send">
                    Save Job
                  </CustomButton>
                </DivCenterButton>
              </RightInputSection>
            </InputSection>
          </Form>
        </CardBox>
      </CreateJobContainer>
    </PageContainer>
  );
};

CreateJob.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  noSalary: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'CreateJobForm',
  initialValues: {
    dateCreated: getStringDate(),
  },
  validate,
})(CreateJob);
