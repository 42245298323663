import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ReactComponent as AddIcon } from 'components/commons/assets/dipper-add.svg';
import { ReactComponent as IconEdit } from 'components/commons/assets/dipper-edit.svg';

import {
  BLUE, BLUE_DARK, DIPPER_BLUE, WHITE,
} from 'styles/colors';
import { TABLET_SIZE } from 'constants/sizes';

export const CreateReviewFloatingContainer = styled(Link)`
  display: none;

  @media (max-width: ${TABLET_SIZE}px) {
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    padding: 13px;
    border-radius: 50%;
    color: ${WHITE};
    background: ${BLUE};
    bottom: 82px;
    
    :hover {
      color: ${WHITE};
      background: ${BLUE_DARK};
    }
  }
`;

export const NewReviewIcon = styled(AddIcon)`
  width: 17px;
  height: 17px;
`;

export const CreateReviewFloatingHome = styled(Link)`
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: flex;
  padding: 10px;
  color: ${WHITE};
  background-color: rgba(71, 79, 99, 0.5);
  bottom: 30px;
  box-shadow: 0px 3px 5px #0000004D;
  border-radius: 4px;
  
  backdrop-filter: blur(10px);
  z-index: 10;

  font: normal normal bold 20px/25px Mulish;
  align-items: center;

  :hover {
    color: ${WHITE};
    background: ${DIPPER_BLUE};
    opacity: 1;
  }
`;

export const EditIcon = styled(IconEdit)`
  width: 19px;
  height: 19px;
  fill: ${WHITE};
`;
