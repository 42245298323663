import React from 'react';
import PropTypes from 'prop-types';

import ArrowSvg from 'components/commons/assets/arrow.svg';

import { StyledArrow } from './styles';

const Arrow = ({ reverse, onClick }) => (
  <StyledArrow reverse={reverse} data-testid="arrow" src={ArrowSvg} onClick={onClick} />
);

Arrow.defaultProps = {
  onClick: () => {},
  reverse: false,
};

Arrow.propTypes = {
  onClick: PropTypes.func,
  reverse: PropTypes.bool,
};

export default Arrow;
