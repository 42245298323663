import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import { MODAL_BACKGROUND } from 'styles/colors';

import {
  ModalBox, StyledModal, CloseModalContainer, Close,
} from './styles';

Modal.defaultStyles.overlay = {
  ...Modal.defaultStyles.overlay,
  backgroundColor: MODAL_BACKGROUND,
  zIndex: 21,
};

const CustomModal = (props) => {
  const { children, isOpen, onClose } = props;

  return (
    <StyledModal isOpen={isOpen} ariaHideApp={false}>
      <ModalBox>
        {onClose
        && (
          <CloseModalContainer>
            <Close onClick={onClose} />
          </CloseModalContainer>
        )}
        {children}
      </ModalBox>
    </StyledModal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default CustomModal;
