import styled from 'styled-components';
import { Button } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { WHITE } from 'styles/colors';

export const InputDiv = styled.div`
  width: 90%;
  background-color: white;
  heigth: fit-content;
`;

export const CustomButton = styled(Button)`
  width: fit-content;
  min-width: 0px !important;
  align-self: flex-end !important;
  
  .MuiOutlinedInput-adornedEnd {
    padding-right: 14px !important;
  }
`;

export const CustomInput = styled(OutlinedInput)`
  width: 100%;
  background-color: white;
  border-radius: 4px;

  .MuiButton-text {
    font: '14px/18px Mulish-Italic';
  }

  .MuiOutlinedInput-input {
    font: normal normal 14px/18px Mulish-Italic;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${WHITE};
  }
`;
