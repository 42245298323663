import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { onOpenPortraitMenu } from 'redux/actions/app.actions';

import Layout from 'containers/Layout';
import LegalInformation from 'views/LegalInformation';

const LegalInformationContainer = (props) => {
  const { isPortraitMenuOpened, onOpenPortraitMenuAction } = props;
  const onClickOutsideMenu = () => isPortraitMenuOpened && onOpenPortraitMenuAction();

  return (
    <Layout hideAddReview>
      <LegalInformation
        isPortraitMenuOpened={isPortraitMenuOpened}
        onClickOutsideMenu={onClickOutsideMenu}
      />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  isPortraitMenuOpened: state.app.isPortraitMenuOpened,
});

const mapDispatchToProps = (dispatch) => ({
  onOpenPortraitMenuAction: bindActionCreators(onOpenPortraitMenu, dispatch),
});

LegalInformationContainer.propTypes = {
  isPortraitMenuOpened: PropTypes.bool.isRequired,
  onOpenPortraitMenuAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalInformationContainer);
