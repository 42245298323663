import React from 'react';
import PropTypes from 'prop-types';

import { userPropTypes } from 'helpers/propTypes';

import AutoComplete from 'components/commons/AutoComplete';
import Logo from 'components/SideMenu/Logo';

import {
  HeaderContainer, AutoCompleteDiv, DivLogo, InfoDiv, ProfileText,
  TextHello, TextName, ProfilePicture,
} from './styles';

const Header = (props) => {
  const {
    companiesList, onSearch, user, hideSearch, onFocusSearch, onBlurSearch, showLogo,
    renderMenuMobile, goToProfile,
  } = props;

  return (
    <HeaderContainer data-testid="header">
      {showLogo
        && (
          <DivLogo>
            <Logo open toDashboard />
          </DivLogo>
        )}
      {!hideSearch && (
        <AutoCompleteDiv>
          <AutoComplete
            input={{ value: '', onChange: onSearch }}
            name="company"
            label="Search company"
            options={companiesList}
            size="small"
            search
            onFocusSearch={onFocusSearch}
            onBlurSearch={onBlurSearch}
            searchSelected={!showLogo}
          />
        </AutoCompleteDiv>
      )}
      {user && (
        <InfoDiv onClick={goToProfile}>
          <ProfileText>
            <TextHello>Hello,</TextHello>
            <TextName>
              {user.name}
            </TextName>
          </ProfileText>
          <ProfilePicture
            alt="placeholder"
            src={user.profileImage}
          />
        </InfoDiv>
      )}
      {renderMenuMobile()}
    </HeaderContainer>
  );
};

Header.propTypes = {
  companiesList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  hideSearch: PropTypes.bool.isRequired,
  onBlurSearch: PropTypes.func.isRequired,
  onFocusSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  showLogo: PropTypes.bool.isRequired,
  user: userPropTypes.isRequired,
  renderMenuMobile: PropTypes.func.isRequired,
  goToProfile: PropTypes.func.isRequired,
};

export default Header;
