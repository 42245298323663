import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import { password, required } from 'helpers/validates';
import { validatePassword } from 'admin/helpers/formValidation';

import Input from 'components/commons/Input';

import {
  InputsSection,
  PasswordInput,
  ButtonContainer,
  ButtonStyle,
} from '../styles';

const FormPassword = (props) => {
  const { handleSubmit, onSubmit } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputsSection isInputPassword>
        <PasswordInput>
          <Field
            component={Input}
            variant="outlined"
            name="oldPassword"
            label="Old password"
            size="small"
            type="password"
            isProfileInput
            isItalic
            validate={[validatePassword, required]}
            maxLength={250}
          />
        </PasswordInput>
        <PasswordInput>
          <Field
            component={Input}
            variant="outlined"
            name="newPassword"
            label="New password"
            size="small"
            type="password"
            isProfileInput
            isItalic
            validate={[validatePassword, required]}
            maxLength={250}
          />
        </PasswordInput>
        <PasswordInput>
          <Field
            component={Input}
            variant="outlined"
            name="newPasswordConfirmation"
            label="Repeat new password"
            size="small"
            type="password"
            isProfileInput
            isItalic
            validate={[password, required]}
            maxLength={250}
          />
        </PasswordInput>
        <ButtonContainer>
          <ButtonStyle isInputPassword type="submit">
            Change
          </ButtonStyle>
        </ButtonContainer>
      </InputsSection>
    </form>
  );
};

FormPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'updatePasswordForm',
})(FormPassword);
