import styled from 'styled-components';
import Modal from 'react-modal';

import { ReactComponent as CloseIcon } from 'components/commons/assets/close.svg';
import { BLUE_GRAY, WHITE } from 'styles/colors';
import { MOBILE_SIZE, SMALL_MOBILE_SIZE } from 'constants/sizes';

export const StyledModal = styled(Modal)`
  && {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;

    @media (max-width: ${MOBILE_SIZE - 40}px) {
      max-width: 100%;
      margin: 0 20px;
    }
  }
`;

export const ModalBox = styled.div`
  padding: 10px 30px 40px;
  background: ${WHITE};
  border-radius: 6px;
  max-height: 90vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    padding: 10px 10px 40px;
  }
`;

export const CloseModalContainer = styled.div`
  display: flex;
  //cursor: pointer;
  height: 15px;
  //width: fit-content; 
`;

export const Close = styled(CloseIcon)`
  margin: auto -18px 15px auto; 
  height: 18px;   
  width: auto;   
  fill: ${BLUE_GRAY};
  cursor: pointer;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    margin: auto 0px 15px auto; 
  }
`;
