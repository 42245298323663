import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { feedPropTypes } from 'helpers/propTypes';
import { timePassedSince } from 'helpers/dates';
import { addStyleToLinks } from 'helpers/string';

import { ReactComponent as ReplyIcon } from 'components/commons/assets/ReplyIcon.svg';
import { ReactComponent as CommentIcon } from 'components/commons/assets/CommentIcon.svg';

import ReplyBoxInput from 'components/commons/ReplyBoxInput';
import Metadata from 'components/commons/Metadata';

import { COMMUNITY_COMMENTS } from 'constants/inputSizes';

import {
  FeedContainer, FeedSection, ProfilePicture, Top, FeedText, FeedTime, DivReplyIcons,
  CommentNumber, CommentLogo, ReplyLogo, ReplyContainer, Description, MetaDataDiv,
} from './styles';

const Feed = (props) => {
  const {
    feed, isCommunity, isDashboard, replySelected, replyCloseInput, replyOpenInput,
    onSubmitAddComment,
  } = props;

  const feedDescription = React.createRef();

  const onSubmit = (formData, feedId) => {
    replyCloseInput();
    if (!formData.description) {
      return;
    }
    onSubmitAddComment({ ...formData, feed: feedId });
  };

  const renderReplySection = (feedId) => (
    <ReplyContainer data-testid="reply-container">
      <ReplyBoxInput
        onSubmit={(formData) => onSubmit(formData, feedId)}
        maxLength={COMMUNITY_COMMENTS}
      />
    </ReplyContainer>
  );

  return (
    <FeedContainer hasBorderBottom={isDashboard} className="col-lg-12" data-testid="div-feed">
      <FeedSection iscommunity={isCommunity}>
        <Top className="row">
          <ProfilePicture src={feed.profilePic} alt="placeholder" />
          <FeedText>
            {feed.author}
            <FeedTime>{timePassedSince(feed.dateCreated)}</FeedTime>
          </FeedText>
        </Top>
        <Description
          innerRef={feedDescription}
          html={addStyleToLinks(feed.description)}
          disabled
        />
        {!!feed.metaLink
          && (
            <MetaDataDiv>
              <Metadata
                description={feed.metaLink.description}
                title={feed.metaLink.title}
                url={feed.metaLink.url}
                image={feed.metaLink.image}
              />
            </MetaDataDiv>
          )}
        <div>
          {!replySelected
            ? (
              <DivReplyIcons>
                <CommentNumber>{feed.comments?.length || 0}</CommentNumber>
                <CommentLogo>
                  {isDashboard
                    ? (
                      <NavLink to="/feeds" activeClassName="active" data-testid="d">
                        <CommentIcon />
                      </NavLink>
                    )
                    : <CommentIcon />}
                </CommentLogo>
                <ReplyLogo onClick={(e) => { replyOpenInput(); e.stopPropagation(); }} data-testid="feed-reply-logo">
                  <ReplyIcon />
                </ReplyLogo>
              </DivReplyIcons>
            )
            : renderReplySection(feed.id)}
        </div>
      </FeedSection>
    </FeedContainer>
  );
};

Feed.defaultProps = {
  isCommunity: false,
  isDashboard: false,
};

Feed.propTypes = {
  feed: feedPropTypes.isRequired,
  isCommunity: PropTypes.bool,
  isDashboard: PropTypes.bool,
  replySelected: PropTypes.bool.isRequired,
  replyCloseInput: PropTypes.func.isRequired,
  replyOpenInput: PropTypes.func.isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
};

export default Feed;
