import * as React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  SelectInput,
  PasswordInput,
  required,
  email,
  FunctionField,
  BooleanField,
  BooleanInput,
  ImageInput,
  ImageField,
  DateField,
} from 'react-admin';
import {
  validateRequired,
  validatePassword,
  validateMaxSize,
} from 'admin/helpers/formValidation';
import { roles } from 'constants/roles';

import { AdminFormContainer } from 'styles/commons';
import {
  USER_BIOGRAPHY,
  USER_COUNTRY,
  USER_EMAIL,
  USER_FACEBOOK,
  USER_FIRST_NAME,
  USER_HEAD_LINE,
  USER_INSTAGRAM,
  USER_JOB_POSITION,
  USER_LAST_NAME,
  USER_LINKEDIN,
} from 'constants/inputSizes';

// TODO: profilePic will be an <ImageField /> component in the list
// and will be an <ImagenInput /> in edit and create

const rolesOptions = () => Object.values(roles);

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by First Name" source="firstName" alwaysOn />
    <TextInput label="Search by Email" source="email" alwaysOn />
    <SelectInput
      label="Search by Role"
      source="role"
      choices={rolesOptions()}
      optionText="name"
      optionValue="id"
      alwaysOn
    />
  </Filter>
);

const userRole = (record) => {
  const currentRole = Object.values(roles).find(
    (role) => role.id === record.role,
  );
  return currentRole ? currentRole.name : 'No Role found';
};

export const UserList = (props) => (
  <List
    filters={<UserFilter />}
    sort={{ field: 'dateUpdated', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField label="Last Update" source="dateUpdated" />
      <TextField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="jobPosition" />
      <BooleanField source="active" />
      <FunctionField label="Role" render={userRole} />
      <EditButton />
    </Datagrid>
  </List>
);

const UserTitle = ({ record }) => (
  <span>{record ? `${record.firstName}` : ''}</span>
);

const UserForm = ({ isEditForm }) => (
  <AdminFormContainer>
    {isEditForm && (
      <TextInput
        disabled
        source="id"
        validate={[required(), validateRequired]}
      />
    )}
    <TextInput
      source="email"
      type="email"
      validate={[
        required(),
        validateRequired,
        email(),
        (field) => validateMaxSize(USER_EMAIL, field),
      ]}
    />
    {isEditForm ? (
      <PasswordInput
        source="newPassword"
        maxLength={250}
        validate={[validatePassword]}
      />
    ) : (
      <PasswordInput
        source="password"
        maxLength={250}
        validate={[required(), validateRequired, validatePassword]}
      />
    )}
    <TextInput
      source="firstName"
      inputProps={{ maxLength: USER_FIRST_NAME }}
      validate={[
        required(),
        validateRequired,
        (field) => validateMaxSize(USER_FIRST_NAME, field),
      ]}
    />
    <TextInput
      source="lastName"
      inputProps={{ maxLength: USER_LAST_NAME }}
      validate={[
        required(),
        validateRequired,
        (field) => validateMaxSize(USER_LAST_NAME, field),
      ]}
    />
    <TextInput
      source="jobPosition"
      label="Current position"
      inputProps={{ maxLength: USER_JOB_POSITION }}
      validate={[(field) => validateMaxSize(USER_JOB_POSITION, field)]}
    />
    <TextInput
      source="headline"
      inputProps={{ maxLength: USER_HEAD_LINE }}
      validate={[(field) => validateMaxSize(USER_HEAD_LINE, field)]}
    />
    <TextInput
      source="country"
      label="Country/Region"
      inputProps={{ maxLength: USER_COUNTRY }}
      validate={[(field) => validateMaxSize(USER_COUNTRY, field)]}
    />
    <TextInput
      source="linkedIn"
      label="LinkedIn"
      inputProps={{ maxLength: USER_LINKEDIN }}
      validate={[(field) => validateMaxSize(USER_LINKEDIN, field)]}
    />
    <TextInput
      source="facebook"
      inputProps={{ maxLength: USER_FACEBOOK }}
      validate={[(field) => validateMaxSize(USER_FACEBOOK, field)]}
    />
    <TextInput
      source="instagram"
      inputProps={{ maxLength: USER_INSTAGRAM }}
      validate={[(field) => validateMaxSize(USER_INSTAGRAM, field)]}
    />
    <TextInput
      source="biography"
      label="Bio"
      multiline
      inputProps={{ maxLength: USER_BIOGRAPHY }}
      validate={[(field) => validateMaxSize(USER_BIOGRAPHY, field)]}
    />
    <BooleanInput source="active" />
    <ImageInput source="profilePic" label="profilePic" accept="image/*">
      <ImageField source="path" title="title" />
    </ImageInput>
    <SelectInput
      source="role"
      validate={required()}
      choices={rolesOptions()}
      optionText="name"
      optionValue="id"
    />
  </AdminFormContainer>
);

export const UserEdit = (props) => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm>
      <UserForm isEditForm />
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <UserForm isEditForm={false} />
    </SimpleForm>
  </Create>
);

UserForm.propTypes = {
  isEditForm: PropTypes.bool.isRequired,
};

UserTitle.defaultProps = {
  record: null,
};

UserTitle.propTypes = {
  record: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }),
};
