import { createSelector } from 'reselect';

import { getYear, getMonth } from 'helpers/dates';

const selectedReview = (props) => props.location.state?.review;

const reviewSelector = createSelector([selectedReview],
  (review) => !!review && {
    company: {
      key: review.company?.code || 0,
      value: review.company?.name || '',
    },
    jobTitle: review.jobTitle,
    startMonth: getMonth(review.startDate),
    startYear: getYear(review.startDate),
    endMonth: review.endDate && getMonth(review.startDate),
    endYear: review.endDate && getYear(review.startDate),
    currentlyWorking: !review.endDate && true,
    companyIsGreatPlaceToWork: review.companyIsGreatPlaceToWork,
    companyTreatment: review.companyTreatment,
    companyDiversity: review.companyDiversity.toString(),
    recommendCompany: review.recommendCompany ? 'Yes' : 'No',
    personalSituation: review.personalSituation.toString(),
    feedback: review.feedback ? 'Yes' : 'No',
    title: review.title,
    description: review.description,
    anonymous: review.anonymous ? 'Yes' : 'No',
    id: review.id,
  });

export default reviewSelector;
