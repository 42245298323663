import {
  BOOSTRAP_MEDIUM, MOBILE_SIZE, SMALL_MOBILE_SIZE, TABLET_SIZE,
  SMALL_TABLET_SIZE,
  BIG_MOBILE_SIZE,
} from 'constants/sizes';

export const isSmallMobile = () => window.innerWidth <= SMALL_MOBILE_SIZE;

export const isMobile = () => window.innerWidth <= MOBILE_SIZE;

export const isBigMobile = () => window.innerWidth <= BIG_MOBILE_SIZE;

export const isTablet = () => window.innerWidth <= TABLET_SIZE && !isMobile();

export const isSmallTablet = () => window.innerWidth <= SMALL_TABLET_SIZE;

export const isBootstrapMedium = () => window.innerWidth <= BOOSTRAP_MEDIUM && !isMobile();
