import React from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@material-ui/icons/Add';

import * as constants from './constants';
import {
  CorporateContainer, RowContainer, TextContainer, CorporateImage, Button,
} from './styles';

const CorporateMembership = (props) => {
  const { onClick, isJobView } = props;

  return (
    <CorporateContainer>
      <RowContainer>
        <TextContainer isJobView={isJobView}>
          <h2>
            {constants.TITLE_SMALL}
          </h2>
          <h1>
            {constants.TITLE_BIG}
          </h1>
          <h6>
            {constants.FIRST_PARAGRAPH}
          </h6>
          <h6>
            {constants.SECOND_PARAGRAPH}
          </h6>
        </TextContainer>
        <CorporateImage isJobView={isJobView} />
      </RowContainer>
      <Button onClick={onClick}>
        <AddIcon />
        Join Now
      </Button>
    </CorporateContainer>
  );
};

CorporateMembership.defaultProps = {
  isJobView: false,
};

CorporateMembership.propTypes = {
  onClick: PropTypes.func.isRequired,
  isJobView: PropTypes.bool,
};

export default CorporateMembership;
