// USER
export const USER_EMAIL = 100;

export const USER_FIRST_NAME = 20;

export const USER_LAST_NAME = 40;

export const USER_JOB_POSITION = 100;

export const USER_HEAD_LINE = 120;

export const USER_COUNTRY = 50;

export const USER_INSTAGRAM = 255;

export const USER_FACEBOOK = 255;

export const USER_LINKEDIN = 255;

export const USER_BIOGRAPHY = 2000;

// Company
export const COMPANY_NAME = 100;

export const COMPANY_DESCRIPTION = 2000;

export const COMPANY_LOCATION = 100;

export const COMPANY_WEBSITE = 70;

export const COMPANY_INDUSTRY = 100;

export const COMPANY_INSTAGRAM = 255;

export const COMPANY_FACEBOOK = 255;

export const COMPANY_LINKEDIN = 255;

export const COMPANY_DEI_COMMITMENTS = 1024;

// Review
export const REVIEW_TITLE = 255;

export const REVIEW_JOB_TITLE = 255;

export const REVIEW_DESCRIPTION = 4095;

export const REVIEW_COMPANY_ANSWER = 4095;

// Community
export const COMMUNITY_FEED = 1024;

export const COMMUNITY_COMMENTS = 1024;

// Job
export const JOB_POSITION = 100;

export const JOB_LOCATION = 180;

export const JOB_LOCATION_CITY = 70;

export const JOB_LOCATION_STATE = 40;

export const JOB_LOCATION_COUNTRY = 70;

export const JOB_OVERVIEW = 500;

export const JOB_DESCRIPTION = 4095;

export const JOB_CONTACT_EMAIL = 100;

export const JOB_SALARY = 10;
