import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StarRoundedIcon from '@material-ui/icons/StarRounded';

import { labels } from '../../../constants/labels';
import {
  StarsRatingContainer, CustomError, RateText, StyledRating, DescriptionText,
} from './styles';

const StarsRating = (props) => {
  const {
    hasPadding, input: { name, value, onChange }, isColumn, meta: { touched, error }, readOnly,
    showText, size,
  } = props;

  const [hover, setHover] = useState(-1);

  return (
    <StarsRatingContainer className="row" hasPadding={hasPadding} isColumn={isColumn}>
      {!readOnly && <RateText>Rate your answer</RateText>}
      <StyledRating
        name={name}
        data-testid="stars-rating"
        value={parseInt(value, 0)}
        precision={1}
        size={size}
        readOnly={readOnly}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        icon={<StarRoundedIcon fontSize="inherit" />}
      />
      {(value && showText) && (
        <DescriptionText isColumn={isColumn}>
          {labels[hover !== -1 ? hover : value]}
        </DescriptionText>
      )}
      {!readOnly && touched && error && <CustomError>{error}</CustomError>}
    </StarsRatingContainer>
  );
};

StarsRating.defaultProps = {
  hasPadding: false,
  isColumn: false,
  readOnly: false,
  showText: true,
  size: 'large',
};

StarsRating.propTypes = {
  hasPadding: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  isColumn: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  readOnly: PropTypes.bool,
  showText: PropTypes.bool,
  size: PropTypes.string,
};

export default StarsRating;
