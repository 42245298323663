import styled, { css } from 'styled-components';

import { ReactComponent as EditIcon } from 'components/commons/assets/dipper-edit-review.svg';
import { ReactComponent as TrashIcon } from 'components/commons/assets/dipper-trash.svg';
import { ReactComponent as MoreOptionsIcon } from 'components/commons/assets/dots.svg';

import {
  GRAY_DARK, DIPPER_BLUE, GRAY_REVIEW_DATE, GRAY_COMMUNITY_LINES, WHITE, GRAY_COMMUNITY,
  GREEN_REVIEW,
} from 'styles/colors';
import {
  BOOSTRAP_MEDIUM, MOBILE_SIZE, SMALL_MOBILE_SIZE,
} from 'constants/sizes';

export const ReviewSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  opacity: ${({ withOpacity }) => (withOpacity ? '0.5' : '1')};

  ${({ changeBackgroundMarginAndBorder }) => (
    changeBackgroundMarginAndBorder
      && css`
        background: ${WHITE};
        margin-bottom: 20px;
        border-radius: 6px;
    `
  )};

  h4 {
    font-weight: bold;
    color: ${GRAY_DARK};
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  ${({ addBorderAndPaddingBottom }) => (
    addBorderAndPaddingBottom
      && css`
        border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
        padding-bottom: 7px;
    `
  )};
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-right: 5px;
  object-fit: cover;
`;

export const NameAndDateDiv = styled.div`
  margin-right: 3px;
  display: flex;
  width: 100%;
  flex-direction: ${({ haveFlexDirectionColumn }) => (haveFlexDirectionColumn ? 'column' : 'row')};
  overflow: ${({ withOverflowHidden }) => (withOverflowHidden ? 'hidden' : '')};
`;

export const TitleDateDiv = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: ${({ haveFlexDirectionColumn }) => (haveFlexDirectionColumn ? 'column' : 'row')};

  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: ${({ haveDirectionRowMobile }) => (haveDirectionRowMobile ? 'row' : 'column')};
  }
`;

export const ReviewTitle = styled.div`
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: ${({ haveFlexDirectionColumn }) => (haveFlexDirectionColumn ? 'column' : 'row')};
  justify-content: flex-start;
  font: normal normal bolder 16px/20px Mulish;
  color: ${DIPPER_BLUE};
`;

export const CompanyTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ReviewJob = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
  margin-left: ${({ haveMarginLeft }) => (haveMarginLeft ? '7px' : '0')};
  font: normal normal normal 12px/20px Mulish;
  align-content: baseline;
  color: ${DIPPER_BLUE};
  
  span {
    padding-right: 7px;
  }
`;

export const ReviewDate = styled.div`
  display: flex;
  flex: 1;
  font: italic normal normal 11px/14px Mulish;
  color: ${GRAY_REVIEW_DATE};
  padding-top: 5px;
  justify-content: ${({ hasFlexEnd }) => (hasFlexEnd ? 'flex-end' : '')};
`;

export const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const PendingText = styled.div`
  background: ${GREEN_REVIEW};
  border-radius: 4px;
  padding: 6px;
  color: ${WHITE};
  font: normal normal normal 11px/14px Mulish;
  opacity: 1 !important;
`;

export const RejectedText = styled.div`
  background: red;
  border-radius: 4px;
  padding: 6px;
  color: ${WHITE};
  font: normal normal normal 11px/14px Mulish;
  opacity: 1 !important;
`;

export const Title = styled.div`
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 14px/18px Mulish;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};
  padding: 10px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ReviewContent = styled.div`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: fit-content;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  font: normal normal normal 12px/16px Mulish;
  color: ${DIPPER_BLUE};
  word-break: break-word;
  padding-top: 5px;
`;

export const CompanyAnswerContainer = styled.div`
  border-left: 3px solid ${GREEN_REVIEW};
  padding-left: 20px;
  margin-top: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AnswerText = styled.div`
  color: ${DIPPER_BLUE};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding-top: 5px;
  white-space: pre-line;
`;

export const AnswerLogo = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;

export const SmallMobileVisibleContainer = styled.div`
  display: none;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    display: flex;
  }
`;

export const DesktopVisibleContainer = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  margin: 3px 0 0 5px;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: flex;
  }

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    display: none;
  }
`;

export const ReplyContainer = styled.div`
  width: 100%;
  margin-top: 3px;
`;

export const EditButton = styled(EditIcon)`
  height: 17px;
  width: auto;
  cursor: pointer;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    height: 25px;
  }
`;

export const VerticalLine = styled.div`
  height: 15px;
  margin: 2px 5px 0 0;
  border-right: 1px solid ${GRAY_COMMUNITY};

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    display: none;
  }
`;

export const DeleteButton = styled(TrashIcon)`
  cursor: pointer;
  height: 13px;
  width: auto;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    height: 20px;
  }
`;

export const MoreOptionsContainer = styled.div`
  position: relative;
  margin-left: 10px;
`;

export const MoreOptionsButton = styled(MoreOptionsIcon)`
  justify-self: flex-end;
  cursor: pointer;
`;

export const PopUpOptions = styled.div`
  position: absolute;
  bottom: -50px;
  right: 0;
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 92px; 
  background: ${WHITE};
  padding: 10px;
  border-radius: 4px;
  justify-content: space-around;
  z-index: 2;

  -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.47);
  -moz-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.47);
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.47);
`;

export const AllScreen = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
