import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Feed from 'components/Feed';
import ViewAll from 'components/commons/ViewAll';
import CreateFeed from 'containers/CreateFeed';
import ItemsList from 'components/commons/ItemsList';

import {
  FeedsSection, Title, FeedContent,
} from './styles';

const FeedsDashboard = (props) => {
  const { feedsList, onSubmitAddComment } = props;

  const [replySelected, setReplySelected] = useState(null);

  const renderFeed = (feed) => (
    <div key={feed.id}>
      <Feed
        feed={feed}
        key={feed.id}
        isCommunity
        isDashboard
        replySelected={feed.id === replySelected}
        replyOpenInput={() => setReplySelected(feed.id)}
        replyCloseInput={() => setReplySelected(null)}
        onSubmitAddComment={onSubmitAddComment}
      />
    </div>
  );

  return (
    <FeedsSection data-testid="feeds-section" className="col-lg-5 column">
      <Title>
        <h1>Community</h1>
        <ViewAll viewMoreLink="/feeds" />
      </Title>
      <FeedContent>
        <h1>How&apos;s work?</h1>
        <CreateFeed />
        <ItemsList isDashboard items={feedsList} renderItem={renderFeed} emptyItemsText="There are no feeds" />
      </FeedContent>
    </FeedsSection>
  );
};

FeedsDashboard.propTypes = {
  feedsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        role: PropTypes.number.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
};

export default FeedsDashboard;
