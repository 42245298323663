import * as constants from 'redux/constants/job.constants';
import { QUANTITY_JOB } from 'constants/configs';

export function onJobDelete(formData) {
  return {
    type: constants.JOB_ON_DELETE_REQUESTED,
    formData,
  };
}

export function onGetJobsFromCompany(quantity, page, statusInactive) {
  return {
    type: constants.JOB_GET_ALL_COMPANY_REQUESTED,
    page,
    quantity,
    statusInactive,
  };
}

export function onGetJobsForPoC(quantity, page, jobPositionFilter, locationFilter) {
  return {
    type: constants.JOB_GET_ALL_POC_REQUESTED,
    page,
    quantity,
    jobPositionFilter,
    locationFilter,
  };
}

export function onAddJobOffers(formData) {
  const newFormData = formData;
  if (!newFormData.employeeType) {
    newFormData.employeeType = '1';
  }
  return {
    type: constants.JOB_ON_ADD_OFFER_REQUESTED,
    formData: newFormData,
  };
}

export function onUpdateJob(formData) {
  return {
    type: constants.JOB_ON_UPDATE_REQUESTED,
    formData,
  };
}

export function onSetJobSelected(job) {
  return {
    type: constants.JOB_SET_SELECTED_REQUESTED,
    job,
  };
}

export function onSetStatusInactive(status) {
  return {
    type: constants.JOB_SET_INACTIVE_STATUS_REQUESTED,
    status,
    page: 0,
    quantity: QUANTITY_JOB,
  };
}

export function onSetFilters(formData) {
  const { jobPositionFilter, locationFilter } = formData;
  return {
    type: constants.JOB_SET_FILTERS_REQUESTED,
    jobPositionFilter,
    locationFilter,
    page: 0,
    quantity: QUANTITY_JOB,
  };
}

export function onSetPageNumber(page) {
  return {
    type: constants.JOB_SET_PAGE_REQUESTED,
    page,
    quantity: QUANTITY_JOB,
  };
}
