export const labels = {
  1: 'Strongly disagree',
  2: 'Disagree',
  3: 'Neutral',
  4: 'Agree',
  5: 'Strongly Agree',
};

export const subjectSituation = {
  1: 'Navigating career mobility',
  2: 'Company culture',
  3: 'Racial or ethnic discrimination',
  4: 'Code switch / Imposter Syndrome',
  5: 'Dealing with microaggresions',
  6: 'Not ready to share yet',
  7: 'Other',
};

export const companyDiversity = {
  1: 'They get it and are doing great',
  2: 'They have the right intent, but poor execution',
  3: 'Its only lip service',
  4: 'Doesnt matter at all',
};

export const reviewStatus = {
  1: 'Pending',
  2: 'Approved',
  3: 'Rejected',
};
