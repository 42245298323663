import { createSelector } from 'reselect';

import { isPoc, isCompany } from 'constants/roles';
import { formatDate } from 'helpers/dates';
import { convertObjectToArray } from 'helpers/array';
import SIZE_OPTIONS from 'constants/companySize';

import DefaultProfilePoc from 'components/commons/assets/avatar-poc.svg';
import DefaultProfileCompany from 'components/commons/assets/avatar-company.svg';

const getUser = (state) => state.user.data;
const getProfileForm = (state) => state.form.profile;

const companySelector = createSelector([getUser],
  (user) => {
    const company = user?.company;
    return !!company && isCompany(user?.role) && {
      name: company.name || '',
      image: company.logo?.path || DefaultProfileCompany,
      location: company.location,
      website: company.website,
      industry: company.industry,
      size: company.size,
      description: company.description,
      deiCommitments: company.deiCommitments,
      instagram: company.instagram,
      facebook: company.facebook,
      linkedIn: company.linkedIn,
      isPremium: company.isPremium,
      founded: company.founded && formatDate(company.founded),
    };
  });

export const pocSelector = createSelector(
  [getUser],
  (user) => !!user && isPoc(user.role) && ({
    headline: user.headline,
    jobPosition: user.jobPosition,
    country: user.country,
    instagram: user.instagram,
    facebook: user.facebook,
    linkedIn: user.linkedIn,
    biography: user.biography,
    name: `${user.firstName} ${user.lastName}`,
    image: user.profilePic?.path || DefaultProfilePoc,
    showOnBoarding: user.userOnBoarding?.showOnBoarding,
  }),
);

export const profileFormImageSelector = createSelector(
  [getUser, getProfileForm],
  (user, profileForm) => {
    if (profileForm?.values.image?.path) {
      return profileForm.values.image.path;
    }
    if (isCompany(user?.role)) {
      return user?.company.logo ? user.company.logo.path : DefaultProfileCompany;
    }
    return user?.profilePic ? user.profilePic.path : DefaultProfilePoc;
  },
);

export const profileImageSelector = createSelector(
  [getUser],
  (user) => {
    if (isCompany(user?.role)) {
      return user?.company.logo ? user.company.logo.path : DefaultProfileCompany;
    }
    return user?.profilePic ? user.profilePic.path : DefaultProfilePoc;
  },
);

export const userSelector = createSelector(
  [getUser, companySelector, pocSelector, profileImageSelector],
  (user, company, poc, profileImage) => ({
    amountOfComments: user?.amountOfComments,
    amountOfFeeds: user?.amountOfFeeds,
    amountOfReviews: user?.amountOfReviews,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    profileImage,
    role: user?.role,
    ...(company || poc),
  }),
);

export const companyIdSelector = createSelector(
  [getUser],
  (user) => user.company?.id,
);

export const contactSelector = createSelector(
  [getUser],
  (user) => !!user && ({
    email: user.email,
    firstName: `${user.firstName} ${user.lastName}`,
    companyName: user.company?.name,
  }),
);

export const companySizeSelector = () => convertObjectToArray(SIZE_OPTIONS).map(
  ([key, value]) => ({
    value: key,
    text: value,
  }),
);
