import styled, { css } from 'styled-components';

export const StyledArrow = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  margin-right: 1px;
  cursor: pointer;

  box-shadow: 0px 2px 60px 0px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0px 2px 60px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 60px 0px rgba(0,0,0,0.1);

  ${({ reverse }) => (
    reverse
      ? css`
        left: 0;
        transform: rotate(180deg);
      `
      : css`
        right: 0;
      `
  )};
`;

export default { StyledArrow };
