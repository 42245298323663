import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import { DivLink, CustomButton } from './styles';

const ViewAll = (props) => {
  const {
    onClick, viewMoreLink, viewMoreText, companyCode,
  } = props;

  return (
    <DivLink biggerPaddingBottom={!viewMoreLink} data-testid="view-all-component">
      {viewMoreLink
        ? (
          <Link to={viewMoreLink} className="Link">
            {' '}
            {viewMoreText}
            {' '}
          </Link>
        )
        : (
          <NavHashLink smooth to={`/company/${companyCode}#top`}>
            <CustomButton type="button" onClick={onClick}>
              {viewMoreText}
            </CustomButton>
          </NavHashLink>
        )}

    </DivLink>
  );
};

ViewAll.defaultProps = {
  viewMoreText: 'View all',
  viewMoreLink: '',
  onClick: () => {},
  companyCode: '',
};

ViewAll.propTypes = {
  viewMoreLink: PropTypes.string,
  viewMoreText: PropTypes.string,
  onClick: PropTypes.func,
  companyCode: PropTypes.string,
};

export default ViewAll;
