import * as constants from 'redux/constants/app.constants';

export function onOpenMenu() {
  return {
    type: constants.APP_ON_OPEN_MENU_REQUESTED,
  };
}

export function onAcceptCookies() {
  return {
    type: constants.APP_ON_ACCEPT_COOKIES_REQUESTED,
  };
}

export function onOpenPortraitMenu() {
  return {
    type: constants.APP_ON_OPEN_PORTRAIT_MENU_REQUESTED,
  };
}
