import { get, put } from 'services/api';
import { saveImage } from 'helpers/images';

export async function getAll() {
  const response = await get('companies/getAll');
  return response;
}

export async function update(formData) {
  const data = { ...formData };
  const { rawFile } = data.logo;
  if (rawFile) {
    data.logo = await saveImage(rawFile, data.logo);
  }
  const response = await put('companies/update', data);
  return response;
}

export async function getRank(companyId = '') {
  const response = await get(`companies/rank?companyId=${companyId}`);
  return response;
}
