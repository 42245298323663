import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { SMALL_DESKTOP_SIZE_WITH_PADDING, TABLET_SIZE } from 'constants/sizes';
import {
  GRAY, DIPPER_BLUE, GRAY_COMMUNITY_LINES, LIGHT_BLUE,
} from 'styles/colors';

export const TermsAndConditons = styled.div`
  padding: 60px 0;
`;

export const PrivacyPolicy = styled.div`
  padding: 30px 0;
`;

export const Container = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 80px;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    padding: 50px 25px;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    ${({ blurPage }) => (blurPage && css`
      box-shadow: 0px 3px 6px #00000017;
      opacity: 1;
      filter: blur(3px);
      -webkit-filter: blur(3px);    
    `)}
  }  
`;

export const Title = styled.p`
  margin-top: ${({ withMarginTop }) => (withMarginTop ? '50px' : '0')};
  font: normal normal bold 24px Mulish;
 
  ${({ biggerAndColor }) => (biggerAndColor && css`
    font: normal normal bold 30px Mulish;
    color: ${DIPPER_BLUE};
  `)}
`;

export const Text = styled.p`
  font: 16px Mulish;
`;

export const SubTitle = styled.p`
  font: normal normal bold 16px Mulish;
`;

export const Answer = styled.p`
  font: normal normal bold 16px Mulish;
  color: ${GRAY};
`;

export const BoldText = styled.div`
  font-weight: bold;
`;

export const Breadcrumbs = styled.div`
  display: flex;
  margin-bottom: 34px;

  @media (max-width: ${TABLET_SIZE}px) {
   padding-bottom: 7px; 
   border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
  }
`;

export const BreacrumbTitle = styled.div`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0px;
  color: ${LIGHT_BLUE};

  ${({ isSubTitle }) => (
    isSubTitle
      ? css`
      padding-left: 5px;
      color: ${DIPPER_BLUE};
    `
      : css`
      font-weight: bold;
      padding-right: 5px;
    `
  )};

  @media (max-width: ${TABLET_SIZE}px) {
    font-size: 22px;
  }
`;

export const Border = styled.div`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};
`;

export const CustomLink = styled(Link)`
  display: flex;
`;
