import styled from 'styled-components';
import { TABLET_SIZE, MOBILE_SIZE } from 'constants/sizes';
import { ReactComponent as Close } from 'components/commons/assets/close.svg';
import { ReactComponent as Menu } from './assets/menu.svg';

export const OpenMenu = styled.div`
  display: none;

  @media (max-width: ${TABLET_SIZE}px) {
    display: flex;
    float: right;
    position: fixed;
    right: 0;
    padding-right: 20px;
    z-index: 3;
  }
`;

export const DivContainer = styled.div`
  display: contents;
`;

export const MenuIconMenu = styled(Menu)`
  width: 27px;
  height: 18px;
  font-size: 480px;

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 22px;
    height: 17px;
  }
`;

export const CloseRoundedIconMenu = styled(Close)`
  width: 18px;
  height: 18px;
`;
