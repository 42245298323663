import React from 'react';
import PropTypes from 'prop-types';

import {
  Container, PrivacyPolicy, TermsAndConditons, Title, SubTitle, Answer,
  BoldText, Breadcrumbs, CustomLink, Border, BreacrumbTitle,
} from './styles';

const LegalInformation = (props) => {
  const { isPortraitMenuOpened, onClickOutsideMenu } = props;

  return (
    <Container
      id="privacy"
      blurPage={isPortraitMenuOpened}
      onClick={onClickOutsideMenu}
    >
      <PrivacyPolicy>
        <Breadcrumbs id="breadcrumb">
          <CustomLink to="/">
            <BreacrumbTitle>
              Dipper
            </BreacrumbTitle>
          </CustomLink>
          <Border>/</Border>
          <BreacrumbTitle isSubTitle>
            Privacy Policy
          </BreacrumbTitle>
        </Breadcrumbs>

        <Title biggerAndColor>
          OUR DIPPER INC. PRIVACY POLICY
        </Title>
        <p>
          When you use our services, you’re trusting us with your information. We understand
          this is a big responsibility and work hard to protect your information and put you
          in control.
          <br />
          <br />
          This Privacy Policy is meant to help you understand what information we collect, why
          we collect it, and how you can update, manage, export, and delete your information.
          {' '}
          <br />
          <br />
          Effective DECEMBER 1, 2020
        </p>
        <div>
          We build a range of services that help people daily to explore and interact with
          the world in new ways. Our services may include:
          <ul>
            <li>Apps, sites, and devices  </li>
            <li>Platforms</li>
            <li>Products that are integrated into third-party apps and sites</li>
          </ul>
          <p>
            You can use our services in a variety of ways to manage your privacy. For example,
            you can sign up for an Our Dipper Inc. (Dipper) Account if you want to create and
            manage content like emails and photos, or see more relevant search results. You can
            also choose to write reviews that are anonymously or public. And across our services,
            you can adjust your settings to control what we share and how your information is used.
          </p>
        </div>
        <div>
          <Title withMarginTop>
            INFORMATION DIPPER COLLECTS
          </Title>
          <p>
            We want you to understand the types of information we collect as you use our services
            <br />
            {' '}
            <br />
            We collect information to provide better services to all our users — from figuring out
            basic stuff like which language you speak, to more complex things like which reviews
            you’ll find most useful, the people who matter most to you online, or which companies
            you might like. The information Dipper collects, and how that information is used,
            depends on how you use our services and how you manage your privacy controls.
            {' '}
            <br />
            When you’re not signed into a Dipper Account, we store the information we collect
            with unique identifiers tied to the browser, application, or device you’re using.
            <br />
            {' '}
            When you’re signed in, we also collect information that we store with your
            Dipper Account, which we treat as personal information.
          </p>
        </div>
        <div>
          <Title withMarginTop>
            Things you create or provide to us
          </Title>
          <p>
            When you create a Dipper Account, you provide us with personal information that
            includes your name and a password. Even if you aren’t signed into a Dipper Account,
            you might choose to provide us with information — like an email address to receive
            updates about our services.
            {' '}
            <br />
            We also collect the content you create, upload, or receive from others when using our
            services. This includes things like reviews you write and read, post, comments, and
            other information.
            {' '}
            <br />
            {' '}
            <br />
            We collect information about the apps, browsers, and devices you use to access Dipper
            services, which helps us provide features like automatic product updates and dimming
            your screen if your battery runs low.
            {' '}
            <br />
            {' '}
            <br />
            The information we collect includes unique identifiers, browser type and settings,
            device type and settings, operating system, mobile network information including
            carrier name and phone number, and application version number. We also collect
            information about the interaction of your apps, browsers, and devices with our
            services, including IP address, crash reports, system activity, and the date,
            time, and referrer URL of your request.
            {' '}
            <br />
            {' '}
            <br />
            We collect this information when a Dipper service on your device contacts our
            servers. This information includes things like your device type, carrier name,
            crash reports, and which apps you&apos;ve installed.
          </p>
          <div>
            <SubTitle>Your activity</SubTitle>
            <p>
              We collect information about your activity in our services, which we use to do
              things like recommending a company you might like. The activity information we
              collect may include:
            </p>
            <ul>
              <li>Terms you search for</li>
              <li>Reviews you write </li>
              <li>Views and interactions with content</li>
              <li>Purchase activity</li>
              <li>People with whom you communicate or share content</li>
              <li>Activity on third-party sites and apps that use our services</li>
            </ul>
            <p>
              You can visit your Dipper Account to find and manager activity information
              that’s saved in your account.
            </p>
            <SubTitle>Your location information</SubTitle>
            <div>
              <p>
                We collect information about your location when you use our services,
                which helps us offer features.
                {' '}
                <br />
                {' '}
                <br />
                Your location can be determined with varying degrees of accuracy by:
              </p>
              <ul>
                <li>GPS </li>
                <li>IP address  </li>
                <li>Sensor data from your device </li>
                <li>
                  Information about things your devices, such as Wi-Fi access points,
                  cell towers, and Bluetooth-enabled devices
                </li>
              </ul>
              <p>
                These types of location data we collect depend in part on your device
                and account settings.
                <br />
                {' '}
                <br />
                In some circumstances, Dipper also collects information about you
                from publicly accessible sources.
                {' '}
                <br />
                {' '}
                <br />
                We use various technologies to collect and store information, including
                cookies, pixel tags, local storage, such as browser web storage or
                application data caches, databases, and server logs.
              </p>
            </div>
            <div>
              <Answer>
                WHY DIPPER COLLECTS DATA?
              </Answer>
              <Title>
                We use data to build better services
              </Title>
              <p>
                We use the information we collect from all our services for the
                following purposes:
              </p>
              <SubTitle>
                Provide our services
              </SubTitle>
              <p>
                We use your information to deliver our services, like processing
                the terms you search for in order to return results or helping you
                share content by suggesting recipients from your contacts.
              </p>
              <SubTitle>
                Maintain & improve our services
              </SubTitle>
              <p>
                We also use your information to ensure our services are working as
                intended, such as tracking outages or troubleshooting issues that
                you report to us. And we use your information to make improvement
                to our services.
              </p>
              <SubTitle>
                Develop new services
              </SubTitle>
              <p>
                We use the information we collect in existing services to help us develop new ones.
              </p>
              <SubTitle>
                Provide personalized services, including content and ads
              </SubTitle>
              <p>
                We use the information we collect to customize our services for you, including
                providing recommendations, personalized content, and customized search results.
                <br />
                {' '}
                <br />
                Depending on your settings, we may also show you personalized ads based on
                your interests. You can control what information we use to show you ads by
                visiting your ad settings.
              </p>
              <ul>
                <li>
                  We don’t show you personalized ads based on sensitive categories, such as race,
                  religion, sexual orientation, or health.
                </li>
                <li>
                  We don’t share information that personally identifies you with advertisers, such
                  as your name or email unless you ask us to.
                </li>
              </ul>
              <SubTitle>
                Measure performance
              </SubTitle>
              <p>
                We use data for analytics and measurement to understand how our services are used.
              </p>
              <Answer>
                Communicate with you
              </Answer>
              <p>
                We use the information we collect, like your email address, to interact with you
                directly.
                {' '}
                <br />
                {' '}
                <br />
                Protect Dipper, our Users and the Public
                {' '}
                <br />
                {' '}
                <br />
                We use the information to help improve the safety and reliability of our services.
                This includes detecting, preventing, and responding to fraud, abuse, security risks,
                and technical issues that could harm Dipper, our users, or the public.
                {' '}
                <br />
                We use different technologies to process your information for these purposes.
                We use automated systems that analyze your content to provide you with things
                like customized search results, personalized ads, or other features tailored
                to how you use our services. And we analyze your content to help us detect abuse
                such as spam, malware, and illegal content. We also use algorithms to recognize
                patterns in data.
                {' '}
                <br />
                We may combine the information we collect among our services and across your
                devices for the purposes described above. Depending on your account settings,
                your activity on other sites and apps may be associated with your personal
                information in order to improve Dipper’s services and the ads delivered
                by Dipper.
                {' '}
                <br />
                If other users already have your email address or other information that
                identifies you, we may show them your publicly visible Dipper Account
                information, such as your name and photo. This helps people identify
                an email coming from you, for example.
                <br />
                We’ll ask for your consent before using your information for a
                purpose that isn’t covered in this Privacy Policy.
              </p>
              <SubTitle>
                YOUR PRIVACY CONTROLS
              </SubTitle>
              <p>
                You have choices regarding the information we collect and how it’s used.
                {' '}
                <br />
                {' '}
                <br />
                This section describes key controls for managing your privacy across
                our services.
              </p>
            </div>
            <div>
              <Title>
                Managing, reviewing, and updating your information
              </Title>
              <p>
                When you’re signed in, you can always review and update information
                by visiting the services you use. For example, your profile is
                designed to help you manage specific types of content you’ve saved
                with Dipper.
                <br />
                {' '}
                <br />
                We also built a place for you to review and control information saved
                in your Dipper Account. Your Dipper Account includes:
              </p>
              <SubTitle>
                Privacy controls
              </SubTitle>
              <SubTitle>
                Activity Controls
              </SubTitle>
              <p>
                Describe what types of activity you’d like to save in your account.
              </p>
              <SubTitle>
                Ad Settings
              </SubTitle>
              <p>
                Manage your preferences about the ads shown to you on Dipper and
                on sites and apps that partner with Dipper to show ads. You can
                modify your interests, choose whether your personal information
                is used to make ads more relevant to you, and turn on or off
                certain advertising services.
              </p>
              <SubTitle>
                About you
              </SubTitle>
              <p>
                Control what others see about you across Dipper services.
                {' '}
                <br />
                Shared endorsements
                {' '}
                <br />
                Choose whether your name and photo appear next to your activity,
                like reviews, post, and comments.
              </p>
              <SubTitle>
                Ways to review & update your information
              </SubTitle>
              <SubTitle>
                My Profile
              </SubTitle>
              <p>
                My profile allows you to review and control data that are created
                when you use Dipper services. You can browse by data and by topic
                and delete part or all of your activity.
              </p>
              <SubTitle>
                Your personal information
              </SubTitle>
              <p>
                Manage contact information, such as your name, email, and phone
                number only when you are signed in.
              </p>
              <SubTitle>
                Exporting, removing & deleting your information
              </SubTitle>
              <p>
                You may be able to export a copy of the content in your Dipper
                Account if you want to back it up or use it with a service outside
                of Dipper.
              </p>
              <p>
                You may also request to remove content from specific Dipper
                services based on applicable law.
                <br />
                {' '}
                <br />
                To delete your information, you can:
              </p>
              <ul>
                <li>
                  Delete your content from specific Dipper services
                </li>
                <li>
                  Search for and then delete specific items from your account
                </li>
                <li>
                  Delete specific Dipper products, including your information
                  associated with those products
                </li>
                <li>
                  Delete your entire Dipper Account
                </li>
              </ul>
              <p>
                There are other ways to control the information Dipper collects
                whether or not you’re signed into a Dipper Account, including:
              </p>
              <ul>
                <li>
                  Browser settings: For example, you can configure your browser to
                  indicate when Dipper has set a cookie in your browser. You can also
                  configure your browser to block all cookies from a specific domain
                  or all domains. But remember that our services rely on cookies to function
                  properly, for things like remembering your language preferences.
                </li>
                <li>
                  Device-level settings: Your device may have controls that determine
                  what information we collect.
                </li>
              </ul>
            </div>
            <div>
              <Title>
                SHARING YOUR INFORMATION
              </Title>
              <SubTitle>
                When you share your information
              </SubTitle>
              <p>
                Many of our services let you share information with other people, and
                you have control over how you share. When you’re signed in and interact
                with some Dipper services your name and photo appear next to your activity.
              </p>
              <SubTitle>
                When Dipper shares your information
              </SubTitle>
              <p>
                We do not share your personal information with companies,
                organizations, or individuals outside of Dipper except in the
                following cases:
              </p>
              <SubTitle>
                With your consent
              </SubTitle>
              <p>
                We’ll share personal information outside of Dipper when we have your
                consent. We’ll ask for your explicit consent to share any sensitive
                personal information.
              </p>
              <SubTitle>
                For external processing
              </SubTitle>
              <p>
                We provide personal information to our affiliates and other trusted
                businesses or persons to process it for us, based on our instructions
                and in compliance with our Privacy Policy and any other appropriate
                confidentiality and security measures. For example, we use service
                providers to help us with customer support.
              </p>
              <SubTitle>
                For legal reasons
              </SubTitle>
              <p>
                We will share personal information outside of Dipper if we have a
                good-faith belief that access, use, preservation, or disclosure of
                the information is reasonably necessary to:
              </p>
              <ul>
                <li>
                  We share information about the number and the type of requests we receive
                  from governments.
                </li>
                <li>
                  Enforce applicable Terms of Service, including investigation of
                  potential violations. Detect, prevent, or otherwise address fraud,
                  security, or technical issues.
                </li>
                <li>
                  Protect against harm to the rights, property, or safety of Dipper,
                  our users, or the public as required or permitted by law.
                </li>
              </ul>
              <p>
                We may share non-personally identifiable information publicly and
                with our partners — like publishers, advertisers, developers, or rights
                holders. For example, we share information publicly to show trends
                about the general use of our services. We also allow specific partners
                to collect information from your browser or device for advertising
                and measurement purposes using their own cookies or similar technologies.
              </p>
              <p>
                If Dipper is involved in a merger, acquisition, or sale of assets,
                we’ll continue to ensure the confidentiality of your personal information
                and give affected users notice before personal information is transferred
                or becomes subject to a different privacy policy.
              </p>
              <Answer>
                KEEPING YOUR INFORMATION SECURE
              </Answer>
              <SubTitle>
                We build security into our services to protect your information
              </SubTitle>
              <p>
                All Dipper products are built with strong security features that
                continuously protect your information. The insights we gain from
                maintaining our services help us detect and automatically block
                security threats from ever reaching you. And if we do detect something
                risky that we think you should know about; we’ll notify you and help
                guide you through steps to stay better protected.
                {' '}
                <br />
                We work hard to protect you and Dipper from unauthorized access,
                alteration, disclosure, or destruction of information we hold, including:
              </p>
              <ul>
                <li>
                  We use encryption to keep your data private while in transit
                </li>
                <li>
                  We offer a range of security features to protect your account
                </li>
                <li>
                  We review our information collection, storage, and processing practices,
                  including physical security measures, to prevent unauthorized access
                  to our systems
                </li>
                <li>
                  We restrict access to personal information to Dipper employees,
                  contractors, and agents who need that information in order to process it.
                  Anyone with this access is subject to strict contractual confidentiality
                  obligations and may be disciplined or terminated if they fail to meet
                  these obligations.
                </li>
              </ul>
            </div>
            <div>
              <Title>
                RETAINING YOUR INFORMATION
              </Title>
              <p>
                We retain the data we collect for different periods of time depending
                on what it is, how we use it, and how you configure your settings:
              </p>
              <ul>
                <li>
                  Some data you can delete whenever you like, such as the content you
                  create or upload. You can also delete activity information saved in
                  your account or choose to have it deleted automatically after a
                  set period of time.
                </li>
                <li>
                  Other data is deleted or held automatically after a set period
                  of time, such as advertising data in server logs.
                </li>
                <li>
                  We keep some data until you delete your Dipper Account, such as
                  information about how often you use our services.
                </li>
                <li>
                  And some data we retain for longer periods of time when necessary
                  for legitimate business or legal purposes, such as security, fraud
                  and abuse prevention, or financial record-keeping.
                </li>
              </ul>
              <p>
                When you delete data, we follow a deletion process to make sure that
                your data is safely and completely removed from our servers or
                retained only in anonymized form. We try to ensure that our services
                protect information from accidental or malicious deletion.
                Because of this, there may be delays between when you delete
                something and when copies are deleted from our active and
                backup systems.
              </p>
              <Answer>
                COMPLIANCE & COOPERATION WITH REGULATORS
              </Answer>
              <p>
                We regularly review this Privacy Policy and make sure that we process
                your information in ways that comply with it.
              </p>
              <SubTitle>
                Data transfers
              </SubTitle>
              <p>
                We may maintain servers around the world and your information may be
                processed on servers located outside of the country where you live. Data
                protection laws vary among countries, with some providing more
                protection than others. Regardless of where your information is processed, we
                apply the same protections described in this policy. We also comply
                with certain legal frameworks relating to the transfer of data.
                <br />
                When we receive formal written complaints, we respond by contacting the
                person who made the complaint. We work with the appropriate regulatory
                authorities, including local data protection authorities, to resolve
                any complaints regarding the transfer of your data that we cannot resolve
                with you directly.
              </p>
            </div>
            <div>
              <Title>
                California requirements
              </Title>
              <p>
                The California Consumer Privacy Act (CCPA) requires specific disclosures
                for California residents.
                {' '}
                <br />
                This Privacy Policy is designed to help you understand how Dipper
                handles your information:
              </p>
              <ul>
                <li>
                  We explain the categories of information Dipper collects and
                  the sources of that information.
                </li>
                <li>
                  We explain how Dipper uses information
                </li>
                <li>
                  We explain when Dipper may share information. Dipper does not
                  sell your personal information.
                </li>
                <li>
                  The CCPA also provides the right to request information about how Dipper
                  collects, uses, and discloses your personal information. And
                  it gives you the right to access your information
                  and request that Dipper delete that information. Finally,
                  the CCPA provides the right to not be discriminated against for
                  exercising your privacy rights.
                </li>
              </ul>
              <p>
                We describe the choices you have to manage your privacy and data.
                You can exercise your right by using these controls, which allow you
                to access, review, update and delete your information, as well as
                export and download a copy of it. When you use them, we’ll validate
                your request by verifying that you’re signed into your Dipper Account.
                If you have questions or requests related to your rights under the
                CCPA, you (or your authorized agent) can also contact us.
                <br />
                {' '}
                <br />
                The CCPA requires a description of data practices using specific
                categories. This table uses these
                categories to organize the information in this Privacy Policy.
              </p>
              <SubTitle>
                Categories of personal information we collect
              </SubTitle>
              <p>
                Identifiers such as your name, phone number and address, as well
                as unique identifiers tied to the browser, application, or device
                you’re using. Demographic information, such as your age,
                gender and language.
                <br />
                {' '}
                <br />
                Commercial information such as your payment information and a history of
                purchases of you make.
                {' '}
                <br />
                Internet, network, and other activity information such as your
                search terms; views and interactions with content and ads;
                information about the interaction of your apps, browsers,
                and devices with ours Services (like IP address, crash reports,
                and system activity); and activity
                on third-party sites and apps that use our services.
                {' '}
                <br />
                {' '}
                <br />
                Geo location data, such as may be determined by GPS, IP address,
                and other data from sensors on or around your device, depending in
                part on your device and account settings.
              </p>
              <Answer>
                Audio, electronic, visual, and similar information.
              </Answer>
              <p>
                Professional, employment, and education information.
                {' '}
                <br />
                {' '}
                <br />
                Other information you create or provide, such as the content
                you create, upload, or receive (like reviews and posts)
                <br />
                {' '}
                <br />
                Inferences drawn from the above.
              </p>
            </div>
            <div>
              <Title>
                Business purposes for which information may be used or disclosed
              </Title>
              <p>
                Protecting against security threats, abuse, and illegal activity:
                Dipper uses and may disclose information
                to detect, prevent and respond to security incidents, and for protecting
                against other malicious, deceptive,
                fraudulent, or illegal activity.
                {' '}
                <br />
                {' '}
                <br />
                Auditing and measurement: Dipper uses information for analytics
                and measurement to understand how our services are used, as well as
                to fulfill obligations to our partners like publishers, advertisers,
                developers, or rights holders. We may disclose non-personally identifiable
                information publicly and
                with these partners, including for auditing purposes.
                <br />
                {' '}
                <br />
                Maintaining our services: Dipper uses information to ensure our
                services are working as intended, such as tracking outages or
                troubleshooting bugs and o her issues that you report to us.
                <br />
                {' '}
                <br />
                Research and development: Dipper uses information to improve
                our services and to develop new products, features,
                and technologies that benefit our users and the public.
                <br />
                {' '}
                <br />
                Use of service providers: Dipper shares information with
                service providers to perform services on our behalf,
                in compliance with our Privacy Policy and other appropriate
                confidentiality and security measures.
                For example, we may rely on service providers to help provide customer support.
              </p>
              <BoldText>
                Advertising:
              </BoldText>
              Dipper processes information, including on-line identifiers and
              information about your interactions with advertisements, to provide
              advertising.
              <br />
              {' '}
              <br />
              <p>
                Legal reasons: Dipper also uses information to satisfy applicable
                laws or regulations, and
                discloses information in response to legal process and enforceable
                government requests, including
                to law enforcement.
                {' '}
                <br />
                {' '}
                <br />
                Parties with who information may be shared
                {' '}
                <br />
                {' '}
                <br />
                Other people whom you choose to share your information with,
                like reviews, profiles or comments.
                <br />
                {' '}
                <br />
                Third parties to whom you consent to sharing your information,
                such as services that
                integrate with Dipper’s services.
                {' '}
                <br />
                {' '}
                <br />
                Service providers, trusted businesses or persons that
                process information on Dipper’s behalf,
                based on our instructions and in compliance with our
                Privacy Policy and any other appropriate
                confidentiality and security measures.
                {' '}
                <br />
                {' '}
                <br />
                Law enforcement or other third parties, for legal reasons.
              </p>
            </div>
            <div>
              <Title>
                ABOUT THIS POLICY
              </Title>
              <SubTitle>
                When this policy applies
              </SubTitle>
              <p>
                This Privacy Policy applies to all of the services offered by
                Our Dipper Inc. and its affiliates.
                This Privacy Policy doesn’t apply to services that have
                separate privacy policies that do not
                incorporate this Privacy Policy.
                {' '}
                <br />
                {' '}
                <br />
                This Privacy Policy doesn’t apply to:
              </p>
              <ul>
                <li>
                  The information practices of other companies and
                  organizations that advertise our services
                </li>
                <li>
                  Services offered by other companies or individuals,
                  including products or sites that may
                  include Dipper services, be displayed to you in search results,
                  or be linked from our services
                </li>
              </ul>
            </div>
            <Title>
              Changes to this policy
            </Title>
            <p>
              We change this Privacy Policy from time to time. We will not
              reduce your rights under this Privacy
              Policy without your explicit consent. We always indicate
              the date the last changes were published.
              If changes are significant, we’ll provide a more prominent
              notice (including, for certain services,
              email notification of Privacy Policy changes).
            </p>
          </div>
        </div>
      </PrivacyPolicy>
      <TermsAndConditons id="terms-and-conditions">
        <Title biggerAndColor> Dipper User Agreement Terms </Title>
        <div>
          <Title> 1. Introduction </Title>
          <SubTitle>1.1 Contract</SubTitle>
          <p>
            You agree that by clicking “Join Now”, “Join Dipper”, “Sign Up” or similar,
            registering, accessing or using our services (described below), you are
            agreeing to enter into a legally binding contract with Dipper
            (even if you are using our Services on behalf of a company). If you do
            not agree to this contract (“Contract” or “User Agreement”), do not click
            “Join Now” (or similar) and do not access or otherwise use any of our
            Services. If you wish to terminate this contract, at any time you can do
            so by closing your account and no longer accessing or using our Services.
          </p>
          <SubTitle>Services</SubTitle>
          <p>
            This Contract applies to Ourdipper.com, Dipper branded apps, and other
            Dipper related sites, apps, communications and other services that state
            that they are offered under this Contract (“Services”), including the
            offsite collection of data for those Services, such as our ads and the
            “Apply with Dipper” and “Share with Dipper” plugins. Registered users
            of our Services are “Members”.
          </p>
          <SubTitle>1.2 Members</SubTitle>
          <p>
            When you register and join the Dipper Services, you become
            a Member.
          </p>
          <SubTitle>1.3 Change</SubTitle>
          <p>
            We may modify this Contract, our Privacy Policy and our Cookies Policy from
            time to time. If we make material changes to it, we will provide you notice
            through our Services, or by other means, to provide you the opportunity to
            review the changes before they become effective. We agree that changes cannot
            be retroactive. If you object to any changes, you may close your account.
            Your continued use of our Services after we publish or send a
            notice about our changes to these terms means that you are consenting to
            the updated terms as of their effective date.
          </p>
        </div>
        <div>
          <Title>2. Obligations</Title>
          <SubTitle>2.1 Service Eligibility </SubTitle>
          <p>
            Here are some promises that you make to us in this Contract:
            {' '}
            <br />
            {' '}
            <br />
            The Services are not for use by anyone under the age of 16. To use the
            Services, you agree that: (1) you must be the “Minimum Age” (described below)
            or older; (2) you will only have one Dipper account, which must be in your real
            name; and (3) you are not already restricted by Dipper from using the Services.
            Creating an account with false information is a violation of our terms,
            including accounts registered on behalf of others or persons under the age of 16.
            <br />
            “Minimum Age” means 16 years old. However, if law requires that you must be
            older in order for Dipper to lawfully provide the Services to you without
            parental consent (including using of your personal data) then the Minimum
            Age is such older age.
          </p>
          <SubTitle>2.2 Your Account</SubTitle>
          <p>
            You will keep your password a secret.
            {' '}
            <br />
            {' '}
            <br />
            You will not share an account with anyone else and will follow our rules and the law.
            Members are account holders. You agree to: (1) use a strong password and keep
            it confidential; (2) not transfer any part of your account (e.g., connections)
            and (3) follow the law and our list of Dos and Don’ts and Community Guidelines.
            You are responsible for anything that happens through your account unless
            you close it or report misuse.
            <br />
            {' '}
            <br />
            As between you and others (including your employer), your account belongs to you.
            However, if the Services were purchased by another party for you to use, the party
            paying for such Service has the right to control access to and get reports on your
            use of such paid Service; however, they do not have rights to your personal account.
          </p>
          <SubTitle>2.3 Payment</SubTitle>
          <p>
            You’ll honor your payment obligations and you are okay with us storing your payment
            information. You understand that there may be fees and taxes that are added to our
            prices.
            {' '}
            <br />
            {' '}
            <br />
            Refunds are subject to our policy.
            {' '}
            <br />
            If you buy any of our paid Services (“Premium Subscriptions”), you agree to pay
            us the applicable fees and taxes and to additional terms specific to the
            paid Services. Failure to pay these fees will result in the termination of
            your paid Services. Also, you agree that:
          </p>
          <ul>
            <li>
              Your purchase may be subject to foreign exchange fees or differences in
              prices based on location (e.g. exchange rates).
            </li>
            <li>
              We may store and continue billing your payment method (e.g. credit card)
              even after it has expired, to avoid interruptions in your Services and
              to use to pay other Services you may buy.
            </li>
            <li>
              If you purchase a subscription, your payment method automatically will be
              charged at the start of each subscription period for the fees and taxes applicable
              to that period. To avoid future charges, cancel before the renewal date. Learn how
              to cancel or suspend your Premium Subscription.
            </li>
            <li>
              All of your purchases of Services are subject to Dipper’s refund policy
              when established.
            </li>
            <li>
              We may calculate taxes payable by you based on the billing information
              that you provide us at the time of purchase.
            </li>
          </ul>
          <SubTitle>
            2.4 Notices and Messages
          </SubTitle>
          <p>
            You agree that we will provide notices and messages to you in the following ways:
            (1) within the Service, or (2) sent to the contact information you provided us
            (e.g., email, mobile number, physical address). You agree to keep your contact
            information up to date.
            {' '}
            <br />
            Please review your settings to control and limit the messages you receive from us.
          </p>
          <SubTitle>
            2.5 Sharing
          </SubTitle>
          <p>
            When you share information on our Services, others can see, copy and use that
            information.
            <br />
            Our Services allow messaging and sharing of information in many ways, such as
            your profile, reviews, feed posts, links to news articles, and job postings.
            Information and content that you share or post may be seen by other Members.
            Where we have made settings available, we will honor the choices you make
            about who can see content or information.
            {' '}
            <br />
            We are not obligated to publish any information or content on our Service
            and can remove it with or without notice.
          </p>
        </div>
        <div>
          <Title>
            3. Rights and Limits
          </Title>
          <SubTitle>
            3.1. Your License to Dipper
          </SubTitle>
          <p>
            You own all of the content, feedback and personal information you provide to us,
            but you also grant us a non-exclusive license to it.
            {' '}
            <br />
            <br />
            We’ll honor the choices you make about who gets to see your information and
            content, including how it can be used for ads.
            {' '}
            <br />
            <br />
            As between you and Dipper, you own the content and information that you submit
            or post to the Services, and you are only granting Dipper and our affiliates
            the following non-exclusive license:
            {' '}
            <br />
            <br />
            A worldwide, transferable and sublicensable right to use, copy, modify,
            distribute, publish and process, information and content that you provide
            through our Services and the services of others, without any further consent,
            notice and/or compensation to you or others. These rights are limited in the
            following ways:
          </p>
          <ol>
            <li>
              You can end this license for specific content by deleting such content from the
              Services, or generally by closing your account, except (a) to the extent you
              shared it with others as part of the Service and they copied, re-shared it or
              stored it and (b) for the reasonable time it takes to remove from backup and
              other systems.
            </li>
            <li>
              We will not include your content in advertisements for the products and services
              of third parties to others without your separate consent
              (including sponsored content). However, we have the right, without payment
              to you or others, to serve ads near your content and information, and
              your social actions may be visible and included with ads, as noted
              in the Privacy Policy. If you use a Service feature, we may mention
              that with your name or photo to promote that feature within our Services,
              subject to your settings.
            </li>
            <li>
              We will get your consent if we want to give others the right to publish your
              content beyond the Services. However, if you choose to share your post as
              &quot;public&quot;,
              we will enable a feature that allows other Members to embed that public post into
              third-party services, and we enable search engines to make that public content
              findable though their services. Learn More
            </li>
            <li>
              While we may edit and make format changes to your content (such as translating or
              transcribing it, modifying the size, layout or file type or removing metadata),
              we will not modify the meaning of your expression.
            </li>
            <li>
              Because you own your content and information and we only have non-exclusive
              rights to it, you may choose to make it available to others.
            </li>
          </ol>
          <p>
            You and Dipper agree that we may access, store, process and use any information
            and personal data that you provide in accordance with, the terms of
            the Privacy Policy and your choices (including settings).
            {' '}
            <br />
            {' '}
            <br />
            By submitting suggestions or other feedback regarding our Services
            to Dipper, you agree that Dipper can use and share (but does not have to) such
            feedback for any purpose without compensation to you.
            {' '}
            <br />
            {' '}
            <br />
            You agree to only provide content or information that does not violate
            the law nor anyone’s rights (including intellectual property rights).
            You also agree that your profile information will be truthful. Dipper
            may be required by law to remove certain information or content in certain
            countries.
          </p>
          <SubTitle>3.2 Service Availability</SubTitle>
          <p>
            We may change or end any Service or modify our prices prospectively.
            We may change, suspend or discontinue any of our Services. We may
            also modify our prices effective prospectively upon reasonable
            notice to the extent allowed under the law.
            {' '}
            <br />
            {' '}
            <br />
            We don’t promise to store or keep showing any information and content
            that you’ve posted. Dipper is not a storage service. You agree that
            we have no obligation to store, maintain or provide you a copy of
            any content or information that you or others provide, except to
            the extent required by applicable law and as noted in our Privacy Policy.
          </p>
          <SubTitle>3.3 Other Content, Sites and Apps</SubTitle>
          <p>
            By using the Services, you may encounter content or information that might be
            inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise
            harmful. Dipper generally does it’s best to review content provided by our
            Members or others. You agree that we are not responsible for others’
            (including other Members’) content or information. We cannot always prevent
            this misuse of our Services, and you agree that we are not responsible for
            any such misuse. You also acknowledge the risk that you or your organization
            may be mistakenly associated with content about others when we let connections
            and followers know you or your organization were mentioned in the news.
            {' '}
            <br />
            {' '}
            <br />
            Dipper may help connect Members offering their services (career coaching,
            accounting, etc.) with Members seeking services. You must be at least 18
            years of age to offer, perform or procure these services. You acknowledge
            that Dipper does not supervise, direct, control, or monitor Members in the
            performance of these services and agree that (1) Dipper is not responsible
            for the offering, performance or procurement of these services, (2) Dipper
            does not endorse any particular Member’s offered services, and (3) nothing
            shall create an employment, agency, or joint venture relationship between
            Dipper and any Member offering services. If you are a Member offering
            services, you represent and warrant that you have all the required licenses
            and will provide services consistent with our Professional Community Policies.
            <br />
            {' '}
            <br />
            Similarly, Dipper may help you register for and/or attend events organized
            by Members and connect with other Members who are attendees at such events.
            You agree that (1) Dipper is not responsible for the conduct of any of the
            Members or other attendees at such events, (2) Dipper does not endorse any
            particular event listed on our Services, (3) Dipper does not review and/or
            vet any of these events, and (4) that you will adhere to these terms and
            conditions that apply to such events.
          </p>
          <SubTitle>3.4 Limits</SubTitle>
          <p>
            Dipper reserves the right to limit your use of the Services, including
            the number of your connections and your ability to contact other Members.
            Dipper reserves the right to restrict, suspend, or terminate your account
            if you breach this Contract or the law or are misusing the Services
            (e.g., violating any of the Dos and Don’ts or Community Guidelines).
          </p>
          <SubTitle>3.5 Intellectual Property Rights</SubTitle>
          <p>
            Dipper reserves all of its intellectual property rights in the Services.
            Trademarks and logos used in connection with the Services are the trademarks
            of their respective owners. Dipper, and “in” logos and other
            Dipper trademarks, service marks, graphics and logos used for our
            Services are trademarks or registered trademarks of Dipper.
          </p>
          <SubTitle>3.6 Automated Processing</SubTitle>
          <p>
            We use the information and data that you provide and that we have about
            Members to make recommendations for connections, content, and features
            that may be useful to you. For example, we use data and information about
            you to recommend jobs to you and you to recruiters. Keeping your profile
            accurate and up to date helps us to make these recommendations more
            accurate and relevant.
          </p>
        </div>
        <div>
          <Title>4. Disclaimer and Limit of Liability</Title>
          <SubTitle>4.1 No Warranty</SubTitle>
          <p>
            DIPPER AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY
            ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES
            WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES
            (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE”
            BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, DIPPER
            AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING
            ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT,
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
          </p>
          <SubTitle>4.2 Exclusion of Liability</SubTitle>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS DIPPER HAS ENTERED INTO
            A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), DIPPER,
            INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS
            CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION
            (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN
            TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR
            ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.
            <br />
            {' '}
            <br />
            DIPPER AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION
            WITH THIS CONTRACT FOR ANY AMOUNT THAT EXCEEDS (A) THE TOTAL FEES
            PAID OR PAYABLE BY YOU TO Dipper FOR THE SERVICES DURING THE TERM
            OF THIS CONTRACT, IF ANY.
          </p>
          <SubTitle>4.3 Basis of the Bargain; Exclusions</SubTitle>
          <p>
            The limitations of liability in this Section 4 are part of the basis
            of the bargain between you and Dipper and shall apply to all claims of
            liability (e.g., warranty, tort, negligence, contract and law) even if
            Dipper or its affiliates has been told of the possibility of any such
            damage, and even if these remedies fail their essential purpose.
            <br />
            {' '}
            <br />
            These limitations of liability do not apply to liability for death
            or personal injury or for fraud, gross negligence or intentional
            misconduct, or in cases of negligence where a material obligation
            has been breached, a material obligation being such which forms
            a prerequisite to our delivery of services and on which you may
            reasonably rely, but only to the extent that the damages were
            directly caused by the breach and were foreseeable upon conclusion
            of this Contract and to the extent that they are typical in the
            context of this Contract.
          </p>
        </div>
        <div>
          <Title>5. Termination</Title>
          <p>
            We can each end this Contract, but some rights and obligations survive.
            Both you and Dippermay terminate this Contract at any time with notice
            to the other. On termination, you lose the right to access or use
            the Services. The following shall survive termination:
          </p>
          <ul>
            <li>
              Our rights to use and disclose your feedback;
            </li>
            <li>
              Members and/or Visitors’ rights to further re-share content and
              information you shared through the Services;
            </li>
            <li>
              Sections 4, 6, 7, and 8.2 of this Contract;
            </li>
            <li>
              Any amounts owed by either party prior to termination remain
              owed after termination.
            </li>
          </ul>
          <p>
            You can visit our Help Center to close your account.
          </p>
        </div>
        <div>
          <Title>6. Governing Law and Dispute Resolution</Title>
          <p>
            This section shall not deprive you of any mandatory consumer protections
            under the law of the country to which we direct Services to you, where
            you have your habitual residence.
            {' '}
            <br />
            {' '}
            <br />
            You and Dipper agree that the laws of Florida, excluding conflict of laws
            rules, shall exclusively govern any dispute relating to this Contract
            and/or the Services. You and Dipper Florida agree that claims and
            disputes can be litigated only in Florida, and we each agree to
            personal jurisdiction of the courts located in Florida.
            {' '}
            <br />
            {' '}
            <br />
            For others outside of Designated Countries, including those who live outside
            of the United States: You and Dipper agree that the laws of the State
            of Florida, U.S.A., excluding its conflict of laws rules, shall
            exclusively govern any dispute relating to this Contract and/or
            the Services. You and Dipper both agree that all claims and disputes
            can be litigated only in the federal or state courts in Orange County,
            Florida, USA, and you and Dipper each agree to personal jurisdiction
            in those courts.
          </p>
        </div>
        <div>
          <Title>7. General Terms</Title>
          <p>
            If a court with authority over this Contract finds any part of it
            unenforceable, you and we agree that the court should modify the
            terms to make that part enforceable while still achieving its intent.
            If the court cannot do that, you and we agree to ask the court to remove
            that unenforceable part and still enforce the rest of this Contract.
            <br />
            {' '}
            <br />
            This Contract (including additional terms that may be provided by us
            when you engage with a feature of the Services) is the only agreement
            between us regarding the Services and supersedes all prior agreements
            for the Services.
            <br />
            {' '}
            <br />
            If we don&apos;t act to enforce a breach of this Contract, that does not mean
            that Dipper has waived its right to enforce this Contract. You may not
            assign or transfer this Contract (or your membership or use of Services)
            to anyone without our consent. However, you agree that Dipper may assign
            this Contract to its affiliates or a party that buys it without your
            consent. There are no third-party beneficiaries to this Contract.
            <br />
            You agree that the only way to provide us legal notice is at the
            addresses provided in Section 10.
          </p>
        </div>
        <div>
          <Title>
            8. Dipper “Dos and Don’ts”
          </Title>
          <SubTitle>
            8.1. Dos
          </SubTitle>
          <p>
            Dipper is a community of professionals. This list of “Dos and Don’ts” along with
            our Professional Community Policies limit what you can and cannot do
            on our Services. You agree that you will:
          </p>
          <ol>
            <li>
              Comply with all applicable laws, including, without limitation, privacy laws,
              intellectual property laws, anti-spam laws, export control laws, tax laws,
              and regulatory requirements;
            </li>
            <li>
              Provide accurate information to us and keep it updated;
            </li>
            <li>
              Use your real name on your profile;
            </li>
            <li>
              Use the Services in a professional manner.
            </li>
          </ol>
          <SubTitle>
            8.2. Don’ts
          </SubTitle>
          <p>
            You agree that you will not:
          </p>
          <ol>
            <li>
              Create a false identity on Dipper,
              misrepresent your identity, create a Member profile for anyone other than
              yourself (a real person), or use or attempt to use another’s account;
            </li>
            <li>
              Develop, support or use software, devices, scripts, robots or any
              other means or processes (including crawlers, browser plugins and
              add-ons or any other technology) to scrape the Services or otherwise
              copy profiles and other data from the Services;
            </li>
            <li>
              Override any security feature or bypass or circumvent any access
              controls or use limits of the Service (such as caps on keyword
              searches or profile views);
            </li>
            <li>
              Copy, use, disclose or distribute any information obtained from
              the Services, whether directly or through third parties (such as
              search engines), without the consent of Dipper;
            </li>
            <li>
              Disclose information that you do not have the consent to disclose
              (such as confidential information of others (including your employer));
            </li>
            <li>
              Violate the intellectual property rights of others, including copyrights,
              patents, trademarks, trade secrets or other proprietary rights. For
              example, do not copy or distribute (except through the available
              sharing functionality) the posts or other content of others without
              their permission, which they may give by posting under a Creative
              Commons license;
            </li>
            <li>
              Violate the intellectual property or other rights of Dipper, including,
              without limitation, (i) copying or distributing our learning videos or
              other materials or (ii) copying or distributing our technology, unless
              it is released under open source licenses; (iii) using the word “Dipper”
              or our logos in any business name, email, or URL except as provided in
              the Brand Guidelines;
            </li>
            <li>
              Post anything that contains software viruses, worms, or any other harmful code;
            </li>
            <li>
              Reverse engineer, decompile, disassemble, decipher or otherwise attempt to
              derive the source code for the Services or any related technology that is
              not open source;
            </li>
            <li>
              Imply or state that you are affiliated with or endorsed by Dipper without
              our express consent (e.g., representing yourself as an accredited Dipper
              trainer);
            </li>
            <li>
              Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services
              or related data or access to the same, without Dipper’s consent;
            </li>
            <li>
              Deep-link to our Services for any purpose other than to promote your
              profile or a Group on our Services, without Dipper’s consent;
            </li>
            <li>
              Use bots or other automated methods to access the Services,
              add or download contacts, send or redirect messages;
            </li>
            <li>
              Monitor the Services’ availability, performance or functionality
              for any competitive purpose;
            </li>
            <li>
              Engage in “framing,” “mirroring,” or otherwise simulating the appearance
              or function of the Services;
            </li>
            <li>
              Overlay or otherwise modify the Services or their appearance
              (such as by inserting elements into the Services or removing,
              covering, or obscuring an advertisement included on the Services);
            </li>
            <li>
              Interfere with the operation of, or place an unreasonable load on,
              the Services (e.g., spam, denial of service attack, viruses, gaming
              algorithms); and/or
            </li>
            <li>
              Violate the Community Guidelines or any additional terms concerning
              a specific Service that are provided when you sign up for or start
              using such Service.
            </li>
          </ol>
        </div>
        <div>
          <Title>
            9. Complaints Regarding Content
          </Title>
          <p>
            We respect the intellectual property rights of others. We require
            that information posted by Members be accurate and not in violation
            of the intellectual property rights or other rights of third parties.
            Any violations should be reported to
            <a href="mailto:hello@ourdipper.com"> hello@ourdipper.com</a>
          </p>
        </div>
        <div>
          <Title>
            10. How To Contact Us
          </Title>
          <p>
            For general inquiries or legal notices, you may contact us online
            <a href="mailto:hello@ourdipper.com"> hello@ourdipper.com</a>
          </p>
        </div>
      </TermsAndConditons>
    </Container>
  );
};

LegalInformation.propTypes = {
  isPortraitMenuOpened: PropTypes.bool.isRequired,
  onClickOutsideMenu: PropTypes.func.isRequired,
};

export default LegalInformation;
