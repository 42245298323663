import {
  get, post, put, remove,
} from 'services/api';

import { QUANTITY_JOB } from 'constants/configs';
import { getLocation } from 'helpers/string';

export async function deleteJob(id) {
  const response = await remove(`jobs/deleteJob/${id}`);
  return response;
}

export async function getAllPoc(page, quantity, jobPositionFilter, locationFilter) {
  let endPointUrl = `jobs/getAll?page=${page}&quantity=${quantity}`;
  if (jobPositionFilter) {
    endPointUrl += `&jobPosition=${jobPositionFilter}`;
  }
  if (locationFilter) {
    endPointUrl += `&location=${locationFilter}`;
  }
  const response = await get(endPointUrl);
  return response;
}

export async function getAllForDashboard() {
  const response = await get(`jobs/getAllDashboard?quantity=${QUANTITY_JOB}`);
  return response;
}

export async function getAllFromCompany(page, quantity, statusInactive) {
  const response = await get(`jobs/getAllFromCompany?page=${page}&quantity=${quantity}&status=${statusInactive ? 'inactive' : 'active'}`);
  return response;
}

export async function addNewJobOffer(formData) {
  const newFormData = { ...formData };
  newFormData.isActive = !formData.isActive || formData.isActive === '1';
  newFormData.location = getLocation(formData.city, formData.state, formData.country);
  if (formData.noSalary) {
    newFormData.fromSalary = '';
    newFormData.toSalary = '';
  }
  const response = await post('jobs/add', newFormData);
  return response;
}

export async function updateJobOffer(formData) {
  const newFormData = { ...formData };
  newFormData.isActive = !formData.isActive || formData.isActive === '1';
  newFormData.location = getLocation(formData.city, formData.state, formData.country);
  if (formData.noSalary) {
    newFormData.fromSalary = '';
    newFormData.toSalary = '';
  }
  const response = await put('jobs/update', newFormData);
  return response;
}
