export const JOB_TITLE = 'Jobs';

export const JOB_DESCRIPTION = `A better workplace is possible! New oportunities will 
  be posted here frequently, so keep coming back!`;

export const JOB_COMING_SOON = 'Coming soon!';

export const JOB_NOT_FOUND_TITLE = 'No matching jobs found.';

export const JOB_NOT_FOUND_DESCRIPTION = 'Please try another search';

export const JOB_TRY_ANOTHER_SEARCH = 'Adjust your search';

export const JOB_TRY_ANOTHER_SEARCH_1 = 'Check your spelling';

export const JOB_TRY_ANOTHER_SEARCH_2 = 'Search by the company’s parent company (‘Citi’ instead of ‘Citibank’)';

export const JOB_TRY_ANOTHER_SEARCH_3 = `Search for an acronym or abbreviation instead of the full 
  name, or viceversa (‘Procter & Gamble’ vs ‘P&G’)`;

export const JOB_TRY_ANOTHER_SEARCH_COMPANY = 'Try filtering by Active/Inactive.';
