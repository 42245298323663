import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as FacebookIcon } from 'components/commons/assets/FacebookIcon.svg';
import { ReactComponent as InstagramIcon } from 'components/commons/assets/InstagramIcon.svg';
import { ReactComponent as LinkedInIcon } from 'components/commons/assets/LinkedinIcon.svg';
import { ReactComponent as ArrowIcon } from 'components/commons/assets/arrowBack.svg';
import { ReactComponent as LocationIcon } from 'components/commons/assets/icon-location.svg';

import { TABLET_SIZE, BOOSTRAP_MEDIUM, MOBILE_SIZE } from 'constants/sizes';
import {
  DIPPER_BLUE, GREEN_REVIEW, BLUE, GRAY_COMMUNITY, GRAY_COMMUNITY_LINES, WHITE, BLUE_DIPPER,
} from 'styles/colors';

export const Content = styled.div`
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;

export const AllInfo = styled.div`
  padding-right: 0;
`;

export const TopTitles = styled.div`
  display: flex;
  margin-bottom: 34px;

  @media (max-width: ${TABLET_SIZE}px) {
   padding-bottom: 7px; 
   border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
  }
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0px;
  color: ${BLUE_DIPPER};

  ${({ isSubTitle }) => (
    isSubTitle
      ? css`
      padding-left: 5px;
    `
      : css`
      font-weight: bold;
      padding-right: 5px;
    `
  )};

  @media (max-width: ${TABLET_SIZE}px) {
    font-size: 22px;
  }
`;

export const Border = styled.div`
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};
`;

export const ChartContainer = styled.div`
  min-height: 260px;
  margin-bottom: 20px;
  display: flex;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    padding: 0 15px;
  }
  `;

export const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  ${({ haveBorderBottom }) => (
    haveBorderBottom
      && css`
        padding-bottom: 21px;
        border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
    `
  )};

  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
  }
`;

export const Name = styled.h1`
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
  color: ${DIPPER_BLUE};
  text-transform: capitalize;
  margin: 0;
`;

export const Industry = styled.h2`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  padding-top: 5px;
  color: ${DIPPER_BLUE};
`;

export const CompanyData = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media (max-width: ${MOBILE_SIZE}px) {
    flex-direction: column;
  }
`;

export const CompanyDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 13px;

  @media (max-width: ${MOBILE_SIZE}px) {
    margin-left: 0;
    padding-top: 20px;
  }
`;

export const Location = styled.div`
  font-size: 14px;
  line-height: 18px
  font-weight: bold;
  color: ${DIPPER_BLUE};
  dispĺay: flex;
  text-transform: capitalize;
  margin-top: 12px;
`;

export const CompanyRating = styled.div`
  background: ${BLUE};
  border-radius: 8px;
  font-size: 12px;
  display: flex;
  flex: 1;
  padding: 8px 0;
  font-weight: bold;
  max-width: 250px;

  @media (max-width: ${MOBILE_SIZE}px) {
    justify-content: center;
    padding: 8px 0 8px 0;
    height: 78px;
    margin: 15px 0 0;
    max-width: none;
  }
`;

export const CompanyOverview = styled.div`
  margin-top: 40px;
`;

export const SubTitle = styled.div`
  color: ${DIPPER_BLUE};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin: 34px 0 19px;

  ${({ isStatsTitle }) => (
    isStatsTitle
      ? css`
        font-size: 15px;
    `
      : css`
        padding-bottom: 5px;
        border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};

        @media (max-width: ${MOBILE_SIZE}px) {
          border-bottom: none;
          margin: 30px 0 10px;
        }
    `
  )};
`;

export const IconLocation = styled(LocationIcon)`
  margin-right: 5px;
  width: 8px;
  height: 12px;
`;

export const WebLink = styled.div`
  color: ${GREEN_REVIEW};
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 14px;
`;

export const CompanyInformation = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${DIPPER_BLUE};
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
`;

export const InformationName = styled.div`
  color: ${DIPPER_BLUE};
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-right: 4px;
`;

export const SocialNetworks = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${DIPPER_BLUE};
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 45px;

  @media (max-width: ${TABLET_SIZE}px) {
    margin-top: 20px;
    border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
    padding-bottom: 25px;
  }
`;

export const Facebook = styled(FacebookIcon)`
  height: 16px;
  width: 16px;
  fill: ${BLUE};
  margin-left: 6px;
  
  @media (max-width: ${TABLET_SIZE}px) {
    height: 26px;
    width: 26px;
  }
`;

export const Instagram = styled(InstagramIcon)`
  height: 16px;
  width: 16px;
  fill: ${BLUE};
  margin-left: 6px;
  
  @media (max-width: ${TABLET_SIZE}px) {
    height: 26px;
    width: 26px;
  }
`;

export const LinkedIn = styled(LinkedInIcon)`
  height: 16px;
  width: 16px;
  margin-left: 6px;
  fill: ${BLUE};
  margin-left: 8px;

  @media (max-width: ${TABLET_SIZE}px) {
    height: 26px;
    width: 26px;
  }
`;

export const ReviewStats = styled.div`
  margin-top: 44px;
  margin: 44px 0 18px;

  @media (max-width: ${MOBILE_SIZE}px) {
    margin-top: 25px;
  }
`;

export const PieCharts = styled.div`
  display: flex;
  width: 100%;
  
  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    flex-direction: column;
  }
`;

export const BarCharts = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    flex-direction: column;
  }
`;

export const Recommended = styled.div`
  color: ${WHITE};
  border-right: 1px solid ${GRAY_COMMUNITY};
  text-align: center;
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const PercentageOfRecommended = styled.div`
  font-size: 30px;
`;

export const Ranking = styled.div`
  color: ${WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const RankingPosition = styled.div`
  font-size: 20px;
  text-align: center;
`;

export const RowContainer = styled.div`
  margin-top: ${({ isInBottom }) => (isInBottom && '10px')};
  display: flex;
`;

export const Logo = styled.img`
  border-radius: 6px;
  width: 81px;
  height: 81px;
`;

export const ReviewsDiv = styled.div`
  @media (max-width: ${TABLET_SIZE}px) {
    display: none;
  }
`;

export const WebsiteDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AllReviewsDiv = styled.div`
  margin-top: 25px;
`;

export const EmptyInformationTitle = styled.div`
  margin-top: 15px;
  color: ${GREEN_REVIEW};
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
`;

export const SeeMore = styled.div`
  color: ${GREEN_REVIEW};
  display: ${({ seeMore }) => (!seeMore ? 'none' : 'inline')};
  font-size: 12px;
  cursor: pointer;

  span {
    color: ${DIPPER_BLUE};
  }
`;

export const ArrowDiv = styled.div`
  cursor: pointer;
  margin-right: 4px;
`;

export const ArrowBack = styled(ArrowIcon)`
  width: 9px;
  height: 10px;
  transform: rotate(90deg);
`;

export const CustomLink = styled(Link)`
  display: flex;
`;

export const EmptyOverviewText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: ${GREEN_REVIEW};
  border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
  padding-bottom: 20px;
`;
