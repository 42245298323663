import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import companyName from 'helpers/company';

import { onCompaniesGetAll } from 'redux/actions/company.actions';
import { formatCompaniesList } from 'redux/selectors/company.selector';
import { userSelector } from 'redux/selectors/user.selector';
import { clientHistory } from 'helpers/history';
import { userPropTypes } from 'helpers/propTypes';
import { isMobile } from 'helpers/devices';

import { isPoc } from 'constants/roles';
import { SMALL_TABLET_SIZE } from 'constants/sizes';

import MenuMobile from 'components/MenuMobile/MenuMobile';
import Header from 'components/Header/Header';

const HeaderContainer = (props) => {
  const {
    companiesList, hideSearch, onCompaniesGetAllAction, user,
  } = props;

  const [showLogo, setShowLogo] = useState(true);

  const [width, setWidth] = useState(window.innerWidth);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onCompaniesGetAllAction();
  }, [onCompaniesGetAllAction]);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const hasSmallTabletSize = width <= SMALL_TABLET_SIZE;

  const onSeachCompany = (newValue) => {
    const valueHelper = companyName(newValue, companiesList);
    if (newValue) {
      clientHistory.push(`/company/${valueHelper?.key}`);
    }
  };

  const onFocusSearch = () => isMobile() && setShowLogo(false);
  const onBlurSearch = () => isMobile() && setShowLogo(true);

  const goToProfile = () => {
    if (hasSmallTabletSize) {
      setIsOpen(true);
    } else {
      clientHistory.push('/profile');
    }
  };

  const renderMenuMobile = () => (
    hasSmallTabletSize
      && (
        <MenuMobile
          onCloseMenu={() => setIsOpen(false)}
          user={user}
          isPoc={isPoc(user.role)}
          isOpen={isOpen}
        />
      )
  );

  return (
    <Header
      user={user}
      onSearch={onSeachCompany}
      companiesList={companiesList}
      hideSearch={hideSearch}
      onBlurSearch={onBlurSearch}
      onFocusSearch={onFocusSearch}
      showLogo={showLogo}
      goToProfile={goToProfile}
      renderMenuMobile={renderMenuMobile}
    />
  );
};

const mapStateToProps = (state) => ({
  companiesList: formatCompaniesList(state),
  user: userSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCompaniesGetAllAction: bindActionCreators(onCompaniesGetAll, dispatch),
});

HeaderContainer.defaultProps = {
  user: null,
  hideSearch: false,
};

HeaderContainer.propTypes = {
  user: userPropTypes,
  companiesList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  hideSearch: PropTypes.bool,
  onCompaniesGetAllAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
