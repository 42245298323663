import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import { REVIEWS_LIMIT_ALL, REVIEWS_LIMIT_RECENT } from 'constants/configs';
import * as constants from 'redux/constants/review.constants';
import {
  addNew, getAllForCompany, getAllForUser, getByCompanyId,
  reviewUpdateAnswer, updateUserReview, deleteReview,
} from 'services/review.services';

export function* reviewDelete(action) {
  try {
    const data = yield call(deleteReview, action.formData);
    yield put({ type: constants.REVIEW_ON_DELETE_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.REVIEW_ON_DELETE_FAILED, error });
  }
}

export function* getReviewsForCompanyRecent() {
  try {
    const data = yield call(getAllForCompany, REVIEWS_LIMIT_RECENT);
    yield put({ type: constants.REVIEW_GET_RECENT_BY_COMPANY_ID_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.REVIEW_GET_RECENT_BY_COMPANY_ID_FAILED, error });
  }
}

export function* getReviewsForPoCRecent() {
  try {
    const data = yield call(getAllForUser, REVIEWS_LIMIT_RECENT);
    yield put({ type: constants.REVIEW_GET_RECENT_BY_USER_ID_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.REVIEW_GET_RECENT_BY_USER_ID_FAILED, error });
  }
}

export function* getAllReviewsForCompany() {
  try {
    const data = yield call(getAllForCompany, REVIEWS_LIMIT_ALL);
    yield put({ type: constants.REVIEW_GET_ALL_BY_COMPANY_ID_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.REVIEW_GET_ALL_BY_COMPANY_ID_FAILED, error });
  }
}

export function* getReviewsByCompanyId(action) {
  try {
    const { companyId, page, quantity } = action;
    const data = yield call(getByCompanyId, companyId, page, quantity);
    yield put({ type: constants.REVIEW_GET_BY_COMPANY_ID_SUCCEDED, data, page });
  } catch (error) {
    yield put({ type: constants.REVIEW_GET_BY_COMPANY_ID_FAILED, error });
  }
}

export function* getAllReviewsForPoC() {
  try {
    const data = yield call(getAllForUser, REVIEWS_LIMIT_ALL);
    yield put({ type: constants.REVIEW_GET_ALL_BY_USER_ID_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.REVIEW_GET_ALL_BY_USER_ID_FAILED, error });
  }
}

export function* addReview(action) {
  try {
    const data = yield call(addNew, action.formData);
    yield put({ type: constants.REVIEW_ON_ADD_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.REVIEW_ON_ADD_FAILED, error });
  }
}

export function* updateReview(action) {
  try {
    const data = yield call(updateUserReview, action.formData);
    yield put({ type: constants.REVIEW_ON_UPDATE_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.REVIEW_ON_UPDATE_FAILED, error });
  }
}

export function* reviewAddAnswer(action) {
  try {
    const data = yield call(reviewUpdateAnswer, action.formData);
    yield put({ type: constants.REVIEW_ADD_ANSWER_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.REVIEW_ADD_ANSWER_FAILED, error });
  }
}

export function* watchReviews() {
  yield all([
    takeLatest(constants.REVIEW_ON_DELETE_REQUESTED, reviewDelete),
    takeLatest(constants.REVIEW_GET_RECENT_BY_COMPANY_ID_REQUESTED, getReviewsForCompanyRecent),
    takeLatest(constants.REVIEW_GET_RECENT_BY_USER_ID_REQUESTED, getReviewsForPoCRecent),
    takeLatest(constants.REVIEW_GET_ALL_BY_COMPANY_ID_REQUESTED, getAllReviewsForCompany),
    takeLatest(constants.REVIEW_GET_BY_COMPANY_ID_REQUESTED, getReviewsByCompanyId),
    takeLatest([constants.REVIEW_GET_ALL_BY_USER_ID_REQUESTED], getAllReviewsForPoC),
    takeLatest(constants.REVIEW_ON_ADD_REQUESTED, addReview),
    takeLatest(constants.REVIEW_ON_UPDATE_REQUESTED, updateReview),
    takeLatest(constants.REVIEW_ADD_ANSWER_REQUESTED, reviewAddAnswer),
  ]);
}
