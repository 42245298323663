import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { onAddFeed } from 'redux/actions/feed.actions';

import {
  addStyleToLinks, limitStringSize, removeAllTagsExceptBreaks, RemoveAllTagsForSubmission,
} from 'helpers/string';

import PLACEHOLDER from 'components/commons/CustomContentEditable/constants';
import { COMMUNITY_FEED } from 'constants/inputSizes';

import CreateFeedComponent from 'components/CreateFeed';

const CreateFeed = (props) => {
  const { onSubmit, user } = props;
  const [html, setHtml] = useState(PLACEHOLDER);

  const onSubmitFeed = (formData) => {
    onSubmit({ description: RemoveAllTagsForSubmission(formData.description.target.value) });
    setHtml(PLACEHOLDER);
  };

  const handleChangeFeed = (event) => {
    /* eslint-disable  no-param-reassign */

    // replace br tag and then remove all tags
    event.target.value = removeAllTagsExceptBreaks(event.target.value);
    event.target.value = limitStringSize(event.target.value, COMMUNITY_FEED);
    event.target.value = addStyleToLinks(event.target.value);

    /* eslint-enable */
    setHtml(event.target.value);
  };

  return (
    <>
      {!user && <h1>Loading...</h1>}
      {user
      && (
        <CreateFeedComponent
          handleChangeFeed={handleChangeFeed}
          html={html}
          setHtml={setHtml}
          onSubmit={onSubmitFeed}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: bindActionCreators(onAddFeed, dispatch),
});

CreateFeed.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.number.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFeed);
