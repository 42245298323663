import styled, { css } from 'styled-components';
import {
  DIPPER_BLUE, BLUE, WHITE, LIGHT_GREEN,
} from 'styles/colors';
import { SMALL_MOBILE_SIZE } from 'constants/sizes';

import { ReactComponent as WelcomeImageSVG } from './assets/welcome-onboarding.svg';

export const WelcomeImage = styled(WelcomeImageSVG)`
  width: 240px;
  margin: 20px 0;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 428px;
  height: 390px;

  @media (max-width: ${SMALL_MOBILE_SIZE}px){
    width: auto;
  }
  `;

export const CircleImages = styled.img` 
  width: 170px;
  height: 170px;

  @media (max-width: ${SMALL_MOBILE_SIZE}px){
    ${({ smallSize }) => (
    smallSize
      && css`
       width: 130px;
       height: 130px;
    `
  )};
  }
`;

export const RowImages = styled.div` 
  display: flex;
  flex-direction: row;
  align-self: center;
`;

export const Text = styled.div` 
  font: normal normal normal 14px Mulish;
  color: ${DIPPER_BLUE};
`;

export const TextWelcome = styled(Text)`
  margin: 0 50px;
  width: 100%;
`;

export const BoldText = styled.div` 
  font: normal normal bold 14px Mulish;
  display: contents;
`;

export const Title = styled.div` 
  font: normal normal 800 24px/30px 'Mulish-ExtraBold';
  color: ${DIPPER_BLUE};
  margin-bottom: 10px;
`;

export const Button = styled.div` 
  background: ${BLUE} 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font: normal normal bold 14px/18px Mulish;
  color: ${WHITE};
  width: fit-content;
  padding: 11px 12px;
  margin-top: 20px;
  cursor: pointer;
`;

export const SelectCircles = styled.div` 
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;

export const Circle = styled.div` 
  border: ${({ isSelected }) => (!isSelected && '1px solid #474F63;')};
  height: 12px;
  width: 12px;
  background-color: ${({ isSelected }) => (isSelected ? LIGHT_GREEN : WHITE)};
  border-radius: 50%;
  margin-right: ${({ dontHaveMarginRight }) => (!dontHaveMarginRight && '13px')};
  cursor: pointer;
`;

export const ModalContent = styled.div` 
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  animation-duration: 0.7s;
  animation-name: animate-fade;
  animation-delay: 0s;
  animation-fill-mode: backwards;

  @keyframes animate-fade {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`;
