import * as constants from 'redux/constants/feed.constants';
import * as userConstants from 'redux/constants/user.constants';
import { isCompany } from 'constants/roles';

import DefaultProfile from 'components/commons/assets/avatar-poc.svg';

const defaultState = {
  hasMore: true,
  items: [],
  selected: null,
};

const updateFeedsComments = (items, updatedComment) => items?.map((feed) => {
  if (feed.id === updatedComment.feed) {
    feed.comments.unshift(updatedComment);
  }
  return feed;
});

const getProfilePic = (feed) => {
  if (isCompany(feed.user.role)) {
    return (feed.user.company?.logo?.path || DefaultProfile);
  }
  return (feed.user.profilePic?.path || DefaultProfile);
};

const parseFeed = (feedToParse) => ({
  ...feedToParse,
  author: isCompany(feedToParse.user.role) ? feedToParse.user.company?.name : `${feedToParse.user.firstName} ${feedToParse.user.lastName}`,
  profilePic: getProfilePic(feedToParse),
});

const selectFeedById = (feedId, feedCollection) => {
  const feedSelected = feedCollection.find((feed) => feed.id === feedId);
  return parseFeed(feedSelected);
};

const feedReducer = (state = defaultState, action) => {
  const { data } = action;
  let items = [];
  switch (action.type) {
  case constants.FEED_GET_RECENT_SUCCEDED:
    items = (action.page === 0) ? [...data.feeds] : [...state.items, ...data.feeds];
    return {
      ...state,
      items,
      hasMore: data.feeds.length > 0,
      selected: (state.selected || parseFeed(items[0])),
    };
  case constants.FEED_ON_ADD_SUCCEDED:
    return {
      ...state,
      items: [data, ...state.items],
      selected: parseFeed(data),
    };
  case constants.FEED_ON_ADD_COMMENT_SUCCEDED:
    return {
      ...state,
      items: updateFeedsComments(state?.items, data),
      selected: selectFeedById(data.feed, state?.items),
    };
  case constants.FEED_SET_SELECTED_REQUESTED:
    return {
      ...state,
      selected: action.feed,
    };
  case userConstants.USER_ON_LOGOUT:
    return defaultState;
  case constants.FEED_ON_ADD_COMMENT_FAILED:
  case constants.FEED_GET_RECENT_FAILED:
  case constants.FEED_ON_ADD_FAILED:
  default:
    return state;
  }
};

export default feedReducer;
