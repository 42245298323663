import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { reviewProptypes, userPropTypes } from 'helpers/propTypes';

import {
  onReviewAddAnswer,
  onReviewGetByCompanyId,
  onSetReviewSelected,
  onReviewGetAllForPoC,
  onReviewDelete,
} from 'redux/actions/review.actions';
import { roles } from 'constants/roles';
import { REVIEWS_LIMIT_ALL } from 'constants/configs';

import { Container, GreenButton } from 'styles/commons';
import {
  BLUE, DIPPER_BLUE, BLUE_DARK, WHITE,
} from 'styles/colors';

import Layout from 'containers/Layout';
import ReviewsView from 'views/Reviews';
import CustomModal from 'components/commons/Modal';

const ModalLegend = styled.div`
  font: normal normal bold 14px/18px Mulish;
  letter-spacing: 0;
  color: ${DIPPER_BLUE};
  margin-bottom: 20px;
  letter-spacing: var(--unnamed-character-spacing-0);
  width: 200px;
`;

const CustomButton = styled(GreenButton)`
  background: ${BLUE} !important;

  :hover {
    background: ${BLUE_DARK} !important;
    color: ${WHITE} !important;
  }
`;

const ButtonText = styled.div`
  font: normal normal bold 14px/18px Mulish;
  padding: 13px 30px;
`;

const Reviews = (props) => {
  const {
    companyId,
    companyReviewsList,
    hasMore,
    onClick,
    onDeleteReviewAction,
    onGetReviewsByCompanyId,
    onGetReviewsFromPoCAction,
    onSetReviewSelectedAction,
    onSubmitAnswer,
    pocReviewsList,
    pageNumber,
    quantity,
    hasToRenderAllReviewDetails,
    user,
    selected,
    totalAmountOfReviews,
    areReviewsFromProfile,
    companyCode,
  } = props;

  const isCompany = user && user.role === roles.COMPANY.id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reviewId, setReviewId] = useState(null);

  const [pageNumberReviews, setPageNumberReviews] = useState(0);

  const handleScroll = (event) => {
    const { target } = event;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
      setPageNumberReviews(pageNumberReviews + 1);
    }
  };

  useEffect(() => {
    if (!companyId) {
      if (!isCompany) {
        onGetReviewsFromPoCAction();
      } else if (hasMore) {
        onGetReviewsByCompanyId(
          user.company?.id,
          REVIEWS_LIMIT_ALL,
          pageNumberReviews,
        );
      }
    } else if (hasMore) {
      onGetReviewsByCompanyId(companyId, quantity, pageNumber);
    }
  }, [
    onGetReviewsFromPoCAction,
    quantity,
    user,
    isCompany,
    companyId,
    pageNumber,
    hasMore,
    onGetReviewsByCompanyId,
    pageNumberReviews,
  ]);

  const onDeleteReview = (id) => {
    setReviewId(id);
    setIsModalOpen(true);
  };

  const onDelete = () => {
    onDeleteReviewAction(reviewId);
    setIsModalOpen(false);
    setReviewId(null);
  };

  const renderReviewView = () => (
    <ReviewsView
      user={user}
      reviewList={
        isCompany || !!companyId ? companyReviewsList : pocReviewsList
      }
      isCompany={isCompany}
      onSubmitAnswer={onSubmitAnswer}
      onDelete={onDeleteReview}
      areReviewsFromProfile={areReviewsFromProfile}
      hasToRenderAllReviewDetails={hasToRenderAllReviewDetails}
      setReviewSelected={onSetReviewSelectedAction}
      selectedReview={selected}
      handleScroll={handleScroll}
      onClick={onClick}
      totalAmountOfReviews={totalAmountOfReviews}
      companyCode={companyCode}
    />
  );

  const renderModal = () => (
    <CustomModal
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        setReviewId(null);
      }}
    >
      <ModalLegend>Are you sure you want to delete this review?</ModalLegend>
      <CustomButton onClick={onDelete}>
        <ButtonText>Yes, delete</ButtonText>
      </CustomButton>
    </CustomModal>
  );

  return (
    !!user
    && (!areReviewsFromProfile ? (
      <Container>
        <Layout isLandscape hideAddReview>
          {renderReviewView()}
          {renderModal()}
        </Layout>
      </Container>
    ) : (
      renderReviewView()
    ))
  );
};

const mapStateToProps = (state) => ({
  user: state.user.data,
  pocReviewsList: state.review.userItems,
  companyReviewsList: state.review.companyItems,
  hasMore: state.review.hasMore,
  totalAmountOfReviews: state.review.totalAmountOfReviews,
  selected: state.review.selected,
});

const mapDispatchToProps = (dispatch) => ({
  onGetReviewsByCompanyId: bindActionCreators(onReviewGetByCompanyId, dispatch),
  onGetReviewsFromPoCAction: bindActionCreators(onReviewGetAllForPoC, dispatch),
  onSetReviewSelectedAction: bindActionCreators(onSetReviewSelected, dispatch),
  onSubmitAnswer: bindActionCreators(onReviewAddAnswer, dispatch),
  onDeleteReviewAction: bindActionCreators(onReviewDelete, dispatch),
});

Reviews.defaultProps = {
  user: null,
  areReviewsFromProfile: false,
  companyId: 0,
  quantity: 0,
  companyCode: '',
};

Reviews.propTypes = {
  areReviewsFromProfile: PropTypes.bool,
  companyId: PropTypes.number,
  user: userPropTypes,
  pocReviewsList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dateCreated: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string,
      description: PropTypes.string.isRequired,
      companyIsGreatPlaceToWork: PropTypes.number.isRequired,
      companyTreatment: PropTypes.number.isRequired,
      companyDiversity: PropTypes.number.isRequired,
      recommendCompany: PropTypes.bool.isRequired,
      personalSituation: PropTypes.number.isRequired,
      feedback: PropTypes.bool.isRequired,
      anonymous: PropTypes.bool.isRequired,
      user: userPropTypes.isRequired,
    }).isRequired,
  ).isRequired,
  companyReviewsList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dateCreated: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string,
      description: PropTypes.string.isRequired,
      companyIsGreatPlaceToWork: PropTypes.number.isRequired,
      companyTreatment: PropTypes.number.isRequired,
      companyDiversity: PropTypes.number.isRequired,
      recommendCompany: PropTypes.bool.isRequired,
      personalSituation: PropTypes.number.isRequired,
      feedback: PropTypes.bool.isRequired,
      anonymous: PropTypes.bool.isRequired,
      user: userPropTypes.isRequired,
    }).isRequired,
  ).isRequired,
  onDeleteReviewAction: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onGetReviewsByCompanyId: PropTypes.func.isRequired,
  onGetReviewsFromPoCAction: PropTypes.func.isRequired,
  onSetReviewSelectedAction: PropTypes.func.isRequired,
  companyCode: PropTypes.string,
  onSubmitAnswer: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  selected: reviewProptypes.isRequired,
  onClick: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  hasToRenderAllReviewDetails: PropTypes.bool.isRequired,
  totalAmountOfReviews: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
