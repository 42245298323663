import React from 'react';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { GRAY_DARK, GREEN_REVIEW } from 'styles/colors';

const CustomToggle = (props) => {
  const {
    toggled, setToggled,
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      width: 120,
      height: 48,
      padding: 8,
      marginRight: -8,
    },
    switchBase: {
      padding: 11,
      color: '#ff6a00',
    },
    thumb: {
      width: 26,
      height: 26,
      backgroundColor: '#fff',
    },
    track: {
      background: `${GREEN_REVIEW}`,
      opacity: '1 !important',
      borderRadius: 20,
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#fff',
        textAlign: 'center',
      },
      '&:before': {
        content: '"Inactive"',
        left: 12,
        opacity: 0,
      },
      '&:after': {
        content: '"Active"',
        right: 17,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#185a9d',
        transform: 'translateX(72px)',
        '&:hover': {
          backgroundColor: 'rgba(24,90,257,0.08)',
        },
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        },
      },
    },
    colorSecondary: {
      '&$checked + $track': {
        backgroundColor: `${GRAY_DARK}`,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Switch
      classes={classes}
      checked={toggled}
      onChange={(e) => setToggled(e.target.checked)}
    />
  );
};

CustomToggle.propTypes = {
  toggled: PropTypes.bool.isRequired,
  setToggled: PropTypes.func.isRequired,
};

export default CustomToggle;
