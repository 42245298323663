import { Field } from 'redux-form';
import styled from 'styled-components';

import { DIPPER_BLUE } from 'styles/colors';

export const RadioContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Mulish';
  font-size: 14px;
  margin-top: 12px;
`;

export const RadioField = styled(Field)`
  width: 17px;
  height: 17px;
`;

export const StyledSpan = styled.span`
  color: ${DIPPER_BLUE};
  padding-left: 15px;
  font: ${({ isBold }) => (isBold ? 'normal normal bold 12px/16px Mulish;' : '12px/16px Mulish;')};
`;
