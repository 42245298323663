import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TABLET_SIZE, BOOSTRAP_MEDIUM } from 'constants/sizes';
import {
  BLUE, LIGHT_GREEN, WHITE, BLUE_DARK,
} from 'styles/colors';

import { ReactComponent as NewReviewImage } from './assets/ilus-new-review-box.svg';

export const NewReviewSection = styled.div`
  position: relative;
  min-height: 152px;
  border-radius: 6px;
  background-color: ${LIGHT_GREEN};
  color: ${WHITE};
  padding: 16px 60px 16px 16px;
  margin: ${({ hasOnlyMarginBottom }) => (hasOnlyMarginBottom ? '0 0 10px' : '10px 0px')};

  @media (max-width: 1300px) {
    padding: 10px;
  }
`;

export const NewReviewTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  margin: 10px 0px;

  @media (max-width: 1300px) {
    font-size: 18px;
  }

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    width: 80%;
  }
`;

export const NewReviewText = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    width: 75%;
  }
`;

export const ButtonContainer = styled.div`
  @media (max-width: ${TABLET_SIZE}px) {
    display: flex;
    flex: 1;
    justify-content: center;  
  }

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: flex;
    flex: 1;
    justify-content: flex-start;  
  }
`;

export const NewReviewButton = styled(Link)`
  display: flex;
  flex: 1;
  text-transform: capitalize;
  max-width: 151px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: ${BLUE};
  border-radius: 4px;
  opacity: 1;
  color: ${WHITE} !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px; 
  margin: 15px 0;

  :hover {
    background: ${BLUE_DARK};
  }
`;

export const NewReviewCustomImage = styled(NewReviewImage)`
  width: 93px;
  heigth: 204px;
  position: absolute;
  top: -5px;
  right: 5px;

  @media (max-width: 1300px) {
    display: none;
  }

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: flex;
    width: 73px;
    top: -8px;
  }

  @media (max-width: 477px) {
    width: 83px;
  }

  @media (max-width: 439px) {
    width: 90px;
  }

  @media (max-width: 307px) {
    display: none;
  }
`;
