import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import { GREEN_REVIEW } from 'styles/colors';
import CheckBoxStyled from './styles';

const CustomCheckbox = (props) => {
  const { input, label } = props;

  const useStyles = makeStyles(() => ({
    root: {
      '&$checked': {
        color: GREEN_REVIEW,
      },
    },
    checked: {},
  }));
  const classes = useStyles();

  return (
    <div>
      <FormControlLabel
        control={(
          <CheckBoxStyled
            {...input}
            checked={!!input.value}
            classes={{ root: classes.root, checked: classes.checked }}
            data-testid="custom-checkbox"
          />
        )}
        label={label}
      />
    </div>
  );
};

CustomCheckbox.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.string.isRequired,
    ]),
    onChange: PropTypes.func,
  }).isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomCheckbox;
