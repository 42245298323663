import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { HelpBlock } from 'styles/commons';

import {
  FormGroup, CustomAutoComplete, CustomSearchIcon, CustomInputAdornment, CustomTextField,
} from './styles';

const AutoComplete = (props) => {
  const {
    disabled, options, size, search, input: { value, onChange }, label, meta: { touched, error },
    onFocusSearch, onBlurSearch, searchSelected, autoSelect, isPlaceholder,
  } = props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = () => {
    if (!disabled && value.length > 0) {
      setOpen(true);
    }
  };

  const onBlur = () => {
    setOpen(false);
    onBlurSearch();
  };

  const handleInputChange = (event, newInputValue) => {
    setOpen(!disabled && newInputValue.length > 0);
    setInputValue(newInputValue);
  };

  const onClickSearch = () => {
    if (inputValue) {
      onChange({ value: inputValue });
      setOpen(false);
    }
  };

  const filter = createFilterOptions();

  return (
    <FormGroup search={search} searchSelected={searchSelected}>
      <CustomAutoComplete
        open={open}
        onOpen={handleOpen}
        searchSelected={searchSelected}
        data-testid="custom-auto-complete"
        size={size}
        value={value}
        label={!isPlaceholder && label}
        placeholder={isPlaceholder && label}
        search={search}
        disabled={disabled}
        onFocus={onFocusSearch}
        onBlur={onBlur}
        onInputChange={handleInputChange}
        onChange={(event, newValue) => {
          setOpen(false);
          if (typeof newValue === 'string') {
            onChange({
              value: newValue,
            });
          } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
            onChange({
              value: newValue.inputValue,
            });
          } else {
            onChange(newValue);
          }
        }}
        filterOptions={(option, params) => {
          const filtered = filter(option, params);
          return filtered;
        }}
        clearOnBlur={!value}
        disableClearable
        handleHomeEndKeys
        id="combo-box-demo"
        options={options}
        getOptionLabel={(option) => {
        // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.value;
        }}
        renderOption={(option) => option.value}
        freeSolo
        autoSelect={autoSelect}
        renderInput={(params) => (
          <CustomTextField
            {...params}
            error={touched && !!error}
            label={!isPlaceholder && label}
            placeholder={isPlaceholder && label}
            variant="outlined"
            search={search}
            InputProps={{
              ...params.InputProps,
              endAdornment:
              search && (
                <CustomInputAdornment onClick={() => onClickSearch()} data-testid="custom-input-adornment" position="end">
                  <CustomSearchIcon />
                </CustomInputAdornment>
              ),
            }}
          />
        )}
      />
      <HelpBlock data-testid="help-block">{(touched && error) || ' '}</HelpBlock>
    </FormGroup>
  );
};

AutoComplete.defaultProps = {
  disabled: false,
  isPlaceholder: false,
  meta: { touched: false, error: '' },
  search: false,
  onBlurSearch: () => {},
  onFocusSearch: () => {},
  searchSelected: false,
  autoSelect: false,
};

AutoComplete.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.string.isRequired,
    ]),
    onChange: PropTypes.func,
  }).isRequired,
  isPlaceholder: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  size: PropTypes.string.isRequired,
  search: PropTypes.bool,
  onBlurSearch: PropTypes.func,
  onFocusSearch: PropTypes.func,
  searchSelected: PropTypes.bool,
  autoSelect: PropTypes.bool,
};

export default AutoComplete;
