import React from 'react';

import Layout from 'containers/Layout';
import { PageContainer } from 'styles/commons';
import { ReactComponent as ErrorImage } from 'constants/assets/404.svg';

import {
  ImageCoitainer, TextContainer, ButtonContainer, StyledGreenButton, CustomLink,
} from './styles';

const Error = () => (
  <Layout hideAddReview>
    <div className="container" data-testid="error-page">
      <PageContainer>
        <ImageCoitainer>
          <ErrorImage />
        </ImageCoitainer>
        <TextContainer>
          Uh oh, we can’t seem to find the page you’re looking for.
          <br />
          Try going back to the previous page or
        </TextContainer>
        <ButtonContainer>
          <StyledGreenButton>
            <CustomLink to="/home">
              Back to home
            </CustomLink>
          </StyledGreenButton>
        </ButtonContainer>
      </PageContainer>
    </div>
  </Layout>
);

export default Error;
