import moment from 'moment';

function getValidDate(date) {
  return date ? new Date(date) : new Date();
}

export function getParsedDate(date) {
  return moment(date).format('dddd l');
}

export const getStringDate = (date = null) => {
  const newDate = getValidDate(date);
  return moment.utc(newDate).format('YYYY-MM-DD HH:m:s');
};

export const getStringMonthNameDayYear = (date = null) => {
  const newDate = getValidDate(date);
  return moment.utc(newDate).format('MMMM DD, YYYY');
};

export const getStringMonthYear = (date = null) => {
  const newDate = getValidDate(date);
  return moment.utc(newDate).format('MM/YYYY');
};

export const getYear = (date = null) => {
  const newDate = getValidDate(date);
  return moment.utc(newDate).format('YYYY');
};

export const getMonth = (date = null) => {
  const newDate = getValidDate(date);
  return moment.utc(newDate).format('MM');
};

function parseTimeAgo(minutes, hours, days) {
  if (days >= 1) {
    return (days === 1) ? '1 day' : `${days} days`;
  } if (hours >= 1) {
    return (hours === 1) ? '1 hour' : `${hours} hours`;
  } if (minutes >= 1) {
    return (minutes === 1) ? '1 minute' : `${minutes} min`;
  }
  return 'Less than a minute';
}

export const timePassedSince = (date) => {
  const sinceDate = new Date(date);
  const actualDate = moment(new Date());
  const minutes = actualDate.diff(sinceDate, 'minutes');
  const hours = actualDate.diff(sinceDate, 'hours');
  const days = actualDate.diff(sinceDate, 'days');
  return parseTimeAgo(minutes, hours, days);
};

// Format date to YYYY-MM-DD
export const formatDate = (date) => {
  const newDate = new Date(date);
  return moment.utc(newDate).format('YYYY-MM-DD');
};

export const isNewDate = (date) => {
  const publicationDate = moment(new Date(date));
  const todayDate = moment(new Date());
  const smallerThan = publicationDate.add(30, 'days');
  return todayDate.isBefore(smallerThan);
};
