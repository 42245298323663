import * as React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  required,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  ImageInput,
  ImageField,
  BooleanInput,
  BooleanField,
  DateField,
  DateInput,
} from 'react-admin';

import { AdminFormContainer } from 'styles/commons';
import { validateMaxSize, validateRequired } from 'admin/helpers/formValidation';
import * as validates from 'helpers/validates';
import { roles } from 'constants/roles';
import SIZE_OPTIONS from 'constants/companySize';
import {
  COMPANY_DEI_COMMITMENTS, COMPANY_DESCRIPTION, COMPANY_FACEBOOK, COMPANY_INDUSTRY,
  COMPANY_INSTAGRAM, COMPANY_LINKEDIN, COMPANY_LOCATION, COMPANY_NAME, COMPANY_WEBSITE,
} from 'constants/inputSizes';

// This will override the style of the <img> inside the <div>
const useImageFieldStyles = makeStyles(() => ({
  image: {
    minWidth: 100,
    minHeight: 100,
  },
}));

const parseValues = (rawValues) => {
  const values = [];
  Object.keys(rawValues).forEach((key) => {
    values.push({ id: key, value: rawValues[key] });
  });
  return values;
};

const companySizesOptions = () => parseValues(SIZE_OPTIONS);

const CompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by Name" source="name" alwaysOn />
  </Filter>
);

const renderOptions = (choices) => `${choices.firstName} ${choices.lastName}`;

export const CompanyList = (props) => (
  <List filters={<CompanyFilter />} sort={{ field: 'dateUpdated', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField label="Last Update" source="dateUpdated" />
      <TextField source="name" />
      <ReferenceField label="User" source="user.id" reference="users">
        <TextField source="firstName" />
      </ReferenceField>
      <BooleanField source="isPremium" />
      <EditButton />
    </Datagrid>
  </List>
);

const CompanyTitle = ({ record }) => (
  <span>
    {record ? `${record.name}` : ''}
  </span>
);

const CompanyForm = ({ isEditForm }) => {
  const imageFieldClasses = useImageFieldStyles();
  return (
    <AdminFormContainer>
      { isEditForm && <TextInput disabled source="id" /> }
      <TextInput
        source="name"
        label="Company Name"
        inputProps={{ maxLength: COMPANY_NAME }}
        validate={[required(), validateRequired, (field) => validateMaxSize(COMPANY_NAME, field)]}
      />
      <BooleanInput source="isPremium" />
      <ImageInput source="logo" label="Company Logo" accept="image/*">
        <ImageField classes={imageFieldClasses} source="path" title="title" />
      </ImageInput>
      <ReferenceInput label="User" source="user.id" reference="users" perPage={1000} filter={{ role: roles.COMPANY.id }} alwaysOn>
        <SelectInput optionText={renderOptions} validate={required()} />
      </ReferenceInput>
      <TextInput
        source="location"
        inputProps={{ maxLength: COMPANY_LOCATION }}
        validate={[(field) => validateMaxSize(COMPANY_LOCATION, field)]}
      />
      <TextInput
        source="website"
        inputProps={{ maxLength: COMPANY_WEBSITE }}
        validate={[validates.website, (field) => validateMaxSize(COMPANY_WEBSITE, field)]}
      />
      <TextInput
        source="industry"
        inputProps={{ maxLength: COMPANY_INDUSTRY }}
        validate={[(field) => validateMaxSize(COMPANY_INDUSTRY, field)]}
      />
      <SelectInput
        source="size"
        choices={companySizesOptions()}
        optionText="value"
        optionValue="id"
      />
      <DateInput
        source="founded"
      />
      <TextInput
        source="instagram"
        inputProps={{ maxLength: COMPANY_INSTAGRAM }}
        validate={[validates.socialMedia, (field) => validateMaxSize(COMPANY_INSTAGRAM, field)]}

      />
      <TextInput
        source="facebook"
        inputProps={{ maxLength: COMPANY_FACEBOOK }}
        validate={[validates.socialMedia, (field) => validateMaxSize(COMPANY_FACEBOOK, field)]}
      />
      <TextInput
        source="linkedIn"
        inputProps={{ maxLength: COMPANY_LINKEDIN }}
        validate={[validates.socialMedia, (field) => validateMaxSize(COMPANY_LINKEDIN, field)]}
      />
      <TextInput
        source="description"
        multiline
        inputProps={{ maxLength: COMPANY_DESCRIPTION }}
        validate={[(field) => validateMaxSize(COMPANY_DESCRIPTION, field)]}
      />
      <TextInput
        source="deiCommitments"
        multiline
        inputProps={{ maxLength: COMPANY_DEI_COMMITMENTS }}
        validate={[(field) => validateMaxSize(COMPANY_DEI_COMMITMENTS, field)]}
      />
    </AdminFormContainer>
  );
};

export const CompanyEdit = (props) => (
  <Edit title={<CompanyTitle />} {...props}>
    <SimpleForm>
      <CompanyForm isEditForm />
    </SimpleForm>
  </Edit>
);

export const CompanyCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <CompanyForm />
    </SimpleForm>
  </Create>
);

CompanyTitle.defaultProps = {
  record: null,
};

CompanyTitle.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

CompanyForm.defaultProps = {
  isEditForm: false,
};
CompanyForm.propTypes = {
  isEditForm: PropTypes.bool,
};
