export const COMPANY_GET_ALL_REQUESTED = 'COMPANY_GET_ALL_REQUESTED';
export const COMPANY_GET_ALL_SUCCEDED = 'COMPANY_GET_ALL_SUCCEDED';
export const COMPANY_GET_ALL_FAILED = 'COMPANY_GET_ALL_FAILED';

export const COMPANY_ON_UPDATE_REQUESTED = 'COMPANY_ON_UPDATE_REQUESTED';
export const COMPANY_ON_UPDATE_SUCCEEDED = 'COMPANY_ON_UPDATE_SUCCEEDED';
export const COMPANY_ON_UPDATE_FAILED = 'COMPANY_ON_UPDATE_FAILED';

export const COMPANY_ON_GET_RANK_REQUESTED = 'COMPANY_ON_GET_RANK_REQUESTED';
export const COMPANY_ON_GET_RANK_SUCCEEDED = 'COMPANY_ON_GET_RANK_SUCCEEDED';
export const COMPANY_ON_GET_RANK_FAILED = 'COMPANY_ON_GET_RANK_FAILED';

export const COMPANY_ON_EMPTY_STATE_REQUESTED = 'COMPANY_ON_EMPTY_STATE_REQUESTED';
