import styled from 'styled-components';

import { BOOSTRAP_MEDIUM } from 'constants/sizes';
import { DIPPER_BLUE, GRAY_COMMUNITY } from 'styles/colors';

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  
  h1 {
    color: ${DIPPER_BLUE};
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
  }
`;

export const FeedsSection = styled.div`
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  
  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    margin: 10px 0;
  }
`;

export const FeedContent = styled.div`
  background-color: ${GRAY_COMMUNITY};
  padding: 15px 20px 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex: 1;

  h1 {
    color: ${DIPPER_BLUE};
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
`;
