import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { BLUE_DARK } from 'styles/colors';
import { ReactComponent as DipperStarImage } from 'views/Home/assets/star.svg';
import { MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';

export const Container = styled.div`
  position: relative;

  h1 {
    color: ${BLUE_DARK};
    font-weight: bold;
    font-size: 32px;
    line-height: 26px;
    letter-spacing: 0px;
    margin: 20px 0px 30px !important;
  }
`;

export const StyledCarousel = styled(Carousel)`
  text-align: center;
`;

export const StyledRightArrow = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  border-radius: 50%;
  margin-right: 1px;

  -webkit-box-shadow: 0px 2px 60px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 60px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 60px 0px rgba(0,0,0,0.1);
`;

export const StyledLeftArrow = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  transform: rotate(180deg);
  border-radius: 50%;
  margin-left: 1px;

  -webkit-box-shadow: 0px 2px 60px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 2px 60px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 2px 60px 0px rgba(0,0,0,0.1);
`;

export const FirstStarImage = styled(DipperStarImage)`
  position: absolute;
  width: 38px;
  heigth: 37px;
  top: 50px;
  left: 245px;

  @media (max-width: ${TABLET_SIZE}px) {
    left: 27px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    left: 10px;
  }
`;

export const SecondStarImage = styled(DipperStarImage)`
  position: absolute;
  width: 34px;
  heigth: 33px;
  bottom: 0px;
  left: 515px;

  @media (max-width: ${TABLET_SIZE}px) {
    left: 285px;
    bottom: 16px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    left: auto;
    right: 70px;
  }
`;

export const ThirdStarImage = styled(DipperStarImage)`
  position: absolute;
  width: 43px;
  heigth: 32px;
  top: 11px;
  right: 20px;

  @media (max-width: ${TABLET_SIZE}px) {
    top: 50px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    top: -70px;
  }
`;
