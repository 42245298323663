const companyName = (name, options) => {
  const formatedName = name.value.toString().replace(/\s+/g, ' ').toLowerCase();
  const objectName = options.find(
    ({ key, value }) => value.replace(/\s+/g, ' ').toLowerCase() === formatedName || key.toLowerCase() === formatedName,
  );
  if (!objectName) {
    return {
      key: null,
      value: name.value.toString().replace(/\s+/g, ' '),
    };
  }
  return objectName;
};

export default companyName;
