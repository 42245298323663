import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { onAcceptCookies } from 'redux/actions/app.actions';

import SideMenuContainer from 'containers/SideMenuContainer';
import HeaderContainer from 'containers/HeaderContainer';
import Footer from 'components/Footer/Footer';
import FloatingCreateReview from 'components/FloatingCreateReview';

import { MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';
import CookieBanner from 'components/CookieBanner';
import { getCookie } from 'helpers/cookies';

const GlobalContainer = styled.div`
  display: flex;
  width: 100vw;
  height: ${({ height }) => (height && `${height}px`)};
    
  ${({ isLandscape }) => (
    isLandscape
      ? css`
        flex-direction: row;
        overflow: hidden;
      `
      : css`
        flex-direction: column;
        width: 100%;
      `
  )};

  @media (max-width: ${TABLET_SIZE}px) {
    flex-direction: column;
    overflow: visible;
    width: 100%;
    overflow-x: hidden;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: ${({ isLandscape }) => (!isLandscape && '1')};
  height: ${({ isLandscape }) => (isLandscape && 'calc(100% - 52px)')};
`;

const PageContainerHome = styled.div`
  height: 100%;
`;

const PageContainerDashboard = styled.div`
  height: 100%;
  padding: 15px 25px 0;

  @media (max-width: ${TABLET_SIZE}px) {
    height: calc(100% - 64px);
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    ${({ smallPaddingMobile }) => (smallPaddingMobile && 'padding: 15px 15px')};
  }
`;

const Layout = (props) => {
  const {
    children, isLandscape, onAcceptCookiesAction, hideAddReview, hideSearch, homeAddReview,
    smallPaddingMobile,
  } = props;
  const [height, setHeight] = useState(window.innerHeight);
  const [showCookieBanner, setShowCookieBanner] = useState(getCookie('acceptCookie') !== '1');

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const PageContainer = isLandscape ? PageContainerDashboard : PageContainerHome;

  const onAccept = () => {
    setShowCookieBanner(false);
    onAcceptCookiesAction();
  };

  return (
    <GlobalContainer isLandscape={isLandscape} height={height}>
      <SideMenuContainer isLandscape={isLandscape} />
      <ContentContainer isLandscape={isLandscape}>
        {isLandscape && <HeaderContainer hideSearch={hideSearch} />}
        <PageContainer smallPaddingMobile={smallPaddingMobile}>
          {children}
        </PageContainer>
      </ContentContainer>
      {!hideAddReview && <FloatingCreateReview homeAddReview={homeAddReview} />}
      {showCookieBanner
        && <CookieBanner isLandscape={isLandscape} onAccept={onAccept} />}
      {!isLandscape && <Footer isLandscape />}
    </GlobalContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onAcceptCookiesAction: bindActionCreators(onAcceptCookies, dispatch),
});

Layout.defaultProps = {
  isLandscape: false,
  hideAddReview: false,
  hideSearch: false,
  homeAddReview: false,
  smallPaddingMobile: false,
};

Layout.propTypes = {
  onAcceptCookiesAction: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  hideAddReview: PropTypes.bool,
  hideSearch: PropTypes.bool,
  isLandscape: PropTypes.bool,
  homeAddReview: PropTypes.bool,
  smallPaddingMobile: PropTypes.bool,
};

export default connect(null, mapDispatchToProps)(Layout);
