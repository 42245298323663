import styled from 'styled-components';

import { BOOSTRAP_MEDIUM } from 'constants/sizes';
import { WHITE, BLUE_DIPPER } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px 19px;
  border-radius: 6px;
  background-color: ${WHITE}
`;

export const Title = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: ${BLUE_DIPPER};
`;

export const ResultsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const ItemContainer = styled.div`
  text-align: left;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0px;
  padding-top: 10px;
  color: ${BLUE_DIPPER};

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    font-size: 14px;
  }
`;
