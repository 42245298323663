import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';

import GuidelinesText from 'components/commons/GuidelinesText';
import emptyReviewImg from './assets/emptyReview.png';

import {
  ReviewContainer, Title, EmptyReview, Description, ButtonContainer,
  CustomButton, ImageContainer, CustomImage, Container, ReviewEmptyContainer,
} from './styles';

const ReviewEmpty = (props) => {
  const { isCompany } = props;

  const renderNewReviewButtonContainer = () => (
    <ButtonContainer data-testid="new-review-button-container">
      <CustomButton to="/review/new">
        <AddIcon />
        New Review
      </CustomButton>
    </ButtonContainer>
  );

  return (
    <ReviewEmptyContainer>
      <Title>
        New Review
      </Title>
      <ReviewContainer>
        <EmptyReview data-testid="empty-review">
          <Description>
            You have NO REVIEWS yet
          </Description>
          {!isCompany && renderNewReviewButtonContainer()}
          <ImageContainer><CustomImage src={emptyReviewImg} /></ImageContainer>
        </EmptyReview>
        <Container>
          <GuidelinesText />
        </Container>
      </ReviewContainer>
    </ReviewEmptyContainer>
  );
};

ReviewEmpty.propTypes = {
  isCompany: PropTypes.bool.isRequired,
};

export default ReviewEmpty;
