import React from 'react';
import PropTypes from 'prop-types';

import { TABLET_SIZE } from 'constants/sizes';
import { publications } from './constants/publications';

import Publication from './components/Publication';
import Arrow from './components/Arrow';

import {
  Container, StyledCarousel, FirstStarImage, SecondStarImage, ThirdStarImage,
} from './styles';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1074 },
    items: 4,
  },
  tabletBig: {
    breakpoint: { max: 1074, min: TABLET_SIZE },
    items: 3,
  },
  tablet: {
    breakpoint: { max: TABLET_SIZE, min: 600 },
    items: 2,
  },
  mobileMedium: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
  },
};

const Publications = (props) => {
  const { autoPlay } = props;

  return (
    <Container>
      <h1>
        Publications
      </h1>
      <StyledCarousel
        swipeable
        draggable
        showDots={false}
        responsive={responsive}
        ssr={false} // means not to render carousel on server-side.
        infinite
        autoPlay={autoPlay}
        autoPlaySpeed={3000}
        partialVisible
        keyBoardControl
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
        customRightArrow={<Arrow />}
        customLeftArrow={<Arrow reverse />}
      >
        {publications.map((publication) => (
          <Publication publication={publication} key={publication.id} />
        ))}
      </StyledCarousel>
      <FirstStarImage />
      <SecondStarImage />
      <ThirdStarImage />
    </Container>
  );
};

Publications.propTypes = {
  autoPlay: PropTypes.bool.isRequired,
};

export default Publications;
