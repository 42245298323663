export const convertFileToBase64 = (file) => {
  const base64FilePromise = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });
  return base64FilePromise;
};

export async function saveImage(rawFile, data) {
  const picture64 = await convertFileToBase64(data);
  const newValue = {
    src: picture64,
    originalname: rawFile.name || 'image',
    size: rawFile.size,
    mimetype: rawFile.type,
    filename: rawFile.name || 'image',
  };
  return newValue;
}
