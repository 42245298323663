import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';

import * as constants from 'redux/constants/insight.constants';
import { getAll, getRanking } from 'services/insight.services';
import { companyIdSelector } from 'redux/selectors/user.selector';

export function* insightGetAll(action) {
  try {
    const { companySerchId } = action;
    const companyId = companySerchId || (yield select(companyIdSelector));
    const data = yield call(getAll, companyId);
    yield put({ type: constants.INSIGHT_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.INSIGHT_ON_GET_ALL_FAILED, error });
  }
}

export function* insightGetRanking() {
  try {
    const data = yield call(getRanking);
    yield put({ type: constants.INSIGHT_ON_GET_RANKING_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.INSIGHT_ON_GET_RANKING_FAILED, error });
  }
}

export function* watchInsights() {
  yield all([
    takeLatest(constants.INSIGHT_ON_GET_ALL_REQUESTED, insightGetAll),
    takeLatest(constants.INSIGHT_ON_GET_RANKING_REQUESTED, insightGetRanking),
  ]);
}
