export const convertObjectToArray = (object) => Object.entries(object);

export const findByCondition = (array, condition) => array.find(condition);

export const sumArrayByKey = (array, key) => array
  .map((item) => item[key])
  .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

export const parseOptionsForDropdown = (array) => array.map(
  ([key, value]) => ({
    value: key,
    text: value,
  }),
);
