import Afrotech from '../assets/afrotech-logo.svg';
import BlackEnterprise from '../assets/black-enterprise.svg';
import Builtin from '../assets/builtin-logo.png';
import Cnn from '../assets/cnn-logo.svg';
import Entrepreneur from '../assets/entrepreneur-logo.svg';
import Forbes from '../assets/forbes.svg';
import Jopwell from '../assets/jopwell.svg';
import KH from '../assets/kh-logo.svg';
import Mogul from '../assets/mogul-millennial.png';
import TechPoint from '../assets/techpoint.png';
import Grio from '../assets/thegrio.png';

export const publications = [
  {
    id: 1,
    url: 'https://afrotech.com/this-digital-solutions-platform-uses-data-to-promote-diversity-and-inclusion-in-the-workplace',
    logo: Afrotech,
    description: '"The insight that Dipper provides could be a game changer for the workplace."',
  },
  {
    id: 2,
    url: 'https://www.blackenterprise.com/dipper-professionals-of-color-rate-their-companies/',
    logo: BlackEnterprise,
    description: '"A virtual safe space that lets Black and Latinx employees build connections and share their experiences."',
  },
  {
    id: 3,
    url: 'https://builtin.com/women-tech/black-women-in-tech',
    logo: Builtin,
    description: '"These prospective employees were interested in the composition of a company’s workforce..."',
  },
  {
    id: 4,
    url: 'https://edition.cnn.com/interactive/2020/10/health/people-of-color-mental-health-apps-wellness/',
    logo: Cnn,
    description: '"to anonymously or identifiably assess a prospective workplace before threatening their mental health and success..."',
  },
  {
    id: 5,
    url: 'https://www.entrepreneur.com/amphtml/349503',
    logo: Entrepreneur,
    description: '"How minority workers are feeling as unemployment rises, and how they can be the best advocate for themselves now..."',
  },
  {
    id: 6,
    url: 'https://www.forbes.com/sites/kinseycrowley/2018/12/18/how-we-can-help-each-other-overcome-inequality-at-work/?sh=62a8a64c6f23',
    logo: Forbes,
    description: '"In this day and age, the way you present yourself to potential candidates is only one part of the story;"',
  },
  {
    id: 7,
    url: 'https://www.jopwell.com/thewell/posts/a-new-social-platform-creates-a-safe-space-for-pocs-to-share-workplace',
    logo: Jopwell,
    description: '"it’s a forum where you are prompted with thought-provoking questions to encourage honest work stories..."',
  },
  {
    id: 8,
    url: 'https://www.youtube.com/watch?v=R5seqDCHqBA',
    logo: KH,
    description: '"The Dipper concept of providing quantitative and qualitative data is a novel one that will resonate with many."',
  },
  {
    id: 9,
    url: 'https://www.mogulmillennial.com/dipper/',
    logo: Mogul,
    description: '"Creating Dipper to improve diversity and inclusion matters more than ever."',
  },
  {
    id: 10,
    url: 'https://techpoint.africa/2020/03/23/meet-the-women-behind-dipper/',
    logo: TechPoint,
    description: '"These women are driving diversity and inclusion in US workplaces with ‘Dipper’."',
  },
  {
    id: 11,
    url: 'https://thegrio.com/2020/01/13/black-tech-startup-founders-take-on-the-racial-status-quo-in-silicon-valley/',
    logo: Grio,
    description: '"platforms like ‘Our Dipper’ are now rising to turn that equation around for Black and Brown faces."',
  },
];

export default { publications };
