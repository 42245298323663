import styled, { css } from 'styled-components';

import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';
import { GRAY_COMMUNITY_LINES, GREEN_REVIEW } from 'styles/colors';

export const FormGroup = styled.div`
  position: relative;
  flex-direction: column;
  margin-right: 30px;

  ${({ search }) => !!search
    && css`
      width: 100%;
  `}

  @media (max-width: ${TABLET_SIZE}px) {
    margin-right: 0;
  }

  .MuiFormControl-fullWidth {
    @media (max-width: ${MOBILE_SIZE}px) {
      ${({ search }) => !!search
        && css`
          width: fit-content;
      `}

      ${({ searchSelected }) => !!searchSelected
      && css`
        width: 100%;
      `}
    }
  }
`;

export const CustomAutoComplete = styled(Autocomplete)`
  label {
    font: 14px Mulish-Italic;
    
    ${({ search }) => !!search
    && css`
      color: ${GRAY_COMMUNITY_LINES};
    `}

    @media (max-width: ${MOBILE_SIZE}px) {
      font: 16px Mulish-Italic;
      ${({ search }) => !!search
      && css`
        display: none;
      `}

      ${({ searchSelected }) => !!searchSelected
      && css`
        display: flex;
      `}
    }
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    ${({ search }) => !!search
    && css`
      display: flex;
      justify-content: flex-end;
    `}
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 10px !important;
    font: 14px Mulish-Italic;

    @media (max-width: ${MOBILE_SIZE}px) {
      font: 16px Mulish-Italic;
      ${({ search }) => !!search
      && css`
        border-radius: 50px;
      `}

      ${({ searchSelected }) => !!searchSelected
      && css`
        border-radius: 4px;
      `}
    }
  }

  .MuiAutocomplete-input {
    margin-bottom: 5px;
  }
`;

export const CustomSearchIcon = styled(SearchIcon)`
  justify-content: flex-end;
  color: ${GREEN_REVIEW};
`;

export const CustomInputAdornment = styled(InputAdornment)`
  justify-content: flex-end;
`;

export const CustomTextField = styled(TextField)`
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
    @media (max-width: ${MOBILE_SIZE}px) {
      ${({ search }) => !!search
      && css`
        padding: 6px 8px 6px 0px;
      `}
      }
    }

  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    @media (max-width: ${MOBILE_SIZE}px) {
      ${({ search }) => !!search
      && css`
        min-width: 0;
      `}

      ${({ searchSelected }) => !!searchSelected
      && css`
        width: 100%;
      `}
    }
  }

  MuiInputBase-input {
    @media (max-width: ${MOBILE_SIZE}px) {
      ${({ search }) => !!search
      && css`
        width: 0;
      `}

      ${({ searchSelected }) => !!searchSelected
      && css`
        width: 100%;
      `}
    }
  }

  .MuiInputAdornment-positionEnd {
    @media (max-width: ${MOBILE_SIZE}px) {
      ${({ search }) => !!search
      && css`
        margin-left: 0;
      `}
    }
  }
`;
