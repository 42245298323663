import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import { SOCIAL_MEDIAS } from 'constants/socialMedias';
import { BLUE_DIPPER, GRAY_REVIEW_DATE } from 'styles/colors';
import { HelpBlock } from 'styles/commons';

import {
  FormGroup, CustomTextField, Facebook, LinkedIn, Instagram, Email,
} from './styles';

const Input = (props) => {
  const {
    disabled, input, isDateInput, isDarkLabel, isItalic, isPlaceholder, label, maxLength,
    meta: { touched, error }, rows, rowsMax, size, socialMedia, type, variant,
    autoComplete, hasLessPaddingTop, hasMorePaddingTop, hasBackgroundWhite,
    borderRadius, renderStartAdornment,

  } = props;
  const { onChange, value } = input;
  const [showPassword, setShowPassword] = useState(false);
  const [shrink, setShrink] = useState(input.value.length > 0 || type === 'number');
  const isInputPassword = type === 'password';
  const isTextArea = type === 'textarea';
  const hasError = touched && !!error;

  const useStyles = makeStyles(() => ({
    input: {
      '&::placeholder': {
        textAlign: isPlaceholder ? 'left' : 'center',
        fontFamily: 'Mulish-Italic',
        fontSize: '14px',
        '@media (max-width:768px)': { fontSize: '16px !important' },
      },
      color: BLUE_DIPPER,
      fontFamily: isItalic ? 'Mulish-Italic' : 'Mulish',
    },
    cssLabel: {
      color: isDarkLabel ? BLUE_DIPPER : GRAY_REVIEW_DATE,
      textAlign: 'center',
      fontFamily: isItalic ? 'Mulish-Italic' : 'Mulish',
      fontSize: '14px',
      '@media (max-width:768px)': { fontSize: '16px !important' },
    },
    formControl: {
      left: 15,
      top: 2,
    },
  }));
  const classes = useStyles();

  const onChangeValue = (event) => {
    onChange(event.target.value);
  };

  const onFocus = (event) => {
    setShrink(true);
    if (input.onFocus) {
      input.onFocus(event);
    }
  };

  const onBlur = (event) => {
    if (event.target.value.length === 0) {
      setShrink(false);
    }
    if (input.onBlur) {
      input.onBlur(event);
    }
  };

  const getInputType = () => {
    let inputType = type;
    if (isInputPassword) {
      inputType = showPassword ? 'text' : type;
    }
    return inputType;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderSocialMedia = () => {
    switch (socialMedia) {
    case SOCIAL_MEDIAS.email:
      return <Email />;
    case SOCIAL_MEDIAS.facebook:
      return <Facebook />;
    case SOCIAL_MEDIAS.instagram:
      return <Instagram />;
    case SOCIAL_MEDIAS.linkedIn:
      return <LinkedIn />;
    default:
      return '';
    }
  };

  return (
    <FormGroup
      hasLessPaddingTop={hasLessPaddingTop}
      hasMorePaddingTop={hasMorePaddingTop}
      isDateInput={isDateInput}
      type={getInputType()}
    >
      <CustomTextField
        {...input}
        autoComplete={autoComplete}
        data-testid={`input-${label}`}
        disabled={disabled}
        error={hasError}
        isDateInput={isDateInput}
        inputProps={{ maxLength }}
        isItalic={isItalic}
        label={(!isDateInput && !isPlaceholder) && label}
        multiline={isTextArea}
        multiple
        onChange={onChangeValue}
        placeholder={(isPlaceholder || isDateInput) && label}
        rows={rows}
        rowsMax={rowsMax}
        size={size}
        type={getInputType()}
        value={value}
        variant={variant}
        onKeyPress={({ charCode, target }) => charCode === 13 && target.blur()}
        onFocus={onFocus}
        onBlur={onBlur}
        hasBackgroundWhite={hasBackgroundWhite}
        borderRadius={borderRadius}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          endAdornment:
            isInputPassword
            && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-testid="toggle-password-button"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          startAdornment:
            renderStartAdornment ? (
              <InputAdornment position="end">
                {renderStartAdornment()}
              </InputAdornment>
            )
              : !!socialMedia
            && (
              <InputAdornment data-testid="social-media-icon" position="end">
                {renderSocialMedia()}
              </InputAdornment>
            ),
          classes: { input: classes.input, underline: classes.underline },
          inputProps: { maxLength, min: '0' },
        }}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            formControl:
              (!!socialMedia || !!renderStartAdornment) && !shrink && classes.formControl,
          },
          shrink,
        }}
      />
      <HelpBlock data-testid="help-block" hasPaddingBottom={hasError && isDateInput}>{(touched && error) || ' '}</HelpBlock>
    </FormGroup>
  );
};

Input.defaultProps = {
  autoComplete: 'off',
  borderRadius: '4px',
  disabled: false,
  hasLessPaddingTop: false,
  hasMorePaddingTop: false,
  hasBackgroundWhite: false,
  isDateInput: false,
  isDarkLabel: false,
  isItalic: false,
  isPlaceholder: false,
  label: '',
  maxLength: 255,
  renderStartAdornment: null,
  rows: 3,
  rowsMax: 3,
  size: 'medium',
  socialMedia: '',
  type: 'text',
  variant: 'outlined',
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  borderRadius: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
  }).isRequired,
  hasLessPaddingTop: PropTypes.bool,
  hasMorePaddingTop: PropTypes.bool,
  hasBackgroundWhite: PropTypes.bool,
  isDateInput: PropTypes.bool,
  isDarkLabel: PropTypes.bool,
  isItalic: PropTypes.bool,
  isPlaceholder: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.string.isRequired,
    touched: PropTypes.bool.isRequired,
  }).isRequired,
  renderStartAdornment: PropTypes.func,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  size: PropTypes.string,
  socialMedia: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
};

export default Input;
