import React from 'react';

import DefaultProfileComp from 'components/commons/assets/avatar-company.svg';

import { jobProptypes } from 'helpers/propTypes';
import { timePassedSince } from 'helpers/dates';

import { employeeTypes } from 'constants/jobs';
import {
  DateDiv, EmployeeType, Featured, HeaderContainer, IconLocation, JobContainer,
  JobPosition, Location, ProfilePicture, Row, RowContainer, ProfileImageContainer,
} from './styles';

const Job = (props) => {
  const {
    job,
  } = props;

  return (
    <JobContainer>
      <RowContainer>
        <ProfileImageContainer>
          <ProfilePicture
            src={job.company.logo ? job.company.logo.path : DefaultProfileComp}
          />
        </ProfileImageContainer>
        <HeaderContainer>
          <JobPosition>
            {job.jobPosition}
          </JobPosition>
        </HeaderContainer>
        {job.isFeatured
          && (
            <Featured>
              Featured
            </Featured>
          )}
      </RowContainer>
      <Location>
        <IconLocation />
        {job.location}
      </Location>
      <Row>
        <EmployeeType>
          {employeeTypes[job.employeeType]}
        </EmployeeType>
        <DateDiv>
          {timePassedSince(job.dateUpdated)}
        </DateDiv>
      </Row>
    </JobContainer>
  );
};

Job.propTypes = {
  job: jobProptypes.isRequired,
};

export default Job;
