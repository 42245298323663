import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import * as constants from 'redux/constants/user.constants';
import * as appConstants from 'redux/constants/app.constants';
import * as feedConstants from 'redux/constants/feed.constants';
import * as reviewConstants from 'redux/constants/review.constants';
import {
  confirmAccount, contact, initialize, forgotPassword, login, googleLogin, googleSignup,
  register, update, updatePassword, resetPassword, closeOnBoarding,
} from 'services/user.services';
import { getCookie } from 'helpers/cookies';

export function* userConfirmAccount(action) {
  try {
    const data = yield call(confirmAccount, action.token, action.password, action.companyName);
    yield put({ type: constants.USER_ON_ACTIVATE_ACCOUNT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_ACTIVATE_ACCOUNT_FAILED, error });
  }
}

export function* userInitialize() {
  try {
    if (getCookie('accessToken')) {
      const data = yield call(initialize);
      yield put({ type: constants.USER_ON_INITIALIZE_SUCCEEDED, data });
    } else {
      yield put({ type: constants.USER_ON_INITIALIZE_FAILED });
    }
  } catch (error) {
    yield put({ type: constants.USER_ON_INITIALIZE_FAILED, error });
  }
}

export function* userLogin(action) {
  try {
    const { formData, pathname } = action;
    const data = yield call(login, formData);
    yield put({ type: constants.USER_ON_LOGIN_SUCCEEDED, data, pathname });
  } catch (error) {
    yield put({ type: constants.USER_ON_LOGIN_FAILED, error });
  }
}

export function* userGoogleLogin(action) {
  try {
    const { credentials, pathname } = action;
    const data = yield call(googleLogin, credentials);
    yield put({ type: constants.USER_ON_LOGIN_SUCCEEDED, data, pathname });
  } catch (error) {
    yield put({ type: constants.USER_ON_LOGIN_FAILED, error });
  }
}

export function* userRegister(action) {
  try {
    const data = yield call(register, action.formData);
    yield put({ type: constants.USER_ON_REGISTER_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_REGISTER_FAILED, error });
  }
}

export function* userGoogleSignup(action) {
  try {
    const data = yield call(googleSignup, action.credentials);
    yield put({ type: constants.USER_ON_REGISTER_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_REGISTER_FAILED, error });
  }
}

export function* userUpdate(action) {
  try {
    const data = yield call(update, action.formData);
    yield put({ type: constants.USER_ON_UPDATE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_UPDATE_FAILED, error });
  }
}

export function* userForgotPassword(action) {
  try {
    const data = yield call(forgotPassword, action.formData);
    yield put({ type: constants.USER_ON_FORGOT_PASSWORD_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_FORGOT_PASSWORD_FAILED, error });
  }
}

export function* userContact(action) {
  try {
    const data = yield call(contact, action.formData);
    yield put({ type: constants.USER_ON_CONTACT_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_CONTACT_FAILED, error });
  }
}

export function* userUpdatePassword(action) {
  try {
    const data = yield call(updatePassword, action.formData);
    yield put({ type: constants.USER_ON_UPDATE_PASSWORD_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_UPDATE_PASSWORD_FAILED, error });
  }
}

export function* userResetPassword(action) {
  try {
    const data = yield call(resetPassword, action.formData);
    yield put({ type: constants.USER_ON_RESET_PASSWORD_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_RESET_PASSWORD_FAILED, error });
  }
}

export function* userCloseOnBoarding() {
  try {
    const data = yield call(closeOnBoarding);
    yield put({ type: constants.USER_ON_CLOSE_ONBOARDING_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USER_ON_CLOSE_ONBOARDING_FAILED, error });
  }
}

export function* watchUsers() {
  yield all([
    takeLatest(
      [
        appConstants.APP_ON_INITIALIZE_REQUESTED,
        constants.USER_ON_LOGIN_SUCCEEDED,
        feedConstants.FEED_ON_ADD_SUCCEDED,
        feedConstants.FEED_ON_ADD_COMMENT_SUCCEDED,
        reviewConstants.REVIEW_ON_ADD_SUCCEDED,
      ],
      userInitialize,
    ),
    takeLatest(constants.USER_ON_ACTIVATE_ACCOUNT_REQUESTED, userConfirmAccount),
    takeLatest(constants.USER_ON_LOGIN_REQUESTED, userLogin),
    takeLatest(constants.USER_ON_GOOGLE_LOGIN_REQUESTED, userGoogleLogin),
    takeLatest(constants.USER_ON_GOOGLE_SIGNUP_REQUESTED, userGoogleSignup),
    takeLatest(constants.USER_ON_REGISTER_REQUESTED, userRegister),
    takeLatest(constants.USER_ON_UPDATE_REQUESTED, userUpdate),
    takeLatest(constants.USER_ON_FORGOT_PASSWORD_REQUESTED, userForgotPassword),
    takeLatest(constants.USER_ON_CONTACT_REQUESTED, userContact),
    takeLatest(constants.USER_ON_UPDATE_PASSWORD_REQUESTED, userUpdatePassword),
    takeLatest(constants.USER_ON_RESET_PASSWORD_REQUESTED, userResetPassword),
    takeLatest(constants.USER_ON_CLOSE_ONBOARDING_REQUESTED, userCloseOnBoarding),
  ]);
}
