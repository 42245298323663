import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

import Logo from 'components/SideMenu/Logo';

import { INSTAGRAM_LINK, LINKEDIN_LINK, FACEBOOK_LINK } from 'constants/socialMedias';
import COPYRIGHT from 'constants/footer';

import {
  FooterSection, FooterContainer, FooterCopyright, Wrapper, LogoDiv, FooterContent,
  FooterAboutBox, FooterLink, SocialMedia, Facebook, Instagram, LinkedIn, Last,
} from './styles';

const Footer = () => (
  <FooterSection data-testid="footer-component">
    <Wrapper>
      <FooterContainer>
        <LogoDiv>
          <Logo open whiteLogo />
          <FooterCopyright>{COPYRIGHT}</FooterCopyright>
        </LogoDiv>
        <FooterContent>
          <FooterAboutBox>
            <ul data-testid="about-list">
              <li>
                <NavHashLink smooth to="/home#how-it-works">
                  <FooterLink>
                    How It Works
                  </FooterLink>
                </NavHashLink>
              </li>
              <li>
                <NavHashLink smooth to="/home#jobs">
                  <FooterLink>
                    Search A Job
                  </FooterLink>
                </NavHashLink>
              </li>
              <li>
                <NavHashLink smooth to="/home#about">
                  <FooterLink>
                    About Us
                  </FooterLink>
                </NavHashLink>
              </li>
              <Last>
                <Link smooth to="/legal">
                  <FooterLink>
                    Privacy Policy
                  </FooterLink>
                </Link>
              </Last>
            </ul>
          </FooterAboutBox>
          <SocialMedia data-testid="social-media-icons">
            <a target="_blank" rel="noopener noreferrer" href={LINKEDIN_LINK}>
              <LinkedIn />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={INSTAGRAM_LINK}>
              <Instagram />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={FACEBOOK_LINK}>
              <Facebook />
            </a>
          </SocialMedia>
          <FooterCopyright last>{COPYRIGHT}</FooterCopyright>
        </FooterContent>
      </FooterContainer>
    </Wrapper>
  </FooterSection>
);

export default Footer;
