import React from 'react';

import * as constants from './constants';
import {
  ReviewContainer, Wrapper, ContentContainer, ReviewPicture, Title, Description,
  ButtonReview, Text, ButtonContainer,
} from './styles';

const LandingReview = () => (
  <ReviewContainer>
    <ReviewPicture alt="placeholder" />
    <Wrapper>
      <ContentContainer>
        <Title>
          Reviews
        </Title>
        <Description>
          <Text>
            {constants.FIRST_TEXT}
          </Text>
          <Text last>
            {constants.SECOND_TEXT}
          </Text>
        </Description>
        <ButtonContainer>
          <ButtonReview href="/reviews">
            View more info
          </ButtonReview>
        </ButtonContainer>
      </ContentContainer>
    </Wrapper>
  </ReviewContainer>
);

export default LandingReview;
