import React from 'react';
import PropTypes from 'prop-types';

import AddIcon from '@material-ui/icons/Add';

import {
  NewReviewSection, NewReviewTitle, NewReviewText,
  NewReviewButton, NewReviewCustomImage, ButtonContainer,
} from './styles';

const ExperienceAtWorkBanner = (props) => {
  const { isCompanyProfile } = props;

  return (
    <NewReviewSection hasOnlyMarginBottom={isCompanyProfile}>
      <NewReviewTitle>
        Tell Us About Your Experiences At Work:
      </NewReviewTitle>
      <NewReviewText>
        Write a helpful review to share with the community.
      </NewReviewText>
      <ButtonContainer>
        <NewReviewButton to="/review/new">
          <AddIcon />
          New Review
        </NewReviewButton>
      </ButtonContainer>
      <NewReviewCustomImage />
    </NewReviewSection>
  );
};

ExperienceAtWorkBanner.defaultProps = {
  isCompanyProfile: false,
};

ExperienceAtWorkBanner.propTypes = {
  isCompanyProfile: PropTypes.bool,
};

export default ExperienceAtWorkBanner;
