import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { userPropTypes } from 'helpers/propTypes';

import {
  DivProfile, Images, ProfilePicture, EditProfileDiv, EditProfile, UserName, CompanyRole,
  Media, CountMedia,
} from './styles';

const ProfileDashboard = (props) => {
  const { user, isCompany } = props;

  return (
    <DivProfile data-testid="div-profile">
      <Images>
        <ProfilePicture src={user.profileImage} alt="Profile image" />
        <EditProfileDiv>
          <Link to="/profile">
            <EditProfile />
          </Link>
        </EditProfileDiv>
      </Images>
      <UserName>
        {user.name}
      </UserName>
      {(!isCompany && !!user.jobPosition) && <CompanyRole>{user.jobPosition}</CompanyRole>}
      <Media>
        <CountMedia>
          Reviews
          <p>{user.amountOfReviews}</p>
        </CountMedia>
        <CountMedia>
          Comments
          <p>{user.amountOfComments}</p>
        </CountMedia>
        <CountMedia>
          Posts
          <span>{user.amountOfFeeds}</span>
        </CountMedia>
      </Media>
    </DivProfile>
  );
};

ProfileDashboard.defaultProps = {
  isCompany: false,
};

ProfileDashboard.propTypes = {
  isCompany: PropTypes.bool,
  user: userPropTypes.isRequired,
};

export default ProfileDashboard;
