import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  SMALL_DESKTOP_SIZE_WITH_PADDING, BOOSTRAP_MEDIUM, MOBILE_SIZE, SMALL_MOBILE_SIZE,
  TABLET_SIZE,
} from 'constants/sizes';
import {
  BLUE_DARK, DIPPER_BLUE, GOOGLE_RED, GREEN_REVIEW, WHITE,
} from 'styles/colors';
import { GreenButton } from 'styles/commons';

import { ReactComponent as LogoGoogle } from './assets/logo-google.svg';
import signInImg from './assets/signInImage.png';

export const Container = styled.div`
  background-image: url(${signInImg});
  background-position: center top;
  background-repeat: no-repeat;

  @media (max-width: ${BOOSTRAP_MEDIUM}px){
    background-image: none;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 60px auto auto;
  max-width: 1024px;

  @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
    margin: 60px 25px auto;
  }
  
  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    ${({ isPortraitMenuOpened }) => (isPortraitMenuOpened && css`
      box-shadow: 0px 3px 6px #00000017;
      opacity: 1;
      filter: blur(3px);
      -webkit-filter: blur(3px);    
    `)}
  }
  
  @media (max-width: ${MOBILE_SIZE}px) {
    margin-top: 48px;
  }
`;

export const CardBox = styled.div`
  width: 50%;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
  margin-bottom: 30px;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    width: 80%;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
  }
`;

export const Form = styled.form`
  width: 100%;
  color: ${DIPPER_BLUE};
`;

export const Title = styled.h1`
  text-align: left;
  text-align: flex-start;
  color: ${BLUE_DARK};
  margin: 32px 0 10px 0;
  font-size: 26px;
  font-weight: bold;
  line-height: 34px;
`;

export const OneFieldOnLineContainer = styled.div`
  display: flex;
`;

export const ForgotPasswordContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

export const ForgotPasswordLink = styled.span`
  font-size: 13px;
  color: ${GREEN_REVIEW};
  cursor: pointer;

  :hover {
    color: ${DIPPER_BLUE};
  }
`;

export const OrContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font: normal normal normal 18px/35px Mulish;
  color: ${DIPPER_BLUE};
`;

export const Line = styled.div`
  margin: 0px 10px;
  width: 20%; 
  align-self: center;
  border-bottom: 1px solid ${GREEN_REVIEW};

  @media (max-width: ${MOBILE_SIZE}px) {
    width: 100%;
    flex: 0 0 50%;
  }
`;

export const GoogleSection = styled.div`
  padding: 37px 20px 24px;
  justify-content: center;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    padding: 37px 0 24px
  }
`;

export const GoogleButton = styled(Button)`
  && {
    border:1px solid ${GOOGLE_RED};
    padding: 10px 34px;
    font: normal normal bold 14px/18px Mulish;
    text-transform: none;
    background: ${GOOGLE_RED};
    color: ${WHITE};
    fill: ${WHITE};

    :hover {
      color: ${GOOGLE_RED};
      background: transparent;
      fill: ${GOOGLE_RED};
    }

    @media (max-width: ${SMALL_MOBILE_SIZE}px) {
      width: 100%;
    }
  }
`;

export const GoogleLogo = styled(LogoGoogle)`
  margin: 0px 5px;
`;

export const SmallText = styled.div`
  font-size: 13px;
  color: ${DIPPER_BLUE};
  display: flex;
  flex-direction: row;
`;

export const StyledAnchor = styled(Link)`
  margin: 0px 5px;
  color: ${GREEN_REVIEW};

  :hover {
    color: ${DIPPER_BLUE};
  }
`;

export const ImageContainer = styled.div`
  width: 50%;

  @media (max-width: ${BOOSTRAP_MEDIUM}px) {
    display: none;
  }
`;

export const CustomButton = styled(GreenButton)`
  && {
    padding: 10px 90px;
    font: normal normal bold 14px/18px Mulish;
  }
`;

export const ButtonContainer = styled.div`
  margin: 37px 20px;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    margin: 37px 0;
  }
`;
