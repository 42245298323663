import styled from 'styled-components';
import { SMALL_DESKTOP_SIZE, TABLET_SIZE } from 'constants/sizes';

import { DIPPER_BLUE, GRAY_COMMUNITY } from 'styles/colors';

export const RightTopText = styled.div`
  color: ${DIPPER_BLUE};
  border-bottom: 1px solid ${GRAY_COMMUNITY};

  h4 {
    font: normal normal bold 20px/25px Mulish;
    margin-bottom: 25px;
  }

  p {
    font: 12px/16px Mulish;
  }
`;

export const RightBottomText = styled.div`
  color: ${DIPPER_BLUE};
  margin-top: 16px; 
  font: 12px/16px Mulish;

  p {
    margin-bottom: 5px;
  }

  li {
    padding-bottom: 8px;
  }
`;

export const Container = styled.div`
  padding-left: 15px;

  @media (max-width: ${SMALL_DESKTOP_SIZE}px) {
    padding-bottom: 130px;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    padding: 0;
  }
`;
