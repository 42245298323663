import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';

import { ReactComponent as FacebookIcon } from 'components/commons/assets/FacebookIcon.svg';
import { ReactComponent as LinkedInIcon } from 'components/commons/assets/LinkedinIcon.svg';
import { ReactComponent as InstagramIcon } from 'components/commons/assets/InstagramIcon.svg';
import { ReactComponent as EmailIcon } from 'components/commons/assets/EmailIcon.svg';

import { GRAY_COMMUNITY_LINES, GRAY_BACKGROUND_HOVER, WHITE } from 'styles/colors';
import { MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';

export const CustomTextField = styled(TextField)`
  height: fit-content;
  width: ${({ type }) => (type === 'radio' ? '20px' : '100%')};
  margin-bottom: 0px !important;
  ${({ hasBackgroundWhite }) => (hasBackgroundWhite && `background: ${WHITE};`)};
  border-radius: ${({ borderRadius }) => (borderRadius)} !important;

  label {
    display: flex;
    font-family: ${({ isItalic }) => (isItalic ? 'Mulish-Italic' : 'Mulish')};
    font-size: 14px;
    span {
      display: none;
    }
  }
  
  .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }

  .MuiOutlinedInput-inputMarginDense {
    margin-bottom: 2px;
  }

  .MuiOutlinedInput-root {
    font-family: 'Mulish';
    border-radius: ${({ borderRadius }) => (borderRadius)} !important;
  }

  .MuiInput-underline:before {
    border-bottom-color: ${GRAY_BACKGROUND_HOVER}; 
  }

.MuiInputBase-input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill::first-line,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px white inset !important;
      background-color: white;
      background-clip: content-box !important;
      font-size: 16px;
    }
  }

  ${({ type }) => (type === 'radio'
    && css`
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
  `)};

  ${({ isDateInput }) => (!!isDateInput
    && css`
      .MuiOutlinedInput-input {
        padding: 10px 0px;
        text-align: center;
        margin-bottom: 0;
      }
  `)};

  ${({ type }) => (type === 'textarea'
      && css`
      .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
        max-height: 110px;
      }
  `)}
`;

export const FormGroup = styled.div`
  position: relative;
  flex-direction: column;
  padding-bottom: 5px;

  ${({ isDateInput }) => (!!isDateInput
    && css`
      width: 65px;

      @media (max-width: ${TABLET_SIZE}px) {
        width: 50px; 
      }
    `)};


  ${({ type }) => (type === 'radio'
    ? css`
        padding-top: 3px;
        margin-bottom: 10px;
      `
    : css`
        padding-top: ${({ hasLessPaddingTop }) => (hasLessPaddingTop ? '10' : '20')}px;
        padding-top: ${({ hasMorePaddingTop }) => (hasMorePaddingTop ? '40' : '20')}px;
        margin-bottom: 0;
        width: 100%;

        @media (max-width: ${MOBILE_SIZE}px) {
          padding-top: ${({ hasLessPaddingTop }) => (hasLessPaddingTop ? '20' : '40')}px;
        }
      `)
};

  @media (max-width: ${TABLET_SIZE}px) {
    ${({ type, isDateInput }) => (type !== 'radio' && !!isDateInput && css`
      padding-top: 0;
      margin-bottom: 0;
    `)};
  }
`;

export const LinkedIn = styled(LinkedInIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 16px;
  height: 16px;
  margin-right: 7px;
`;

export const Facebook = styled(FacebookIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 16px;
  height: 16px;
  margin-right: 7px;
`;

export const Instagram = styled(InstagramIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 16px;
  height: 16px;
  margin-right: 7px;
`;

export const Email = styled(EmailIcon)`
  fill: ${GRAY_COMMUNITY_LINES};
  width: 15px;
  height: 11px;
  margin-right: 7px;
`;
