import * as constants from 'redux/constants/company.constants';

export function onCompaniesGetAll() {
  return {
    type: constants.COMPANY_GET_ALL_REQUESTED,
  };
}

export function onCompanyUpdateRequested(formData) {
  return {
    type: constants.COMPANY_ON_UPDATE_REQUESTED,
    formData,
  };
}

export const onGetRank = (companySerchId) => ({
  type: constants.COMPANY_ON_GET_RANK_REQUESTED,
  companySerchId,
});

export const onEmptyCompanyState = () => ({
  type: constants.COMPANY_ON_EMPTY_STATE_REQUESTED,
});

export const onLeaveCompanyView = () => ({
  type: constants.COMPANY_ON_EMPTY_STATE_REQUESTED,
});
