import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DefaultProfileComp from 'components/commons/assets/avatar-company.svg';

import { jobProptypes } from 'helpers/propTypes';
import { getStringMonthNameDayYear, isNewDate, timePassedSince } from 'helpers/dates';

import { employeeTypes } from 'constants/jobs';

import {
  Bullet, ButtonsContainer, CompanyName, CompanyNameAndDateContainer, CustomLi, CustomUl,
  CustomButton, DateDiv, Description, Featured, HeaderContainer, LocationAndDateContainer,
  IconLocation, Item, JobContainer, JobSection, JobTitle, Location, NewText, ProfilePicture, Row,
  RowElement, EditButton, VerticalLine,
  DeleteButton,
} from './styles';

const JobDetails = (props) => {
  const {
    isCompany, job, onDelete,
  } = props;

  const renderEditAndDeleteButtons = () => (
    <>
      <Link
        to={{
          pathname: '/jobs/new',
          state: { job },
        }}
      >
        <EditButton />
      </Link>
      <VerticalLine />
      <DeleteButton onClick={(e) => { onDelete(job.id); e.stopPropagation(); }} />
    </>
  );

  const renderDescriptionsBullets = (description) => (
    <CustomUl>
      {description.split(';').map((bullet) => (
        <CustomLi>
          <Bullet />
          {bullet}
        </CustomLi>
      ))}
    </CustomUl>
  );

  const renderPoCHeader = () => (
    <Row>
      <ProfilePicture
        src={job.company.logo ? job.company.logo.path : DefaultProfileComp}
      />
      <HeaderContainer>
        <CompanyNameAndDateContainer>
          <JobTitle>
            {job.jobPosition}
          </JobTitle>
          {job.isFeatured
            && (
              <Featured>
                Featured
              </Featured>
            )}
        </CompanyNameAndDateContainer>
        <CompanyName to={`/company/${job.company.code}`}>
          {job.company.name}
        </CompanyName>
        <LocationAndDateContainer>
          <Location>
            <IconLocation />
            {job.location}
          </Location>
          {isNewDate(job.dateUpdated) && <NewText>New</NewText>}
          <DateDiv>
            {timePassedSince(job.dateUpdated)}
          </DateDiv>
        </LocationAndDateContainer>
      </HeaderContainer>
    </Row>
  );

  const renderCompanyHeader = () => (
    <Row isCompany>
      <HeaderContainer isCompany>
        <CompanyNameAndDateContainer>
          <JobTitle>
            {job.jobPosition}
          </JobTitle>
          <DateDiv isCompany>
            {getStringMonthNameDayYear(job.dateUpdated)}
          </DateDiv>
        </CompanyNameAndDateContainer>
        <CompanyNameAndDateContainer isCompany>
          <Location>
            <IconLocation />
            {job.location}
          </Location>
          <>
            <ButtonsContainer>
              {renderEditAndDeleteButtons()}
            </ButtonsContainer>
          </>
        </CompanyNameAndDateContainer>
      </HeaderContainer>
    </Row>
  );

  return (
    <JobContainer isCompany={isCompany}>
      {!!job && (
        <JobSection>
          {isCompany ? renderCompanyHeader() : renderPoCHeader()}
          <Row withBorder>
            <RowElement>
              <h2>Employee type</h2>
              <h1>{employeeTypes[job.employeeType]}</h1>
            </RowElement>
            <RowElement>
              <h2>Annual salary range</h2>
              <h1>{job.fromSalary ? `${job.fromSalary} - ${job.toSalary}` : 'Pay range unavailable'}</h1>
            </RowElement>
          </Row>
          <Item>
            <h1>Overview</h1>
            <h2>{job.overview}</h2>
          </Item>
          <Description>
            <h1>Job description</h1>
            {renderDescriptionsBullets(job.description)}
          </Description>
          <Item>
            <h1>Contact information</h1>
            <a
              href={`mailto:${job.contactEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {job.contactEmail}
            </a>
          </Item>
          {isCompany
          && (
            <CustomButton to={{
              pathname: '/jobs/new',
              state: { job },
            }}
            >
              Edit Job
            </CustomButton>
          )}
        </JobSection>
      )}
    </JobContainer>
  );
};

JobDetails.propTypes = {
  isCompany: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  job: jobProptypes.isRequired,
};

export default JobDetails;
