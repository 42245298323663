import { post, get, put } from 'services/api';
import { saveImage } from 'helpers/images';

export async function confirmAccount(token, password, companyName) {
  const data = {
    password,
    companyName,
  };
  const response = await put(`users/activate?token=${token}`, data);
  return response;
}

export async function initialize() {
  const response = await get('users/profile');
  return response;
}

export async function login(formData) {
  const response = await post(`users/${formData.platformId ? 'loginSSO' : 'login'}`, formData);
  return response;
}

export async function googleLogin(credentials) {
  const response = await post('users/loginSSO', credentials);
  return response;
}

export async function register(formData) {
  const data = { ...formData };
  const { profilePic } = data;
  if (profilePic) {
    const blob = await fetch(profilePic).then((r) => r.blob());
    const file = { path: data.profilePic, rawFile: blob };
    if (file.rawFile) {
      data.profilePic = await saveImage(file.rawFile, file);
    }
  }
  const response = await post('users/register', data);
  return response;
}

export async function googleSignup(credentials) {
  const response = await post('users/register-google', credentials);
  return response;
}

export async function update(formData) {
  const data = { ...formData };
  const { rawFile } = data.profilePic;
  if (rawFile) {
    data.profilePic = await saveImage(rawFile, data.profilePic);
  }
  const response = await put('users/update', data);
  return response;
}

export async function forgotPassword(formData) {
  const response = await post('users/forgotPassword', formData);
  return response;
}

export async function contact(formData) {
  const response = await post('users/contact', formData);
  return response;
}

export async function updatePassword(formData) {
  const response = await put('users/updatePassword', formData);
  return response;
}

export async function resetPassword(formData) {
  const response = await post('users/resetPassword', formData);
  return response;
}

export async function closeOnBoarding() {
  const response = await post('users/completeOnBoarding');
  return response;
}
