import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import companyName from 'helpers/company';

import { onCloseOnBoarding } from 'redux/actions/user.actions';
import { formatCompaniesList } from 'redux/selectors/company.selector';
import { clientHistory } from 'helpers/history';

import {
  companyPropTypes,
  feedPropTypes, insightsArrayPropTypes, jobsArrayPropTypes, userPropTypes,
} from 'helpers/propTypes';

import CustomModal from 'components/commons/Modal';
import OnBoardingModal from 'components/OnBoardingModal';

import DashboardPocView from 'views/DashboardPoC';

const DashboardPoC = (props) => {
  const {
    amountOfJobs, companiesList, feedsList, insights, jobsList, onSubmitAddComment, user,
    onCloseOnBoardingModal,
  } = props;

  const onSearchCompany = (newValue) => {
    const valueHelper = companyName(newValue, companiesList);
    if (newValue) {
      clientHistory.push(`/company/${valueHelper?.key}`);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(user.showOnBoarding);

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <DashboardPocView
        amountOfJobs={amountOfJobs}
        feedsList={feedsList}
        user={user}
        insights={insights}
        jobsList={jobsList}
        onSubmitAddComment={onSubmitAddComment}
        onSearch={onSearchCompany}
        companiesList={companiesList}
      />
      {user.showOnBoarding
        && (
          <CustomModal
            isOpen={isModalOpen}
            onClose={onCloseModal}
          >
            <OnBoardingModal
              onFinishOnBoarding={onCloseOnBoardingModal}
            />
          </CustomModal>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  amountOfJobs: state.job.amountOfJobs,
  companiesList: formatCompaniesList(state),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseOnBoardingModal: bindActionCreators(onCloseOnBoarding, dispatch),
});

DashboardPoC.propTypes = {
  amountOfJobs: PropTypes.number.isRequired,
  companiesList: PropTypes.arrayOf(companyPropTypes).isRequired,
  feedsList: PropTypes.arrayOf(
    feedPropTypes,
  ).isRequired,
  insights: insightsArrayPropTypes.isRequired,
  jobsList: jobsArrayPropTypes.isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
  user: userPropTypes.isRequired,
  onCloseOnBoardingModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPoC);
