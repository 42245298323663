import styled, { css } from 'styled-components';
import { Button } from '@material-ui/core';
import {
  BLACK, GRAY, WHITE_BACKGROUND, GREEN_SELECTED_NAVBAR,
  GREEN_REVIEW, WHITE, LIGHT_GREEN, BLUE_NAVBAR_ITEMS, GRAY_COMMUNITY_LINES,
} from 'styles/colors';
import { TABLET_SIZE } from 'constants/sizes';

export const PortraitItems = styled.div`
  font-size: 20px;
  color: ${GRAY};
  font-weight: 250;
  text-transform: uppercase;
  
  a:hover span {
    color: ${BLACK};
  }
`;

export const Item = styled.span`
  cursor: pointer;
`;

export const Divlink = styled.div`
  font-size: 14px;
  text-transform: initial;
  font-weight: ${({ isLandscape }) => (isLandscape ? 'bold' : 'regular')};
  margin: auto;
  ${({ isLandscape }) => (isLandscape
    ? css`
    display: flex;
    `
    : css`
    :hover {
      color: ${GREEN_REVIEW};
    }
    `)
}

  ${({ open }) => (
    open
      ? css`
        height: 100%;
        padding: 0 25px;
        display: flex;
        flex: 1;
        ${({ isLandscape }) => isLandscape && css`
          display: flex;
        `}
      `
      : css`
        display: flex;
        justify-content: center;
        padding: 5px 6px;
      `
  )};

  @media (max-width: ${TABLET_SIZE}px) {
    padding: 12px 0 10px;
    margin: 0;
    justify-content: center;
    display: flex;
    flex: 1;
    color: ${({ isLandscape }) => (isLandscape ? WHITE : BLUE_NAVBAR_ITEMS)};
  }
`;

export const LandscapeItems = styled.div`
  li {
    padding: 10px 0;
  }

  li:hover span {
    color: ${BLACK};
  }

  li a {
    fill: ${GRAY_COMMUNITY_LINES} !important;
    color: ${GRAY_COMMUNITY_LINES} !important;

    :hover {
      fill: ${WHITE} !important;
      color: ${WHITE} !important;
    }
  }

  li a.active { 
    color: ${WHITE_BACKGROUND} !important;
    fill: ${WHITE_BACKGROUND} !important;
    background-color: ${GREEN_SELECTED_NAVBAR};
  }

  @media (max-width: ${TABLET_SIZE}px) {
    li {
      padding: 0;
      width: 25%;
    }

    li a {
      color: ${WHITE_BACKGROUND} !important;
      fill: ${WHITE_BACKGROUND} !important;
    }

    li a.active { 
      color: ${WHITE_BACKGROUND} !important;
      fill: ${WHITE_BACKGROUND} !important;
      background-color: ${GREEN_SELECTED_NAVBAR};
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ open }) => (open ? '0 5px' : '10px')};

  @media (max-width: ${TABLET_SIZE}px) {
    margin: 0;
    .MuiSvgIcon-root {
      font-size: 30px;
     }

    #Insights {
      width: 28px;
      height: 28px;
    }
  }
`;

export const UserLogOut = styled.span`
  cursor: pointer;
`;

export const ButtonStyle = styled(Button)`
  && {
    height: 40px;
    width: 120px;
    align-text: center;
    margin-left: 0px;
    color: ${WHITE};
    
    .MuiButton-label {
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
      color: ${({ isOutlined }) => (isOutlined ? WHITE : GREEN_REVIEW)};
      padding: 0px;
      text-transform: initial;
    }

    ${({ isOutlined }) => (
    isOutlined
      ? css`
          padding: 0 26px 0 24px;
          justify-content: flex-start;
          background: ${GREEN_REVIEW};
          
          :hover {
            background: ${LIGHT_GREEN};
          }
        `
      : css`
          padding: 5px 6px;
          justify-content: center;
          background: ${WHITE};
          border: 1px solid ${GREEN_REVIEW};
          margin-right: 10px;
        `
  )};

    @media (max-width: ${TABLET_SIZE}px) {
      width: 160px;
      margin-left: 10px;

      ${({ isOutlined }) => (!isOutlined && css`
        margin: 30px 0px 23px 10px;
      `)};
    }
  }
`;

export const ButtonsNotLogIn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${TABLET_SIZE}px) {
    flex-direction: column;
    text-align: center;
  }
`;
