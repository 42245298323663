import { clientHistory } from 'helpers/history';

import * as constants from 'redux/constants/user.constants';
import * as jobConstants from 'redux/constants/job.constants';
import * as reviewConstants from 'redux/constants/review.constants';

const redirectMiddleware = () => (next) => (action) => {
  switch (action.type) {
  case constants.USER_ON_LOGIN_SUCCEEDED:
  case constants.USER_ON_ACTIVATE_ACCOUNT_SUCCEEDED:
    clientHistory.push(action.pathname || '/dashboard');
    break;
  case constants.USER_ON_LOGOUT:
  case constants.USER_ON_REGISTER_SUCCEEDED:
  case constants.USER_ON_ACTIVATE_ACCOUNT_FAILED:
    clientHistory.push('/home');
    break;
  case jobConstants.JOB_ON_ADD_OFFER_SUCCEDED:
  case jobConstants.JOB_ON_UPDATE_SUCCEDED:
    clientHistory.push('/jobs');
    break;
  case reviewConstants.REVIEW_ON_ADD_SUCCEDED:
  case reviewConstants.REVIEW_ON_UPDATE_SUCCEDED:
    clientHistory.push('/reviews');
    break;
  default:
    break;
  }
  return next(action);
};

export default redirectMiddleware;
