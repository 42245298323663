import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import * as constants from 'redux/constants/job.constants';
import * as appConstants from 'redux/constants/app.constants';
import * as userConstants from 'redux/constants/user.constants';

import {
  getAllPoc, getAllForDashboard, getAllFromCompany, addNewJobOffer, updateJobOffer, deleteJob,
} from 'services/job.services';

export function* getPocJobs(action) {
  try {
    const {
      page, quantity, jobPositionFilter, locationFilter,
    } = action;
    const data = yield call(
      getAllPoc,
      page,
      quantity,
      jobPositionFilter,
      locationFilter,
    );
    yield put({ type: constants.JOB_GET_ALL_POC_SUCCEDED, data, page });
  } catch (error) {
    yield put({ type: constants.JOB_GET_ALL_POC_FAILED, error });
  }
}

export function* getJobsDashboard() {
  try {
    const data = yield call(
      getAllForDashboard,
    );
    yield put({ type: constants.JOB_GET_ALL_DASHBOARD_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.JOB_GET_ALL_DASHBOARD_FAILED, error });
  }
}

export function* getCompanyJobs(action) {
  try {
    const { page, quantity, statusInactive } = action;
    const data = yield call(
      getAllFromCompany,
      page,
      quantity,
      statusInactive,
    );
    yield put({ type: constants.JOB_GET_ALL_COMPANY_SUCCEDED, data, page });
  } catch (error) {
    yield put({ type: constants.JOB_GET_ALL_COMPANY_FAILED, error });
  }
}

export function* getCompanyJobsAfterFilter(action) {
  try {
    const { page, quantity, status } = action;
    const data = yield call(
      getAllFromCompany,
      page,
      quantity,
      status,
    );
    yield put({
      type: constants.JOB_SET_INACTIVE_STATUS_SUCCEDED, data, page, status,
    });
  } catch (error) {
    yield put({ type: constants.JOB_SET_INACTIVE_STATUS_FAILED, error });
  }
}

export function* addJobOffer(action) {
  try {
    const data = yield call(addNewJobOffer, action.formData);
    yield put({ type: constants.JOB_ON_ADD_OFFER_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.JOB_ON_ADD_OFFER_FAILED, error });
  }
}

export function* onDeleteJob(action) {
  try {
    const id = action.formData;
    const data = yield call(deleteJob, id);
    yield put({ type: constants.JOB_ON_DELETE_SUCCEDED, data, id });
  } catch (error) {
    yield put({ type: constants.JOB_ON_DELETE_FAILED, error });
  }
}

export function* updateJob(action) {
  try {
    const data = yield call(updateJobOffer, action.formData);
    yield put({ type: constants.JOB_ON_UPDATE_SUCCEDED, data });
  } catch (error) {
    yield put({ type: constants.JOB_ON_UPDATE_FAILED, error });
  }
}

export function* getPocJobsAfterFilter(action) {
  try {
    const {
      page, quantity, jobPositionFilter, locationFilter,
    } = action;
    const data = yield call(
      getAllPoc,
      page,
      quantity,
      jobPositionFilter,
      locationFilter,
    );
    yield put({
      type: constants.JOB_SET_FILTERS_SUCCEDED, data, jobPositionFilter, locationFilter,
    });
  } catch (error) {
    yield put({ type: constants.JOB_SET_FILTERS_FAILED, error });
  }
}

export function* watchJobs() {
  yield all([
    takeLatest([userConstants.USER_ON_LOGIN_SUCCEEDED, appConstants.APP_ON_INITIALIZE_REQUESTED],
      getJobsDashboard),
    takeLatest(constants.JOB_GET_ALL_COMPANY_REQUESTED, getCompanyJobs),
    takeLatest(constants.JOB_GET_ALL_POC_REQUESTED, getPocJobs),
    takeLatest(constants.JOB_ON_ADD_OFFER_REQUESTED, addJobOffer),
    takeLatest(constants.JOB_ON_DELETE_REQUESTED, onDeleteJob),
    takeLatest(constants.JOB_ON_UPDATE_REQUESTED, updateJob),
    takeLatest(constants.JOB_SET_FILTERS_REQUESTED, getPocJobsAfterFilter),
    takeLatest(constants.JOB_SET_INACTIVE_STATUS_REQUESTED, getCompanyJobsAfterFilter),
  ]);
}
