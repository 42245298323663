import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { onContact } from 'redux/actions/user.actions';
import { contactSelector } from 'redux/selectors/user.selector';

import { userPropTypes } from 'helpers/propTypes';

import Contact from 'views/Home/components/Contact';

import { ReactComponent as LogoDipperPic } from 'components/commons/assets/dipper-logo.svg';

import { BLUE_DIPPER } from 'styles/colors';

const TextDiv = styled.div`
  color: ${BLUE_DIPPER};
  font-size: 16px;
`;

const BottomText = styled.div`
  margin-top: 10px;
  color: ${BLUE_DIPPER};
  font-weight: bold;
  font-size: 16px;
  font-weight: bold;
`;

const LogoDipper = styled(LogoDipperPic)`
  width: 180px;
  height: 60px;
  vertical-align: middle;
  margin: 5px 0 23px 0;
`;

const PremiumContactContainer = (props) => {
  const {
    initialValuesContact, onSubmitContact, onCloseModal,
  } = props;

  const OnSubmitModal = (formData) => {
    onSubmitContact(formData);
    onCloseModal();
  };

  return (
    <>
      <LogoDipper />
      <TextDiv>
        Membership has its privileges.
        Take the first step today to join Dipper’s change-making community
        and you’ll be well on your way to breaking down systemic gaps within your workplace.
        <BottomText>
          Complete the form below and a Dipper membership representative will get back to you.
          <br />
          <br />
          Be the change, Dipper
        </BottomText>
      </TextDiv>
      <Contact
        onSubmit={OnSubmitModal}
        isFromModal
        initialValues={initialValuesContact}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  initialValuesContact: contactSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitContact: bindActionCreators(onContact, dispatch),
});

PremiumContactContainer.propTypes = {
  onSubmitContact: PropTypes.func.isRequired,
  initialValuesContact: userPropTypes.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumContactContainer);
