/* Color Palette */
export const BLACK = '#000';
export const BLACK_MINE_SHAFT = '#363636';

export const BLUE = '#393769';
export const BLUE_GRAY = '#414a59';
export const BLUE_PREMIUM = '#131360';
export const BLUE_DIPPER = '#474F63';
export const BLUE_SELECTED_MOBILE = '#424A5A';
export const BLUE_DARK = '#080544';
export const BLUE_PORT = '#232157';
export const BLUE_NAVBAR_ITEMS = '#010F3D';
export const DIPPER_BLUE = '#474F63';
export const MENU_BLUE = '#3C4353';
export const LIGHT_BLUE = '#2699FB';

export const GRAY = '#727272';
export const GRAY_ALTO = '#d5d5d5';
export const GRAY_BAR = '#d4d4d4';
export const GRAY_DARK = '#434c59';
export const GRAY_NOBEL = '#bab3b3';
export const GRAY_MYSTIC = '#e6ecf0';
export const GRAY_SCORPION = '#5c5b5b';
export const GRAY_SIDE_MENU_MOBILE = '#B0B2B7';
export const GRAY_BACKGROUND = '#F7F7F8';
export const GRAY_BACKGROUND_HOVER = '#E5E5E5';
export const GRAY_REVIEW_DATE = '#9C9BB4';
export const GRAY_COMMUNITY = '#E5E6E9';
export const GRAY_COMMUNITY_LINES = '#CACDD2';
export const GRAY_FOOTER = '#BABABA';

export const GOOGLE_RED = '#C94130';

export const BACKGROUND_COLOR = '#ededed';

export const GREEN_REVIEW = '#7DB00E';
export const GREEN_FEATURED = '#B1D06E';
export const DARK_GREEN_REVIEW = '#11cc11';
export const DARK_GREEN_BUTTON = '#67910B';
export const GREEN_SELECTED_NAVBAR = '#7DB00E';
export const LIGHT_GREEN = '#97C03E';
export const LIGHT_LIME_GREEN = '#F1F6E6';
export const WHITE = '#fff';
export const WHITE_BACKGROUND = '#F7F7F8';

export const GREEN_DECO = '#CBDF9F';

export const RED_ERROR = '#FF0000';

export const BOX_SHADOW = '#00000017';

export const VIOLET_CHART = '#601852';

export const MODAL_BACKGROUND = 'rgba(71,79,99,0.75)';
