import * as React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  Filter,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  required,
  RichTextField,
  DateField,
  DateInput,
} from 'react-admin';

import { validateMaxSize, validateRequired } from 'admin/helpers/formValidation';
import { getStringDate } from 'helpers/dates';
import { AdminFormContainer } from 'styles/commons';
import { COMMUNITY_FEED } from 'constants/inputSizes';

const FeedFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by description" source="description" alwaysOn />
    <TextInput label="Search by Email" source="user.email" alwaysOn />
  </Filter>
);

export const FeedList = (props) => (
  <List filters={<FeedFilter />} sort={{ field: 'dateUpdated', order: 'DESC' }} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField label="Last Update" source="dateUpdated" />
      <RichTextField source="description" />
      <ReferenceField label="User" source="user.id" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

const FeedTitle = ({ record }) => (
  <span>
    {record ? `${record.title}` : ''}
  </span>
);

const FeedForm = ({ isEditForm }) => (
  <AdminFormContainer>
    {isEditForm && <TextInput disabled source="id" validate={required()} />}
    {isEditForm
      ? <DateInput source="dateCreated" />
      : <DateInput source="dateCreated" initialValue={getStringDate()} disabled />}
    <TextInput
      multiline
      source="description"
      inputProps={{ maxLength: COMMUNITY_FEED }}
      validate={[required(), validateRequired, (field) => validateMaxSize(COMMUNITY_FEED, field)]}
    />
    <ReferenceInput label="User" source="user.id" reference="users" perPage={1000}>
      <SelectInput optionText="firstName" validate={required()} />
    </ReferenceInput>
  </AdminFormContainer>
);

export const FeedEdit = (props) => (
  <Edit title={<FeedTitle />} {...props}>
    <SimpleForm>
      <FeedForm isEditForm />
    </SimpleForm>
  </Edit>
);

export const FeedCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <FeedForm isEditForm={false} />
    </SimpleForm>
  </Create>
);

FeedForm.propTypes = {
  isEditForm: PropTypes.bool.isRequired,
};

FeedTitle.defaultProps = {
  record: null,
};

FeedTitle.propTypes = {
  record: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
};
