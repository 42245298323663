import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DefaultProfileComp from 'components/commons/assets/avatar-company.svg';

import { jobProptypes } from 'helpers/propTypes';
import { getStringMonthNameDayYear, isNewDate, timePassedSince } from 'helpers/dates';

import { employeeTypes } from 'constants/jobs';
import {
  ButtonsContainer, CompanyNameAndDateContainer, Company, DateDiv, DeleteButton,
  EmployeeType, EmployeeAndFeatured, EditButton, Featured, HeaderContainer,
  IconLocation, JobContainer, JobPosition, LocationAndEmployeeTypeContainer,
  Location, NewText, OverviewContainer, ProfilePicture, RowContainer,
  ProfileImageContainer, VerticalLine,
} from './styles';

const Job = (props) => {
  const {
    isCompany, job, selected, onDelete,
  } = props;

  const renderEditAndDeleteButtons = () => (
    <>
      <Link
        to={{
          pathname: '/jobs/new',
          state: { job },
        }}
      >
        <EditButton />
      </Link>
      <VerticalLine />
      <DeleteButton onClick={(e) => { onDelete(job.id); e.stopPropagation(); }} />
    </>
  );

  const renderJobForCompany = () => (
    <JobContainer selected={selected} isCompany={isCompany}>
      <RowContainer>
        <HeaderContainer>
          <CompanyNameAndDateContainer>
            <JobPosition>
              {job.jobPosition}
            </JobPosition>
            <ButtonsContainer>
              {renderEditAndDeleteButtons()}
            </ButtonsContainer>
          </CompanyNameAndDateContainer>
          <LocationAndEmployeeTypeContainer>
            <Location>
              <IconLocation />
              {job.location}
            </Location>
            <DateDiv isCompany>
              {getStringMonthNameDayYear(job.dateUpdated)}
            </DateDiv>
          </LocationAndEmployeeTypeContainer>
        </HeaderContainer>
      </RowContainer>
      <OverviewContainer>
        {job.overview}
      </OverviewContainer>
      <EmployeeAndFeatured>
        <EmployeeType>
          {employeeTypes[job.employeeType]}
        </EmployeeType>
        {job.isFeatured
          && (
            <Featured>
              Featured
            </Featured>
          )}
      </EmployeeAndFeatured>
    </JobContainer>
  );

  const renderJobForPoC = () => (
    <JobContainer selected={selected} isCompany={isCompany}>
      <RowContainer>
        <ProfileImageContainer>
          <ProfilePicture
            src={job.company.logo ? job.company.logo.path : DefaultProfileComp}
          />
        </ProfileImageContainer>
        <HeaderContainer>
          <CompanyNameAndDateContainer>
            <JobPosition>
              {job.jobPosition}
            </JobPosition>
            {job.isFeatured
              && (
                <Featured>
                  Featured
                </Featured>
              )}
          </CompanyNameAndDateContainer>
          <Company>
            {job.company.name}
          </Company>
          <LocationAndEmployeeTypeContainer>
            <Location>
              <IconLocation />
              {job.location}
            </Location>
            {isNewDate(job.dateUpdated) && <NewText>New</NewText>}
            <DateDiv>
              {timePassedSince(job.dateUpdated)}
            </DateDiv>
          </LocationAndEmployeeTypeContainer>
        </HeaderContainer>
      </RowContainer>
    </JobContainer>
  );

  return isCompany ? renderJobForCompany() : renderJobForPoC();
};

Job.defaultProps = {
  selected: null,
};

Job.propTypes = {
  isCompany: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  job: jobProptypes.isRequired,
  selected: PropTypes.bool,
};

export default Job;
