import React from 'react';
import PropTypes from 'prop-types';

import { PageContainer } from 'styles/commons';
import {
  companyPropTypes,
  feedsArrayPropTypes, insightsArrayPropTypes, jobsArrayPropTypes, userPropTypes,
} from 'helpers/propTypes';

import FeedsDashboard from 'components/commons/FeedsDashboard';
import InsightsDashboard from 'components/commons/InsightsDashboard';
import ProfileDashboard from 'components/commons/ProfileDashboard';
import ExperienceAtWorkBanner from 'components/ExperienceAtWorkBanner';
import JobsDashboard from 'components/commons/JobsDashboard';
import AutoComplete from 'components/commons/AutoComplete';

import {
  BackgroundWhite, Container, OnlyMobile, OnlyDesktop, ProfileContainer, RowTest,
  SearchBarContainer,
} from './styles';

const DashboardPoC = (props) => {
  const {
    amountOfJobs, companiesList, feedsList, insights, jobsList, onSubmitAddComment, user,
    onSearch,

  } = props;

  const renderCompanySearch = () => (
    <SearchBarContainer>
      <BackgroundWhite>
        <AutoComplete
          input={{ value: '', onChange: onSearch }}
          name="company"
          label="Search company reviews"
          options={companiesList}
          size="small"
          search
          searchSelected
          isPlaceholder
        />
      </BackgroundWhite>
    </SearchBarContainer>
  );

  return (
    <PageContainer data-testid="dashboard-poc-page">
      <Container className="col-lg-12 row">
        <RowTest>
          <InsightsDashboard insights={insights} />
          <FeedsDashboard
            user={user}
            feedsList={feedsList}
            onSubmitAddComment={onSubmitAddComment}
          />
          <ProfileContainer className="col-lg-3 column">
            <OnlyMobile>{renderCompanySearch()}</OnlyMobile>
            <OnlyDesktop><ProfileDashboard user={user} /></OnlyDesktop>
            <ExperienceAtWorkBanner />
            <OnlyMobile><ProfileDashboard user={user} /></OnlyMobile>
            <JobsDashboard amountOfJobs={amountOfJobs} jobsList={jobsList} maxJobs={2} />
          </ProfileContainer>
        </RowTest>
      </Container>
    </PageContainer>
  );
};

DashboardPoC.propTypes = {
  amountOfJobs: PropTypes.number.isRequired,
  companiesList: PropTypes.arrayOf(companyPropTypes).isRequired,
  feedsList: feedsArrayPropTypes.isRequired,
  insights: insightsArrayPropTypes.isRequired,
  jobsList: jobsArrayPropTypes.isRequired,
  onSubmitAddComment: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  user: userPropTypes.isRequired,
};

export default DashboardPoC;
