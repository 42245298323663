import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import { isCompany } from 'constants/roles';
import companyName from 'helpers/company';
import { reviewProptypes } from 'helpers/propTypes';

import { onCompaniesGetAll } from 'redux/actions/company.actions';
import { Container } from 'styles/commons';
import Layout from 'containers/Layout';
import { onAddReview, onUpdateReview } from 'redux/actions/review.actions';
import CreateReviewView from 'views/CreateReview';
import { formatCompaniesList } from 'redux/selectors/company.selector';
import reviewSelector from 'redux/selectors/review.selector';

const CreateReview = (props) => {
  const {
    companiesList, onSubmit, onUpdate, onCompaniesGetAllAction, user, currentlyWorking,
    location, editReview,
  } = props;

  useEffect(() => {
    onCompaniesGetAllAction();
  }, [onCompaniesGetAllAction]);

  const onSubmitForm = (formData) => {
    const valueHelper = companyName(formData.company, companiesList);
    const newFormData = {
      ...formData,
      company: valueHelper,
    };

    if (editReview) {
      onUpdate(newFormData);
    } else {
      onSubmit(newFormData);
    }
  };

  if (user && isCompany(user.role)) {
    return <Redirect to="/reviews" />;
  }

  return (
    <Container>
      <Layout isLandscape hideAddReview>
        {!user && <h1>Loading...</h1>}
        {user
          && (
            <CreateReviewView
              onSubmit={onSubmitForm}
              companiesList={companiesList}
              currentlyWorking={currentlyWorking}
              initialValues={location && editReview}
              CompanyAutocompleteDisable={!!editReview}
            />
          )}
      </Layout>
    </Container>
  );
};

const selector = formValueSelector('CreateReviewForm');

const mapStateToProps = (state, props) => ({
  user: state.user.data,
  companiesList: formatCompaniesList(state),
  currentlyWorking: selector(state, 'currentlyWorking'),
  editReview: reviewSelector(props),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmit: bindActionCreators(onAddReview, dispatch),
  onUpdate: bindActionCreators(onUpdateReview, dispatch),
  onCompaniesGetAllAction: bindActionCreators(onCompaniesGetAll, dispatch),
});

CreateReview.defaultProps = {
  currentlyWorking: false,
  user: null,
  location: {},
  editReview: {},
};

CreateReview.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    role: PropTypes.number.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({
      review: reviewProptypes,
    }),
  }),
  companiesList: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  onCompaniesGetAllAction: PropTypes.func.isRequired,
  currentlyWorking: PropTypes.bool,
  editReview: reviewProptypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReview);
