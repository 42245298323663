import {
  applyMiddleware, combineReducers, createStore, compose,
} from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { adminReducer, adminSaga } from 'react-admin';
import { reducer as formReducer } from 'redux-form';
import { createLogger } from 'redux-logger';

import appReducer from 'redux/reducers/app.reducer';
import userReducer from 'redux/reducers/user.reducer';
import jobReducer from 'redux/reducers/job.reducer';
import reviewReducer from 'redux/reducers/review.reducer';
import feedReducer from 'redux/reducers/feed.reducer';
import companyReducer from 'redux/reducers/company.reducer';
import insightReducer from 'redux/reducers/insight.reducer';

import storageMiddleware from 'redux/middlewares/storage.middleware';
import redirectMiddleware from 'redux/middlewares/redirect.middleware';
import formMiddleware from 'redux/middlewares/form.middleware';
import notificationMiddleware from 'redux/middlewares/notification.middleware';

import { watchUsers } from 'redux/sagas/user.sagas';
import { watchJobs } from 'redux/sagas/job.sagas';
import { watchReviews } from 'redux/sagas/review.sagas';
import { watchCompanies } from 'redux/sagas/company.sagas';
import { watchFeeds } from 'redux/sagas/feed.sagas';
import { watchInsights } from 'redux/sagas/insight.sagas';

import { ENVIRONMENTS } from 'constants/environments';
import { ENVIRONMENT } from 'constants/configs';

export default ({ authProvider, dataProvider, history }) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    // Add your own reducers here
    form: formReducer,
    app: appReducer,
    user: userReducer,
    job: jobReducer,
    review: reviewReducer,
    feed: feedReducer,
    company: companyReducer,
    insight: insightReducer,
  });

  const saga = function* rootSaga() {
    yield all([
      adminSaga(dataProvider, authProvider),
      yield all([
        // Add your own sagas here
        watchUsers(),
        watchJobs(),
        watchReviews(),
        watchFeeds(),
        watchCompanies(),
        watchInsights(),
      ]),
    ].map(fork));
  };

  const composeEnhancers = (ENVIRONMENT !== ENVIRONMENTS.production && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const sagaMiddleware = createSagaMiddleware();
  const loggerMiddleware = createLogger();

  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    storageMiddleware,
    redirectMiddleware,
    formMiddleware,
    notificationMiddleware,
    // Add your own middlewares here
  ];

  if (ENVIRONMENT !== ENVIRONMENTS.production) {
    middlewares.push(loggerMiddleware);
  }

  const store = createStore(
    reducer,
    composeEnhancers(
      applyMiddleware(...middlewares),
    ),
  );

  sagaMiddleware.run(saga);
  return store;
};
