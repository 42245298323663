import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import { userPropTypes } from 'helpers/propTypes';

import { INSTAGRAM_LINK, LINKEDIN_LINK, FACEBOOK_LINK } from 'constants/socialMedias';

import {
  PortraitContainer, ProfilePicture, Content,
  UserDiv, Name, Description, Section, Profile, Setting,
  SocialMediaDiv, Info, IconsDiv, Facebook, Instagram, LinkedIn,
  TransparentDiv, Close,
} from './styles';

const MenuMobile = (props) => {
  const {
    user, onCloseMenu, isPoc, isOpen,
  } = props;

  return (
    <>
      {isOpen && <TransparentDiv onClick={onCloseMenu} />}
      <PortraitContainer hasTransition={isOpen} data-testid="navbar">
        <Close show={isOpen} onClick={onCloseMenu} />
        <Content>
          <div>
            <UserDiv>
              <ProfilePicture src={user.profileImage} alt="placeholder" />
              <Name>{user.firstName}</Name>
              <Description>{isPoc ? user.jobPosition : user.industry}</Description>
            </UserDiv>
            <Link to="/profile">
              <Section>
                <Profile />
                Edit Profile
              </Section>
            </Link>
            <Link to="/settings">
              <Section>
                <Setting />
                Settings
              </Section>
            </Link>
          </div>
          <SocialMediaDiv>
            <NavHashLink target="_blank" rel="noopener noreferrer" smooth to="/legal#privacy">
              <Info />
            </NavHashLink>
            <IconsDiv>
              <a target="_blank" rel="noopener noreferrer" href={LINKEDIN_LINK}>
                <LinkedIn />
              </a>
              <a target="_blank" rel="noopener noreferrer" href={INSTAGRAM_LINK}>
                <Instagram />
              </a>
              <a target="_blank" rel="noopener noreferrer" href={FACEBOOK_LINK}>
                <Facebook />
              </a>
            </IconsDiv>
          </SocialMediaDiv>
        </Content>
      </PortraitContainer>
    </>
  );
};

MenuMobile.defaultProps = {
  isOpen: false,
};

MenuMobile.propTypes = {
  user: userPropTypes.isRequired,
  onCloseMenu: PropTypes.func.isRequired,
  isPoc: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool,
};

export default MenuMobile;
