import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import { companySizeOptionsPropTypes } from 'helpers/propTypes';

import FileInput from 'components/commons/FileInput';

import FormPoc from './components/FormPoc';
import FormCompany from './components/FormCompany';
import FormPassword from './components/FormPassword';

import {
  CardBox, ProfileContainer, ProfileImageContainer, Top, Text, PictureSection, LabelImage,
  ProfilePicture, ImageContainer, EditProfileLogo, ButtonStyle, SendButton,
  SettingsSection, SubTitle, OptionsSection, UserOptions,
} from './styles';

const Profile = (props) => {
  const {
    handleSubmit, image, isPoc, isPremium, onLoadImageFailed, onLogout, onSubmit, onSubmitPassword,
    companySizeOptions, showSettingsMobile, onOpenModal,
  } = props;

  const [toolTipOpen, setToolTipOpen] = React.useState(false);

  return (
    <ProfileContainer onScroll={() => setToolTipOpen(false)} data-testid="profile-page">
      {!showSettingsMobile
      && (
        <>
          <Top>
            <h1>Profile</h1>
            <Text>Set up your Dipper presence</Text>
          </Top>
          <CardBox>
            <form data-testid="form" onSubmit={handleSubmit(onSubmit)}>
              <PictureSection>
                <ProfileImageContainer>
                  <ProfilePicture src={image} alt="placeholder" />
                  <ImageContainer>
                    {(isPremium || isPoc)
              && (
                <Field
                  component={FileInput}
                  name="image"
                  onLoadImageFailed={onLoadImageFailed}
                  disableTouchListener
                />
              )}
                  </ImageContainer>
                  <Tooltip
                    disableHoverListener={isPremium || isPoc}
                    title="Only premium users can change the profile pic"
                    placement="right"
                    open={!(isPremium || isPoc) && toolTipOpen}
                  >
                    <LabelImage htmlFor="files">
                      <EditProfileLogo onClick={() => setToolTipOpen(!toolTipOpen)} />
                    </LabelImage>
                  </Tooltip>
                </ProfileImageContainer>
              </PictureSection>
              {isPoc
                ? <FormPoc />
                : (
                  <FormCompany
                    isPremium={isPremium}
                    companySizeOptions={companySizeOptions}
                    onOpenModal={onOpenModal}
                  />
                )}
              <SendButton
                isPremium={isPremium}
                isPoc={isPoc}
                justifyContentCenter={isPoc || isPremium}
              >
                <ButtonStyle type="submit">Save Change</ButtonStyle>
              </SendButton>
            </form>
          </CardBox>
        </>
      )}
      <SettingsSection displayInMobile={showSettingsMobile}>
        <Top>
          <h1>Settings</h1>
        </Top>
        <SubTitle hasLessMarginAndBorderTop>Password</SubTitle>
        <FormPassword onSubmit={onSubmitPassword} />
      </SettingsSection>
      <OptionsSection displayInMobile={showSettingsMobile}>
        {/* <UserOptions
          isRemoveAcount
          role="presentation"
          data-testid="button-logout"
        >
          Remove Acount
        </UserOptions> */}
        <UserOptions
          onClick={onLogout}
          role="presentation"
          data-testid="button-logout"
        >
          Log Out
        </UserOptions>
      </OptionsSection>
    </ProfileContainer>
  );
};

Profile.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  isPoc: PropTypes.bool.isRequired,
  isPremium: PropTypes.bool.isRequired,
  showSettingsMobile: PropTypes.bool.isRequired,
  onLoadImageFailed: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onSubmitPassword: PropTypes.func.isRequired,
  companySizeOptions: companySizeOptionsPropTypes.isRequired,
};

export default reduxForm({
  form: 'profile',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(Profile);
