import styled from 'styled-components';
import Highlighter from 'react-highlight-words';

import {
  DIPPER_BLUE, GRAY_COMMUNITY, GRAY_COMMUNITY_LINES, GRAY_REVIEW_DATE, GREEN_REVIEW,
} from 'styles/colors';
import { TABLET_SIZE, BOOSTRAP_MEDIUM } from 'constants/sizes';

export const DetailsContainer = styled.div`
  height: 100%;

  @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
    height: 0;
    display: flex;
    flex: 1;
  }
`;

export const DetailsSection = styled.div`
  vertical-align: middle;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  background: ${GRAY_COMMUNITY};
  flex: 1;

  @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
    border-radius: 0;
    background: none;
  }

  @media (max-width: ${TABLET_SIZE}px) {
    margin: 10px 14px;
    padding: 0;
  }
`;

export const FeedSection = styled.div` 
  width: 100%;
`;

export const FeedTitle = styled.div` 
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const ProfilePicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const FeedText = styled.div`
  color: ${DIPPER_BLUE};
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
`;

export const FeedTime = styled.div`
  color: ${GRAY_REVIEW_DATE};
  font: normal normal 11px/14px Mulish-Italic;
`;

export const FeedDescription = styled.div`
  margin: 17px 0 30px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-line;
`;

export const Low = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 11px 0;
`;

export const DivReplyIcons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0 5px;
  justify-content: flex-end;
`;

export const CommentNumber = styled.div`
  margin-right: 4px;
  font-size: 11px;
  color: ${DIPPER_BLUE};
  font-weight: medium;

`;

export const CommentLogo = styled.div`
  border-right: 1px solid ${GRAY_COMMUNITY_LINES};
  padding-right: 7px;
  margin-right: 7px;
  height: fit-content;

  #commentIcon {
    display: block;
  }
`;

export const ReplyLogo = styled.div`
  height: fit-content;

  #replyIcon {
    display: block;
  }
`;

export const CommentSection = styled.div`
  color: ${DIPPER_BLUE};

  h1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: ${BOOSTRAP_MEDIUM - 1}px) {
      border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
      padding-bottom: 10px;
    }
  }
`;

export const FeedLink = styled.a`
  color: ${GREEN_REVIEW};
`;

export const DetailsDescription = styled(Highlighter)`
  white-space: pre-line;
  font: normal normal medium 12px/16px Mulish;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};
  word-break: break-word;
`;
