import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AddPostIcon } from '../../CreateFeed/assets/AddPostIcon.svg';
import { ReactComponent as SendIcon } from '../../CreateFeed/assets/SendIcon.svg';

import { AdornmentDivStart, AdornmentDivEnd, EditableContent } from './styles';

const CustomContentEditable = (props) => {
  const {
    handleChangeFeed, html, isFocused, input, focus,
  } = props;

  const handleChange = (event) => {
    handleChangeFeed(event);
    input.onChange(event);
  };

  return (
    <>
      <EditableContent
        html={html}
        isFocused={isFocused}
        onChange={handleChange}
        onFocus={focus}
      />
      <AdornmentDivStart isFocused={isFocused}>
        <AddPostIcon />
      </AdornmentDivStart>
      <AdornmentDivEnd type="submit" isFocused={isFocused} disabled={!isFocused}>
        <SendIcon />
      </AdornmentDivEnd>
    </>
  );
};

CustomContentEditable.propTypes = {
  focus: PropTypes.func.isRequired,
  handleChangeFeed: PropTypes.func.isRequired,
  html: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  isFocused: PropTypes.bool.isRequired,
};

export default CustomContentEditable;
