import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

import * as validates from 'helpers/validates';
import { companySizeOptionsPropTypes } from 'helpers/propTypes';

import Input from 'components/commons/Input';
import Dropdown from 'components/commons/Dropdown';
import DatePicker from 'components/commons/DatePicker';

import {
  COMPANY_DEI_COMMITMENTS, COMPANY_DESCRIPTION, COMPANY_INDUSTRY, COMPANY_LOCATION,
  COMPANY_NAME, COMPANY_WEBSITE, USER_EMAIL, USER_FIRST_NAME, USER_LAST_NAME,
} from 'constants/inputSizes';
import NotPremiumImg from '../assets/not-premium-img.png';

import {
  InputDiv, InputsSection, LeftInputs, InputsName, RightInputs,
  NotPremiumSection, ImagePremium, DivTextandImage, ButtonPremium, PlusIcon, DivText,
  TitlePremium, SubTitlePremium, SendButtonCompany, ButtonStyle, InputSelect,
} from '../styles';

const FormCompany = (props) => {
  const { isPremium, companySizeOptions, onOpenModal } = props;

  return (
    <div>
      <InputsSection>
        <LeftInputs>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              name="name"
              label="Company name"
              validate={validates.required}
              size="small"
              isProfileInput
              isItalic
              maxLength={COMPANY_NAME}
            />
          </InputDiv>
          <InputsName>
            <InputDiv isInputName>
              <Field
                component={Input}
                variant="outlined"
                name="firstName"
                label="Name"
                validate={validates.required}
                size="small"
                isProfileInput
                isItalic
                maxLength={USER_FIRST_NAME}
              />
            </InputDiv>
            <InputDiv isInputName>
              <Field
                component={Input}
                variant="outlined"
                name="lastName"
                label="Last name"
                validate={validates.required}
                size="small"
                isProfileInput
                isItalic
                maxLength={USER_LAST_NAME}
              />
            </InputDiv>
          </InputsName>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              name="email"
              label="Mail"
              validate={[validates.required, validates.email]}
              size="small"
              socialMedia="email"
              disabled
              isProfileInput
              isItalic
              maxLength={USER_EMAIL}
            />
          </InputDiv>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              name="location"
              label="Location"
              size="small"
              isProfileInput
              isItalic
              maxLength={COMPANY_LOCATION}
            />
          </InputDiv>
          <InputDiv>
            <Field
              component={Input}
              validate={validates.website}
              variant="outlined"
              name="website"
              label="Website"
              size="small"
              isProfileInput
              isItalic
              maxLength={COMPANY_WEBSITE}
            />
          </InputDiv>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              name="industry"
              label="Industry"
              size="small"
              isProfileInput
              isItalic
              maxLength={COMPANY_INDUSTRY}
            />
          </InputDiv>
          <InputDiv>
            <InputSelect>
              <Field
                component={Dropdown}
                variant="outlined"
                name="size"
                label="Company size"
                options={companySizeOptions}
                isProfileInput
                isItalic
              />
            </InputSelect>
          </InputDiv>
          <InputDiv>
            <Field
              component={DatePicker}
              name="founded"
              label="Founded"
              validate={validates.date}
              size="small"
              isItalic
            />
          </InputDiv>
        </LeftInputs>
        <RightInputs isUserPremium={isPremium}>
          <h1>Social Profiles</h1>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              name="linkedIn"
              label="linkedIn"
              size="small"
              socialMedia="linkedIn"
              isProfileInput
              isItalic
              hasLessPaddingTop
              validate={validates.socialMedia}
            />
          </InputDiv>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              name="facebook"
              label="facebook"
              size="small"
              socialMedia="facebook"
              isProfileInput
              isItalic
              validate={validates.socialMedia}
            />
          </InputDiv>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              name="instagram"
              label="instagram"
              size="small"
              socialMedia="instagram"
              isProfileInput
              isItalic
              validate={validates.socialMedia}
            />
          </InputDiv>
          <InputDiv hasMarginTop>
            <h1>Description</h1>
            <Field
              component={Input}
              variant="outlined"
              name="description"
              label="Add a small text"
              type="textarea"
              size="small"
              isProfileInput
              rows={5}
              rowsMax={5}
              maxLength={COMPANY_DESCRIPTION}
              isItalic
              hasLessPaddingTop
            />
          </InputDiv>
          <InputDiv hasMarginTop>
            <h1>DEIB & Anti-Racism Commitments</h1>
            <Field
              component={Input}
              variant="outlined"
              name="deiCommitments"
              label="Add a small text"
              type="textarea"
              size="small"
              isProfileInput
              rows={5}
              rowsMax={5}
              maxLength={COMPANY_DEI_COMMITMENTS}
              isItalic
              hasLessPaddingTop
            />
          </InputDiv>
        </RightInputs>
        <SendButtonCompany>
          <ButtonStyle type="submit">Save Change</ButtonStyle>
        </SendButtonCompany>
        <NotPremiumSection isUserPremium={isPremium}>
          <DivTextandImage>
            <DivText>
              <TitlePremium>
                Add more information to your profile!
              </TitlePremium>
              <SubTitlePremium>
                Register as a corporate member of Dipper and get more benefits!
              </SubTitlePremium>
            </DivText>
            <ImagePremium src={NotPremiumImg} />
          </DivTextandImage>
          <ButtonPremium onClick={onOpenModal} startIcon={<PlusIcon />}>
            Join Now
          </ButtonPremium>
        </NotPremiumSection>
      </InputsSection>
    </div>
  );
};

FormCompany.propTypes = {
  isPremium: PropTypes.bool.isRequired,
  companySizeOptions: companySizeOptionsPropTypes.isRequired,
  onOpenModal: PropTypes.func.isRequired,
};

export default FormCompany;
