import React from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import RateReview from '@material-ui/icons/RateReview';
import ForumIcon from '@material-ui/icons/Forum';
import WorkIcon from '@material-ui/icons/Work';
import { ReactComponent as Insights } from './assets/dipper-insights.svg';

export const PortraitLinks = [
  {
    id: 'nav-how-it-works',
    text: 'How It Works',
    to: '/home#how-it-works',
  },
  {
    id: 'nav-jobs',
    text: 'Search A Job',
    to: '/home#jobs',
  },
  {
    id: 'nav-about',
    text: 'About Us',
    to: '/home#about',
  },

];

export const LandscapeLinks = [
  {
    id: 'nav-dashboard',
    text: 'Dashboard',
    to: '/dashboard',
    icon: <DashboardIcon />,
    isCollapsed: false,
    desktopPosition: 1,
  },
  {
    id: 'nav-reviews',
    text: 'Reviews',
    to: '/reviews',
    icon: <RateReview />,
    isCollapsed: false,
    mobilePosition: 2,
    desktopPosition: 2,
  },
  {
    id: 'nav-insights',
    text: 'Insights',
    to: '/insights',
    icon: <Insights />,
    isCollapsed: false,
    mobilePosition: 3,
    desktopPosition: 3,
  },
  {
    id: 'nav-feeds',
    text: 'Community',
    to: '/feeds',
    icon: <ForumIcon />,
    isCollapsed: false,
    mobilePosition: 1,
    desktopPosition: 4,
  },
  {
    id: 'nav-jobs-landscape',
    text: 'Jobs',
    to: '/jobs',
    icon: <WorkIcon />,
    isCollapsed: false,
    mobilePosition: 4,
    desktopPosition: 5,
  },
];
