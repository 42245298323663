import styled, { css } from 'styled-components';
import { DIPPER_BLUE } from 'styles/colors';

export const Container = styled.div`
  margin-top: 10px;
  margin-right: 7px;
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: white;
  color: ${DIPPER_BLUE};
  padding: 8px;
  border-radius: 6px;
  white-space: pre-line;
  justify-content: space-between;

  ${({ isBigger }) => (isBigger && css`
      padding: 20px;
      height: auto;  
  `)}
`;

export const Link = styled.a`
  display: flex;
  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  overflow: hidden;
  padding-right: 20px;

  ${({ isBigger }) => (isBigger && css`
    padding-top: 4px;
  `)}
`;

export const Title = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  color: ${DIPPER_BLUE};

  ${({ isBigger }) => (isBigger && css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

export const Description = styled.p`
  font: 12px/16px Mulish;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin-bottom: 0;
  color: ${DIPPER_BLUE};
`;

export const URLImage = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 6px;
  object-fit: cover;
  align-self: center;

  ${({ isBigger }) => (isBigger && css`
    width: 80px;
    height: 80px; 
  `)}
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
