import {
  get, post, remove, put,
} from 'services/api';

export async function deleteReview(id) {
  const response = await remove(`reviews/deleteReview/${id}`);
  return response;
}

export async function getAllForCompany(quantity) {
  const response = await get(`reviews/getAllByUserCompanyId?quantity=${quantity}`);
  return response;
}

export async function getByCompanyId(companyId, page, quantity) {
  const response = await get(`reviews/getAll?quantity=${quantity}&companyId=${companyId}&page=${page}`);
  return response;
}

export async function getAllForUser(quantity) {
  const response = await get(`reviews/getAllByUserPoCId?quantity=${quantity}`);
  return response;
}

export async function addNew(formData) {
  const data = { ...formData };
  const { key, value } = formData.company;
  data.companyCode = key;
  data.companyName = value;
  if (formData.currentlyWorking) {
    data.endDate = null;
  } else {
    data.endDate = new Date(formData.endYear, formData.endMonth - 1);
  }
  data.startDate = new Date(formData.startYear, formData.startMonth - 1);
  data.recommendCompany = formData.recommendCompany === 'Yes';
  data.feedback = formData.feedback === 'Yes';
  data.anonymous = formData.anonymous === 'Yes';
  const response = await post('reviews/add', data);
  return response;
}

export async function updateUserReview(formData) {
  const data = { ...formData };
  const { key, value } = formData.company;
  data.companyCode = key;
  data.companyName = value;
  if (formData.currentlyWorking) {
    data.endDate = null;
  } else {
    data.endDate = new Date(formData.endYear, formData.endMonth - 1);
  }
  data.startDate = new Date(formData.startYear, formData.startMonth - 1);
  data.recommendCompany = formData.recommendCompany === 'Yes';
  data.feedback = formData.feedback === 'Yes';
  data.anonymous = formData.anonymous === 'Yes';
  const response = await put('reviews/update', data);
  return response;
}

export async function reviewUpdateAnswer(formData) {
  const response = await post('reviews/replyReview', formData);
  return response;
}
