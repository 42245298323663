import React from 'react';
import PropTypes from 'prop-types';

import ProgressBar from 'components/commons/ProgressBar';

import {
  Container, Title, ResultsContainer, ItemContainer,
} from './styles';

const BarChart = ({ labels, title, total }) => (
  <Container>
    <Title>
      {title}
    </Title>
    <ResultsContainer>
      {!!labels && labels.map((item) => (
        <ItemContainer>
          {item.label}
          <ProgressBar progress={item.value} total={total} />
        </ItemContainer>
      ))}
    </ResultsContainer>
  </Container>
);

BarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
};

export default BarChart;
