import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import Tooltip from '@material-ui/core/Tooltip';

import { TABLET_SIZE, MOBILE_SIZE } from 'constants/sizes';
import { ENVIRONMENTS } from 'constants/environments';
import { ENVIRONMENT } from 'constants/configs';

import {
  LandscapeItems, PortraitItems, UserLogOut, Icon, Divlink, ButtonStyle, ButtonsNotLogIn,
} from './styles';

const MenuItems = (props) => {
  const {
    isLandscape, links, loading, onClickPortrait, onLogoutAction,
    open, openLanding, user, UlStyledComponent,
  } = props;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const DivItems = isLandscape ? LandscapeItems : PortraitItems;
  const hasTabletOrMobileSize = width <= TABLET_SIZE;
  const position = hasTabletOrMobileSize ? 'mobilePosition' : 'desktopPosition';
  links.sort((a, b) => a[position] - b[position]);

  const renderSectionsMapped = () => (
    <>
      {links.map((link) => (
        ((hasTabletOrMobileSize && link.id !== 'nav-dashboard') || !hasTabletOrMobileSize)
        && (
          <Tooltip
            key={link.id}
            disableHoverListener={!hasTabletOrMobileSize && open}
            title={link.text}
          >
            <li key={link.id}>
              <NavHashLink smooth to={link.to} activeClassName="active" data-testid={link.id}>
                <Divlink
                  isLandscape={isLandscape}
                  onClick={onClickPortrait}
                  open={open}
                >
                  <Icon open={open}>{link.icon}</Icon>
                  {' '}
                  {(!hasTabletOrMobileSize && (open || !isLandscape)) && link.text}
                  {(hasTabletOrMobileSize && !isLandscape) && link.text}
                </Divlink>
              </NavHashLink>
            </li>
          </Tooltip>
        )
      ))}
    </>
  );

  const renderLogOut = () => (
    <li key="log-out-button">
      <UserLogOut
        onClick={onLogoutAction}
        role="presentation"
        data-testid="button-logout"
      >
        Log out
      </UserLogOut>
    </li>
  );

  const renderUserNotLoggedIn = () => (
    <ButtonsNotLogIn>
      <li key="sign-up-button">
        <Link to="/signup" activeClassName="active" data-testid="nav-sign-up">
          <ButtonStyle
            className="button-div"
            data-testid="button-send"
            id="white-button"
            onClick={onClickPortrait}
          >
            Sign Up
          </ButtonStyle>
        </Link>
      </li>
      <li key="log-in-button">
        <Link to="/login" activeClassName="active" data-testid="nav-sign-in">
          <ButtonStyle
            className="button-div"
            data-testid="button-send"
            isOutlined
            onClick={onClickPortrait}
          >
            Log In
          </ButtonStyle>
        </Link>
      </li>
    </ButtonsNotLogIn>
  );

  const renderUserLoggedIn = () => (
    <>
      {isLandscape
        ? (
          <>
            {width > TABLET_SIZE && ENVIRONMENT === ENVIRONMENTS.local && renderLogOut()}
          </>
        )
        : (
          <li key="dashboard-log-in">
            <NavLink
              to="/dashboard"
              activeClassName="active"
              data-testid="nav-dashboard"
            >
              <Divlink
                isLandscape={isLandscape}
                onClick={onClickPortrait}
                open={open}
              >
                <Icon open={open} />
                {' '}
                Dashboard
              </Divlink>
            </NavLink>
          </li>
        )}
    </>
  );

  const renderSections = () => (
    <>
      {width > MOBILE_SIZE && (
        <DivItems className="fill-height">
          <UlStyledComponent data-testid="menu-items" open={open}>
            {renderSectionsMapped(open)}
          </UlStyledComponent>
        </DivItems>
      )}
    </>
  );

  return (
    <DivItems className="fill-height" data-testid="menu-items">
      <UlStyledComponent open={open} openLanding={openLanding}>
        {(!isLandscape || hasTabletOrMobileSize) && renderSectionsMapped(false)}
        {(isLandscape && !hasTabletOrMobileSize) && renderSections()}
        {!loading && (user ? renderUserLoggedIn() : renderUserNotLoggedIn())}
      </UlStyledComponent>
    </DivItems>
  );
};

MenuItems.defaultProps = {
  user: null,
  onClickPortrait: () => {},
};

MenuItems.propTypes = {
  UlStyledComponent: PropTypes.elementType.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  onLogoutAction: PropTypes.func.isRequired,
  onClickPortrait: PropTypes.func,
  isLandscape: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  openLanding: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default MenuItems;
