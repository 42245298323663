import styled, { css } from 'styled-components';

import { SMALL_MOBILE_SIZE, TABLET_SIZE } from 'constants/sizes';
import { ReactComponent as ArrowIcon } from 'components/commons/assets/arrowBack.svg';
import { ReactComponent as EditIcon } from 'components/commons/assets/dipper-edit-review.svg';
import { ReactComponent as TrashIcon } from 'components/commons/assets/dipper-trash.svg';
import { ReactComponent as MoreOptionsIcon } from 'components/commons/assets/dots.svg';

import {
  GRAY_DARK, GRAY, WHITE, DIPPER_BLUE, GRAY_BAR, BLUE_DIPPER, GRAY_COMMUNITY,
} from 'styles/colors';

export const ReviewContainer = styled.div`
  height: 100vh;
`;

export const ReviewSection = styled.div`
  vertical-align: middle;
  padding: ${({ hasSmallerPadding }) => (hasSmallerPadding ? '19px 20px 14px 20px' : '30px 20px')};
  margin: 10px 0;
  background: ${WHITE};
  border-radius: 10px;
  flex: 1;
  z-index: 3;

  h4 {
    font-weight: bold;
    color: ${GRAY_DARK};
  }

  @media (max-width: ${TABLET_SIZE}px) {
    z-index: 0;
    margin: 10px 14px;
  }
`;

export const TitleContainer = styled.div`
  padding: 5px;
  border-bottom: 1px solid ${GRAY_BAR};

  h6 {
    font-weight: bold;
    color: ${GRAY};
    padding: 5px;
  }
`;

export const ReviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  font: normal normal bold 20px/25px Mulish;
  color: ${DIPPER_BLUE};
  align-items: center;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    ${({ hasFlexDirectionRow }) => (
    hasFlexDirectionRow
      ? css`
        flex-direction: row;
      `
      : css`
        flex-direction: column;
        align-items: flex-start;
      `
  )};
  }
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 34px;
  height: 34px;
  margin-right: 10px;
  object-fit: cover;

  ${({ isBigger }) => (
    isBigger
      ? css`
        width: 45px;
        height: 45px;
    `
      : `
        width: 34px;
        height: 34px;
    `
  )};
`;

export const ColumnCompany = styled.div`
  display: flex;
  flex-direction: ${({ hasFlexDirectionColumn }) => (hasFlexDirectionColumn ? 'column' : 'row')};

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    width: 100%;
  }
`;

export const ReviewTitle = styled.div`
  padding: 15px 0px 5px 0px;
  display: flex;
  flex-direction: row;
  font: normal normal bold 16px/20px Mulish;
  color: ${DIPPER_BLUE};
`;

export const SmallTextDate = styled.div`
  font: normal normal normal 12px/16px Mulish;
  margin-bottom: 5px;
  color: ${DIPPER_BLUE};
`;

export const SmallText = styled.div`
  padding-left: ${({ hasNotPaddingLeft, isJobTitle }) => {
    const padding = isJobTitle ? '4px' : '10px';
    return hasNotPaddingLeft ? '0' : padding;
  }};
  margin-left: 0px;
  color: ${({ isBlue }) => (isBlue ? BLUE_DIPPER : GRAY)};
  font: ${({ isBolder }) => (isBolder ? 'normal normal bold 14px/28px Mulish' : '14px/28px Mulish')};
`;

export const ReviewDate = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font: italic normal normal 11px/14px Mulish;
  padding: 5px;
  color: ${GRAY};

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    padding-left: 0;
  }
`;

export const ReviewContent = styled.h6`
  padding: 20px 5px 5px 5px;
  justify-content: space-between;
  font: normal normal normal 12px/18px Mulish;
  word-break: break-word;
  color: ${DIPPER_BLUE};
  word-break: break-word;
`;

export const ReviewInsightItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 5px 15px 5px;
  justify-content: space-between;
  align-items: center;
  ${({ lastItem }) => (lastItem ? '' : `border-bottom: 1px solid ${GRAY_BAR};`)}

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    flex-direction: ${({ hasRating }) => (hasRating ? 'column' : 'row')};
  }
`;

export const ReviewInsightDescription = styled.div`
  width: 50%;
  text-align: left;
  font: 12px/16px Mulish;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};
  padding-right: 10px;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    width: ${({ hasRating }) => (hasRating ? '100%' : '50%')};
  }
`;

export const ReviewInsightDetails = styled.div`
  width: 50%;
  text-align: left;
  font: normal normal bold 12px/16px Mulish;
  letter-spacing: 0px;
  color: ${DIPPER_BLUE};
  padding-top: ${({ hasRating }) => (hasRating ? '10px' : '0')};

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    width: ${({ hasRating }) => (hasRating ? '100%' : '50%')};
  }
`;

export const WordAndReviewDateDiv = styled.div`
  display: flex;
  flex-direction: row;  
`;

export const ArrowDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  flex: 1;
`;

export const ArrowBack = styled(ArrowIcon)`
  width: 17px;
  height: 10px;
`;

export const CompanyNameTitle = styled.p`
  font: normal normal bold 18px/25px Mulish;
  margin-bottom: 0;
  padding-top: 2px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    display: none;
  }
`;

export const EditButton = styled(EditIcon)`
  height: 17px;
  width: auto;
  cursor: pointer;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    height: 25px;
  }
`;

export const VerticalLine = styled.div`
  height: 15px;
  margin: 2px 5px 0 0;
  border-right: 1px solid ${GRAY_COMMUNITY};

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    display: none;
  }
`;

export const DeleteButton = styled(TrashIcon)`
  cursor: pointer;
  height: 13px;
  width: auto;
  margin-top: 2px;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    height: 20px;
    margin-top: 0;
  }
`;

export const ReviewOptionsContainer = styled.div`
  display: none;
  flex: 1;
  justify-content: flex-end;

  @media (max-width: ${SMALL_MOBILE_SIZE}px) {
    display: flex;
  }
`;

export const MoreOptionsContainer = styled.div`
  position: relative;
  margin-left: 5px;
`;

export const MoreOptionsButton = styled(MoreOptionsIcon)`
  margin-bottom: 5px;
  justify-self: flex-end;
  cursor: pointer;
`;

export const PopUpOptions = styled.div`
  position: absolute;
  background: ${WHITE};
  bottom: -50px;
  right: 0;
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 92px; 
  padding: 10px;
  border-radius: 4px;
  justify-content: space-around;
  z-index: 2;

  -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.47);
  -moz-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.47);
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.47);
`;

export const AllScreen = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
