import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CustomModal from 'components/commons/Modal';
import Loading from 'components/commons/Loading';

import { OnConfirmAccount } from 'redux/actions/user.actions';
import { Container } from 'styles/commons';

import Layout from 'containers/Layout';
import HomeContainer from 'containers/HomeContainer';
import SSOForm from '../views/Signup/components/SSOForm';

const ConfirmAccountContainer = (props) => {
  const {
    loading, onConfirmAccountAction, token, userType,
  } = props;

  const onSubmit = (data) => {
    onConfirmAccountAction(token, data.password, data.companyName);
  };

  const newUserType = parseInt(userType);

  useEffect(() => {
    if (!newUserType) {
      onConfirmAccountAction(token);
    }
  }, [onConfirmAccountAction, token, newUserType]);

  return (
    <Container>
      <Layout hideAddReview>
        <HomeContainer
          isPortraitMenuOpened={false}
        />
        <CustomModal isOpen>
          {!newUserType && loading && <Loading message="The account is being verified" />}
          {newUserType && <SSOForm isCompany={newUserType === 2} onSubmit={onSubmit} />}
        </CustomModal>
      </Layout>
    </Container>
  );
};

const mapStateToProps = (state, props) => ({
  loading: state.user.loading,
  token: props.match.params.token,
  userType: props.match.params.userType,
});

const mapDispatchToProps = (dispatch) => ({
  onConfirmAccountAction: bindActionCreators(OnConfirmAccount, dispatch),
});

ConfirmAccountContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  onConfirmAccountAction: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  userType: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAccountContainer);
