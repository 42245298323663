import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  DIPPER_BLUE, WHITE, BOX_SHADOW,
} from 'styles/colors';
import { TABLET_SIZE, MOBILE_SIZE } from 'constants/sizes';

export const HeaderContainer = styled.div`
  background-color: ${WHITE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: white;
  height: 60px;
  padding: 12px 25px;

  @media (max-width: ${MOBILE_SIZE}px) {
    box-shadow: 0px 3px 6px ${BOX_SHADOW};
    justify-content: space-between;
  }
`;

export const InfoDiv = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  p {
    margin: 0;
  }
`;

export const AutoCompleteDiv = styled.div`
  width: 28%;
  min-width: 196px;

  @media (max-width: ${TABLET_SIZE}px) {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding: 15px 10px 10px 0;
    max-width: 196px;
  }

  @media (max-width: ${MOBILE_SIZE}px) {
    max-width: 100%;
    min-width: 0;
    padding: 0;
  }
`;

export const ProfileText = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${MOBILE_SIZE}px) {
    display: none;
  }
`;

export const TextHello = styled.p`
  color: ${DIPPER_BLUE};
  font: normal normal normal 12px/15px Mulish;
  justify-content: flex-end;
  margin-bottom: 0px;
  text-align: right;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  object-fit: cover;
`;

export const DivLogo = styled.div`
  display: none;
  
  @media (max-width: ${TABLET_SIZE}px) {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }
`;

export const TextName = styled.p`
  color: ${DIPPER_BLUE};
  font: normal normal bold 12px/15px Mulish;
`;
