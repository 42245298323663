import React from 'react';

import { timePassedSince } from 'helpers/dates';
import { commentPropTypes } from 'helpers/propTypes';
import { isPoc } from 'constants/roles';

import DefaultProfile from 'components/commons/assets/avatar-poc.svg';
import DefaultProfileCompany from 'components/commons/assets/avatar-company.svg';

import {
  CommentContainer, ProfilePicture, TextSection, Row, Name, CommentTime,
} from './styles';

const Comment = (props) => {
  const { comment } = props;

  const isPocUser = isPoc(comment?.user.role);

  const renderProfileImage = () => {
    if (isPocUser) {
      return comment.user.profilePic
        ? comment.user.profilePic.path : DefaultProfile;
    }
    return comment.user.company.logo
      ? comment.user.company.logo.path
      : DefaultProfileCompany;
  };
  return (
    <CommentContainer data-testid="comment-container">
      <ProfilePicture
        src={renderProfileImage()}
      />
      <TextSection>
        <Row>
          <Name>
            {isPocUser
              ? `${comment.user.firstName} ${comment.user.lastName}`
              : comment.user.company.name}
          </Name>
          <CommentTime>
            {timePassedSince(comment.dateCreated)}
          </CommentTime>
        </Row>
        {comment.description}
      </TextSection>
    </CommentContainer>
  );
};

Comment.propTypes = {
  comment: commentPropTypes.isRequired,
};

export default Comment;
