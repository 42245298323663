import styled from 'styled-components';

import { ReactComponent as FacebookIcon } from 'components/commons/assets/FacebookIcon.svg';
import { ReactComponent as LinkedInIcon } from 'components/commons/assets/LinkedinIcon.svg';
import { ReactComponent as InstagramIcon } from 'components/commons/assets/InstagramIcon.svg';
import { ReactComponent as InfoIcon } from 'components/commons/assets/InfoIcon.svg';
import { ReactComponent as CloseIcon } from 'components/commons/assets/close.svg';

import { TABLET_SIZE, MOBILE_SIZE, SMALL_DESKTOP_SIZE_WITH_PADDING } from 'constants/sizes';
import {
  BOX_SHADOW, MENU_BLUE, WHITE, DIPPER_BLUE, GREEN_REVIEW,
} from 'styles/colors';
import { ReactComponent as ProfileIcon } from './assets/icon-profile.svg';
import { ReactComponent as SettingIcon } from './assets/icon-settings.svg';

export const TransparentDiv = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  height: 104%;
  width: 100%;
  z-index: 3;
`;

export const PortraitContainer = styled.div`
  background: ${MENU_BLUE};
  position: fixed;
  z-index: 4;
  padding: 18px 20px 21px 20px;
  font-size: 20px;
  flex-direction: column;
  box-shadow: 0px 3px 6px ${BOX_SHADOW};
  height: 100%;
  top: 0;
  right: 0;
  width: 63%;
  max-width: 236px;
  transform: ${({ hasTransition }) => (hasTransition ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.5s ease-in-out;

  .container {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0;

    @media (max-width: ${SMALL_DESKTOP_SIZE_WITH_PADDING}px) {
      padding: 0 25px;
      max-width: 100%;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const PortraitContent = styled.div`
  align-items: center;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  line-height: 50px;
  justify-content: space-between;
  padding-left: 0px !important;
  height: 60px;

  @media (max-width: ${MOBILE_SIZE}px) {
    height: 48px;
  }
`;

export const NavbarSection = styled.nav`
  width: 100%;
  line-height: 50px;
  padding-left: 0;
  padding-right: 0;

  ul li a,
  ul li span { 
    display: flex;
    flex: 1;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    -webkit-transition: color 0.3s ease;
    -moz-transition: color 0.3s ease;
    -ms-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;
  }

  ul li a:hover,
  li span:hover {
   
  }

  @media (max-width: ${TABLET_SIZE}px) {
    margin-bottom: 0px;
  }
`;

export const All = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UserDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-bottom: 25px;
  text-align: center;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover
`;

export const Name = styled.div`
  color: ${WHITE};
  font-size: 16px;
  font-weight: bold;
`;

export const Description = styled.div`
  color: ${WHITE};
  font-size: 14px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${DIPPER_BLUE};
  border-bottom: 1px solid ${DIPPER_BLUE};
  color: ${WHITE};
  font-size: 16px;
  line-height: 20px;
  padding: 10px 0;
`;

export const Profile = styled(ProfileIcon)`
  margin-right: 10px;
`;

export const Setting = styled(SettingIcon)`
  margin-right: 16px;
`;

export const SocialMediaDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconsDiv = styled.div`
  padding-top: 15px;
  border-top: 1px solid ${DIPPER_BLUE};
`;

export const Info = styled(InfoIcon)`
  width: 36px;
  height: 36px;
  margin-bottom: 15px;
  fill: ${GREEN_REVIEW};
`;

export const Facebook = styled(FacebookIcon)`
  fill: ${WHITE};
  width: 34px;
  heigth: 34px;
`;

export const Instagram = styled(InstagramIcon)`
  fill: ${WHITE};
  margin-right: 15px;
  width: 34px;
  heigth: 34px;
`;

export const LinkedIn = styled(LinkedInIcon)`
  fill: ${WHITE};
  margin-right: 15px;
  width: 34px;
  heigth: 34px;
`;

export const Close = styled(CloseIcon)`
  position: fixed;
  height: 15px;
  width: 15px;
  top: 20px;
  right: 20px;
  fill: ${WHITE};
  display: ${({ show }) => (show ? 'flex' : 'none')};
  z-index: 0;
`;
