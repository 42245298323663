export const JOB_GET_ALL_POC_REQUESTED = 'JOB_GET_ALL_POC_REQUESTED';
export const JOB_GET_ALL_POC_SUCCEDED = 'JOB_GET_ALL_POC_SUCCEDED';
export const JOB_GET_ALL_POC_FAILED = 'JOB_GET_ALL_POC_FAILED';

export const JOB_GET_ALL_DASHBOARD_REQUESTED = 'JOB_GET_ALL_DASHBOARD_REQUESTED';
export const JOB_GET_ALL_DASHBOARD_SUCCEDED = 'JOB_GET_ALL_DASHBOARD_SUCCEDED';
export const JOB_GET_ALL_DASHBOARD_FAILED = 'JOB_GET_ALL_DASHBOARD_FAILED';

export const JOB_GET_ALL_COMPANY_REQUESTED = 'JOB_GET_ALL_COMPANY_REQUESTED';
export const JOB_GET_ALL_COMPANY_SUCCEDED = 'JOB_GET_ALL_COMPANY_SUCCEDED';
export const JOB_GET_ALL_COMPANY_FAILED = 'JOB_GET_ALL_COMPANY_FAILED';

export const JOB_ON_ADD_OFFER_REQUESTED = 'JOB_ON_ADD_OFFER_REQUESTED';
export const JOB_ON_ADD_OFFER_SUCCEDED = 'JOB_ON_ADD_OFFER_SUCCEDED';
export const JOB_ON_ADD_OFFER_FAILED = 'JOB_ON_ADD_OFFER_FAILED';

export const JOB_ON_UPDATE_REQUESTED = 'JOB_ON_UPDATE_REQUESTED';
export const JOB_ON_UPDATE_SUCCEDED = 'JOB_ON_UPDATE_SUCCEDED';
export const JOB_ON_UPDATE_FAILED = 'JOB_ON_UPDATE_FAILED';

export const JOB_ON_DELETE_REQUESTED = 'JOB_ON_DELETE_REQUESTED';
export const JOB_ON_DELETE_SUCCEDED = 'JOB_ON_DELETE_SUCCEDED';
export const JOB_ON_DELETE_FAILED = 'JOB_ON_DELETE_FAILED';

export const JOB_SET_FILTERS_REQUESTED = 'JOB_SET_FILTERS_REQUESTED';
export const JOB_SET_FILTERS_SUCCEDED = 'JOB_SET_FILTERS_SUCCEDED';
export const JOB_SET_FILTERS_FAILED = 'JOB_SET_FILTERS_FAILED';

export const JOB_SET_SELECTED_REQUESTED = 'JOB_SET_SELECTED_REQUESTED';

export const JOB_SET_PAGE_REQUESTED = 'JOB_SET_PAGE_REQUESTED';

export const JOB_SET_INACTIVE_STATUS_REQUESTED = 'JOB_SET_INACTIVE_STATUS_REQUESTED';
export const JOB_SET_INACTIVE_STATUS_SUCCEDED = 'JOB_SET_INACTIVE_STATUS_SUCCEDED';
export const JOB_SET_INACTIVE_STATUS_FAILED = 'JOB_SET_INACTIVE_STATUS_FAILED';
