import Yash_KamaaT from '../assets/Yash-KamaaT.jpg';
import Jesus_Cardenas from '../assets/jesus_cardenas.jpeg';
import Obioma_Richardson from '../assets/Obioma-Richardson.jpeg';
import Nye_Jones from '../assets/Nye-Jones.jpg';

export const quotes = [
  {
    id: 1,
    image: Yash_KamaaT,
    name: 'Yash Kamaat',
    jobTitle: 'Data Analytics',
    description: 'Dipper provides career insights meaningful for people of color that other, conventional career websites don’t. Those combinations of experiences have been like a light in the night.',
  },
  {
    id: 2,
    image: Jesus_Cardenas,
    name: 'Jesus Cardenas',
    jobTitle: 'Software Engineer',
    description: 'Finally, a platform that gives PoC insight on what’s really happening at a company before joining. This platform is going to help PoC from not waste time in spaces that perpetuate microaggressions.',
  },
  {
    id: 3,
    image: Obioma_Richardson,
    name: 'Obioma Richardson',
    jobTitle: 'Telecommunications Specialist',
    description: '15 years plus of being in the IT industry and not having a safe space to discuss how being in certain industries as a woman of color in tech has been challenging.  Along comes Dipper which gave people of a color a safe space to expose what really happens in/at places of work.  I value the work that goes into providing peace of mind to discuss what happens and gives us a chance to select where we choose to bring our talent and experience wisely.',
  },
  {
    id: 4,
    image: Nye_Jones,
    name: 'Nye Jones',
    jobTitle: 'Certified Diversity Professional',
    description: 'Dipper is an awesome tool and resource for an organization’s journey regarding the importance of taking risks and making mistakes for the sake of learning.  Dipper provides the authentic feelings of employees expert and will help organizations learn and succeed sooner at diversity, inclusion, equity, and equality.',
  },
];

export default { quotes };
