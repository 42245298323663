import styled from 'styled-components';

import { DIPPER_BLUE, GRAY_COMMUNITY_LINES } from 'styles/colors';

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  color: ${DIPPER_BLUE};
  border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
  padding-top: 15px;
  word-break: break-all;
`;

export const ProfilePicture = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  object-fit: cover;
`;

export const TextSection = styled.div`
  width: 100%;
  padding: 10px 0 15px 10px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  white-space: pre-line;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

export const Name = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 4px;
`;

export const CommentTime = styled.div`
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
`;
