import React from 'react';
import PropTypes from 'prop-types';

import Menu from 'components/SideMenu/Menu';
import Logo from 'components/SideMenu/Logo';
import SocialMedia from 'components/SideMenu/SocialMedia';

import {
  Arrow, NavbarSection, PortraitContent, LandscapeContent, PortraitMenu, LandscapeMenu,
  PortraitContainer, LandscapeContainer, All, ArrowIcon,
} from './styles';

const Navbar = (props) => {
  const {
    isLandscape, isMenuOpened, isPortraitMenuOpened, links, loading, onClick, onClickPortrait,
    onLogoutAction, user,
  } = props;

  const NavbarContainer = isLandscape ? LandscapeContainer : PortraitContainer;
  const NavbarContent = isLandscape ? LandscapeContent : PortraitContent;

  return (
    <NavbarContainer data-testid="navbar">
      <All className={!isLandscape ? 'container' : ''}>
        <NavbarSection>
          <NavbarContent>
            {isLandscape
              && (
                <Arrow open={isMenuOpened} isLandscape={isLandscape}>
                  <ArrowIcon onClick={onClick} />
                </Arrow>
              )}
            <Logo isLandscape={isLandscape} open={isMenuOpened} toDashboard={isLandscape} />
            <Menu
              open={isMenuOpened}
              links={links}
              loading={loading}
              onLogoutAction={onLogoutAction}
              UlStyledComponent={isLandscape ? LandscapeMenu : PortraitMenu}
              user={user}
              isLandscape={isLandscape}
              onClickPortrait={onClickPortrait}
              openLanding={isPortraitMenuOpened}
            />
          </NavbarContent>
        </NavbarSection>
      </All>
      <SocialMedia open={isMenuOpened} isLandscape={isLandscape} />
    </NavbarContainer>
  );
};

Navbar.defaultProps = {
  user: null,
  isMenuOpened: true,
  onClick: () => {},
  onClickPortrait: () => {},
};

Navbar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
  onLogoutAction: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onClickPortrait: PropTypes.func,
  isLandscape: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  isMenuOpened: PropTypes.bool,
  isPortraitMenuOpened: PropTypes.bool.isRequired,
};

export default Navbar;
