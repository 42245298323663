import * as constants from 'redux/constants/user.constants';

export const onLogin = (formData, pathname) => ({
  type: constants.USER_ON_LOGIN_REQUESTED,
  formData,
  pathname,
});

export const onGoogleLogin = (credentials, pathname) => ({
  type: constants.USER_ON_GOOGLE_LOGIN_REQUESTED,
  credentials,
  pathname,
});

export const onLoginSucceded = (data, pathname) => ({
  type: constants.USER_ON_LOGIN_SUCCEEDED,
  data,
  pathname,
});

export const onLoginFailed = (error) => ({
  type: constants.USER_ON_LOGIN_FAILED,
  error,
});

export const onLogout = () => ({ type: constants.USER_ON_LOGOUT });

export const onResetPassword = (formData) => ({
  type: constants.USER_ON_RESET_PASSWORD_REQUESTED,
  formData,
});

export const onRegister = (formData) => ({
  type: constants.USER_ON_REGISTER_REQUESTED,
  formData,
});

export const onGoogleSignup = (credentials) => ({
  type: constants.USER_ON_GOOGLE_SIGNUP_REQUESTED,
  credentials,
});

export const onSignupSucceded = (data) => ({
  type: constants.USER_ON_REGISTER_SUCCEEDED,
  data,
});

export const onSignupFailed = (formData) => ({
  type: constants.USER_ON_REGISTER_FAILED,
  formData,
});

export const onUpdate = (formData) => ({
  type: constants.USER_ON_UPDATE_REQUESTED,
  formData,
});

export const OnConfirmAccount = (token, password, companyName) => ({
  type: constants.USER_ON_ACTIVATE_ACCOUNT_REQUESTED,
  token,
  password,
  companyName,
});

export const onForgotPassword = (formData) => ({
  type: constants.USER_ON_FORGOT_PASSWORD_REQUESTED,
  formData,
});

export const onContact = (formData) => ({
  type: constants.USER_ON_CONTACT_REQUESTED,
  formData,
});

export const onUpdatePassword = (formData) => ({
  type: constants.USER_ON_UPDATE_PASSWORD_REQUESTED,
  formData,
});

export const onLoadProfileImageFailed = () => ({
  type: constants.USER_ON_LOAD_PROFILE_IMAGE_FAILED,
});

export const onCloseOnBoarding = () => (
  {
    type: constants.USER_ON_CLOSE_ONBOARDING_REQUESTED,
  });
