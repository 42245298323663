import styled from 'styled-components';

import { GRAY_BACKGROUND, GRAY_COMMUNITY_LINES, GREEN_REVIEW } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  height: 11px;
  border-radius: 4px;
  opacity: 1;
  margin-top: 3px;
  background-color:  ${({ isEmpty }) => (isEmpty ? GRAY_BACKGROUND : GRAY_COMMUNITY_LINES)};
`;

export const Progress = styled.div`
  width: ${({ progress, total }) => (progress && `${(progress * 100) / total}%`)};
  border-radius: 4px;
  background-color: ${GREEN_REVIEW};
`;
