import * as constants from 'redux/constants/company.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState = {
  items: [],
  rank: null,
};

const companyReducer = (state = defaultState, action) => {
  const { data } = action;
  switch (action.type) {
  case constants.COMPANY_ON_EMPTY_STATE_REQUESTED:
    return {
      ...state,
      rank: null,
    };
  case constants.COMPANY_GET_ALL_SUCCEDED:
    return {
      ...state,
      items: data.companies,
    };
  case constants.COMPANY_ON_GET_RANK_SUCCEEDED:
    return {
      ...state,
      rank: data,
    };
  case userConstants.USER_ON_LOGOUT:
    return defaultState;
  case constants.COMPANY_ON_GET_RANK_FAILED:
  case constants.COMPANY_GET_ALL_FAILED:
  default:
    return state;
  }
};

export default companyReducer;
