import React, { useEffect } from 'react';
import { Router, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Notification } from 'react-admin';
import reduxStore from 'redux/store';

import authProvider from 'admin/helpers/authProvider';
import dataProvider from 'admin/helpers/dataProvider';
import { adminHistory, clientHistory } from 'helpers/history';
import ScrollToTop from 'containers/ScrollToTop';
import NavigationContainer from 'containers/NavigationContainer';
import * as constants from 'redux/constants/app.constants';
import GlobalFonts from 'styles/fonts/fonts';

const props = { authProvider, dataProvider, history: adminHistory };
const store = reduxStore(props);

const App = () => {
  useEffect(() => {
    store.dispatch({ type: constants.APP_ON_INITIALIZE_REQUESTED });
  }, []);

  const Canonical = () => {
    const location = useLocation();
    const canonicalUrl = `${window.location.origin}${location.pathname}`;

    return location.pathname !== '/' ? <link rel="canonical" href={canonicalUrl} /> : <></>;
  };

  return (
    <>
      <GlobalFonts />
      <Provider store={store}>
        <Router history={clientHistory}>
          <Canonical />
          <ScrollToTop />
          <NavigationContainer {...props} />
          <Notification />
        </Router>
      </Provider>
    </>
  );
};

export default App;
