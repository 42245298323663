import React from 'react';
import { Field } from 'redux-form';

import Input from 'components/commons/Input';
import * as validates from 'helpers/validates';

import {
  USER_BIOGRAPHY, USER_COUNTRY, USER_EMAIL, USER_FIRST_NAME, USER_HEAD_LINE,
  USER_JOB_POSITION, USER_LAST_NAME,
} from 'constants/inputSizes';

import {
  LeftInputsContainer, InputDiv, InputsSection, LeftInputs, InputsName, RightInputs,
} from '../styles';

const FormPoc = () => (
  <InputsSection>
    <LeftInputsContainer>
      <LeftInputs>
        <InputsName>
          <InputDiv isInputName>
            <Field
              component={Input}
              variant="outlined"
              name="firstName"
              label="Name"
              validate={validates.required}
              size="small"
              isProfileInput
              maxLength={USER_FIRST_NAME}
            />
          </InputDiv>
          <InputDiv isInputName>
            <Field
              component={Input}
              variant="outlined"
              name="lastName"
              label="Last name"
              validate={validates.required}
              size="small"
              isProfileInput
              maxLength={USER_LAST_NAME}
            />
          </InputDiv>
        </InputsName>
        <InputDiv>
          <Field
            component={Input}
            variant="outlined"
            name="headline"
            label="Headline"
            size="small"
            isProfileInput
            maxLength={USER_HEAD_LINE}
          />
        </InputDiv>
        <InputDiv>
          <Field
            component={Input}
            variant="outlined"
            name="jobPosition"
            label="Current position"
            size="small"
            isProfileInput
            maxLength={USER_JOB_POSITION}
          />
        </InputDiv>
        <InputDiv>
          <Field
            component={Input}
            variant="outlined"
            name="country"
            label="Country/Region"
            size="small"
            isProfileInput
            maxLength={USER_COUNTRY}
          />
        </InputDiv>
        <InputDiv>
          <Field
            component={Input}
            variant="outlined"
            name="email"
            label="Email"
            validate={[validates.required, validates.email]}
            disabled
            size="small"
            socialMedia="email"
            maxLength={USER_EMAIL}
          />
        </InputDiv>
      </LeftInputs>
    </LeftInputsContainer>
    <RightInputs isUserPremium>
      <h1>Social Profiles</h1>
      <InputDiv>
        <Field
          component={Input}
          variant="outlined"
          name="linkedIn"
          label="LinkedIn"
          size="small"
          socialMedia="linkedIn"
          isProfileInput
          hasLessPaddingTop
        />
      </InputDiv>
      <InputDiv>
        <Field
          component={Input}
          variant="outlined"
          name="facebook"
          label="Facebook"
          size="small"
          socialMedia="facebook"
          isProfileInput
        />
      </InputDiv>
      <InputDiv>
        <Field
          component={Input}
          variant="outlined"
          name="instagram"
          label="Instagram"
          size="small"
          socialMedia="instagram"
          isProfileInput
        />
      </InputDiv>
      <InputDiv hasMarginTop>
        <h1>Bio</h1>
        <Field
          component={Input}
          variant="outlined"
          name="biography"
          label="Add a small bio to your profile"
          type="textarea"
          isProfileInput
          hasLessPaddingTop
          rows={5}
          rowsMax={5}
          maxLength={USER_BIOGRAPHY}
        />
      </InputDiv>
    </RightInputs>
  </InputsSection>
);

export default FormPoc;
