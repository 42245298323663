/* eslint-disable consistent-return */

export function validateRequired(field) {
  if (!field || field.length < 1 || !/[a-zA-Z0-9-,]+/.test(field)) {
    return 'Required';
  }
}

export function validateMaxSize(maxSize, field) {
  if (field && field.length > maxSize) {
    return `Field should be shorter than ${maxSize} characters`;
  }
}

export function validatePassword(password) {
  // This IF is for edit view where password in not require
  if (!password) {
    return;
  }

  if (password.length < 8) {
    return 'Password require at least 8 characters';
  }
  if (password.length > 250) {
    return 'Password maximum characters is 250';
  }
  /* eslint-disable-next-line no-useless-escape */
  const complex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!_@#$%^&+=¨'¿¡?<>\"(),;.\-}{\[\]\*])/;
  const isComplex = complex.test(password);
  if (!isComplex) {
    return 'Password require at least 1 uppercase character, numbers and symbol';
  }
}
