import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';

import { PageContainer } from 'styles/commons';
import {
  companyPropTypes, rankPropTypes, insightsArrayPropTypes,
} from 'helpers/propTypes';

import PieChartComponent from 'components/commons/PieChart';
import BarChart from 'components/commons/BarChart';
import StarsRating from 'components/commons/StarsRating';
import CorporateMembership from 'components/CorporateMembership';
import ExperienceAtWorkBanner from 'components/ExperienceAtWorkBanner';

import { PIE_CHART_COLORS } from '../Insights/constants';

import {
  Content, TopTitles, Title, ChartContainer,
  RowContainer, Border, TopInfo, Name, Industry, Location, CompanyDescription,
  CompanyData, CompanyRating, SubTitle, IconLocation, WebLink, CompanyInformation,
  InformationName, SocialNetworks, Facebook, LinkedIn, Instagram, ReviewStats, Recommended,
  PercentageOfRecommended, Ranking, RankingPosition, Logo, WebsiteDiv,
  AllReviewsDiv, SeeMore, ArrowDiv, ArrowBack, CustomLink, EmptyOverviewText,
} from './styles';

import * as constants from './constants';

const Insights = (props) => {
  const {
    company, insights, isCompanyUser, isPremiumUser, rank,
    hasToRenderAllReviewDetails, reviews, handleScroll, onOpenModal,
    isMyCompany,
  } = props;
  const {
    description, facebook, instagram, founded, image, industry, isPremium, linkedIn, location,
    name, size, website, deiCommitments,
  } = company;
  const [isClamped, setIsClamped] = useState('');
  const [seeMore, setSeeMore] = useState(true);
  const [seeMoreDei, setSeeMoreDei] = useState(true);

  const ellipsis = (state, setState) => isClamped && (
    <SeeMore seeMore={state} onClick={() => setState(!state)}>
      {' '}
      <span>...</span>
      {' '}
      see more
      {' '}
    </SeeMore>
  );

  const renderInformationWithSeeMore = (info, state, setState) => (
    <LinesEllipsis
      text={info}
      maxLine="2"
      ellipsis={ellipsis(state, setState)}
      trimRight
      basedOn="letters"
      onReflow={({ clamped }) => setIsClamped(clamped)}
    />
  );

  const renderInformation = (infoName, info) => {
    if (infoName === constants.ABOUT && seeMore) {
      return renderInformationWithSeeMore(info, seeMore, setSeeMore);
    }
    if (infoName === constants.DEI && seeMoreDei) {
      return renderInformationWithSeeMore(info, seeMoreDei, setSeeMoreDei);
    }
    return info;
  };

  const renderCompanyInformation = (infoName, info) => (
    <CompanyInformation>
      <InformationName>
        {infoName}
        :
      </InformationName>
      {renderInformation(infoName, info)}
    </CompanyInformation>
  );

  const renderColumn = (children) => (
    <ChartContainer className="col-lg-6 col-md-12">
      {children}
    </ChartContainer>
  );

  const renderTitle = () => {
    if (hasToRenderAllReviewDetails) {
      return 'Company Reviews';
    }
    if (isMyCompany) {
      return 'My company';
    }
    return 'Company';
  };

  return (
    <PageContainer data-testid="company-page">
      <Content onScroll={hasToRenderAllReviewDetails && handleScroll}>
        <TopTitles id="top">
          <CustomLink to="/insights">
            <ArrowDiv>
              <ArrowBack />
            </ArrowDiv>
            <Title>
              Dipper Insights
            </Title>
          </CustomLink>
          <Border>|</Border>
          <Title isSubTitle>
            {renderTitle()}
          </Title>
        </TopTitles>
        <div className="row ">
          <div className="col-lg-9 col-md-12.no-gutters ">
            <div className="column">
              <TopInfo haveBorderBottom={hasToRenderAllReviewDetails}>
                <CompanyData>
                  <Logo src={image} />
                  <CompanyDescription>
                    <Name>
                      {name}
                    </Name>
                    <Industry>
                      {industry}
                    </Industry>
                    {location
                      && (
                        <Location>
                          <IconLocation />
                          {location}
                        </Location>
                      )}
                  </CompanyDescription>
                </CompanyData>
                {!!rank
                && (
                  <CompanyRating>
                    <Recommended>
                      Recommended
                      <PercentageOfRecommended>
                        {rank.recommendedPercentage}
                        %
                      </PercentageOfRecommended>
                    </Recommended>
                    <Ranking>
                      Ranking
                      <RankingPosition>
                        {rank.rank}
                      </RankingPosition>
                      <div>
                        <StarsRating
                          input={{ name: '', value: rank.stars, onChange: () => {} }}
                          meta={{ touched: false, error: '' }}
                          readOnly
                          showText={false}
                          size="small"
                        />
                      </div>
                    </Ranking>
                  </CompanyRating>
                )}
              </TopInfo>
              {!hasToRenderAllReviewDetails ? (
                <>
                  <SubTitle>Company Overview </SubTitle>
                  {description || facebook || founded || linkedIn || location
                    || size || website || instagram || deiCommitments
                    ? (
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          {website && (
                            <WebsiteDiv>
                              <a target="_blank" rel="noopener noreferrer" href={`//${website}`}>
                                <WebLink>
                                  {website}
                                </WebLink>
                              </a>
                            </WebsiteDiv>
                          )}
                          {size && renderCompanyInformation(constants.SIZE, size)}
                          {founded
                            && renderCompanyInformation(constants.FOUNDED, founded.slice(0, 4))}
                          {industry
                            && renderCompanyInformation(constants.INDUSTRY, industry)}
                        </div>
                        <div className="col-lg-6 col-md-12">
                          {description
                            && renderCompanyInformation(constants.ABOUT, description, true)}
                          {deiCommitments
                            && renderCompanyInformation(constants.DEI, deiCommitments, true)}
                          {
                            (isPremium && (facebook || linkedIn || instagram))
                              && (
                                <SocialNetworks>
                                  Social networks:
                                  {linkedIn
                                    && (
                                      <a target="_blank" rel="noopener noreferrer" href={linkedIn}>
                                        <LinkedIn />
                                      </a>
                                    )}
                                  {facebook
                                  && (
                                    <a target="_blank" rel="noopener noreferrer" href={facebook}>
                                      <Facebook />
                                    </a>
                                  )}
                                  {instagram
                                  && (
                                    <a target="_blank" rel="noopener noreferrer" href={instagram}>
                                      <Instagram />
                                    </a>
                                  )}
                                </SocialNetworks>
                              )
                          }
                        </div>
                      </div>
                    )
                    : (
                      <EmptyOverviewText>
                        This company does not have information yet
                      </EmptyOverviewText>
                    )}
                  <ReviewStats>
                    <SubTitle isStatsTitle>
                      Reviews Stats
                    </SubTitle>
                    <RowContainer className="row">
                      {renderColumn(
                        <PieChartComponent {...insights[0]} colors={PIE_CHART_COLORS} />,
                      )}
                      {renderColumn(
                        <PieChartComponent {...insights[1]} colors={PIE_CHART_COLORS} />,
                      )}
                    </RowContainer>
                    <RowContainer className="row">
                      {renderColumn(<BarChart {...insights[3]} />)}
                      {renderColumn(<BarChart {...insights[4]} />)}
                    </RowContainer>
                  </ReviewStats>
                </>
              )
                : (
                  <AllReviewsDiv>
                    {reviews}
                  </AllReviewsDiv>
                )}
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            {!isCompanyUser && <ExperienceAtWorkBanner isCompanyProfile />}
            {!hasToRenderAllReviewDetails && reviews}
            {(!isPremiumUser && isCompanyUser) && <CorporateMembership onClick={onOpenModal} />}
          </div>
        </div>
      </Content>
    </PageContainer>
  );
};

Insights.propTypes = {
  company: companyPropTypes.isRequired,
  insights: insightsArrayPropTypes.isRequired,
  isCompanyUser: PropTypes.bool.isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
  rank: rankPropTypes.isRequired,
  hasToRenderAllReviewDetails: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  reviews: PropTypes.func.isRequired,
  handleScroll: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  isMyCompany: PropTypes.bool.isRequired,
};

export default Insights;
