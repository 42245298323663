import styled from 'styled-components';

import { WHITE, BLUE_DIPPER, GRAY_COMMUNITY_LINES } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px 19px;
  border-radius: 6px;
  background-color: ${WHITE}
`;

export const Title = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: ${BLUE_DIPPER};
  font-weight: bold;
`;

export const RankingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Ranking = styled.ol`
  margin: 13px 0 0;
  padding: 0;
  counter-reset: section;   
`;

export const ItemList = styled.li`
  display: flex;
  flex-direction: row;
  margin: 0;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${GRAY_COMMUNITY_LINES};
  counter-increment: section;
  margin-top: 6px;
  padding-bottom: 5px;

  ::before {
    content: counter(section);
    font-size: 12px;
    color: ${BLUE_DIPPER};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 10px;
`;

export const LogoAndName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
`;

export const Logo = styled.img`
  margin-right: 13px;
  width: 28px;
  height: 28px;
`;

export const Name = styled.div`
  font-size: 12px;
  color: ${BLUE_DIPPER};
`;

export const Stars = styled.div`
  display: flex;
  margin: 0 13px;
  align-self: center;
`;

export const EmptyRankingText = styled.div`
  font-size: 10px !important;
  line-height: 12px;
  color: #474F63;
  margin-top: 14px;
  list-style: none;
  font-size: 13px;
  line-height: 15px;
`;
