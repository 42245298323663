import React from 'react';

import {
  StyledDiv, TextSection, ImagesSection, OneImageContainer, CustomImage, NameText, CoFounderText,
  StarsLeft, StarsRight, CustomH1, CustomH5,
} from './styles';

import JacintaImg from './assets/jacinta@2x-min.png';
import NettaImg from './assets/netta@2x-min.png';

const Founders = () => (
  <StyledDiv>
    <TextSection>
      <CustomH1>
        Meet our founders
      </CustomH1>
      <CustomH5>
        In villages from Liberia to Louisiana our ancestors looked to the stars,
        {' '}
        specifically the Big Dipper and Little Dipper, as a way to lead them on a
        {' '}
        journey and to a better life. This is why we built Dipper.
      </CustomH5>
      <CustomH5>
        Our goal is to build a community of professionals of color who are willing
        {' '}
        to share their experience, insights, and truth regarding their experience within
        {' '}
        the workplace.
      </CustomH5>
    </TextSection>
    <ImagesSection>
      <OneImageContainer>
        <CustomImage src={JacintaImg} />
        <div>
          <NameText>
            Jacinta C. Mathis
          </NameText>
          <CoFounderText>
            Co-Founder
          </CoFounderText>
        </div>
      </OneImageContainer>
      <OneImageContainer rightImage>
        <CustomImage src={NettaImg} />
        <div>
          <NameText>
            Netta Jenkins
          </NameText>
          <CoFounderText>
            Co-Founder
          </CoFounderText>
        </div>
      </OneImageContainer>
    </ImagesSection>
    <StarsLeft />
    <StarsRight />
  </StyledDiv>
);

export default Founders;
