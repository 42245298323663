import * as constants from 'redux/constants/feed.constants';

export function onFeedGetRecent(quantity, page = 0) {
  return {
    type: constants.FEED_GET_RECENT_REQUESTED,
    quantity,
    page,
  };
}

export function onAddFeed(formData) {
  return {
    type: constants.FEED_ON_ADD_REQUESTED,
    formData,
  };
}

export function onFeedAddComment(formData) {
  return {
    type: constants.FEED_ON_ADD_COMMENT_REQUESTED,
    formData,
  };
}

export function onSetFeedSelected(feed) {
  return {
    type: constants.FEED_SET_SELECTED_REQUESTED,
    feed,
  };
}
