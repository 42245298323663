import * as constants from 'redux/constants/review.constants';
import * as companyConstants from 'redux/constants/company.constants';
import * as userConstants from 'redux/constants/user.constants';

const defaultState = {
  companyItems: [],
  userItems: [],
  hasMore: true,
  page: 0,
  totalAmountOfReviews: 0,
  selected: null,
};

const replaceReviewIn = (review, colection) => {
  const newColection = colection.map((elem) => {
    if (elem.id === review.id) {
      return review;
    }
    return elem;
  });
  return newColection;
};

const reviewReducer = (state = defaultState, action) => {
  const { data } = action;
  let companyItems = [];
  let userItems = [];
  switch (action.type) {
  case companyConstants.COMPANY_ON_EMPTY_STATE_REQUESTED:
    return {
      ...state,
      companyItems: [],
      hasMore: true,
      page: 0,
      totalAmountOfReviews: 0,
      selected: null,
    };
  case constants.REVIEW_GET_RECENT_BY_COMPANY_ID_SUCCEDED:
  case constants.REVIEW_GET_ALL_BY_COMPANY_ID_SUCCEDED:
  case constants.REVIEW_GET_BY_COMPANY_ID_SUCCEDED:
    companyItems = (action.page === 0) ? [...data.reviews]
      : [...state.companyItems, ...data.reviews];
    return {
      ...state,
      companyItems,
      hasMore: data.reviews.length > 0,
      page: data.page,
      totalAmountOfReviews: data.totalAmountOfReviews,
      selected: (state.selected || companyItems[0]),
    };
  case constants.REVIEW_ON_ADD_SUCCEDED:
    return {
      ...state,
      selected: data,
    };
  case constants.REVIEW_ADD_ANSWER_SUCCEDED:
    return {
      ...state,
      companyItems: [...replaceReviewIn(data, state.companyItems)],
    };
  case constants.REVIEW_GET_RECENT_BY_USER_ID_SUCCEDED:
  case constants.REVIEW_GET_ALL_BY_USER_ID_SUCCEDED:
    return {
      ...state,
      userItems: data.reviews,
      selected: (state.selected || data.reviews[0]),
    };
  case userConstants.USER_ON_LOGOUT:
    return defaultState;
  case constants.REVIEW_ON_UPDATE_SUCCEDED:
    return {
      ...state,
      userItems: [...replaceReviewIn(data, state.companyItems)],
      selected: data.review,
    };
  case constants.REVIEW_SET_SELECTED_REQUESTED:
    return {
      ...state,
      selected: action.review,
    };
  case constants.REVIEW_ON_DELETE_SUCCEDED:
    userItems = state.userItems.filter((elem) => elem.id !== state.selected.id);
    return {
      ...state,
      userItems,
      selected: userItems.length ? userItems[0] : null,
    };
  case constants.REVIEW_GET_BY_COMPANY_ID_FAILED:
  case constants.REVIEW_GET_RECENT_BY_COMPANY_ID_FAILED:
  case constants.REVIEW_GET_RECENT_BY_USER_ID_FAILED:
  default:
    return state;
  }
};

export default reviewReducer;
