import styled, { css } from 'styled-components';
import { BLACK, GRAY } from 'styles/colors';
import { TABLET_SIZE, MOBILE_SIZE } from 'constants/sizes';
import { ReactComponent as LogoDipperPic } from 'components/commons/assets/dipper-logo.svg';

export const PortraitContainer = styled.div`
  padding-left: 0 !important;
  
  a span {
    color: ${GRAY};
  }

  a:hover span {
    color: ${BLACK};
  }

  @media (max-width: 1024px) {
    padding-left: 25px;
  }
`;

export const LogoDipper = styled(LogoDipperPic)`
  width: 100px;
  height: 41px;
  vertical-align: middle;
  filter: ${({ whiteLogo }) => (whiteLogo && 'brightness(800%)')};

  ${({ islandscape }) => (islandscape
    && css`
      width: 72px;
      height: 30px;
      filter: brightness(800%);
    `
  )};

  @media (max-width: ${MOBILE_SIZE}px) {
    ${({ whiteLogo }) => (!whiteLogo && css`
      width: 79px;
      height: 33px;
      margin-bottom: 5px;
    `)};
  }
`;

export const LandscapeContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;

  ${({ open }) => (open
    ? css`
      margin-top: 0px;
      margin-left: 28px;
    `
    : css`
      margin-left: 17px; 
      margin-top: 10px;
      @media (max-width: 992px) {
        margin-top: 14px;
      }
    `)
};

  @media (max-width: ${TABLET_SIZE}px) {
    display: none;
  }
`;
