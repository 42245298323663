import styled, { css } from 'styled-components';

export const StyledArrow = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 48px;
  bottom: auto;
  border-radius: 50%;
  z-index: 2;
  cursor: pointer;

  ${({ reverse }) => (
    reverse
      ? css`
      left: 0;
      transform: rotate(180deg);
    `
      : css`
      right: 0;
    `
  )};
`;

export default { StyledArrow };
