import styled from 'styled-components';
import { BOOSTRAP_MEDIUM } from 'constants/sizes';

import { DIPPER_BLUE, GRAY_COMMUNITY, WHITE } from 'styles/colors';
import { ReactComponent as EditProfileImage } from './assets/dipper-edit-profile-img.svg';

export const DivProfile = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 2px 0 20px;
  background-color: ${WHITE};
  border-radius: 6px;
  width: 100%;
`;

export const Images = styled.div`
  position: relative;
  height: fit-content;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 71px;
  height: 71px;
  margin-top: 13px;
  object-fit: cover;
`;

export const EditProfileDiv = styled.div`
  margin-left: 27px;
`;

export const EditProfile = styled(EditProfileImage)`
  position: absolute;
  height: 25px;
  width: 28px;
  bottom: -2px;
`;

export const UserName = styled.p`
  color: ${DIPPER_BLUE};
  margin-top: 2px;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
`;

export const CompanyRole = styled.p`
  color: ${DIPPER_BLUE};
  font-size: 12px;
`;

export const Media = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${DIPPER_BLUE};
  font-size: 12px;
  padding: 10px 20px 17px;
  border-top: 1px solid ${GRAY_COMMUNITY};

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 40px;
  }

  @media (max-Width: ${BOOSTRAP_MEDIUM}px) {
    flex-direction: row;
  }
`;

export const CountMedia = styled.div`
  display: flex;
  flex-direction: row;
  color: ${DIPPER_BLUE};
  font-size: 12px;

  p {
    font-weight: bold;
    margin: 0 18px 0 4px;
  }
  
  span {
    font-weight: bold;
    margin-left: 4px;
  }
  `;
