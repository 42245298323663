import styled from 'styled-components';

import {
  DIPPER_BLUE, GRAY_COMMUNITY, GRAY_REVIEW_DATE, GREEN_FEATURED, WHITE,
} from 'styles/colors';

import { ReactComponent as LocationIcon } from 'components/commons/assets/icon-location.svg';

export const JobPosition = styled.div`
  font: normal normal bold 15px/17px Mulish;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  width: 100%;
  display: block;
  padding-right: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ProfileImageContainer = styled.div`
  width: 50px;
  display: block;
`;

export const DateDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  font: normal normal normal 11px/13px Mulish;
  color: ${GRAY_REVIEW_DATE};
`;

export const EmployeeType = styled.div`
  display: flex;
  flex: 1;
  font: normal normal bold 11px/13px Mulish;
  margin-right: 5px;
`;

export const Featured = styled.div`
  font: normal normal 500 14px/18px Mulish;
  color: ${WHITE};
  background: ${GREEN_FEATURED};
  border: 1px solid ${GREEN_FEATURED};
  border-radius: 6px;
  margin: 0 5px;
  padding: 4px 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  margin-right: 5px;
`;

export const IconLocation = styled(LocationIcon)`
  margin-right: 4px;
  width: 8px;
  height: 12px;
`;

export const JobContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  color: ${DIPPER_BLUE};
  background: ${WHITE};
  border-radius: 8px;
  margin-bottom: 18px; 
`;

export const Location = styled.div`
  font: italic normal normal 11px/14px Mulish;
  word-break: break-word;
  overflow: hidden;
  color: ${GRAY_REVIEW_DATE};
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
  margin-bottom: 5px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  object-fit: cover;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const RowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  border-bottom: 1px solid ${GRAY_COMMUNITY};
  padding-bottom: 5px;
`;
