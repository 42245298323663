import * as constants from 'redux/constants/review.constants';
import { QUANTITY_REVIEWS_COMPANY_PROFILE } from 'constants/configs';

export function onReviewDelete(formData) {
  return {
    type: constants.REVIEW_ON_DELETE_REQUESTED,
    formData,
  };
}

export function onReviewGetRecentForCompany() {
  return {
    type: constants.REVIEW_GET_RECENT_BY_COMPANY_ID_REQUESTED,
  };
}

export function onReviewGetRecentForPoC() {
  return {
    type: constants.REVIEW_GET_RECENT_BY_USER_ID_REQUESTED,
  };
}

export function onReviewGetByCompanyId(
  companyId, quantity = QUANTITY_REVIEWS_COMPANY_PROFILE, page = 0,
) {
  return {
    type: constants.REVIEW_GET_BY_COMPANY_ID_REQUESTED,
    companyId,
    quantity,
    page,
  };
}

export function onReviewGetAllForCompany() {
  return {
    type: constants.REVIEW_GET_ALL_BY_COMPANY_ID_REQUESTED,
  };
}

export function onReviewGetAllForPoC() {
  return {
    type: constants.REVIEW_GET_ALL_BY_USER_ID_REQUESTED,
  };
}

export function onSetReviewSelected(review) {
  return {
    type: constants.REVIEW_SET_SELECTED_REQUESTED,
    review,
  };
}

export function onAddReview(formData) {
  return {
    type: constants.REVIEW_ON_ADD_REQUESTED,
    formData,
  };
}

export function onReviewAddAnswer(formData) {
  return {
    type: constants.REVIEW_ADD_ANSWER_REQUESTED,
    formData,
  };
}

export function onUpdateReview(formData) {
  return {
    type: constants.REVIEW_ON_UPDATE_REQUESTED,
    formData,
  };
}
