import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';

import Founders from 'components/Founders';
import LandingCard from 'components/LandingCard';
import Publications from 'components/Publications';
import LandingReview from 'components/LandingReview';
import Quotes from 'components/Quotes';

import Contact from './components/Contact';
import IlusInsight from './assets/ilus-insights@2x-min.png';
import IlusCommunity from './assets/ilus-community@2x-min.png';
import IlusJobs from './assets/ilus-jobs@2x-min.png';

import {
  LandingContainer, HeroContainer, Wrapper, Welcome, TextDiv, LogoDipper, Title, HeroButton,
  WhatIsDipperTable, WhatIsDipperDescription, WhatIsDipperCard, StarsImage, LandingSection,
  HowItWorksContainer, HowItWorksTable, HowItWorksCard, HowItWorksCommunityImage, HowItWorksText,
  HowItWorksEaseUseImage, HowItWorksFirstMarketImage, CorporateTable, TwoCards,
  StarImageFirstPanel, StarImageSecondPanel, StarImageFourthPanel, ButtonDiv, CustomButton,
  StyledText, FoundersContainer, QuotesContainer, Top, ButtonContainer,
  CustomH1, CustomH4, CustomH5,
} from './styles';

const renderInsightsText = () => (
  <>
    <CustomH1 hasMarginTop>
      Deeper insights
    </CustomH1>
    <StyledText>
      Would you describe your company as a great place to work?
    </StyledText>
    <StyledText>
      Do you believe your coworkers and management view you as a competent,
      {' '}
      valuable team member?
    </StyledText>
    <StyledText last>
      Plus more information on global companies!
    </StyledText>
  </>
);

const renderCommunityText = () => (
  <>
    <CustomH1 hasMarginTop>
      Community
    </CustomH1>
    <StyledText>
      A new, safe-space to connect and commiserate with your people.
    </StyledText>
    <StyledText>
      A live, private chat room where you can discuss what’s happening in
      {' '}
      the workplace today in real time.
    </StyledText>
    <StyledText last>
      A valued resource for advice, connection and education. Truly your place to shine.
    </StyledText>
  </>
);

const renderJobsText = () => (
  <>
    <CustomH1 hasMarginTop>
      Job Openings
    </CustomH1>
    <StyledText>
      Opportunities and Insights all in one place.
    </StyledText>
    <StyledText>
      Learn more about your peers’ true experiences while exploring available opportunities at
      {' '}
      evolving organizations.
    </StyledText>
    <StyledText last>
      New jobs are posted frequently. Why wait when a better workplace is possible today?
    </StyledText>
  </>
);

const Home = (props) => {
  const { isPortraitMenuOpened, onClickOutsideMenu, onSubmitContact } = props;
  const [autoPlayPublications, setAutoPlayPublications] = useState(false);
  const [autoPlayQuotes, setAutoPlayQuotes] = useState(false);

  return (
    <LandingContainer
      data-testid="home-page"
      isPortraitMenuOpened={isPortraitMenuOpened}
      onClick={onClickOutsideMenu}
    >
      <Top id="top" />
      <HeroContainer data-testid="div-hero">
        <Wrapper>
          <Welcome data-testid="div-welcome">
            <TextDiv>
              <LogoDipper className="dipper-logo" src={LogoDipper} data-testid="LogoDipper" alt="Dipper logo" />
              <Title>
                Guiding professionals of color
                to a better workplace, one
                review at a time.
              </Title>
              <ButtonContainer>
                <HeroButton href="/review/new">
                  Share your experience
                </HeroButton>
              </ButtonContainer>
            </TextDiv>
          </Welcome>
        </Wrapper>
      </HeroContainer>
      <LandingSection>
        <CustomH1>What is Dipper?</CustomH1>
        <WhatIsDipperTable>
          <WhatIsDipperDescription>
            <CustomH4>
              Dipper is an online, interactive community and safe-space for professionals
              of color to share their workplace experiences both past and present. We make
              it easy and safe for our members to openly communicate about their time at work.
              Whether good, bad or indifferent.
            </CustomH4>
            <CustomH5>
              Dipper is also a solutions platform.
              We provide an outlet for our members to not only build their network but
              also share resources and reach new heights in their careers.
            </CustomH5>
          </WhatIsDipperDescription>
          <WhatIsDipperCard>
            <p>
              Dipper provides consulting services in
              {' '}
              <span>diversity</span>
              ,
              {' '}
              <span>inclusion</span>
              {' '}
              and
              {' '}
              <span>workforce solutions</span>
              .
            </p>
            <StarsImage />
          </WhatIsDipperCard>
        </WhatIsDipperTable>
      </LandingSection>
      <HowItWorksContainer data-testid="div-how-it-works" id="how-it-works">
        <LandingSection>
          <CustomH1>How it Works</CustomH1>
          <HowItWorksTable>
            <HowItWorksCard firstCard>
              <HowItWorksCommunityImage />
              <HowItWorksText>
                Community
                <CustomH5>
                  With the prompt, “How’s work?”, Dipper members are compelled to
                  share and hear in real-time what’s going on in the workplace today.
                </CustomH5>
              </HowItWorksText>
            </HowItWorksCard>
            <HowItWorksCard>
              <HowItWorksEaseUseImage />
              <HowItWorksText>
                Ease of use
                <CustomH5>
                  Dipper members are able to give clear and concise insights on organizations
                  through our thoughtful surveys.
                </CustomH5>
              </HowItWorksText>
            </HowItWorksCard>
            <HowItWorksCard>
              <HowItWorksFirstMarketImage />
              <HowItWorksText>
                First to market
                <CustomH5>
                  A holistic platform for people of color, by people of color that guides PoC
                  to a better workplace one review at a time.
                </CustomH5>
              </HowItWorksText>
            </HowItWorksCard>
          </HowItWorksTable>
        </LandingSection>
      </HowItWorksContainer>
      <LandingSection avoidWebPaddingBotom>
        <CustomH1>Corporate Membership. Join Now! Here’s Why:</CustomH1>
        <CorporateTable>
          <TwoCards>
            <CustomH5>
              By joining Dipper, you&apos;ll secure a seat in our dynamic,
              change-making community, breaking down systemic gaps within the workplace!
              <StarImageFirstPanel />
            </CustomH5>
            <CustomH5>
              You will be at the forefront of a diverse and active C-Suite,
              management and associate level talent pool.
              <StarImageSecondPanel />
            </CustomH5>
          </TwoCards>
          <TwoCards avoidLastMargin>
            <CustomH5>
              Your employees will have access to a third party resource center where
              they can openly share their experiences. We call this our Retention Generator.
            </CustomH5>
            <CustomH5>
              You’ll have all data and insights needed to thoughtfully measure your
              company’s standing, how you can improve and where you shine.
              <StarImageFourthPanel />
            </CustomH5>
          </TwoCards>
        </CorporateTable>
        <ButtonDiv>
          <CustomButton href="/dashboard">
            View more info
          </CustomButton>
        </ButtonDiv>
      </LandingSection>
      <LandingSection data-testid="div-insights" avoidTabletPaddingTop>
        <LandingCard href="/insights" image={IlusInsight} renderText={renderInsightsText} />
      </LandingSection>
      <LandingReview />
      <LandingSection data-testid="div-community" avoidWebPaddingBotom>
        <LandingCard href="/feeds" image={IlusCommunity} renderText={renderCommunityText} />
      </LandingSection>
      <LandingSection data-testid="div-jobs" id="jobs" avoidTabletPaddingTop>
        <LandingCard hideButton href="/jobs" image={IlusJobs} isReverse renderText={renderJobsText} />
      </LandingSection>
      <FoundersContainer id="about">
        <LandingSection>
          <Founders />
        </LandingSection>
      </FoundersContainer>
      <Waypoint
        onEnter={() => setAutoPlayPublications(true)}
        onLeave={() => setAutoPlayPublications(false)}
      >
        <LandingSection data-testid="div-publications">
          <Publications autoPlay={autoPlayPublications} />
        </LandingSection>
      </Waypoint>
      <Waypoint
        onEnter={() => setAutoPlayQuotes(true)}
        onLeave={() => setAutoPlayQuotes(false)}
      >
        <QuotesContainer>
          <LandingSection data-testid="div-quotes">
            <Quotes autoPlay={autoPlayQuotes} />
          </LandingSection>
        </QuotesContainer>
      </Waypoint>
      <LandingSection data-testid="div-contact">
        <Contact onSubmit={onSubmitContact} />
      </LandingSection>
    </LandingContainer>
  );
};

Home.propTypes = {
  isPortraitMenuOpened: PropTypes.bool.isRequired,
  onClickOutsideMenu: PropTypes.func.isRequired,
  onSubmitContact: PropTypes.func.isRequired,
};

export default Home;
