import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { onGetAll } from 'redux/actions/insight.actions';
import { onEmptyCompanyState, onGetRank } from 'redux/actions/company.actions';
import {
  insightSelector, companySelector,
} from 'redux/selectors/insight.selector';
import { rankSelector } from 'redux/selectors/company.selector';
import { contactSelector } from 'redux/selectors/user.selector';

import {
  companyPropTypes, rankPropTypes, insightsArrayPropTypes, userPropTypes,
} from 'helpers/propTypes';
import { QUANTITY_REVIEWS_COMPANY_PROFILE } from 'constants/configs';
import { isCompany } from 'constants/roles';

import Layout from 'containers/Layout';
import Reviews from 'containers/Reviews';
import PremiumContactContainer from 'containers/PremiumContactContainer';
import CustomModal from 'components/commons/Modal';
import Company from 'views/Company';
import NoResultsFound from 'views/NoResultsFound';

import { Container } from 'styles/commons';

const InsightsContainer = (props) => {
  const {
    company, insights, onGetAllAction, onGetRankAction, onEmptyCompanyStateAction,
    user, rank,
  } = props;

  const [hasToRenderAllReviewDetails, setHasToRenderAllReviewDetails] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onCloseModal = () => setIsModalOpen(false);
  const onOpenModal = () => setIsModalOpen(true);

  const isMyCompany = user?.company?.code === company.code;

  useEffect(() => {
    if (company) {
      onGetAllAction(company.id);
      onGetRankAction(company.id);
      setHasToRenderAllReviewDetails(false);
    }
    return () => {
      onEmptyCompanyStateAction();
    };
  }, [company, onGetAllAction, onGetRankAction, onEmptyCompanyStateAction]);

  const handleScroll = (event) => {
    const { target } = event;
    if (target.scrollHeight - target.scrollTop <= target.clientHeight) {
      setPageNumber(pageNumber + 1);
    }
  };

  const renderReview = () => (
    <Reviews
      areReviewsFromProfile
      hasToRenderAllReviewDetails={hasToRenderAllReviewDetails}
      companyId={company.id}
      onClick={() => setHasToRenderAllReviewDetails(!hasToRenderAllReviewDetails)}
      quantity={QUANTITY_REVIEWS_COMPANY_PROFILE}
      pageNumber={hasToRenderAllReviewDetails && pageNumber + 1}
      companyCode={company.code}
    />
  );

  const renderView = () => {
    if (!company?.code) {
      return <NoResultsFound />;
    }
    return (
      <>
        <Company
          insights={insights}
          company={company}
          isCompanyUser={isCompany(user.role)}
          isPremiumUser={user.company?.isPremium}
          rank={rank}
          hasToRenderAllReviewDetails={hasToRenderAllReviewDetails}
          reviews={renderReview()}
          handleScroll={handleScroll}
          onOpenModal={onOpenModal}
          isMyCompany={isMyCompany}
        />
        <CustomModal
          isOpen={isModalOpen}
          onClose={onCloseModal}
        >
          <PremiumContactContainer
            onCloseModal={onCloseModal}
          />
        </CustomModal>
      </>
    );
  };

  return (
    <Container>
      <Layout isLandscape hideAddReview={user && isCompany(user.role)}>
        {!user ? <h1>Loading...</h1> : renderView()}
      </Layout>
    </Container>
  );
};

const mapStateToProps = (state, props) => ({
  company: companySelector(state, props),
  insights: insightSelector(state),
  rank: rankSelector(state),
  user: state.user.data,
  initialValuesContact: contactSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onGetAllAction: bindActionCreators(onGetAll, dispatch),
  onGetRankAction: bindActionCreators(onGetRank, dispatch),
  onEmptyCompanyStateAction: bindActionCreators(onEmptyCompanyState, dispatch),
});

InsightsContainer.defaultProps = {
  user: null,
  company: null,
};

InsightsContainer.propTypes = {
  insights: insightsArrayPropTypes.isRequired,
  onGetAllAction: PropTypes.func.isRequired,
  onGetRankAction: PropTypes.func.isRequired,
  onEmptyCompanyStateAction: PropTypes.func.isRequired,
  rank: rankPropTypes.isRequired,
  user: userPropTypes,
  company: companyPropTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsContainer);
